/**
 * @author: sergeyu
 * [-]
 */
angular.module('uetp')
    .component('personalAccount', {
        templateUrl: 'app/components/profile/personalAccount/personalAccount.html',
        bindings: {
            personalAccountData: '<',
            personalAccountList: '<',
            personalAccountView: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', '$log', 'httpSrv', 'profileSrv', 'authSrv', 'dialogSrv', 'cryptography',
            'alertSrv', '$filter', 'dictionarySrv', '$q', 'desktopSrv', 'timeout','paginationSrv',
            function ($scope, $rootScope, $state, $stateParams, $log, httpSrv, profileSrv, authSrv, dialogSrv, cryptography,
                      alertSrv, $filter, dictionarySrv, $q, desktopSrv, timeout, paginationSrv) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.isSupport = desktopSrv.getCabType() === 'support';
                    $scope.personalAccountData = this.personalAccountData || authSrv.getUser();
                    $scope.personalAccountView = this.personalAccountView;
                    $scope.personalAccountList = this.personalAccountList;
                    $scope.personalAccountProfileAccountant = false;
                    $scope.options = {};
                    $scope.modelView = {};
                    $scope.filter = {};
                    $scope.form = {};
                    $scope.hasMoneyMovingHistory = false;

                    $scope.appDoc = {};
                    $scope.appDoc.organization = {};
                    $scope.appDoc.account = {};
                    $scope.appDoc.recipient = {};

                    $scope.options.cashFlowInfo = false;
                    $scope.options.applicationRefund = false;
                    $scope.options.blockedFoundsSumInfo = false;
                    $scope.options.revise = false;
                    $scope.paginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    $scope.activeTab = $stateParams.activeProfileTab;
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    $scope.yearRevise = [{year:2018},{year:2019},{year:2020},{year:2021},{year:2022},
                        {year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
                    $scope.loadRevise($scope.paginator);
                    $scope.initAccount();

                };
                $scope.saveAppRef = saveAppRefFn;
                $scope.showApplicationRefund = showApplicationRefundFn;
                $scope.showWithDrawalOfFunds = showWithDrawalOfFundsFn;
                $scope.saveWithDrawalOfFunds = saveWithDrawalOfFundsFn;
                $scope.showCashFlowInfo = showCashFlowInfoFn;
                $scope.showRevise = showReviseFn;
                $scope.loadOperationsInfo = loadOperationsInfoFn;
                $scope.showBlockedFoundsSumInfo = showBlockedFoundsSumInfoFn;
                $scope.loadBlockedFoundsSumInfo = loadBlockedFoundsSumInfoFn;
                $scope.loadCashFlowInfo = loadCashFlowInfoFn;
                $scope.loadRevise = loadReviseFn;
                $scope.downloadRevise = downloadReviseFn;
                $scope.isExistAccount = isExistAccountFn;
                $scope.recChangeHandler = recChangeHandlerFn;
                $scope.changeSum = changeSumFn;
                //$scope.validateCertKSKP = validateCertKSKPFn;
                $scope.changeSort = changeSortFn;
                $scope.resetPaginator = resetPaginatorFn;
                $scope.refreshDict = function(object, value, refreshScrolling,needToAdd, searchValue) {
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling,null,needToAdd);
                };
                $scope.getTitle = getTitleFn;
                $scope.bikChangeHandler = bikChangeHandlerFn;
                $scope.showDialogRefundOperation = showDialogRefundOperationFn;
                $scope.onAccountChange = onAccountChangeFn;
                $scope.initAccount = initAccountFn;
                $scope.loadPayersList = loadPayersListFn;
                $scope.getRefundName = getRefundNameFn;
                $scope.downloadFile = downloadFileFn;
                $scope.loading = false;

                $scope.goBack = goBackFn;

                function goBackFn(){
                    history.back();
                }

                function getTitleFn() {
                    var title;
                    var wNds = 0;
                    var woutNds = 0;
                    if($scope.modelView.payers){
                        angular.forEach($scope.modelView.payers, function (payer) {
                            if (payer.ndsSumm) {
                                angular.forEach(payer.ndsSumm, function (ndsItem) {
                                    if (ndsItem.nds) {
                                        wNds += ndsItem.sum;
                                    } else {
                                        woutNds += ndsItem.sum;
                                    }
                                });
                            }
                        });
                    }
                    if($scope.personalAccountData && $scope.personalAccountData.account){
                        wNds = Math.min(wNds, $scope.personalAccountData.account.availableBalance);
                        woutNds = Math.min(woutNds, $scope.personalAccountData.account.availableBalance);
                    }
                    title = 'Свободные средства с НДС: ' + $filter('currency')(wNds, '₽') + "\nСвободные средства без НДС: " + $filter('currency')(woutNds, '₽');
                    return title;
                }

                function showDialogRefundOperationFn(operation) {
                   profileSrv.showDialogRefundOperation($scope, operation);
                }

                function showApplicationRefundFn() {
                    $scope.options.applicationRefund = !$scope.options.applicationRefund;
                    if ($scope.isSupport) appRefCreate();
                    else if ($scope.options.applicationRefund) {
                        delete $scope.bicNotFound;
                        var certId = $scope.personalAccountData.certId;
                        if (!certId) {
                            dialogSrv.showDialogMsgHtml($scope, 'В профиле пользователя не назначен сертификат для подписи!', "reqError");
                            $scope.options.applicationRefund = false;
                            return;
                        }
                        var certObj = '';
                        appRefCreate();
                        cryptography.getCertInfo(certId).then(function (value) {
                            certObj = value;
                            if (certObj == null) {
                                dialogSrv.showDialogMsg($scope, "Не выбран сертификат для подписи или выбранный сертификат не установлен!", "reqError");
                                $scope.options.applicationRefund = false;
                                return;
                            }
                            $scope.options.blockedFoundsSumInfo = false;
                            $scope.options.cashFlowInfo = false;
                            $scope.options.revise = false;
                            $scope.loadPayersList($scope.personalAccountData.account.accountType);
                        });

                    }

                    function appRefCreate() {
                        $scope.appRefView = false;
                        $scope.submitted = false;
                        $scope.appDoc = {};
                        $scope.appDoc.organization = {};
                        $scope.appDoc.creationDate = getServerTimeMsk();
                        $scope.appDoc.account = $scope.personalAccountData.account; // номер лицевого счёта=номер счета со вкладки "Лицевой счет"
                        $scope.appDoc.recipient = {}; //Заполняется данными при выборе получателя
                        $scope.appDoc.purpose = "Возврат средств с л/с " + ($scope.personalAccountData.account ? $scope.personalAccountData.account.accountId : '') + " по заявлению от " + $filter('date')($scope.appDoc.creationDate, 'dd.MM.yyyy') + " без налога НДC";
                        delete $scope.modelView.selectedPayer;
                        if ($scope.appDoc.recipient.bicBank) {
                            $scope.refreshDict('rBIK', $scope.appDoc.recipient.bicBank, false, true)
                        }
                        timeout($scope);
                    }
                }

                /**
                 * Загрузить список получателей для заявления на возврат
                 * */
                function loadPayersListFn(accountType) {
                    var paginator = {};
                    if ($scope.personalAccountData.account) {
                        paginator.searchByField = {accountId: $scope.personalAccountData.account.id};
                    }
                    profileSrv.loadPayersList(paginator,accountType).then(function (response) {
                        if (response.data.success) {
                            $scope.modelView.payers = response.data.items;
                        }
                    },function (reason) {
                        console.log(reason);
                    });
                }

                function saveWithDrawalOfFundsFn() {
                    saveAccountRefund($scope.accountRefundData).then(function (savedData) {
                        $scope.accountRefundData = savedData;
                        $scope.accountRefundIsNew = false;
                    });
                    $scope.accountRefundIsNew = false;
                }

                function saveAppRefFn() {
                    $scope.loading = true;
                    const bicBank = $scope.dictPaginated.rBIK.find(b => b.bic === $scope.appDoc.recipient.bicBank);
                    $scope.appDoc.recipient.bank = (bicBank || {}).participant + ', ' + (bicBank || {}).locality;
                    if ($scope.form.mainForm.$invalid) {
                        $scope.alertsaveAppRef = alertSrv.getErrorMsgWithBtn($scope.isSupport
                            ?'Поля заполнены некорректно!'
                            :'Заполните все обязательные поля или проверьте корректность их заполнения!'
                        );
                        $scope.submitted = true;
                        $scope.loading = false;
                    } else {
                        if($scope.appDoc.summ && $scope.modelView.maxSumm){
                            if(parseFloat($scope.appDoc.summ) > parseFloat($scope.modelView.maxSumm)){
                                $scope.alertsaveAppRef = alertSrv.getErrorMsg('Возвращаемая сумма не может быть больше '+$scope.modelView.maxSumm);
                                $scope.loading = false;
                                return;
                            }
                            if ($scope.isSupport) {
                                $scope.alertsaveAppRef = alertSrv.getSuccessMsg('Заявление на возврат денежных средств корректно!');
                                $scope.loading = false;
                            }
                            else saveAccountRefund($scope.appDoc).then(function (savedData) {
                                $scope.appDoc = savedData;
                                $scope.appRefView = true;
                            });

                        }
                    }
                }

                function saveAccountRefund(dataForSave) {
                    const result = $q.defer();
                    const certId = $scope.personalAccountData.certId;
                    if (!authSrv.getAuth().withoutCertEnabled) {
                        cryptography.initPluginWorking().then(function (plugin) {
                            plugin.findCertificate(certId).then(function (data) {
                                client.get('dict/serverTime', function (serverDateInt) {
                                    const serverDate = new Date(parseInt(serverDateInt));
                                    if (typeof data.ValidFromDate === "date") {
                                        if (_checkFromDate(serverDate, data.ValidFromDate) && _checkToDate(serverDate, data.ValidToDate)) {
                                            save(dataForSave, certId, result);
                                        } else {
                                            $scope.loading = false;
                                        }
                                    } else {
                                        data.ValidFromDate.then(function (fromDate) {
                                            _checkFromDate(serverDate, fromDate);
                                            data.ValidToDate.then(function (toDate) {
                                                if (_checkToDate(serverDate, toDate)) {
                                                    save(dataForSave, certId, result);
                                                } else {
                                                    $scope.loading = false;
                                                }
                                            });
                                        });
                                    }
                                });
                            }, function errorCallback(response) {
                                dialogSrv.showDialogMsgHtml($scope, 'В профиле пользователя не назначен сертификат для подписи!', "reqError");
                                result.reject(response);
                                $scope.loading = false;
                            });
                        }, function errorCallback(response) {
                            dialogSrv.showDialogMsgHtml($scope, 'Крипто-Про плагин не установлен!', "reqError");
                            result.reject(response);
                            $scope.loading = false;
                        });
                    }

                    return result.promise;

                    function _checkFromDate(serverDate, fromDate) {
                        if (serverDate < new Date(fromDate)) {
                            dialogSrv.showDialogMsgHtml($scope, 'Создание документа не доступно. Причина: cрок действия сертификата истек', "reqError");
                            return false;
                        }

                        return true;
                    }

                    function _checkToDate(serverDate, toDate) {
                        if (new Date(toDate) < serverDate) {
                            dialogSrv.showDialogMsgHtml($scope, 'Создание документа не доступно. Причина: cрок действия сертификата еще не наступил', "reqError");
                            return false;
                        }

                        return true;
                    }

                    function save(data, certId, result) {
                        delete $scope.saveRefMchdErrorMsg;
                        cryptography.getCertInfo(certId).then(function (certObj) {

                            data.certificate = certObj.base64Value;

                            return httpSrv.http({
                                method: 'POST',
                                url: '/auth/account/saveAccountRefund' + "/" + getAccType(),
                                data: data
                            }).then(function successCallback(response) {
                                if (response.data.success) {
                                    cryptography.generateSignature(response.data.result[0], $scope.personalAccountData.certId).then(function (xmlSign) {
                                        // var signObj = {message:value};
                                        data.signatureXml = xmlSign;
                                        cryptography.generateSignature(response.data.result[1], $scope.personalAccountData.certId).then(function (pdfSign) {
                                            data.signaturePdf = pdfSign;

                                            httpSrv.http({
                                                url: '/auth/account/addSignatuteToAccountRefunds/' + response.data.message,
                                                data: data,
                                                block: true
                                            }).then(function successCallback(response) {
                                                $scope.processing = false;
                                                if (response.data.success) {
                                                    profileSrv.loadOrganizationInfo().then(function (response) {
                                                        var user = response.data.result;
                                                        authSrv.setUser(user);
                                                        updateAccountView(user.id);
                                                        showApplicationRefundFn();
                                                        $scope.loading = false;
                                                    })
                                                } else {
                                                    showSaveError(response);
                                                }

                                            });

                                        }, function (reason) {
                                            if (typeof reason === 'string') {
                                                dialogSrv.showDialogMsgHtml($scope, 'Произошла ошибка при подписи pdf. ', reason);

                                            } else {
                                                dialogSrv.showDialogMsgHtml($scope, 'Произошла ошибка при подписи pdf. ', reason.name + ': ' + reason);
                                            }
                                            $scope.loading = false;
                                            result.reject(reason);
                                        })
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            dialogSrv.showDialogMsgHtml($scope, 'Произошла ошибка при подписи xml. ', reason);

                                        } else {
                                            dialogSrv.showDialogMsgHtml($scope, 'Произошла ошибка при подписи xml. ', reason.name + ': ' + reason);
                                        }
                                        $scope.loading = false;
                                        result.reject(reason);
                                    })

                                } else {
                                    if(['427','429','430','430_2'].includes(response.data.errorCode)) {
                                        $scope.saveRefMchdErrorMsg = response.data.errorMessage.replace('\n','<br/>');
                                    } else {
                                        showSaveError(response);
                                    }
                                    result.reject(response);
                                }
                            }, function errorCallback(response) {
                                console.log(response);
                                showSaveError(response);
                                result.reject(response);
                            });

                        }, function errorCallback(response) {
                            console.log(response);
                            dialogSrv.showDialogMsgHtml($scope, 'Произошла ошибка при получении данных сертификата!', "reqError");
                            $scope.loading = false;
                            result.reject(response);
                        });
                    }
                }

                function showSaveError(response) {
                    dialogSrv.showDialogMsg($scope, response.data.errorCode === '812' ?
                        'В процессе создания заявления на возврат возникла ошибка. Обратитесь в службу технической поддержки.' : (['427','429'].includes(response.data.errorCode) ? response.data.errorMessage : response.data.message));
                    $scope.loading = false;
                }

                function updateAccountView(orgId) {
                    profileSrv.loadAccountsList(orgId).then(function (response) {
                        if (!response.data.success) {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                            return;
                        }
                        $scope.personalAccountList = response.data.result;
                        $scope.initAccount();
                    });
                }

                function showWithDrawalOfFundsFn() {
                    $scope.options.withDrawalOfFunds = !$scope.options.withDrawalOfFunds;
                    if ($scope.options.withDrawalOfFunds) {
                        $scope.accountRefundIsNew = true;
                        createNewAccountRefundData();
                    }

                    function createNewAccountRefundData() {
                        $scope.accountRefundData = {};
                        $scope.accountRefundData.creationDate = getServerTimeMsk();
                        $scope.accountRefundData.bicBank = $scope.personalAccountData.requisites.bicBank;
                        $scope.accountRefundData.account = $scope.personalAccountData.account;
                        $scope.accountRefundData.bankName = $scope.personalAccountData.requisites.bicBank + ' ' + $scope.personalAccountData.requisites.bank;
                        $scope.accountRefundData.bankAcc = $scope.personalAccountData.requisites.bankAcc;
                        $scope.accountRefundData.accountReciepient = $scope.personalAccountData.requisites.acc;
                        $scope.accountRefundData.purpose = "Возврат средств с л/с " +  $scope.accountRefundData.account.id + " по заявлению от " + $filter('date')($scope.accountRefundData.creationDate, 'dd.MM.yyyy');
                    }
                }

                function showCashFlowInfoFn(control) {
                    if(control)
                        $scope.options.cashFlowInfo = !$scope.options.cashFlowInfo;
                    if ($scope.options.cashFlowInfo) {
                        $scope.options.blockedFoundsSumInfo = false;
                        $scope.options.applicationRefund = false;
                        $scope.options.revise = false;
                        $scope.filter = {};
                        resetPaginatorFn(control);
                        loadCashFlowInfoFn($scope.paginator);
                    }
                }
                /*
                * Показать сверку
                * */
                function showReviseFn() {
                    $scope.options.revise = !$scope.options.revise;
                    if ($scope.options.revise) {
                        $scope.options.blockedFoundsSumInfo = false;
                        $scope.options.applicationRefund = false;
                        $scope.options.cashFlowInfo = false;

                    }
                }
                function loadOperationsInfoFn() {
                    if($scope.options.cashFlowInfo){
                        showCashFlowInfoFn(false);
                    }else{
                        showBlockedFoundsSumInfoFn(false);
                    }
                }
                function showBlockedFoundsSumInfoFn(control) {
                    if(control)
                        $scope.options.blockedFoundsSumInfo = !$scope.options.blockedFoundsSumInfo;
                    if ($scope.options.blockedFoundsSumInfo) {
                        $scope.options.cashFlowInfo = false;
                        $scope.options.applicationRefund = false;
                        $scope.options.revise = false;
                        $scope.filter = {};
                        resetPaginatorFn(control);
                        loadBlockedFoundsSumInfoFn($scope.paginator);
                    }
                }

                function bikChangeHandlerFn(selectObj, dataObj) {
                    const selected = selectObj.$select.selected;
                    dataObj.bank = selected ? selected.participant : "";
                    dataObj.bankAcc = selected ? selected.accounts : "";
                    if (selected?.treasury) {
                        $scope.treasury = selected.treasury;
                    } else {
                        delete $scope.treasury;
                    }

                    if(!selected) return;
                    
                    const param = {
                        search: dataObj.bicBank,
                        itemsPerPage: 1000,
                        page: 0
                    };

                    dictionarySrv.loadPaginatedDict($scope, 'BIK', param, 'rBIK').then(function (res) {
                        if (res.items.length > 0) {
                            dataObj.bank = res ? `${res.items[0].participant}${res.items[0].locality ? ', ' + res.items[0].locality : ''}` : dataObj.bank;
                            $scope.form.mainForm.bicBank.$setValidity("bicBank", true);
                            delete $scope.bicNotFound;
                        } else {
                            $scope.form.mainForm.bicBank.$setValidity("bicBank", false);
                            $scope.bicNotFound = 'Банк с реквизитами ' + dataObj.bicBank + ' ' + dataObj.bank + ' отсутствует в актуальном справочнике БИК'
                        }
                        if ($scope.form.mainForm.recAcc) {
                            const validator = $scope.form.mainForm.recAcc.$parsers.filter(p=>p.name==='checkValidity');
                            if (validator && validator.length>0) {
                                validator[0]($scope.appDoc.recipient.acc);
                                if ($scope.treasury) {
                                    $scope.form.mainForm.recAcc.$setValidity("parse", true);
                                }
                            }
                        }
                    });
                }

                function resetPaginatorFn(control) {
                    if(control) {
                        //$scope.paginator.itemsPerPage = 20;
                        $scope.paginator.page = 0;

                        $scope.totalPages = 1;
                    }
                    delete $scope.paginator.search;
                    delete $scope.paginator.searchByField;
                }

                function loadBlockedFoundsSumInfoFn(paginator) {
                    const filter = {};
                    filter.operationType = 'blocking';
                    filter.accountId = $scope.personalAccountData.account.id;
                    paginator.searchByField = filter;
                    profileSrv.loadBlockedFoundsSumInfo(angular.copy(paginator)).then(function (response) {
                        if (!response.data.success) {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                            return;
                        }
                        $scope.operations = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / paginator.itemsPerPage);
                    })
                }

                function loadCashFlowInfoFn(paginator) {
                    const filter = {};
                    const configPag = paginationSrv.getPaginationStateConfig('pagination', $state.current.name);
                    if (configPag) {
                        $scope.paginator.page = configPag.page;
                        $scope.paginator.itemsPerPage = configPag.itemsPerPage;
                    }
                    filter.accountId = $scope.personalAccountData.account.id;
                    paginator.searchByField = filter;
                    profileSrv.loadCashFlowInfo(angular.copy(paginator)).then(function (response) {
                        if (!response.data.success) {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                            return;
                        }
                        $scope.operations = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / paginator.itemsPerPage);
                    });
                }
                /*
                * Проверяем наличие операций по свертке
                * */
                function loadReviseFn(paginator) {
                    if($scope.personalAccountData.account) {
                        const filter = {};
                        filter.accountId = $scope.personalAccountData.account.id;
                        filter.operationType = 'refillOrder';
                        paginator.searchByField = filter;
                        profileSrv.loadRevise(angular.copy(paginator)).then(function (response) {
                            if (!response.data.success) {
                                dialogSrv.showDialogMsg($scope, response.data.message);
                                return;
                            }
                            $scope.hasMoneyMovingHistory = response.data.total>0;
                            $scope.revise = response.data.total
                        });
                    }
                }

                function isExistAccountFn() {
                    return $scope.personalAccountData.account;
                }
                function downloadReviseFn() {
                    var reportData = {
                        orgId: $scope.personalAccountData.id,
                        orgName: $scope.personalAccountData.fullName,
                        year: $scope.modelView.year.year,
                        accountId: $scope.personalAccountData.account.id
                    };
                    var url = '/accounts/account/downloadOperationLockInfo';
                    httpSrv.downloadFile(url, 'Сверка_'+$scope.modelView.year.year+'_'+$filter('date')(new Date(), "dd.MM.yyyy")+'.pdf',reportData);
                }
                /*
                * Изменения поля суммы
                * */
                function changeSumFn(sum, appDoc) {
                    if(sum){
                        //высчитываем сумму НДС
                        if(appDoc.withoutNDS){
                            appDoc.ndsSumm = 0;
                        } else {
                            var nds = angular.copy(appDoc.nds);
                            if(typeof nds === "string")  nds = parseFloat(nds);
                            appDoc.ndsSumm =  (sum * nds / (nds + 100)).toFixed(2);
                            updatePurpose();
                        }
                    }
                }

                function fillNDS(selected){
                    $scope.appDoc.summ = selected.sum > $scope.personalAccountData.account.availableBalance ?
                                         $scope.personalAccountData.account.availableBalance :
                                         selected.sum;
                    $scope.modelView.maxSumm = $scope.appDoc.summ;//сохраняем сумму - больше этой суммы нельзя назначить
                    $scope.form.mainForm.sum.$setViewValue($scope.appDoc.summ);
                    $scope.appDoc.ndsSumm = selected.sumNds;
                    updatePurpose();
                }

                function updatePurpose(){
                    if($scope.appDoc.nds && $scope.appDoc.nds > 0){
                        $scope.appDoc.purpose = "Возврат средств с л/с " + ($scope.personalAccountData.account ? $scope.personalAccountData.account.accountId : '') + " по заявлению от " + $filter('date')($scope.appDoc.creationDate, 'dd.MM.yyyy') + " в т.ч. НДС " + $scope.appDoc.nds + "% " + $scope.appDoc.ndsSumm + " руб.";
                    } else {
                        $scope.appDoc.purpose = "Возврат средств с л/с " + ($scope.personalAccountData.account ? $scope.personalAccountData.account.accountId : '') + " по заявлению от " + $filter('date')($scope.appDoc.creationDate, 'dd.MM.yyyy') + " без налога НДC";
                    }
                }

                function recChangeHandlerFn(selectObj, dataObj) {
                    var selected = selectObj.$select.selected;
                    if(selected) {
                        $scope.modelView.selectedPayer = selected;

                        dataObj.recipient = {
                            inn: selected ? selected.inn : "",
                            kpp: selected && selected.kpp !== '0' ? selected.kpp : "",
                            acc: selected ? selected.acc : "",
                            bank: selected ? selected.bank : "",
                            bicBank: selected ? selected.bicBank : "",
                            bankAcc: selected ? selected.bankAcc : "",
                            name: selected ? selected.name : ""
                        };

                        if (dataObj.recipient.acc) {
                            if ($scope.form && $scope.form.mainForm && $scope.form.mainForm.recAcc) {
                                $scope.form.mainForm.recAcc.$setViewValue(dataObj.recipient.acc);
                            }
                            $scope.appDoc.recipient = dataObj.recipient;
                        }
                        if (dataObj.recipient.bicBank) {
                            //подгружаем значения в справочник для отображения
                            const param = {
                                search: dataObj.recipient.bicBank,
                                itemsPerPage: 1,
                                page: 0
                            };

                            dictionarySrv.loadPaginatedDict($scope, 'BIK', param, 'rBIK').then(function (res) {
                                if (res.items.length > 0) {
                                    $scope.appDoc.recipient.bankAcc = res.items[0].accounts;
                                    $scope.appDoc.recipient.bank = (res.items[0].participant + ', ' + res.items[0].locality);
                                    $scope.form.mainForm.bicBank.$setValidity("bicBank", true);
                                    delete $scope.bicNotFound;
                                } else {
                                    const bic = $scope.appDoc.recipient.bicBank;
                                    delete $scope.appDoc.recipient.bicBank;
                                    $scope.form.mainForm.bicBank.$setValidity("bicBank", false);
                                    $scope.bicNotFound = 'Банк с реквизитами ' + bic + ' ' + dataObj.recipient.bank + ' отсутствует в актуальном справочнике БИК'
                                }
                            });
                        }

                        if(selected.ndsSumm && selected.ndsSumm.length === 1){
                            $scope.appDoc.nds = selected.ndsSumm[0].nds;
                            fillNDS(selected.ndsSumm[0]);
                        } else {
                            $scope.appDoc.nds = null;
                            $scope.appDoc.summ = null;
                        }
                        //Если 'рекомендуемая к возврату сумма' = 0, проставляем 0, блокируем поле и форму
                        $scope.modelView.isZeroSumm = $scope.modelView.selectedPayer.sumRefund === 0;
                    }else{
                        //сбрасываем поля сумма и НДС
                        delete dataObj.summ;
                        delete dataObj.ndsSumm;
                        $scope.modelView.isZeroSumm = false;
                        delete $scope.modelView.maxSumm;
                        delete $scope.modelView.selectedPayer;
                    }
                }

                function changeSortFn($event) {
                    var th = $event.currentTarget,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
                    if (orderBy) {
                        $scope.paginator.page = 0;
                        $scope.paginator.orderBy = orderBy;
                        $scope.paginator.orderAsc = orderAsc;
                        if ($scope.options.cashFlowInfo) loadCashFlowInfoFn($scope.paginator);
                        else if ($scope.options.blockedFoundsSumInfo) loadBlockedFoundsSumInfoFn($scope.paginator);
                    }
                }

                function onAccountChangeFn(){
                    $scope.options.cashFlowInfo = false;
                    $scope.options.applicationRefund = false;
                    $scope.options.blockedFoundsSumInfo = false;
                    $scope.options.revise = false;
                    $scope.appDoc = {};
                    delete $scope.modelView.maxSumm;
                    delete $scope.modelView.selectedPayer;
                    $scope.loadRevise($scope.paginator);
                    $scope.loadPayersList($scope.personalAccountData.account.accountType);
                }
                function initAccountFn(){
                    if($scope.personalAccountList&&$scope.personalAccountList.length>0){
                        for (var i = 0; i < $scope.personalAccountList.length; i++) {
                            if($scope.personalAccountList[i].accountType === 'atcom'){
                                $scope.personalAccountData.account = $scope.personalAccountList[i];
                            }
                        }
                        $scope.loadPayersList($scope.personalAccountData.account.accountType);
                        $scope.personalAccountProfileAccountant = $scope.cabType() === 'accountant'
                    }

                }

                function getAccType(){
                    return $scope.personalAccountData.account.accountType || "comita";
                }

                function getRefundNameFn(appDoc){
                    if (!appDoc) return 'Refund.zip';
                    return 'Refund_'+appDoc.number+'_'+appDoc.creationDate.replace(/-/g,'.')+'.zip';
                }

                function downloadFileFn(url, fileName) {
                    httpSrv.downloadFile(url, fileName);
                }
                $scope.excludeZeroComita = (el) => !(el.availableBalance===0 && el.id.indexOf('comita')!==-1)
                $scope.isSingleOperator = () => $scope.personalAccountList.filter(el=>$scope.excludeZeroComita(el)).length===1
            }]
    });

