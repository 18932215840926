/**
 * Проверки полей по типу указанной валидации в них (validation)
 */
var EMAIL_VALID_REG_EXP = /^[a-zA-Zа-яА-ЯёЁ0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Zа-яА-ЯёЁ0-9-]+(?:\.[a-zA-Zа-яА-ЯёЁ0-9-]{2,})+(?:\.[a-zA-Zа-яА-ЯёЁ0-9-]{2,})?$/,
    GUID_VALID_REG_EXP = /^[a-fA-F0-9]{8}\-[a-fA-F0-9]{4}\-[a-fA-F0-9]{4}\-[a-fA-F0-9]{4}\-[a-fA-F0-9]{12}$/;

angular.module('uetp').directive('validation', ['timeout', 'onlineCheckSrv', '$parse', '$rootScope', 'systemMessages',
    function (timeout, onlineCheck, $parse, $rootScope, systemMessages) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elem, attr, ctrl) {
                var validationType = attr['validation'],
                    validationParam = attr['param'],
                    dateOptions = $parse(attr['datepickerOptions'])(scope),
                    userId = attr['userid'],
                    name = elem[0].name,
                    error;

                //Новый код
                //------------------------------------------------------------------------------------------------------
                var valids = {

                    //Валидатор УИН
                    uinOnlineCheck: {
                        formatter: true,
                        validator: function () {
                            setTimeout(function () {//tmp fix
                                if (ctrl.$valid) {
                                    var url = "/auth/registration/validateInn",
                                        err = {};
                                    onlineCheck(url, err, {
                                        param: elem[0].value,
                                        message: validationParam
                                    }).then(function () {
                                        elem[0].title = err.value ? err.title : "";
                                        ctrl.$setValidity(name, !err.value);
                                    }, function () {
                                    });
                                }
                            }, 200);
                        }
                    }

                    //...
                };

                //Выбор валидатора по типу
                if (valids[validationType]) {
                    ["change", "blur", "input"].forEach(function (event) {
                        elem[0].addEventListener(event, valids[validationType].validator);
                    });
                }
                //------------------------------------------------------------------------------------------------------

                if (validationType == 'emailOnlineCheck' || validationType == 'emailOnlineCheckSecured' || validationType == 'emailOnlineCheckSecuredCert') {
                    var events = ["change", "blur", "input"];
                    for (var eventIdx in events) {
                        elem[0].addEventListener(events[eventIdx], function (event) {
                            var emailValue = event.target.value,
                                errorObj = validateEmail(emailValue);
                            if (!errorObj.value) {
                                var url = "/auth/registration/validateMail",
                                    err = {};
                                if (validationType == 'emailOnlineCheckSecured') url += 'Secured';

                                if (elem[0].value) {
                                    //берем из $$attr тк при link еще не успело все прогрузиться
                                    onlineCheck(url, err, {
                                        param: elem[0].value,
                                        id: ctrl.$$attr.userid ? ctrl.$$attr.userid : null
                                    }).then(function () {
                                        if (elem[0].value.endsWith('.rzd')) {
                                            elem[0].title = 'Указанный адрес электронной почты является внутренним для сети ОАО "РЖД", и на него не могут быть отправлены письма из сети Интернет. Введите внешний адрес электронной почты!';
                                            ctrl.$setValidity(name, false);
                                        } else {
                                            elem[0].title = err.value ? err.title : "";
                                            ctrl.$setValidity(name + '_inbase', err.value ? false : true);
                                        }

                                    }, function () {
                                    });
                                }
                            }
                        })
                    }
                }

                function validateEmail(value, validationParam) {
                    var error = {};
                    error.value = !(EMAIL_VALID_REG_EXP.test(value)) && value != "";
                    error.title = "Неверно указан email";
                    if(validationParam) {
                        $rootScope.$emit(validationParam, value, error);
                    }
                    return error;
                }

                function validateGuid(value) {
                    var error = {};
                    error.value = !(GUID_VALID_REG_EXP.test(value)) && value != "";
                    if(error.value)
                        error.title = "Неверно указан идентификатор";
                    return error;
                }

                if (validationType == 'loginOnlineCheck') {
                    var events = ["change", "blur", "input"];
                    for (var eventIdx in events) {
                        elem[0].addEventListener(events[eventIdx], function () {
                            setTimeout(function () {//tmp fix
                                if (!ctrl.$invalid) {
                                    var url = "/auth/registration/validateLogin",
                                        err = {};
                                    onlineCheck(url, err, {
                                        loginName: elem[0].value,
                                        id: userId ? userId : null
                                    }).then(function () {
                                        elem[0].title = err.value ? err.title : "";
                                        ctrl.$setValidity(name, err.value ? false : true);
                                    }, function () {
                                    });
                                }
                            }, 200);
                        })
                    }
                }
                if (validationType == 'codeOnlineCheck') {
                    var events = ["change", "blur", "input"];
                    for (var eventIdx in events) {
                        elem[0].addEventListener(events[eventIdx], function () {
                            setTimeout(function () {//tmp fix
                                if (!ctrl.$invalid) {
                                    var url = "/auth/registration/validateCode",
                                        err = {};
                                    onlineCheck(url, err, {
                                        code: elem[0].value,
                                        id: userId ? userId : null
                                    }).then(function () {
                                        elem[0].title = err.value ? err.title : "";
                                        ctrl.$setValidity(name, err.value ? false : true);
                                    }, function () {
                                    });
                                }
                            }, 200);
                        })
                    }
                }
                // при вводе текста поле становится валидным.(Испольуется для снятия ошибки при ее ручном выставлении )
                if (validationType === 'inputChange') {
                    elem[0].addEventListener("change", function () {
                        if (!ctrl.$invalid) {
                            ctrl.$setValidity(name, true);
                        }
                    })
                }
                if (validationType === 'isAccountValidBik') {//tmp - select change event issue
                    if (!validationParam) {
                        return elem[0].value;
                    }
                    const selectModel = elem[0].getAttribute("ng-model"),
                          isulbudget = elem[0].getAttribute("isulbudget"),
                          error = {};
                    if (!$rootScope.tmpWatch) {
                        $rootScope.tmpWatch = {};
                    }
                    if ($rootScope.tmpWatch[selectModel]) {
                        $rootScope.tmpWatch[selectModel]();
                    }
                    $rootScope.tmpWatch[selectModel] = scope.$watch(selectModel, (value) => {
                        const accountField = document.querySelector("input[ng-model='" + validationParam + "']"),
                            accountValue = accountField ? accountField.value : undefined;
                        if (!accountField) {
                            return value;
                        }
                        error.value = checkAccountValue(accountValue.toString(), selectModel);
                        error.title = accountField.title = error.value ? "Введенный расчетный счет не прошел проверку по контрольным цифрам!" : "";
                        if ((isulbudget === 'true' ? ['appDoc.recipient.acc'] : ['modelView.dealParticipant.acc', 'appDoc.recipient.acc']).includes(validationParam)) {
                            scope.form.mainForm[accountField.name].$setValidity(accountField.name, !error.value);
                            scope.form.mainForm[accountField.name].$setValidity("parse", !error.value);
                            scope.form.mainForm[accountField.name].$setDirty();
                        } else if(validationParam === 'model.lotData.depositPaymentDetails.account') {
                        	if (!accountValue) {
                                return value;
                            }
                            scope.lots.form[accountField.name].$setValidity(accountField.name, !error.value);
                            scope.lots.form[accountField.name].$setValidity("parse", !error.value);
                            scope.lots.form[accountField.name].$setDirty();
                        } else if(validationParam === 'user.requisites.acc') {
                            scope.form.registerForm[accountField.name].$setValidity(accountField.name, !error.value);
                            scope.form.registerForm[accountField.name].$setValidity("parse", !error.value);
                            scope.form.registerForm[accountField.name].$setDirty();
                        } else if(scope.form.profileForm) {
                            scope.form.profileForm[accountField.name].$setValidity(accountField.name, !error.value);
                            scope.form.profileForm[accountField.name].$setValidity("parse", !error.value);
                            scope.form.profileForm[accountField.name].$setDirty();
                        }
                    });
                }
                ctrl.$formatters.unshift(checkValidityF);
                ctrl.$parsers.unshift(checkValidity);

                function checkValidityF(modelValue) {
                    error = {};

                    if (valids[validationType] && valids[validationType].formatter) {
                        valids[validationType].validator();
                    }

                    if (validationType === 'isKPPvalid' && modelValue) {
                        error.value = !(/^[\d]{9}$/.test(modelValue) || modelValue.length == 0);
                        error.title = "КПП должен представлять собой девятизначный цифровой код";
                        setValidity();
                    }
                    if (validationType === 'isKPPvalidAllowZero' && modelValue) {
                        if(modelValue !== '0') {
                            error.value = !(/^[\d]{9}$/.test(modelValue) || modelValue.length == 0);
                            error.title = "КПП должен представлять собой девятизначный цифровой код";
                            setValidity();
                        }
                    }
                    if (validationType === 'isDateTimeValid' && modelValue) {
                        var isMsk = attr['isMsk'];

                        var modelValueVar = modelValue.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
                        if(isMsk){
                            error.value = !(!modelValue || new Date(modelValueVar) >= getServerTimeMsk());
                        }else {
                            error.value = !(!modelValue || new Date(modelValueVar) >= new Date());
                        }
                        error.title = "Введена некорректная дата";

                        // Если есть ограничение по дате
                        if (dateOptions.maxDate && !error.value) {
                            error.value = !(new Date(modelValueVar) < dateOptions.maxDate);
                            error.title = "Не более " + dateOptions.term + " дней";
                        }
                        setValidity();
                    }
                    if (validationType === 'isDateOptionValid' && modelValue) {

                        var modelValueVar = modelValue.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');

                        // Если есть ограничение по дате
                        if (dateOptions.maxDate && !error.value) {
                            error.value = !(new Date(modelValueVar) <=new Date(dateOptions.maxDate));
                            error.title = "Введена некорректная дата";
                        }
                        if (dateOptions.minDate && !error.value) {
                            error.value = !(new Date(modelValueVar) >=new Date(dateOptions.minDate));
                            error.title = "Введена некорректная дата";
                        }
                        setValidity();
                    }
                    if (validationType === 'emailOnlineCheckSecuredCert' || validationType === 'emailOnlineCheck') {
                        var url = "/auth/registration/validateMail",
                            err = {};
                        if (validationType === 'emailOnlineCheckSecuredCert') url += 'Secured';

                        ctrl.$setValidity(name+'_inbase', true);

                        setTimeout(function () {//tmp fix
                            if (elem[0].value) {
                                onlineCheck(url, err, {
                                    param: elem[0].value,
                                    id: ctrl.$$attr.userid ? ctrl.$$attr.userid : null
                                }).then(function () {
                                    if (validationType === 'emailOnlineCheckSecuredCert') {
                                        elem[0].title = err?.value ? err?.title : "";
                                        // ctrl.$setValidity(name, err.value ? false : true);
                                        ctrl.$setValidity(elem[0].name, !err?.value);
                                        ctrl.$setDirty(elem[0].name, true);
                                    } else {
                                        if (elem[0].value.endsWith('.rzd')) {
                                            elem[0].title = 'Указанный адрес электронной почты является внутренним для сети ОАО "РЖД", и на него не могут быть отправлены письма из сети Интернет. Введите внешний адрес электронной почты!';
                                            ctrl.$setValidity(name, false);
                                        } else {
                                            elem[0].title = err?.value ? err.title : "";
                                            ctrl.$setValidity(name + '_inbase', !err?.value);
                                        }
                                    }
                                }, function () {
                                });
                            }
                        }, 200);

                    }
                    if (validationType === 'isDateTimeValidMsk' && modelValue) {
                        error.value = getDatetimeStatusMsk(modelValue) === -1;
                        error.title = systemMessages.INCORRECT_DATETIME;
                        if (dateOptions.maxDate && !error.value) {
                            error.value = modelValue >= dateOptions.maxDate;
                            error.title = "Не более " + dateOptions.term + " дней";
                        }
                        if(error.value){
                           elem[0].title = error.title;
                           ctrl.$setTouched();
                           ctrl.$setValidity(elem[0].name, false);
                        }
                    }
                    if (validationType === 'isValueNoLessThanValid' && modelValue) {
                    	if (!validationParam) return modelValue;
                    	error.value = parseFloat(modelValue.replace(/\s/g, "")) < parseFloat(validationParam);
                        error.title = "Значение не должно быть менее " + validationParam + ".";

                        if(error.value){
                            elem[0].title = error.title;
                            ctrl.$setTouched();
                            ctrl.$setValidity(elem[0].name, false);
                         }
                    }
                    if (validationType == 'isAccountValidType' && modelValue) {
                        error.value = checkAccountValue(modelValue.toString(), validationParam);
                        error.title = "Введенный расчетный счет не прошел проверку по контрольным цифрам!";
                        setValidity();
                    }
                    return modelValue;
                }

                function checkValidity(value) {
                    error = {};
                    if (!validationType || validationType == 'onlineCheck') return;
                    if (validationType === 'isINNvalid') {
                        if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Введённый ИНН не является числом";
                        } else if (value.length !== 0 && value.length !== 12 && value.length !== 10) {
                            error.value = true;
                            error.title = "Указанный ИНН не прошел логический контроль";
                        } else if (value.length === 10) {
                            var dgt10 = String(((
                                2 * value[0] + 4 * value[1] + 10 * value[2] +
                                3 * value[3] + 5 * value[4] + 9 * value[5] +
                                4 * value[6] + 6 * value[7] + 8 * value[8]) % 11) % 10);
                            if (value[9] !== dgt10) {
                                error.value = true;
                                error.title = "Введённый ИНН не прошёл проверку по контрольным цифрам";
                            }
                        } else if (value.length === 12) {
                            var dgt11 = String(((
                                7 * value[0] + 2 * value[1] + 4 * value[2] +
                                10 * value[3] + 3 * value[4] + 5 * value[5] +
                                9 * value[6] + 4 * value[7] + 6 * value[8] +
                                8 * value[9]) % 11) % 10);
                            var dgt12 = String(((
                                3 * value[0] + 7 * value[1] + 2 * value[2] +
                                4 * value[3] + 10 * value[4] + 3 * value[5] +
                                5 * value[6] + 9 * value[7] + 4 * value[8] +
                                6 * value[9] + 8 * value[10]) % 11) % 10);
                            if (value[10] !== dgt11 || value[11] !== dgt12) {
                                error.value = true;
                                error.title = "Введённый ИНН не прошёл проверку по контрольным цифрам";
                            }
                        }
                    }
                    if (validationType === 'isOKPOValid') {
                        const orgType = elem[0].attributes.orgtype?.nodeValue;
                        if (!value || !value.length || !orgType && (value.length!=10 && value.length!=8) ||
                            orgType && (orgType==='ul' && value.length!=8 || orgType==='ip' && value.length!=10)) {
                            error.value = true;
                            error.title = "Указанный ОКПО не прошел логический контроль";
                        } else if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Введённый ОКПО не является числом";
                        } else {
                            var okpoCheckValue = value,
                                sum = 0;
                            //while (okpoCheckValue.length<10)    okpoCheckValue = "0"+okpoCheckValue;
                            for (var idx = 1; idx < okpoCheckValue.length; idx++) {
                                sum = sum + idx * Number(okpoCheckValue.charAt(idx - 1));
                            }
                            var code = sum % 11;
                            if (code == 10) {
                                sum = 0;
                                for (var idx = 1; idx < okpoCheckValue.length-1; idx++) {
                                    sum = sum + (idx + 2) * Number(okpoCheckValue.charAt(idx - 1));
                                }
                                var multiplier = okpoCheckValue.length == 8 ? 9 : 1;
                                sum = sum + multiplier * Number(okpoCheckValue.charAt(okpoCheckValue.length - 2));
                                code = sum % 11;
                                if (code == 10) code = 0;

                            }
                            error.value = okpoCheckValue.charAt(okpoCheckValue.length - 1) != code;
                            error.title = "Указанный ОКПО не прошел логический контроль";
                        }
                    }
                    if (validationType === 'isOKTMOValid') {
                        if (!value || !value.length || (value.length!=8 && value.length!=11)) {
                            error.value = true;
                            error.title = "Указанный ОКТМО не прошел логический контроль";
                        } else if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Введённый ОКТМО не является числом";
                        }
                    }
                    if (validationType === 'isPwdValid') {
                        error.value = !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9\/\!\?\.\,\~\'\^\*\_\+\=\;\:\?\|\@\#\$\&\%\_\\\-\{\}\(\)\[\]]{8,}$/.test(value);
                        error.title = "Введенный пароль не соответствует описанным выше требованиям!";
                        var confirmPwdField = document.querySelector("input[ng-model='" + validationParam + "']"),
                            confirmPwd = confirmPwdField.value || null;
                        if (confirmPwd && value) {
                            confirmPwdField.title = confirmPwd != value ? "Пароли не совпадают" : "";
                            scope.form.pwdForm[confirmPwdField.name].$setValidity(confirmPwdField.name, confirmPwd == value);
                            scope.form.pwdForm[confirmPwdField.name].$setValidity("parse", confirmPwd == value);
                            scope.userPassword.newPassword1 = confirmPwd;                                         //allow-invalid issue
                            scope.form.pwdForm[confirmPwdField.name].$setDirty();
                        }
                    }
                    if (validationType === 'isPwdRepeatValid') {
                        error.value = !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9\/\!\?\.\,\~\'\^\*\_\+\=\;\:\?\|\@\#\$\&\%\_\\\-\{\}\(\)\[\]]{8,}$/.test(value);
                        error.title = "Введенный пароль не соответствует описанным выше требованиям!";
                        var newPwd = $parse(validationParam)(scope);
                        if (!error.value) {
                            error.value = value != newPwd;
                            error.title = "Пароли не совпадают";
                        }
                    }
                    if (validationType === 'isINNvalidType') {
                        const innType = elem[0].attributes.inntype.nodeValue;
                        if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Введённый ИНН не является числом";
                        } else if (value.length !== 0 && value.length !== 12 && value.length !== 10 && value.length !== 5) {
                            error.value = true;
                            error.title = "Указанный ИНН не прошел логический контроль";
                        } else if (value.length === 10 && (innType === '1' || innType === 'ul' || innType === 'variable')) {
                            var dgt10 = String(((
                                2 * value[0] + 4 * value[1] + 10 * value[2] +
                                3 * value[3] + 5 * value[4] + 9 * value[5] +
                                4 * value[6] + 6 * value[7] + 8 * value[8]) % 11) % 10);
                            if (value[9] !== dgt10) {
                                error.value = true;
                                error.title = "Введённый ИНН не прошёл проверку по контрольным цифрам";
                            }
                        } else if (value.length === 12 && (innType === '2' || innType === 'ip' || innType === '3' || innType === 'fl' || innType === 'variable')) {
                            var dgt11 = String(((
                                7 * value[0] + 2 * value[1] + 4 * value[2] +
                                10 * value[3] + 3 * value[4] + 5 * value[5] +
                                9 * value[6] + 4 * value[7] + 6 * value[8] +
                                8 * value[9]) % 11) % 10);
                            var dgt12 = String(((
                                3 * value[0] + 7 * value[1] + 2 * value[2] +
                                4 * value[3] + 10 * value[4] + 3 * value[5] +
                                5 * value[6] + 9 * value[7] + 4 * value[8] +
                                6 * value[9] + 8 * value[10]) % 11) % 10);
                            if (value[10] !== dgt11 || value[11] !== dgt12) {
                                error.value = true;
                                error.title = "Введённый ИНН не прошёл проверку по контрольным цифрам";
                            }
                        } else if (value.length){
                            error.value = true;
                            error.title = "Введённый ИНН не прошёл проверку";
                        }
                    }
                    if(validationType === 'isOKOPFvalidType') {
                        if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Введённый ОКОПФ не является числом";
                        } else if (value.length !== 0 && value.length !== 5) {
                            error.value = true;
                            error.title = "Указанный ОКОПФ должен быть 5-значным";
                        }
                    }
                    if(validationType === 'isOKFSFvalidType') {
                        if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Введённый ОКФС не является числом";
                        } else if (value.length !== 0 && value.length !== 2) {
                            error.value = true;
                            error.title = "Указанный ОКФС должен быть 2-значным";
                        }
                    }
                    if (validationType === 'isTelValid') {
                        if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Допустим ввод только цифр";
                        } else if (value.length > 30) {
                            error.value = true;
                            error.title = "Указанный телефон не прошел логический контроль";
                        }
                    }
                    if (validationType === 'isNameRValid') {
                        error.value = !/^[^A-z]*$/.test(value);
                        error.title = "В наименовании присутствуют английские буквы";
                    }
                    if (validationType === 'isIntValueValid') {
                        let val = !Number.isNaN(value) && Math.abs(value)<=2147483647;
                        if(!val) {
                            error.value = !val;
                            error.title = "Превышено максимально возможное значение для типа";
                        }
                    }
                    if (validationType && validationType.indexOf('isOgrnValid') != -1) {
                        if (/\D/.test(value)) {
                            error.value = true;
                            error.title = "Введённый ОГРН не является числом";
                        } else if (value.length !== 0 && value.length !== 15 && value.length !== 13) {
                            error.value = true;
                            error.title = "ОГРН состоит из "+elem.attr('maxlength')+" цифр";
                        } else if (value.length === 13) {
                            var dgt13, num12, arrayEx = [1027100980032, 1026303277850, 1106375000012];
                            if (arrayEx.indexOf(+value) !== -1) {
                                return;
                            }
                            num12 = Math.floor((+value / 10) % 11);
                            dgt13 = num12 % 10;
                            if (dgt13 !== +value[12]) {
                                error.value = true;
                                error.title = "Введённый ОГРН не прошёл проверку по контрольным цифрам";
                            }
                        } else if (value.length === 15) {
                            var dgt15, num14;
                            num14 = Math.floor((+value / 10) % 13);
                            dgt15 = num14 % 10;
                            if (+value[14] !== dgt15) {
                                error.value = true;
                                error.title = "Введённый ОГРН не прошёл проверку по контрольным цифрам";
                            }
                        }
                    }
                    if (validationType === 'isOgrnFlValid') {
                        error.value = !(value.length == 0 || (value.length == 15 && /\d/.test(value)) || value == "0");
                        error.title = "ОГРН состоит из 15 цифр";
                    }
                    if (validationType === 'isKPPvalid') {
                        if (!(/^[\d]{9}$/.test(value) || value.length == 0)){
                            error.value = true;
                            error.title = "КПП должен представлять собой девятизначный цифровой код";
                        } else if (value.length) {
                            error.value = value[4]=='0' && value[5]=='0';
                            error.title = "Код причины постановки на учет (5 и 6 цифра) не может быть 00";
                        }
                    }
                    if (validationType === 'isKPPvalidAllowZero') {
                        if(value !== '0') {
                            if (!(/^[\d]{9}$/.test(value) || value.length == 0)) {
                                error.value = true;
                                error.title = "КПП должен представлять собой девятизначный цифровой код";
                            } else if (value.length) {
                                error.value = value[4] == '0' && value[5] == '0';
                                error.title = "Код причины постановки на учет (5 и 6 цифра) не может быть 00";
                            }
                        }
                    }
                    if (validationType == 'isUrlValid') {
                        error.value = !(/^(http(s)?:\/\/)([\w\.-]+)\.([a-z]{2,6}\.?)(\/[\w\.]*)*\/?$/.test(value) || !value);
                        error.title = "Неверно указан адрес сайта. Пример адреса:\nhttp://www.yandex.ru";
                    }
                    if (validationType == 'isFloatValid') {
                        error.value = !/^[-+]?[0-9]*\.?[0-9]+$/.test(value);
                        error.title = "Введённое значение не является числом";
                    }
                    if (validationType == 'isSearchValid') {
                        error.value = value.indexOf("%") != -1 || value.indexOf("_") != -1;
                        error.title = "Введенное значение содержит недопустимые символы % _";
                    }
                    if (validationType == 'isEmailValid' || validationType == 'emailOnlineCheck' || validationType == 'emailOnlineCheckSecured' || validationType == 'emailOnlineCheckSecuredCert') {
                        error = validateEmail(value, validationParam);
                    }
                    if (validationType == 'isGuidValid') {
                        error = validateGuid(value);
                        setValidity();
                        return value;
                    }
                    if (validationType == 'isEmailListValid') {
                        var values = (value.length>0 && value.indexOf(';')!=-1 ? value.split(";") : [value]), errorList=[], rzdErrorList=[];
                        for(var i=0; i<values.length; i++){
                            var val = values[i].trim();
                            if(val != "") {
                                if (!EMAIL_VALID_REG_EXP.test(val))
                                    errorList.push(val);
                                else if (val.endsWith('.rzd') || val.endsWith('@rzd'))
                                    rzdErrorList.push(val);
                            }
                        }
                        error.value = errorList.length>0 || rzdErrorList.length>0;

                        if(rzdErrorList.length==1)
                            error.title = "Адрес электронной почты "+rzdErrorList[0]+" являются внутренним для сети ОАО \"РЖД\" и на него не могут быть отправлены письма из сети Интернет. Введите внешний адрес электронной почты.";
                        else if(rzdErrorList.length>1)
                            error.title = "Адреса электронной почты "+rzdErrorList.toString().replaceAll(',','; ')+" являются внутренними для сети ОАО \"РЖД\" и на них не могут быть отправлены письма из сети Интернет. Введите внешние адреса электронной почты.";

                        if(errorList.length==1)
                            error.title = "Адрес электронной почты указан неверно - " + errorList[0];
                        else if(errorList.length>1)
                            error.title = "Адреса электронной почты указаны неверно - " + errorList.toString().replaceAll(',','; ');
                    }
                    if (validationType == 'isAccountValid') {
                        error.value = value == undefined || checkAccountValue(value.toString(), validationParam, elem[0].getAttribute("isulbudget") === 'true');
                        if (error.value) {
                            error.title = "Введенный расчетный счет не прошел проверку по контрольным цифрам!";
                        }
                        setValidity();
                        return value;
                    }
                    if (validationType == 'isAccountValidType') {
                        error.value = value == undefined || checkAccountValue(value.toString(), validationParam);
                        error.title = "Введенный расчетный счет не прошел проверку по контрольным цифрам!";
                        setValidity();
                        return value;
                    }
                    if (validationType === 'isDateValid') {
                        var isMsk = attr['isMsk'];
                        if(isMsk){
                            error.value = !(!value || new Date(value) >= getServerTimeMsk().setHours(0, 0, 0, 0));
                        }else {
                            error.value = !(!value || new Date(value) >= new Date().setHours(0, 0, 0, 0));
                        }
                        error.title = "Введена некорректная дата";
                    }
                    if (validationType === 'isDateBeforeCurrentDateValid') {
                        error.value = !(!value || new Date(value).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0));
                        error.title = "Введена некорректная дата";
                    }
                    if (validationType === 'isDateTimeValid') {
                        var isMsk = attr['isMsk'];
                        if(isMsk){
                            error.value = !(!value || new Date(value) >= getServerTimeMsk());
                        }else {
                            error.value = !(!value || new Date(value) >= new Date());
                        }
                        error.title = "Введена некорректная дата";

                        if (dateOptions.maxDate && !error.value) {
                            error.value = !(new Date(value) < dateOptions.maxDate);
                            error.title = "Не более " + dateOptions.term + " дней";
                        }
                    }
                    if (validationType === 'isDateTimeValidMsk') {
                        error.value = !(!value || value >= getServerTimeMsk());
                        error.title = systemMessages.INCORRECT_DATETIME;
                        if (dateOptions.maxDate && !error.value) {
                            error.value = !(value < dateOptions.maxDate);
                            error.title = "Не более " + dateOptions.term + " дней";
                        }
                    }
                    if (validationType === 'isTimeValid') {
                        if(value.length>0 && (value.length<8 || !/^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(value))) {
                            error.value = true;
                            error.title = error.value ? "Введённое значение не допустимо!" : "";
                        }
                        setValidity();
                        return value;
                    }
                    if (validationType === 'isDateOptionValid' && value) {
                        // Если есть ограничение по дате
                        if (dateOptions.maxDate && !error.value) {
                            error.value = !(new Date(value) <= new Date(dateOptions.maxDate));
                            error.title = "Введена некорректная дата";
                        }
                        if (dateOptions.minDate && !error.value) {
                            error.value = !(new Date(value) >= new Date(dateOptions.minDate));
                            error.title = "Введена некорректная дата";
                        }
                        setValidity();
                        return value;
                    }
                    if (validationType === 'isAppRefundSummValidType') {
                        if (!validationParam) return value;
                        var availableBalance = $parse(validationParam)(scope);
                        if (!availableBalance) return value;
                        if (/^[-+]?[0]*\.?[0]+$/.test(value) || parseFloat(value) === 0) {
                            error.value = true;
                            error.title = "Введённое значение не допустимо!";
                        } else if(value > availableBalance){
                            error.value = true;
                            error.title = "Указанный для возврата размер денежных средств не должен превышать остаток свободных средств по счету.";
                        } else {
                            var maxSum = $parse("modelView.maxSumm")(scope);
                            if (maxSum && parseInt(value) > parseInt(maxSum)) {
                                error.value = true;
                                error.title = "Указанный для возврата размер денежных средств не должен превышать допустимую для вывода сумму.";
                            }
                        }
                        setValidity();
                        return value;
                    }
                    if (validationType === 'winnerIndicationValid') {
                        error.value = (value.code === 'N');
                        error.title = "Публикация протокола невозможна. У одного или нескольких участников не определен статус.";
                    }
                    if (validationType === 'isPriceZeroValid') {
                        error.value = !value;
                        error.title = "Цена должна быть больше 0.";
                    }
                    if (validationType === 'isContractSumZeroValid') {
                        error.value = !value;
                        error.title = "Сумма должна быть больше 0.";
                        setValidity();
                        return value;
                    }
                    if (validationType === 'isValueZeroValid') {
                        error.value = !value || parseFloat(value) == 0;
                        error.title = "Значение должно быть больше 0.";
                        setValidity();
                        return value;
                    }
                    if (validationType === 'isValueNoLessThanValid') {
                    	if (!validationParam) return value;
                    	error.value = !value || parseFloat(value) < parseFloat(validationParam);
                        error.title = "Значение не должно быть менее " + validationParam + ".";
                        setValidity();
                        return value;
                    }
                    setValidity();
                    return error.value ? undefined : value;
                }

                function setValidity() {
                    ctrl.$setDirty(elem[0].name, true);
                    elem[0].title = error.value ? error.title : "";
                    ctrl.$setValidity(elem[0].name, error.value ? false : true);
                }

                function checkAccountValue(value, bikModel, isbudget) {
                    if (!value) return false;
                    if (!bikModel || (value && value.length !== 20)) return true;
                    if (isbudget) {
                        return false;
                    }
                    var bikValue = $parse(bikModel)(scope);
                    if (!bikValue) return false;
                    var credit = checkAccount(bikValue.substring(bikValue.length - 3) + value),  //для клиентов кредитных организаций
                        rkc = checkAccount("0" + bikValue[4] + bikValue[5] + value);            // для клиентов ркц
                    return credit && rkc;
                }

                function checkAccount(value) {
                    var SchetMask = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1], // весовые коэффициенты
                        sum = 0;
                    if (value.length != 23) return true;
                    for (var i = 0; i < 23; i++) {
                        if (isNaN(value[i])) return true;
                        sum = sum + (parseInt(value[i]) * SchetMask[i]) % 10; // вычисляем контрольную сумму
                    }
                    return sum % 10 != 0; // проверяем остаток от деления контрольной суммы на 10 с нулём
                }
            }
        };
    }]);

