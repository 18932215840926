angular.module('uetp').controller('carouselController', ['$scope', '$rootScope', 'httpSrv', 'desktopSrv', 'loggerUiSrv',
    function ($scope, $rootScope, httpSrv, desktopSrv, loggerUiSrv) {
        this.$onInit = function () {
            const rubles = desktopSrv.casesOfwords()(['рубль', 'рубля', 'рублей']);
            const lots = desktopSrv.casesOfwords()(['лот', 'лота', 'лотов']);
            const slides = $scope.slides = [];
            let currIndex = 0;
            $scope.myInterval = 10000;
            $scope.noWrapSlides = false;
            $scope.active = 0;

            $rootScope.lotStat = {
                money: 0,
                lotCount: 0,
                moneyText: 'рублей',
                lotCountText: 'лотов'
            };
            httpSrv.http({
                method: 'GET',
                url: '/site/getPurchaseStats'
            }).then(function successCallback(response) {
                if (response.data.success) {
                    const result = response.data.result;
                    $rootScope.lotStat.money = result.message ? parseFloat(result.message).toFixed() : 0;
                    $rootScope.lotStat.lotCount = result.param;
                    $rootScope.lotStat.moneyText = rubles($rootScope.lotStat.money);
                    $rootScope.lotStat.lotCountText = lots($rootScope.lotStat.lotCount);
                } else {
                    _resetLotStat()
                }
            }, function (reason) {
                console.log(reason);
                _resetLotStat()
            });

            function _resetLotStat() {
                $rootScope.lotStat.money = 0;
                $rootScope.lotStat.lotCount = 0;
            }

            $scope.addSlide = function () {
                slides.push({
                    image: {
                        res8000: 'img/newStartPage/carousel/3840-new.png',
                        res1920: 'img/newStartPage/carousel/1920-new.png',
                        res1440: 'img/newStartPage/carousel/1440-new.png',
                        res992: 'img/newStartPage/carousel/992-new.png'
                    },
                    caption: 'Электронная торговая площадка КОМИТА',
                    text: 'Автоматизированная информационная система <br/>в сфере закупок товаров, работ, услуг.',
                    lotCount: $rootScope.lotStat.lotCount,
                    money: $rootScope.lotStat.money,
                    clazz: 'slide-uetp',
                    id: currIndex++
                });

                slides.push({
                    image: {
                        res8000: 'img/newStartPage/carousel/3840x300.gif',
                        res1920: 'img/newStartPage/carousel/1920х300.gif',
                        res1440: 'img/newStartPage/carousel/1440х300.gif',
                        res992: 'img/newStartPage/carousel/992х300.gif'
                    },
                    small: '',

                    imgId: 'a7Adv',
                    imgcls: 'a7Adv',
                    targetDom: {
                        targetMth: () => {
                            loggerUiSrv.sendLog('A7_SLIDE_CLICK');
                            window.open("https://www.a7-agent.ru", "_blank");
                        },
                        targetId:'a7Adv'
                    },
                    clazz:'a7Adv',

                    onActive: {
                        targetMth: () => {
                            loggerUiSrv.sendLog('A7_SLIDE_VIEW');
                        }
                    },
                    id: currIndex++
                });

                slides.push({
                    image: {
                        res8000: 'img/newStartPage/carousel/optimize_1920х300.png',
                        res1920: 'img/newStartPage/carousel/optimize_1920х300.png',
                        res1440: 'img/newStartPage/carousel/optimize_1440х300.png',
                        res992: 'img/newStartPage/carousel/optimize_992х300.png'
                    },
                    caption: 'Оптимизируйте торгово-закупочную деятельность!',
                    text: 'КОМИТА ЭТП обеспечивает высокую эффективность закупок:<br/>' +
                        'более 30 тысяч реальных Поставщиков, отлаженный функционал,<br/>' +
                        'полное досье на контрагента, анализ цен, полезные онлайн-сервисы.'+
                        '<a href="/becomeCustomer" metrika-aim="participantAim" class="carousel-become-btn carousel-become-customer" target="_blank">Стать Заказчиком</a>',
                    small: '',
                    clazz:'become-customer-cls',
                    id: currIndex++
                });

                slides.push({
                    image: {
                        res8000: 'img/newStartPage/carousel/mchd_1920x300.jpg',
                        res1920: 'img/newStartPage/carousel/mchd_1920x300.jpg',
                        res1440: 'img/newStartPage/carousel/mchd_1440х300.jpg',
                        res992: 'img/newStartPage/carousel/mchd_992x300.jpg'
                    },
                    caption: '<img src="img/newStartPage/mchd_logo.svg" height="59"><span class="carousel-caption-mchd">Комита МЧД</span>',
                    text: 'Решение всего спектра связанных с применением МЧД задач. <br>Создание и проверка доверенности в электронной форме.<br><br>' +
                        '<a id="btnMCHD" href="https://mchd.comita.ru/" metrika-aim="participantAim" class="carousel-become-btn carousel-btn-mchd" target="_blank">Подробнее</a>',
                    small: '',
                    targetDom: {
                        targetMth: () => {
                            loggerUiSrv.sendLog('MCHD_SLIDE_CLICK');
                        },
                        targetId:'btnMCHD'
                    },
                    clazz:'become-customer-cls',
                    id: currIndex++
                });

                slides.push({
                    image: {
                        res8000: 'img/newStartPage/carousel/reliability_1920х300.png',
                        res1920: 'img/newStartPage/carousel/reliability_1920х300.png',
                        res1440: 'img/newStartPage/carousel/reliability_1440х300.png',
                        res992: 'img/newStartPage/carousel/reliability_992х300.png'
                    },
                    caption: 'Используйте возможности <br/>КОМИТА ЭТП для развития бизнеса!',
                    text: 'Площадка позволяет работать с надёжными Заказчиками: ОАО «РЖД» <br/>  и его дочерними и зависимыми обществами, предприятиями Вологодской<br/> области, а также коммерческими предприятиями по всей России.'+
                        '<a href="/becomeSupplier" metrika-aim="participantAim" class="carousel-become-btn carousel-become-supplier" target="_blank">Стать Поставщиком</a>',
                    small: '',
                    clazz:'become-supplier-cls',
                    id: currIndex++
                });

                slides.push({
                    image: {
                        res8000: 'img/newStartPage/carousel/1920х300_improve_service.jpg',
                        res1920: 'img/newStartPage/carousel/1920х300_improve_service.jpg',
                        res1440: 'img/newStartPage/carousel/1440х300_improve_service.jpg',
                        res992: 'img/newStartPage/carousel/992х300_improve_service.jpg'
                    },
                    caption: 'Улучшайте клиентский сервис!<br>',
                    text: 'Один чат-бот заменяет колл-центр &mdash; круглосуточно <br>' +
                        'и мгновенно отвечает на 100% типовых вопросов клиентов.<br><br>' +
                        '<a class="carousel-detailed-btn" href="https://www.comita.ru/projects/robot_assistant_chatbot/" target="_blank">Подробнее</a>',
                    small: '',
                    targetDom: {
                        targetMth: () => {
                            loggerUiSrv.sendLog('ROBOT_SLIDE_CLICK');
                        },
                        targetId:'btnROBOT'
                    },
                    clazz:'robot',
                    id: currIndex++
                });
            };
            /**
             * отслеживание кликов по слайду
             * @param event - элемент на который нажали
             * @param slide - объект слайда с готовым обработчиком и указанием цели
             * {
             *    targetDom: {
             *      targetMth функция которая должна быть выполнена,
             *      targetId: идентификатор html элемента при клике на который надо выполнить действие
             *    }
             * }
             */
            $scope.clickedTarget = (event, slide) => {
                if (slide.targetDom && slide.targetDom.targetId) {
                    if (event.target.id === slide.targetDom.targetId || slide.targetDom.targetId === slide.imgId) {
                        slide.targetDom.targetMth()
                    }
                }
            };

            /**
             * отслеживаем смену слайдов
             */
            $scope.$watch('active', () => {
                if($scope.slides[$scope.active]?.onActive?.targetMth) {
                    $scope.slides[$scope.active]?.onActive?.targetMth();
                }
            })

            $scope.addSlide();

        };
    }]).directive('carousel', [function () {
    return {
        restrict: 'E',
        controller: 'carouselController',
        templateUrl: 'views/carousel.html'
    };
}]);
