/**
 * @author: sergeyu
 */
angular.module('uetp').controller('tablesCtrl', ['$scope', '$log', '$q', '$rootScope', '$sce', '$http', 'constants',
    '$state', '$stateParams', 'filterService', 'httpSrv', '$injector', 'tablesSrv', 'noticeSrv', 'authSrv',
    'dictionarySrv', 'newsCreateSrv', 'desktopSrv', 'dialogSrv', 'newsletterCreateSrv', 'alertSrv', 'paginationSrv',
    'paymentOrderSrv', 'homeNewsSrv', 'proceduresSrv', 'timeout', 'questionAnswerSrv', 'documentSamplesSrv', '$interval',
    'invoiceSrv', 'needConfirmSrv', 'scService', 'sysMessageSrv', 'subscriptionSrv', '$timeout', 'organizatorSrv', 'propertiesService',
    'bankGuaranteeSrv', 'documentsList', 'presentationList', 'videoList', 'participantSrv', 'offerSrv', 'cartSrv', 'loggerUiSrv', 'orderSrv',
    'rfGuaranteeAppSrv', 'bankGuaranteeLokoSrv', 'bankGuaranteeVTBSrv', 'tassBusinessSrv', '$uibModal', 'tariffsSrv', '$filter', 'statPrices', 'versionInfoCreateSrv',
    function ($scope, $log, $q, $rootScope, $sce, $http, constants, $state, $stateParams, filterService, httpSrv, $injector,
              tablesSrv, noticeSrv, authSrv, dictionarySrv, newsCreateSrv, desktopSrv, dialogSrv, newsletterCreateSrv,
              alertSrv, paginationSrv, paymentOrderSrv, homeNewsSrv, proceduresSrv, timeout, questionAnswerSrv, documentSamplesSrv, $interval,
              invoiceSrv, needConfirmSrv, scService, sysMessageSrv, subscriptionSrv, $timeout, organizatorSrv, propertiesService,
              bankGuaranteeSrv, documentsList, presentationList, videoList, participantSrv, offerSrv, cartSrv, loggerUiSrv, orderSrv, rfGuaranteeAppSrv, bankGuaranteeLokoSrv, bankGuaranteeVTBSrv,
              tassBusinessSrv, $uibModal, tariffsSrv, $filter, statPrices, versionInfoCreateSrv) {
        var state = $state.current;

        $scope.$state = $state;
        $scope.$stateParams = $stateParams;

        $scope.isDemo = isDemoFn;
        $scope.getOfferRef = getOfferRefFn;
        $scope.goLink = goLinkFn;

        $scope.webmarketURL = 'https://eshoprzd.ru';
        $scope.webmarketUEM = 'https://market.comita.ru';

        $scope.state = state.name;
        $scope.startMth = state.startMth;
        $scope.auth = authSrv.getAuth();
        $scope.user = authSrv.getUser();
        $scope.obj = {};
        $scope.obj.showInvoiceRequestForm = false;
        $scope.modelView = {};
        $scope.checkLimitLoad = false;
        $scope.offerSiteTitle = offerSrv.siteTitle;
        $scope.bankGuaranteeTypeLoko = {};
        $scope.usedTarifsArr = null;
        $scope.openModal = openModalFn;

        /*
        * Статусы Специальных счетов
        * */
        var specialAccountStates = {
            draft: {
                text: 'Ожидание проверки',
                text_class: 'black'
            },
            wait: {
                text: 'Рассмотрение',
                text_class: 'black'
            },
            waitResult: {
                text: 'Ожидает резервирования',
                text_class: 'black'
            },
            reject: {
                text: 'Отказ',
                text_class: 'red'
            },
            accept: {
                text: 'Зарезервирован',
                text_info: 'Номер счёта успешно зарезервирован. В течение ближайшего времени с Вами свяжется специалист банка по указанному в форме заявки телефону.',
                text_class: 'darkgreen'
            }
        };
        /**
         * Статусы Банковских гарантий локо банка
         */
        var stateGuaranteesLoko = {
            'sended': 'Ожидает обработки',
            'inProcess': 'В работе',
            'waitClient': 'Направлено уточнение',
            'waitBank': 'Получено уточнение',
            'agreementClient': 'Согласование гарантии клиентом',
            'agreementBank': 'Согласование гарантии банком',
            'finished': 'Гарантия выдана',
            'rejected': 'Отказ',
            'payComission': 'Макет согласован'
        }

        /**
         * Статусы Банковских гарантий банка ВТБ
         */
        const stateGuaranteesVtb = angular.extend({
            'waitResponseFromClient': 'Ожидается ответ на запрос банка'
            }, stateGuaranteesLoko);

        $scope.resetPaginator = resetPaginatorFn;
        $scope.newsPublishedLoad = newsPublishedLoadFn;
        $scope.newsLoad = newsLoadFn;
        $scope.versionInfoLoad = versionInfoLoadFn;
        $scope.newslettersLoad = newslettersLoadFn;
        $scope.questionAnswerLoad = questionAnswerLoadFn;
        $scope.documentSamplesLoad = documentSamplesLoadFn;
        $scope.questionAnswerPublishedLoad = questionAnswerPublishedLoadFn;
        $scope.documentSamplesPublishedLoad = documentSamplesPublishedLoadFn;

        $scope.participantLoad = participantLoadFn;
        $scope.publicParticipantLoad = publicParticipantLoadFn;
        $scope.organizatorLoad = organizatorLoadFn;
        $scope.proceduresLoad = proceduresLoadFn;
        $scope.jointProceduresLoad = jointProceduresLoadFn;
        $scope.noticesLoad = noticesLoadFn;
        $scope.publicProceduresLoad = publicProceduresLoadFn;
        $scope.commercialProceduresLoad = commercialProceduresLoadFn;
        $scope.publicProcedures44Load = publicProcedures44LoadFn;
        $scope.publicProceduresSOCLoad = publicProceduresSOCLoadFn;
        $scope.otherProceduresLoad = otherProceduresLoadFn;
        $scope.purchasePlanLoad = purchasePlanLoadFn;
        $scope.explanationReqsLoad = explanationReqsLoadFn;
        $scope.explanationsLoad = explanationsLoadFn;
        $scope.allProcedures = allProceduresFn;
        $scope.myProcedures = myProceduresFn;
        $scope.customerLoad = customerLoadFn;
        $scope.eisIntegrationProc = eisIntegrationProcFn;
        $scope.eisMonitoring = eisMonitoringFn;
        $scope.eisSend = eisSendFn;
        $scope.eisRetrySend = eisRetrySendFn;
        $scope.subscriptionLoad = subscriptionLoadFn;
        $scope.priceAnalysisTableLoad = priceAnalysisTableLoadFn;
        $scope.filterPriceAnalysis = filterPriceAnalysisFn;
        $scope.openPriceAnalysisSubTable = openPriceAnalysisSubTableFn;
        $scope.closePriceAnalysisSubTable = closePriceAnalysisSubTableFn;
        $scope.priceAnalysisSubTableLoad = priceAnalysisSubTableLoadFn;
        $scope.checkSubscription = checkSubscriptionFn;
        //$scope.invoiceLoad = invoiceLoadFn;
        $scope.specialAccount = specialAccountFn;
        $scope.ucLoad = ucLoadFn;
        $scope.getds = getdsFn;
        $scope.accreditationLoad = accreditationLoadFn;
        $scope.customerRegistrationTableLoad = customerRegistrationTableLoadFn;
        $scope.isVipAccreditation = isVipAccreditationFn;
        $scope.paymentOrdersLoad = paymentOrdersLoadFn;
        $scope.openAccreditationReport = openAccreditationReportFn;
        $scope.downloadOrganizationsListCsv = downloadOrganizationsListCsvFn;

        $scope.positionsLoad = positionsLoadFn;
        $scope.offersMyLoad = offersMyLoadFn;
        $scope.ordersLoad = ordersLoadFn;
        $scope.ordersMyLoad = ordersMyLoadFn;
        $scope.addToCartPosition = addToCartPositionFn;
        $scope.companiesLoad = companiesLoadFn;
        $scope.openCompany = openCompanyFn;
        $scope.offersLoad = offersLoadFn;
        $scope.getFullName = getFullNameFn;
        $scope.changeSort = changeSortFn;
        $scope.sortBy = sortByFn;
        $scope.sortByDropdown = sortByDropdownFn;
        $scope.dbClickRow = dbClickRowFn;
        $scope.createNotice = createNoticeFn;
        $scope.changeFavorite = changeFavoriteFn;
        $scope.getCustomerByType = getCustomerByTypeFn;
        $scope.getPortalTypeByCode = getPortalTypeByCodeFn;
        $scope.getVersionInfoTypeByCode = getVersionInfoTypeByCodeFn;
        $scope.getAccreditationColor = getAccreditationColorFn;

        $scope.rfGuaranteesLoad = rfGuaranteesLoadFn;
        $scope.systemMessagesLoad = systemMessagesLoadFn;
        $scope.bankGuaranteesLoad = bankGuaranteesLoadFn;
        $scope.bankGuaranteesLokoLoad = bankGuaranteesLokoLoadFn;
        $scope.bankGuaranteesVTBLoad = bankGuaranteesVTBLoadFn;
        $scope.bankGuaranteesAdminLoad = bankGuaranteesAdminLoadFn;
        $scope.bankGuaranteesVTBAdminLoad = bankGuaranteesVTBAdminLoadFn;
        $scope.bgAgentsLoad = bgAgentsLoadFn;
        $scope.bgBanksLoad = bgBanksLoadFn;
        $scope.bankGuaranteesRefreshLimits = bankGuaranteesRefreshLimitsFn;
        $scope.protocolNotificationsLoad = protocolNotificationsLoadFn;
        $scope.proceduresSrv = proceduresSrv;
        $scope.getProcedureName = proceduresSrv.getProcedureName;
        $scope.procedureNameType = proceduresSrv.procedureNameType;
        $scope.getSectionName = dictionarySrv.getStaticSectionName;

        $scope.paymentService = paymentOrderSrv;
        $scope.sentPaymentOrders = sentPaymentOrdersFn;
        $scope.sentAllPaymentOrders = sentAllPaymentOrdersFn;
        $scope.bulkCheckbox = bulkCheckboxFn;
        $scope.getStateGuaranteesLoko = getStateGuaranteesLokoFn
        $scope.getStateGuaranteesVTB = getStateGuaranteesVTBFn


        $scope.downloadFile = httpSrv.downloadFile;
        $scope.downloadFileByOrderIdAndType = bankGuaranteeSrv.downloadFileByOrderIdAndType;
        $scope.downloadFileVTBByOrderIdAndType = bankGuaranteeVTBSrv.downloadFileByOrderIdAndType;
        $scope.hasJsonFile = bankGuaranteeVTBSrv.hasJsonFile;
        $scope.getLastJsonFile = bankGuaranteeVTBSrv.getLastJsonFile;
        
        $scope.getStateInfo = bankGuaranteeSrv.getStateInfo;
        $scope.downloadFileEis = downloadFileEisFn;

        $scope.deleteItem = deleteItemFn;
        $scope.undoItem = undoItemFn;
        $scope.getVersionInfoFileName = getVersionInfoFileNameFn;

        $scope.btnDeleteClick = function (id) {
            $scope.btnDelete[id] = !$scope.btnDelete[id]
        };
        $scope.toggleCheckedItem = toggleCheckedItemFn;
        $scope.alertObjImport = alertObjImportEvent;

        $scope.exportExcel = exportExcelFn;
        $scope.getNotificationProtocolType = getNotificationProtocolTypeFn;

        $scope.findOtherProcedures = findOtherProceduresFn;

        $scope.EISInfo = authSrv.isEISInfo() ? false : true;
        $scope.fillTimeZone = proceduresSrv.fillTimeZone;
        $scope.needSupplySum = proceduresSrv.needSupplySum;

        $scope.loginModal = loginModalFn;
        $scope.getState = getStateFn;
        $scope.enterSpecialAccount = enterSpecialAccountFn;
        $scope.userAccess = userAccessFn;
        $scope.userAccessRfGuarantee = rfGuaranteeAppSrv.userAccess;
        $scope.isUserAgent = authSrv.isUserAgent;
        $scope.autoFillByTypeDict = autoFillByTypeDictFn;
        $scope.refreshDict = refreshDictFn;

        $scope.showThrobber = false;

        $scope.isAdminBGChatView = isAdminBGChatViewFn;

        $scope.goBack = goBackFn;
        //todo вынести в сервис
        $scope.actionButtonDisabled = actionButtonDisabledFn;

        $scope.openPriceAnalysisChart = openPriceAnalysisChartFn;
        $scope.openPriceAnalysisChartView = '1y'
		$scope.chartLoaded = false;
        $scope.changeAnalysisView = changeAnalysisViewFn;
        $scope.getCurrencyName = getCurrencyNameFn;
        $scope.addFilterParam = addFilterParamFn;

        $scope.priceAnalysisChart = {
            templateUrl: '_priceAnalysisChart.html'
        };

        $scope.getEisState = proceduresSrv.getEisState;
        $scope.getFileLink = getFileLinkFn;
        $scope.getFileDesc = getFileDescFn;
        $scope.getCurrencyPatternByCode = (code) => dictionarySrv.getCurrencyPatternByCode(code);

        function openPriceAnalysisChartFn(data, intervalScale) {
            $scope.chartLoaded = false;
            if(intervalScale){
            	$scope.openPriceAnalysisChartView = intervalScale;
            }

            propertiesService.getProperty("otherProcedures.url").then(function (host) {
                let param = {
                    addParams: {
                        withNestedCodes: 'false'
                    }
                };
                param.searchByField = angular.copy($scope.paginator.searchByField);
                if (data.okpd2)
                    param.searchByField['items.okpd2_code'] = data.okpd2.id;
                if (data.okei)
                    param.searchByField['items.okei_code'] = data.okei.id;
                if (data.ktru)
                    param.searchByField['items.ktru_code'] = data.ktru.id;
                if (data.regnum)
                    param.searchByField['customers.region'] = data.regnum.slice(0, 2) + '0';
                if (intervalScale)
                    param.searchByField['intervalScale'] = intervalScale;
                httpSrv.requestXHR(null, {
                    method: 'POST',
                    url: host + '/rest/other-purchases/proc/statPriceCharts',
                    data: param
                }).then(function successCallback(response) {
                    const abbr = ["руб.", "тыс.руб.", "млн.руб."];

                    function scaleChartData(value, suf){
                        return suf === abbr[2] ? value / 1.0e+6 : suf === abbr[1] ? value / 1.0e+3 : value;
                    }

                    function calcScaleSuffix(data) {
                        const average = data.reduce((sum, a) => sum + a, 0) / data.length;
                        if (average >= 1.0e+6) {
                           return abbr[2];
                        } else if (average >= 1.0e+3) {
                            return abbr[1];
                        } else {
                            return abbr[0];
                        }
                    }

                    const priceChartData = [data.minPrice, data.avgPrice, data.medianPrice, data.maxPrice],
                        priceChartSuffix = calcScaleSuffix(priceChartData);
                    $scope.openPriceAnalysisChartView = response.data.interval;
                    $scope.chartLoaded = true;
                    $scope.priceAnalysisChart = {
                        templateUrl: '_priceAnalysisChart.html',
                        data: data,
                        chartData: response.data,
                        contractsChart: {
                            labels: response.data.contractsChart.labels,
                            data: [response.data.contractsChart.data],
                            colors: ['#0077B9'],
                            options: {
                                tooltips: {
                                    callbacks: {
                                        label: tooltipItem => 'Количество контрактов: ' + tooltipItem.yLabel
                                    }
                                }
                            }
                        },
                        medianChart: {
                            labels: response.data.medianChart.labels,
                            data: [response.data.medianChart.data.map(val=>scaleChartData(val,response.data.medianChart.scaleDesc))],
                            dataFull: response.data.medianChart.data,
                            colors: [
                                {
                                    backgroundColor: "rgba(255, 255, 255, 0)",
                                    pointBackgroundColor: "#FFFFFF",
                                    pointHoverBackgroundColor: "#FFFFFF",
                                    borderColor: "#F5A623",
                                    pointBorderColor: "#F5A623",
                                    pointHoverBorderColor: "#F5A623"
                                }
                            ],
                            options: {
                                tooltips: {
                                    callbacks: {
                                        label: tooltipItem => `Медианная цена: ${$filter('currency')($scope.priceAnalysisChart.medianChart.dataFull[tooltipItem.index], 'руб.')}`
                                    }
                                }
                            },
                            suffix: response.data.medianChart.scaleDesc
                        },
                        economyChart: {
                            labels: response.data.economyChart.labels,
                            data: [response.data.economyChart.purchaseData.map(val=>scaleChartData(val,response.data.economyChart.scaleDesc)),
                                response.data.economyChart.contractData.map(val=>scaleChartData(val,response.data.economyChart.scaleDesc))],
                            dataFull: [response.data.economyChart.purchaseData, response.data.economyChart.contractData],
                            noData: response.data.economyChart.purchaseData.length == 0 && response.data.economyChart.contractData.length == 0,
                            series: ['Сумма закупок','Сумма контрактов'],
                            colors: [
                                {
                                    backgroundColor: "rgba(255, 255, 255, 0)",
                                    pointBackgroundColor: "#0077B9",
                                    pointHoverBackgroundColor: "#0077B9",
                                    borderColor: "#0077B9",
                                    pointBorderColor: "rgba(255, 255, 255, 0)",
                                    pointHoverBorderColor: "rgba(255, 255, 255, 0)"
                                },
                                {
                                    backgroundColor: "rgba(255, 255, 255, 0)",
                                    pointBackgroundColor: "#E21A1A",
                                    pointHoverBackgroundColor: "#E21A1A",
                                    borderColor: "#E21A1A",
                                    pointBorderColor: "rgba(255, 255, 255, 0)",
                                    pointHoverBorderColor: "rgba(255, 255, 255, 0)"
                                }
                            ],
                            options: {
                                tooltips: {
                                    callbacks: {
                                        label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label}: ${$filter('currency')($scope.priceAnalysisChart.economyChart.dataFull[tooltipItem.datasetIndex][tooltipItem.index], 'руб.')}`,
                                        footer: tooltipItems => `Экономия: ${$filter('currency')($scope.priceAnalysisChart.economyChart.dataFull[tooltipItems[0].datasetIndex][tooltipItems[0].index] 
                                            - $scope.priceAnalysisChart.economyChart.dataFull[tooltipItems[1].datasetIndex][tooltipItems[1].index], 'руб.')}`
                                    }
                                }
                            },
                            suffix: response.data.economyChart.scaleDesc
                        },
                        priceChart: {
                            labels: ['Минимальная цена', 'Средняя цена', 'Медианная цена', 'Максимальная цена'],
                            data: [priceChartData.map(val=>scaleChartData(val,priceChartSuffix))],
                            dataFull: priceChartData,
                            colors: [
                                {
                                    backgroundColor: "#EA5757",
                                    pointBackgroundColor: "#EA5757",
                                    pointHoverBackgroundColor: "#EA5757",
                                    borderColor: "#EA5757",
                                    pointBorderColor: "#EA5757",
                                    pointHoverBorderColor: "#EA5757"
                                }
                            ],
                            options: {
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            callback: value => value.toLowerCase()
                                        }
                                    }]
                                },
                                tooltips: {
                                    intersect: false,
                                    displayColors: false,
                                    callbacks: {
                                        title: () => '',
                                        label: tooltipItem => `${tooltipItem.xLabel}: ${$filter('currency')($scope.priceAnalysisChart.priceChart.dataFull[tooltipItem.index], 'руб.')}`
                                    }
                                }
                            },
                            suffix: priceChartSuffix
                        }
                    };
                    if(!intervalScale){
                        $uibModal.open({
                            ariaLabelledBy: 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            size: 'lg',
                            templateUrl: $scope.priceAnalysisChart.templateUrl,
                            scope: $scope,
                            openedClass: 'modal-open priceAnalysisChart--window'
                        });
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            });
        }

        function changeAnalysisViewFn(view) {
            $scope.analysisView = view;
            $scope.dataTable = null;
            $scope.paginator.page = 0;
            delete $scope.paginator.orderBy;
            delete $scope.paginator.orderAsc
            priceAnalysisTableLoadFn();
        }

        $scope.isInCart = cartSrv.isInCart;

        $scope.excludePurchase = proceduresSrv.excludePurchase;

        $scope.trustAsHtml = $sce.trustAsHtml;

        function addToCartPositionFn(id) {
            cartSrv.addToCartPosition(id).then(function () {
                cartSrv.countPositions();
                cartSrv.loadCart();
                // $scope.positionsLoad();
            })
        }

        function actionButtonDisabledFn(position) {
            return position.statusEng !== 'eshop_published';
        }

        function userAccessFn() {
            const type = $scope.user.typeOrg;
            return type === 'ul' || type === 'ip';
        }

        $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);


        /**
         * Автозаполнение полей
         * @param object - объект
         * @param select - выбранное значение - объект
         */
        function autoFillByTypeDictFn(object, select) {
            if (object === "participant") {
                $scope[object] = {};
                const item = select.selected;
                if (item) {
                    $scope[object].inn = item.inn;
                    $scope[object].name = item.fullName;
                    $scope[object].ogrn = item.ogrn;
                } else {
                    delete $scope[object];
                    select.search = '';
                }

                if (state.name === 'rfGuarantees') {
                    $state.go('rfGuarantees', {
                        participant: $scope.modelView.rfGuaranteesParticipant,
                        clearConfig: true
                    });
                } else if (state.name === 'lokobank') {
                    $scope.bankGuaranteesLokoLoad();
                } else if (state.name === 'bankGuaranteesVTB') {
                    $scope.bankGuaranteesVTBLoad();
                } else {
                    loadBankGuarantees();
                    bankGuaranteesRefreshLimitsFn();
                }
            }
        }

        function refreshDictFn(object, value, refreshScrolling, needToAdd) {
            dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd);
        }

        //удаление из таблиц с перезагрузкой таблицы и обнулением кнопки удаления. вызов соотв. сервиса для удаления позиции предположительно не обобщить
        function deleteItemFn(id) {
            var promise = {};
            if (state.name == "showNews" || state.name == "news") {
                promise = newsCreateSrv.deleteItem(id);
            }
            if (state.name == "versionInfo") {
                promise = versionInfoCreateSrv.deleteItem(id);
            }
            if (state.name == "newsletters") {
                promise = newsletterCreateSrv.deleteItem(id);
            }
            if (state.name == "questionAnswer") {
                promise = questionAnswerSrv.deleteItem(id);
            }
            if (state.name == "documentSamples") {
                promise = documentSamplesSrv.deleteItem(id);
            }
            if (state.name == "bankGuarantees") {
                promise = bankGuaranteeSrv.deleteApp(id);
            }
            if (state.name == "lokobank") {
                promise = bankGuaranteeLokoSrv.deleteApp(id);
            }
            if (state.name == "bankGuaranteesVTB") {
                promise = bankGuaranteeVTBSrv.deleteApp(id);
            }
            if (["purchases", "purchasesMy"].indexOf(state.name) != -1) {
                //promise = purchaseService.deletePurchase(id);
                //сервис отсутствует
            }
            if (["offers", "offersMy"].indexOf(state.name) != -1) {
                promise = offerSrv.deleteOffer($scope, id);
            }
            if (["ordersMy"].indexOf(state.name) != -1) {
                promise = orderSrv.deleteOrder(id);
            }
            if (state.name === "subscriptionTable") {
                //promise = subscriptionSrv.deleteItem(id);
                promise = propertiesService.getProperty("otherProcedures.url").then(function (host) {
                    return subscriptionSrv.deleteItem(id, host, $scope)
                        .then(function (response) {
                            return response;
                        })
                })
            }
            //после выполнения метода удаления позиции выполняем соотв. состоянию метод обновления таблицы и обнуляем кнопку удаления
            promise.then(function () {
                loadScopeMth();
                $scope.btnDelete = {};
            })
        }

        function undoItemFn(id) {
            versionInfoCreateSrv.undoItem(id).then(function () {
                loadScopeMth();
                $scope.btnDelete = {};
            })
        }

        function getVersionInfoFileNameFn(version) {
            return `Информация об изменениях в версии ${version.number.replaceAll('.', '_')} от ${$filter('date')(version.releaseDate, 'dd.MM.yyyy','UTC+3').replaceAll('.', '_')}.pdf`;
        }

        function getNotificationProtocolTypeFn(procedureType, protocolType, stage) {
            if (['openauction','saleauction','limitedauction'].includes(procedureType) && protocolType == 'REZ')
                return 'Протокол проведения аукциона';
            else if ('openpublicoffer'===procedureType && protocolType == 'REZ')
                return 'Протокол проведения открытого публичного предложения';
            else if (procedureType == 'requestproposals' || procedureType == 'limitedrequestproposals') {
                if (protocolType == 'OPEN' && stage == 'stage2')
                    return 'Протокол вскрытия окончательных предложений';
                else if (protocolType == 'PART1' && stage == 'stage2')
                    return 'Протокол рассмотрения и оценки окончательных предложений';
                else if (protocolType == 'PART1')
                    return 'Протокол запроса предложений';
                else if (protocolType == 'OPEN')
                    return 'Протокол вскрытия заявок';
            } else if (procedureType == 'open2stagecontest') {
                if (protocolType == 'OPEN' && stage == 'stage2')
                    return 'Протокол вскрытия окончательных заявок';
                else if (protocolType == 'PART1' && stage == 'stage2')
                    return 'Протокол рассмотрения и оценки окончательных заявок';
                else if (protocolType == 'PART1')
                    return 'Протокол рассмотрения первоначальных заявок';
                else if (protocolType == 'OPEN')
                    return 'Протокол вскрытия первоначальных заявок';
            }

            if (protocolType == 'OPEN')
                return 'Протокол вскрытия';
            else if (protocolType == 'PARTS')
                return 'Протокол рассмотрения заявок';
            else if (protocolType == 'PART1')
                return 'Протокол рассмотрения и оценки заявок';
            else if (protocolType == 'REZ')
                return 'Протокол конкурсной комиссии';
            else return protocolType;
        }

        /**
         * Запуск функций по выставленным параметрам контроллера
         * isStateParam - использовать параметры переданные вместе со state
         */
        function loadScopeMth() {
            var mth = $scope.startMth || state.name + "Load";
            if (typeof $scope[mth] === "function") $scope[mth](true);
        }

        //Документы и Видео
        $scope.selectDocument = function (index, fileName) {
            $scope.setActiveFrame('docs');
            if (!fileName || fileName.indexOf(".pdf") == -1) return;
            $scope.selectedDocument = index;
        };
        $scope.selectVideo = function (index) {
            $scope.selectedVideo = index;
            $scope.setActiveFrame('video');
            window.scrollTo(0, 0);
        };
        $scope.setActiveFrame = (frame) => $scope.activeFrame = frame;
        $scope.getActiveFrame = () => { return $scope.activeFrame; }

        $scope.getDocPath = function (filename) {
            return '/downloads/' + filename;
        };
        $scope.getVideoPath = function (filename) {
            return $sce.trustAsResourceUrl(filename);
        };
        $scope.isItemLink = function (fileName) {
            return fileName && fileName.indexOf(".pdf") != -1;
        };
        $scope.checkVisibility = function (cabType, userRole) {
            if (cabType == 'all' && userRole == 'all') {
                return true
            } else {
                var checkCabType = cabType.split(','),
                    checkedCabType = false,
                    checkUserRole = userRole.split(','),
                    checkedUserRole = false;

                if (checkCabType.length == 1 && checkCabType[0] == 'all') {
                    checkedCabType = true;
                } else {
                    const cabTypeArr = [];
                    angular.forEach(checkCabType, function (key) {
                        if (key === $scope.cabType) cabTypeArr.push(key)
                    });
                    checkedCabType = !!cabTypeArr.length
                }

                if (checkUserRole.length == 1 && checkUserRole[0] == 'all') {
                    checkedUserRole = true;
                } else {
                    for (var i = 0, len = checkUserRole.length; i < len; i++) {
                        if (($scope.auth.userRole && checkUserRole[i] === $scope.auth.userRole[0]) || (checkUserRole[i] === 'unauthorized' && !$scope.auth.userRole)) {
                            checkedUserRole = true;
                            break;
                        }
                        checkedUserRole = false;
                    }
                }

                return checkedCabType && checkedUserRole;
            }
        };
        $scope.selectedDocument = 0;
        $scope.selectedVideo = 0;

        if (constants.configProject.type == 'etp') {
            $scope.documents = documentsList['UETP'];
            $scope.presentations = presentationList['UETP'];
            $scope.videos = videoList['UETP'];
        } else {
            $scope.documents = documentsList['PPK'];
            $scope.presentations = presentationList['PPK'];
            $scope.videos = videoList['PPK'];
        }
        $scope.statPrices = statPrices;

        init();

        function resetPaginatorFn() {
            $scope.paginator = {
                itemsPerPage: 20,
                page: 0
            };
            $scope.totalPages = 1;
            delete $scope.paginator.search;
            delete $scope.paginator.searchByField;
        }

        function init() {
            if ($scope.$state.current.name === 'documents')
                $scope.documentsTemplate = 'app/components/documents/documents_' + constants.configProject.type + '.html';
            $scope.btnDelete = {};
            $scope.isExpand = {};
            $scope.typeCustomer = dictionarySrv.getStaticDictByType('typeCustomer');
            $scope.typePortal = dictionarySrv.getStaticDictByType('typePortal');
            $scope.dateMonth = dictionarySrv.getStaticDictByType('dateMonth');
            $scope.versionInfoTypes = dictionarySrv.getStaticDictByType('versionInfoTypes');
            $scope.purchaseMethod = [];
            $scope.paginator = {
                itemsPerPage: 20,
                page: 0,
                search: '',
                searchByField: {}
            };
            $scope.modelView.loadingView = false;
            var config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name); // память пагинации
            if (config) {
                $scope.paginator.page = config.page;
                $scope.paginator.itemsPerPage = config.itemsPerPage;
            }
            $scope.sortByObject = tablesSrv.initSort($scope.sortByObject, $scope.paginator);
            $scope.constants = constants;
            $scope.cabType = desktopSrv.getCabType();
            // Загрузка данных таблицы
            loadTableData();
            if ($stateParams.docTab) $scope.docTab = $stateParams.docTab;
            dictionarySrv.getProcedureList('223').then(function (result) {
                if (result.data.success) {
                    $scope.purchaseMethod = filterService.sortProcedures(angular.copy(result.data.result));
                    $scope.purchaseCommercialMethod = angular.copy(result.data.result);
                    $scope.purchaseCommercialMethod.push({id: "saleauction", message: "Доходный аукцион"});
                    $scope.purchaseCommercialMethod.push({id: "openpublicoffer", message: "Открытое публичное предложение"});
                    $scope.purchaseCommercialMethod.push({id: "requestprices", message: "Запрос цен"});
                    $scope.purchaseCommercialMethod = filterService.sortProcedures($scope.purchaseCommercialMethod);
                }
            });
            dictionarySrv.getProcedureList('44').then(function (result) {
                if (result.data.success) {
                    $scope.purchase44Method = result.data.result;
                }
            });
            if ($stateParams.checkLimit && userAccessFn()) {
                $scope.checkLimitLoad = true;
                bankGuaranteeSrv.getLimits($scope.user.inn, $scope.user.ogrn, $scope.user.fullName, $scope.user.id, $scope.user.typeOrg).then(
                    function (limit) {
                        if (limit.data.success && limit.data.result) {
                            $scope.checkLimitLoad = false;
                            $state.go('bankGuaranteePrimaryAppCreate', {clearConfig: true, purchasData: $stateParams.purchasData, purchasDataType: $stateParams.purchasDataType}, {location: 'replace'});
                        } else {
                            bankGuaranteeSrv.refreshLimits($scope.user.inn, $scope.user.ogrn, $scope.user.fullName, $scope.user.id, $scope.user.typeOrg).then(
                                function (limit) {
                                    $scope.checkLimitLoad = false;
                                    if (limit.data.success && limit.data.result) {
                                        $state.go('bankGuaranteePrimaryAppCreate', {clearConfig: true, purchasData: $stateParams.purchasData, purchasDataType: $stateParams.purchasDataType}, {location: 'replace'});
                                    } else {
                                        $state.go('bankGuaranteeAppCreate', {clearConfig: true, purchasData: $stateParams.purchasData, purchasDataType: $stateParams.purchasDataType}, {location: 'replace'});
                                    }
                                },
                                function (reason) {
                                    $scope.checkLimitLoad = false;
                                    console.log(reason);
                                });
                        }
                    },
                    function (reason) {
                        $scope.checkLimitLoad = false;
                        console.log(reason);
                    });

            }

            //автообновление заявлений на аккредитацию в лк оператора
            if ($state.current.name == 'accreditation') {
                if (!$rootScope.refreshAccred) {
                    $rootScope.refreshAccred = $interval(function () {
                        accreditationLoadFn();
                    }, 60000);
                }
                $scope.$onDestroy = function () {
                    $interval.cancel($rootScope.refreshAccred);
                };
            } else if ($state.current.name == 'specialAccount') {
                $scope.specAccount = false;
                if ($scope.cabType === 'participant') {
                    $scope.specAccount = true;
                    $state.go(state);
                } else {
                    var messageTxt = 'Функционал по онлайн резервированию и открытию счетов в банках-партнёрах доступен только участникам КОМИТА ЭТП.';
                    dialogSrv.openModal('_dialogMessage.html',
                        {message: messageTxt}).then(function () {
                        $state.go('financialService')
                    })
                }
            } else if ($rootScope.refreshAccred) {
                $interval.cancel($rootScope.refreshAccred);
                $rootScope.refreshAccred = null;

            } else if ($state.current.name == 'rfGuarantees') {
                if ($stateParams.saveParam) {
                    $scope.alertObjbankGuarantee = alertSrv.getSuccessMsg(angular.copy($stateParams.saveParam));
                }
            }
            $scope.dictionary = dictionarySrv.getRemoteData();
            _initDict(['CATEGORY_doc', 'CATEGORY_question', 'fias_region'], false);
            
            $scope.selectedCategory = questionAnswerSrv.getSelectedCategory;
            $scope.total = 0;

            $scope.positionsDetails = {
                position: {}
            };
            $scope.positionsDetailsId = {
                id: ''
            };
            $scope.currentPaginator = angular.copy($scope.paginator);
            if (["customer", "organizer", "participant"].includes($scope.cabType) && $state.current.name === "positions") {
                cartSrv.loadCart();
            }
        }

        /**
         * Загрузка данных таблицы по startMth или по названию состояния + Load
         * Если в $stateParams есть filterType и:
         * searchParams - данные простого поиска
         * filterParams - данные расширеного поиска
         */
        function loadTableData() {
            if(($scope.startMth || state.name)==='purchasePlanAdmin')
                return;
            const searchParams = $stateParams.searchParams;
            const filterParams = $stateParams.filterParams;
            const filterType = $stateParams.filterType;
            if (filterType) {
                // Сбрасываем предидущие данные фильтра
                filterService.resetCurrent(filterType);
                // сохраняем в фильтр данные поиска, затем получим в prepareTableParams
                if (searchParams) {
                    // Передаем данные для компонента фильтра
                    filterService.setSearchParam(searchParams, filterType)
                } else if (filterParams) {
                    scService.clearCounter();
                    // Передаем данные для компонента фильтра
                    filterService.setFilterNoModifyParams(filterParams, filterType);
                    // Устанавливаем данные для пагинатора в виде для сервера
                    const paramFromServer = angular.copy(filterParams);
                    filterService.prepareFilterFields(paramFromServer, $scope.$stateParams.type);
                    filterService.setFilterParams(paramFromServer, filterType);
                    // Сбрасываем простой поиск
                    filterService.setSearchParam('', filterType);
                }
            }
            const mth = $scope.startMth || state.name + "Load"; // запускаем по названию метода или по названию состояния.
            if (typeof $scope[mth] === "function") $scope[mth](true);
        }

        function _initDict(dictList, reload) {
            angular.forEach(dictList, function (name) {
                dictionarySrv.getDictionaryRemote(name, reload)
            })
        }

        function openAccreditationReportFn() {
            return httpSrv.downloadFile("/admin/accreditationReport");
        }

        function downloadOrganizationsListCsvFn() {
            return httpSrv.downloadFile("/admin/downloadOrganizationsListCsv");
        }

        function newsLoadFn() {
            filterService.initModelView('Поиск по теме и тексту новости', 'Новости');
            prepareTableParams('filter');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/news/newsList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.newsList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function versionInfoLoadFn() {
            filterService.initModelView('Поиск по номеру версии', 'Версии');
            prepareTableParams('filter');
            var param = angular.copy($scope.paginator);
            
            if (!param.orderBy) {
                param.orderBy = "createDate";
                param.orderAsc = false;
            }
            httpSrv.http({
                url: '/news/versions/getVersionInfos',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.versionInfoList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function newslettersLoadFn() {
            filterService.initModelView('Поиск по теме и тексту рассылки', 'Рассылки');
            prepareTableParams('filter');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/message/api/v2/delivery/list',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.newslettersList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Номера ЕИС. Оператор
         */
        function eisIntegrationProcFn() {
            filterService.initModelView('Поиск по номеру и наименованию закупки, ИНН заказчика, наименованию заказчика и организатора', 'Контроль интеграции');
            setTimeout(function () {
                prepareTableParams('filterEisIntegrationProc');
                if (!$scope.paginator.orderBy) {
                    $scope.paginator.orderBy = "startDate";
                    $scope.paginator.orderAsc = true;
                }
                var param = angular.copy($scope.paginator);
                httpSrv.http({
                    url: '/admin/eis/procedures',
                    data: param,
                    showCounter: true
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.eisProc = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            }, 600);
        }

        /**
         * Оператор. Мониторинг интеграции с ЕИС
         */
        function eisMonitoringFn() {
            filterService.initModelView('Поиск по номеру и наименованию закупки, номеру ЕИС, ИНН заказчика, наименованию заказчика и организатора', 'Мониторинг интеграции');
            setTimeout(function () {
                prepareTableParams('filterEisMonitoring');
                if (!$scope.paginator.orderBy) {
                    $scope.paginator.orderBy = "doc_public";
                    $scope.paginator.orderAsc = true;
                }
                var param = angular.copy($scope.paginator);
                httpSrv.http({
                    url: '/admin/eis/documents',
                    data: param,
                    showCounter: true
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.eisProc = [];
                        if(response.data.items) {
                            response.data.items.forEach(item => $scope.eisProc.push(item.multipartStates ? getMultipartItem(item) : item));
                        }
                        
                        $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            }, 600);
        }

        function getMultipartItem(data) {
            const result = data;
            result.multipartActions = getMultipartObject(data);
            result.multipartStates = getMultipartObject(data, true);
            return result;
        }

        function getMultipartObject(data, state = false) {
            let parts = [], att = 0;
            if(!data.multipartStates) return [];
            data.multipartStates.forEach(eds=>{
                const isNotice = eds.filename.startsWith("purchaseNotice");
                if(!isNotice) {
                    att += 1;
                }
                if(state) {
                    parts.push({
                        "id": eds.id,
                        "name": isNotice ? "Извещение: " : "Вложение " + att + ": ",
                        "filename": eds.filename,
                        "state": eds.state
                    });
                } else {
                    parts.push({
                        "id": eds.id,
                        "name": isNotice ? "Извещение" : "Вложение " + att,
                        "filename": eds.filename
                    });
                }
            });
            return parts;
        }

        function getFileLinkFn(link, option) {
            return link+option.id;
        }

        function getFileDescFn(data, option) {
            if(data.docType.id==='notice' && (option?.filename||'').startsWith("documentUpload")) {
                return 'eis_description_documentUpload_'+option.id+'.xml';
            }
            return 'eis_description_'+data.docType.id+'_'+data.docId+'.xml';
        }

        function eisSendFn(docId, docType, isPart = false) {
            httpSrv.http({
                url: isPart?`/admin/eis/eisSend/${docId}`:`/admin/eis/eisSend/${docId}/${docType}`,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.data.success) {
                    eisMonitoringFn();
                } else {
                    console.log(response);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function eisRetrySendFn(docId, docType, isPart = false) {
            httpSrv.http({
                url: isPart?`/admin/eis/eisRetrySend/${docId}`:`/admin/eis/eisRetrySend/${docId}/${docType}`,
                method: 'GET'
            }).then(function successCallback(response) {
                if (response.data.success) {
                    eisMonitoringFn();
                } else {
                    console.log(response);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Вопрос-ответ. Оператор
         */
        function questionAnswerLoadFn() {
            filterService.initModelView('Поиск по вопросу и ответу', 'Вопрос-ответ');
            prepareTableParams('filterQuestionAnswer');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/admin/getQuestionsList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.questionAnswerList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Образцы документов. Оператор
         */
        function documentSamplesLoadFn() {
            //filterService.initModelView('Поиск по теме, вопросу и ответу', 'Вопрос-ответ');
            //prepareTableParams('filterQuestionAnswer');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/site/getDocSamplesList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.documentSamplesList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Вопрос-ответ на ОЧ
         */
        function questionAnswerPublishedLoadFn() {
            filterService.initModelView('Поиск по вопросу и ответу', 'Вопрос-ответ');
            prepareTableParams('filterQuestionAnswer');
            var param = angular.copy($scope.paginator);
            var selectedCategory = questionAnswerSrv.getSelectedCategory();
            if (selectedCategory) {
                if (!param.searchByField) param.searchByField = {};
                param.searchByField.category = selectedCategory;
            }
            httpSrv.http({
                url: '/site/getPublishedQuestionsList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.questionAnswerList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Образцы документов на ОЧ
         */
        function documentSamplesPublishedLoadFn() {
            //filterService.initModelView('Поиск по теме, вопросу и ответу', 'Вопрос-ответ');
            //prepareTableParams('filterQuestionAnswer');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/site/getDocSamplesList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.documentSamplesList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function systemMessagesLoadFn() {
            filterService.initModelView('Поиск по номеру закупки, теме или тексту письма', 'Уведомления');
            prepareTableParams('searchMessage');

            if (!$scope.paginator.orderBy) {
                var defaultSortElement = document.getElementById('createDate');
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
            }
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/message/api/v2/showMessage/0',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    angular.forEach($scope.dataTable, function (data) {
                        data.message = initMessFn(data.message)
                    });
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function rfGuaranteesLoadFn() {
            if ($scope.cabType === 'participant' && ($scope.user.typeOrg === 'ul' || $scope.user.typeOrg === 'ip') || authSrv.isUserAgent()) {
                loadRfGuarantees();
            } else {
                console.log("Отсутствуют права для доступа к разделу 'Райффайзенбанк'");
                $state.go('home');
            }
        }

        function loadRfGuarantees() {

            if (!$scope.paginator.orderBy) {
                $scope.paginator.orderBy = "created";
                $scope.paginator.orderAsc = false;
            }

            var param = angular.copy($scope.paginator);
            var url = '/bankguarantee/rf/orders/getOrders/';

            if (authSrv.isUserAgent()) {
                if (!($scope.modelView.rfGuaranteesParticipant || {}).id && $stateParams.participant) {
                    $scope.modelView.rfGuaranteesParticipant = angular.copy($stateParams.participant);
                }
                if (($scope.modelView.rfGuaranteesParticipant || {}).id) {
                    url += $scope.modelView.rfGuaranteesParticipant.id;
                } else {
                    return;
                }
            }

            httpSrv.http({
                method: 'POST',
                url: url,
                data: param
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function bankGuaranteesLoadFn() {
            // Временно убираем проверку по комментарию
            // https://jira.comita.lan/browse/UETP-5202?focusedCommentId=586469&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-586469
            //propertiesService.getProperty("bankguarantee.enabled").then(function() {
            //    if (!desktopSrv.isBGEnabled()) {
            //        $state.go('home');
            //    } else {
            if (authSrv.canActiveBankGuarantee($scope.cabType, $scope.user) || authSrv.isUserAgent() || ['admincab', 'support'].includes($scope.cabType)) {
                loadBankGuarantees();
            }
            //    }
            //});
        }

        function bankGuaranteesLokoLoadFn() {
            if ($scope.cabType === 'bank') {
                $scope.bankGuaranteeTypeLoko = {};
                dictionarySrv.getStaticDictByType('bankGuaranteeTypeLoko').forEach((item) => {
                    $scope.bankGuaranteeTypeLoko[item.code] = item.name;
                })
            }
            if (authSrv.canActiveBankGuarantee($scope.cabType, $scope.user) || authSrv.isUserAgent() || ['admincab', 'support', 'bank'].includes($scope.cabType)) {
                if ($stateParams.saveParam) {
                    $scope.bankGuaranteessaveParam = $stateParams.saveParam;
                }
                if (!$scope.paginator.orderBy) {
                    $scope.paginator.orderBy = "created";
                    $scope.paginator.orderAsc = false;
                }

                var param = angular.copy($scope.paginator);
                var url = '/bankguarantee/lk/orders/getOrders';
                //заявки агента
                if (authSrv.isUserAgent()) {
                    url = '/bankguarantee/lk/orders/getOrdersByAgent';
                    // Если есть данные о участнике - проставляем и грузим
                    if ($stateParams.participantInfo && $stateParams.participantSelected && !$scope.modelView.participantStateParamLoad) {
                        $scope.modelView.bankGuaranteesParticipant = $stateParams.participantInfo;
                        $scope.modelView.participantStateParamLoad = true;
                    }
                    if ($scope.modelView.bankGuaranteesParticipant && $scope.modelView.bankGuaranteesParticipant.id) {
                        if (!param.searchByField) param.searchByField = {};
                        param.searchByField.participantId = $scope.modelView.bankGuaranteesParticipant.id
                    }
                }
                if (['admincab', 'support'].includes($scope.cabType) && $stateParams.participantId) {
                    if (!param.searchByField) {
                        param.searchByField = {};
                    }
                    param.searchByField.participantId = $stateParams.participantId
                }
                httpSrv.http({
                    method: 'POST',
                    url: url,
                    data: param
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.dataTable = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            }
        }
        function bankGuaranteesVTBLoadFn() {
            if ($scope.cabType === 'bank') {
                $scope.bankGuaranteeTypeLoko = {};
                dictionarySrv.getStaticDictByType('bankGuaranteeTypeLoko').forEach(item => {
                    $scope.bankGuaranteeTypeLoko[item.code] = item.name;
                })
            }
            if (authSrv.canActiveBankGuarantee($scope.cabType, $scope.user) || authSrv.isUserAgent() ||
                ['admincab', 'support', 'bank'].includes($scope.cabType)) {
                if ($stateParams.saveParam) {
                    $scope.bankGuaranteessaveParam = $stateParams.saveParam;
                }
                if (!$scope.paginator.orderBy) {
                    $scope.paginator.orderBy = $scope.cabType === 'bank'? "sendDate" : "createDate";
                    $scope.paginator.orderAsc = false;
                }
                $scope.isLoadBG = true;

                const param = angular.copy($scope.paginator);
                let url = '/bankguarantee/vtb/orders/getOrders';
                //заявки агента
                if (authSrv.isUserAgent()) {
                    url = '/bankguarantee/vtb/orders/getOrdersByAgent';
                    // Если есть данные о участнике - проставляем и грузим
                    if ($stateParams.participantInfo && $stateParams.participantSelected && !$scope.modelView.participantStateParamLoad) {
                        $scope.modelView.bankGuaranteesParticipant = $stateParams.participantInfo;
                        $scope.modelView.participantStateParamLoad = true;
                    }
                    if ($scope.modelView.bankGuaranteesParticipant && $scope.modelView.bankGuaranteesParticipant.id) {
                        if (!param.searchByField) param.searchByField = {};
                        param.searchByField.participantId = $scope.modelView.bankGuaranteesParticipant.id
                    }
                }
                if (['admincab', 'support'].includes($scope.cabType) && $stateParams.participantId) {
                    if (!param.searchByField) {
                        param.searchByField = {};
                    }
                    param.searchByField.participantId = $stateParams.participantId
                    $scope.modelView.bankGuaranteesParticipant = participantSrv.getOrgInfo();
                }
                httpSrv.http({
                    method: 'POST',
                    url: url,
                    data: param
                }).then(response => {
                    if (response.data.success) {
                        $scope.dataTable = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                    }
                    $scope.isLoadBG = false;
                }, function errorCallback(error) {
                    console.log(error);
                    $scope.isLoadBG = false;
                });
            }
        }

        function bankGuaranteesAdminLoadFn() {
            if (['admincab', 'support'].includes($scope.cabType)) {
                loadBankGuarantees();
            }
        }
        function bankGuaranteesVTBAdminLoadFn() {
            if (['admincab', 'support'].includes($scope.cabType)) {
                $scope.bankGuaranteesVTBLoad();
            }
        }

        function loadBankGuarantees() {
            $scope.isLoadBG = true;
            if ($stateParams.saveParam) {
                $scope.bankGuaranteessaveParam = $stateParams.saveParam;
            }
            if (!$scope.paginator.orderBy) {
                $scope.paginator.orderBy = "createDate";
                $scope.paginator.orderAsc = false;
            }

            var param = angular.copy($scope.paginator);
            var url = '/bankguarantee/orders/getOrders';
            //заявки агента
            if (authSrv.isUserAgent()) {
                url = '/bankguarantee/orders/getOrdersByAgent';
                // Если есть данные о участнике - проставляем и грузим
                if ($stateParams.participantInfo && $stateParams.participantSelected && !$scope.modelView.participantStateParamLoad) {
                    $scope.modelView.bankGuaranteesParticipant = $stateParams.participantInfo;
                    $scope.modelView.participantStateParamLoad = true;
                }
                if ($scope.modelView.bankGuaranteesParticipant && $scope.modelView.bankGuaranteesParticipant.id) {
                    if (!param.searchByField) param.searchByField = {};
                    param.searchByField.participantId = $scope.modelView.bankGuaranteesParticipant.id
                }
            }
            if (['admincab', 'support'].includes($scope.cabType) && ($stateParams.participantId || $stateParams.participantInfo?.id)) {
                if (!param.searchByField) {
                    param.searchByField = {};
                }
                param.searchByField.participantId = $stateParams.participantId ? $stateParams.participantId : $stateParams.participantInfo?.id;
                $scope.modelView.bankGuaranteesParticipant = participantSrv.getOrgInfo();
            }
            httpSrv.http({
                method: 'POST',
                url: url,
                data: param
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
                $scope.isLoadBG = false;
            }, function errorCallback(response) {
                console.log(response);
                $scope.isLoadBG = false;
            });
            getLimits();

            function getLimits() {
                var inn = $scope.user.inn;
                var ogrn = $scope.user.ogrn;
                var fullName = $scope.user.fullName;
                var orgId = $scope.user.id;
                var typeOrg = $scope.user.typeOrg;
                $scope.bankGuaranteeLimits = {};
                $scope.bankGuaranteeLimitsNotSet = false;
                if ($scope.isUserAgent()) {
                    if (!$scope.modelView.bankGuaranteesParticipant) {
                        $scope.bankGuaranteeLimits = {};
                        $scope.bankGuaranteeLimitsNotSet = true;
                        return;
                    }
                    inn = $scope.modelView.bankGuaranteesParticipant.inn;
                    ogrn = $scope.modelView.bankGuaranteesParticipant.ogrn;
                    fullName = $scope.modelView.bankGuaranteesParticipant.fullName;
                    orgId = $scope.modelView.bankGuaranteesParticipant.id;
                    typeOrg = $scope.modelView.bankGuaranteesParticipant.typeOrg;
                }
                if (['admincab', 'support'].includes($scope.cabType)) {
                    var participantData = participantSrv.getOrgInfo();
                    if (!participantData) {
                        $scope.bankGuaranteeLimits = {};
                        $scope.bankGuaranteeLimitsNotSet = true;
                        return;
                    }
                    inn = participantData.inn;
                    ogrn = participantData.ogrn;
                    fullName = participantData.fullName;
                    orgId = participantData.id;
                    typeOrg = participantData.typeOrg;
                }

                bankGuaranteeSrv.getLimits(inn, ogrn, fullName, orgId, typeOrg).then(function (limit) {
                    if (limit.data.success && limit.data.result) {
                        $scope.bankGuaranteeLimits = limit.data.result;
                    } else {
                        $scope.bankGuaranteeLimits = {};
                    }
                });
            }
        }

        function bankGuaranteesRefreshLimitsFn() {
            $scope.bankGuaranteeLimitsNotSet = false;
            var inn = $scope.user.inn, ogrn = $scope.user.ogrn, fullName = $scope.user.fullName, orgId = $scope.user.id, typeOrg = $scope.user.typeOrg;
            if ($scope.isUserAgent()) {
                if (!$scope.modelView.bankGuaranteesParticipant) {
                    $scope.alertObjbankGuarante = alertSrv.getErrorMsg('Не выбран участник!');
                    $scope.bankGuaranteeLimits = {};
                    $scope.bankGuaranteeLimitsNotSet = true;
                    return;
                }
                inn = $scope.modelView.bankGuaranteesParticipant.inn;
                ogrn = $scope.modelView.bankGuaranteesParticipant.ogrn;
                fullName = $scope.modelView.bankGuaranteesParticipant.fullName;
                orgId = $scope.modelView.bankGuaranteesParticipant.id;
                typeOrg = $scope.modelView.bankGuaranteesParticipant.typeOrg;
            }
            if ($scope.cabType == 'admincab') {
                var participantData = participantSrv.getOrgInfo();
                if (!participantData) {
                    $scope.bankGuaranteeLimits = {};
                    $scope.bankGuaranteeLimitsNotSet = true;
                    return;
                }
                inn = participantData.inn;
                ogrn = participantData.ogrn;
                fullName = participantData.fullName;
                orgId = participantData.id;
                typeOrg = participantData.typeOrg;
            }
            bankGuaranteeSrv.refreshLimits(inn, ogrn, fullName, orgId, typeOrg).then(function (limit) {
                if (limit.data.success && limit.data.result) {
                    $scope.bankGuaranteeLimits = limit.data.result;
                    $scope.bankGuaranteeLimitsNotSet = false;
                } else {
                    $scope.bankGuaranteeLimits = {};
                    $scope.bankGuaranteeLimitsNotSet = true;
                }
            });
        }

        function protocolNotificationsLoadFn() {
            filterService.initModelView('Поиск по номеру закупки', 'Уведомления');
            prepareTableParams('searchProtocolNotification');
            if (!$scope.paginator.orderBy) {
                var defaultSortElement = document.getElementById('createDate');
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
            }
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/message/api/v2/showPNMessage',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function newsPublishedLoadFn(titleSize, contentSize) {
            filterService.initModelView('Поиск по теме и тексту новости', 'Новости');
            $scope.paginator.addParams = {};
            $scope.paginator.addParams.titleSize = titleSize || 200;
            $scope.paginator.addParams.contentSize = contentSize || 800;
            prepareTableParams('filter');
            httpSrv.http({
                url: '/news/newsPublishedList',
                data: $scope.paginator,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.newsList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                    var newsCount = response.data.total > 3 ? 3 : response.data.total;
                    $scope.newsMain = $scope.newsList.slice(0, newsCount);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка участников
         */
        function participantLoadFn() {
            filterService.initModelView('Поиск по наименованию и ИНН/УИН организации', 'Участники');
            prepareTableParams('filterParticipant');
            var param = angular.copy($scope.paginator);
            if ($stateParams.isAccreditationDelayed) {
                if (!param.searchByField)
                    param.searchByField = {};
                param.searchByField.isAccreditationDelayed = $stateParams.isAccreditationDelayed;
            }
            httpSrv.http({
                url: '/admin/obtainOrgList/participant',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function publicParticipantLoadFn() {
            filterService.initModelView('Поиск по наименованию и ИНН/УИН организации', 'Участники');
            prepareTableParams('filterPublicParticipant');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/site/participant',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка организаторов
         */
        function organizatorLoadFn() {
            filterService.initModelView('Поиск по наименованию, ИНН и коду организатора', 'Организаторы');
            prepareTableParams('filterOrganizator');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/admin/obtainOrgList/organizer',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка агентов для банковских гарантий
         */
        function bgAgentsLoadFn() {
            filterService.initModelView('Поиск по наименованию, ИНН и коду агента', 'Агенты');
            prepareTableParams('filterAgent');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/admin/obtainAgentList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function bgBanksLoadFn() {
            filterService.initModelView('Поиск по наименованию, ИНН и коду банка', 'Банки');
            prepareTableParams('filterBgBank');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                url: '/admin/obtainBankList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка реестра платежей
         */
        function paymentOrdersLoadFn() {
            filterService.initModelView('Поиск по плательщику, получателю, номеру', 'Организаторы');
            prepareTableParams('filterPaymentOrder');
            if (!$scope.paginator.searchByField) {
                $scope.paginator.searchByField = {};
            }

            httpSrv.http({
                url: '/accounts/paymentOrder/paymentOrdersList/' + $stateParams.type,
                data: $scope.paginator,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.checkedItems = [];
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                    $scope.poIsErrorDisplay = response.data.errors;
                    $scope.poNotProcessedDisplay = response.data.notProcessed;
                    $scope.poTotalDisplay = response.data.all;
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка процедур
         */
        function proceduresLoadFn() {
            filterService.initModelView('Поиск по номеру и наименованию закупки', 'Закупки');
            prepareTableParams('filterCabProcedures');
            var param = angular.copy($scope.paginator);
            if (param.searchByField) {
                desktopSrv.removeKeys(param, 'deff__');
                paramToStr(param.searchByField);
            }
            httpSrv.http({
                method: 'POST',
                url: '/organizer/procedures',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка совместных процедур
         */
        function jointProceduresLoadFn() {
            filterService.initModelView('Поиск по номеру и наименованию закупки, по номеру договора', 'Закупки');
            prepareTableParams('filterCabProcedures');
            var param = angular.copy($scope.paginator);
            if (param.searchByField) {
                desktopSrv.removeKeys(param, 'deff__');
                paramToStr(param.searchByField);
            }
            httpSrv.http({
                method: 'POST',
                url: '/organizer/procedures/joint',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка процедур
         */
        function noticesLoadFn() {
            filterService.initModelView('Поиск по номеру и наименованию закупки', 'Извещения');
            prepareTableParams('filterNotices');
            var filter = angular.copy($scope.paginator.search);
            var orderAsc = angular.copy($scope.paginator.orderAsc);
            var orderBy = angular.copy($scope.paginator.orderBy);
            var page = angular.copy($scope.paginator.page);
            var pageSize = angular.copy($scope.paginator.itemsPerPage);
            if (!$scope.paginator.searchByField) {
                $scope.paginator.searchByField = {};
            }
            var param = {};
            param.searchByField = $scope.paginator.searchByField;
            param.page = page;
            param.itemsPerPage = pageSize;
            if (filter) param.search = filter;
            if (orderAsc || orderBy) {
                param.orderAsc = orderAsc;
                param.orderBy = orderBy;
            }
            httpSrv.http({
                method: 'POST',
                url: '/organizer/drafts',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });

            defineDraftActionPermissionsFn();
        }

        function defineDraftActionPermissionsFn() {
            httpSrv.http({
                method: 'POST',
                url: '/organizer/drafts/actionPermissions'
            }).then(function successCallback(response) {
                if (response.data.success) {
                    angular.forEach(response.data.result, function (item) {
                        if (item.param === 'importNotice223' && item.message === 'true')
                            $scope.import223Permission = true;
                        else if (item.param === 'importNoticeKGZVO' && item.message === 'true') {
                            $scope.importKGZVOPermission = true;
                        } else if (item.param === 'allowed223' && item.message === 'true') {
                            $scope.allowed223 = true;
                        } else if (item.param === 'allowed44' && item.message === 'true') {
                            $scope.allowed44 = true;
                        } else if (item.param === 'allowedCommercial' && item.message === 'true') {
                            $scope.allowedCommercial = true;
                        }
                    });
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /*
        * Загрузка списка подписок
        * */
        function subscriptionLoadFn() {
            $scope.createSubscriptionEnable = true;
            authSrv.reloadOrgInfo().then(function (user) {
                $scope.user = user;
                getUsedTarifsArr();
                if (authSrv.canActiveOtherPurchase($scope.cabType, $scope.user)) {
                    var param = angular.copy($scope.paginator);
                    if (param) {
                        if (param.orderBy === undefined) {
                            var defaultSortElement = document.getElementById('subscriptionName');
                            tablesSrv.changeSort($scope.paginator, defaultSortElement);
                            tablesSrv.changeSort($scope.paginator, defaultSortElement);
                        }
                    }

                    propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        if(!authSrv.haveTariffsFormService()) {
                            tassBusinessSrv.subscribeParticipantLimit(host).then(function (result){
                                if(result.data && angular.isDefined(result.data.result))
                                    $scope.createSubscriptionEnable = result.data.result>0;
                            })

                        }
                        httpSrv.requestXHR($scope, {
                            method: 'POST',
                            url: host + '/rest/other-purchases/proc/browseSubscribe',
                            data: param
                        }).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.dataTable = response.data.items;
                                $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                                $scope.orgCount = response.data.message;
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    })
                } else {
                    var messageTxt = 'Доступ к подпискам Реестра закупок ЕИС закрыт.';
                    if ($scope.cabType === 'participant') {
                        messageTxt = 'Раздел доступен только для пользователей тарифного плана "Годовой"';
                    }
                    dialogSrv.openModal('_dialogMessage.html',
                        {message: messageTxt}).then(function () {
                        if ($scope.cabType === 'participant') {
                            $state.go('myProcedures')
                        } else {
                            $state.go('home')
                        }

                    })
                }
            });
        }

        function checkSubscriptionFn(subsCount) {
            if (!subsCount) {
                return false;
            }
            if ($scope.cabType != 'participant' || parseInt(subsCount) < 5) {
                $state.go('createSubscription');
            } else {
                authSrv.reloadOrgInfo().then(function successCallback(response) {
                    if (response) {
                        if (response.userTariffs && (response.userTariffs.Z04 || response.userTariffs.KZT04 || response.userTariffs.D01 || response.userTariffs.Z14 || response.userTariffs.D11)) {
                            $state.go('createSubscription');
                        } else {
                            $scope.alertCheckSubscription = alertSrv.getErrorMsg('Достигнуто максимальное количество пробных подписок — 5. Для восстановления доступа к сервису необходимо подключить тарифный план "Безопасность закупок" или "Годовой".');
                        }
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            }
        }

        function checkPriceAnalysisAccess() {
            const deferred = $q.defer();
            if (!authSrv.canActiveOtherPurchase($scope.cabType, $scope.user)) {
                deferred.reject({
                    messageTxt: 'Доступ закрыт.',
                    state: $scope.cabType === 'participant' ? 'myProcedures' : 'home'
                });
            } else {
                if (authSrv.checkTariffsZ04_D01($scope.cabType, $scope.user)) {
                    propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        tassBusinessSrv.statPriceParticipantLimit(host).then(response => {
                            if (response.data.success && response.data.result > 0) {
                                deferred.resolve();
                            } else {
                                tariffsSrv.getNeedTariffForServiceMsg().then(function(msg){
                                    deferred.reject({
                                        messageTxt: msg,
                                        state: 'tariffsMenu'
                                    });
                                });
                            }
                        });
                    });
                } else {
                    deferred.resolve();
                }
            }
            return deferred.promise;
        }

        function getUsedTarifsArr() {
            $scope.usedTarifsArr = [];
            angular.forEach($scope.user.userTariffs, function(value, key){
                if(['D01', 'Z04', 'D11', 'Z14'].includes(key)){
                    $scope.usedTarifsArr.push(key);
                }
            });
        }

        function checkPriceAnalysisSearchParams() {
            const filterParams = filterService.getFilterParams('filterPriceAnalysis');
            return filterParams && (filterParams["items.okpd2_code"] || filterParams["items.ktru_code"] || filterParams.subjectContract);
        }

        /*
       * Анализ цен
       * */
        function priceAnalysisTableLoadFn(invoker) {
            if (!checkPriceAnalysisSearchParams()) return;
            if (!$scope.paginatorSub) {
                $scope.paginatorSub = {
                    itemsPerPage: 20,
                    page: 0
                };
            }
            return authSrv.reloadOrgInfo().then(function (user) {
                $scope.user = user;
                getUsedTarifsArr();
                return checkPriceAnalysisAccess().then(() => {
                    prepareTableParams('filterPriceAnalysis');
                    $scope.analysisView = $scope.analysisView || 'contracts';
                    if (!$scope.paginator.orderBy) {
                        $scope.paginator.orderBy = $scope.analysisView === 'contracts' ? 'priceForQty' : "sumPrice";
                        $scope.paginator.orderAsc = $scope.analysisView === 'contracts';
                    }
                    const param = angular.copy($scope.paginator);
                    scService.clearCounter();
                    if (!param.searchByField) return;
                    $scope.activeIndex = null;
                    $scope.modelView.loadingView = true;
                    $rootScope.$emit("searchInProcess", true);
                    timeout($scope);
                    $scope.currentPaginator = angular.copy(param);
                    if (invoker) {
                        param.addParams = param.addParams || {};
                        param.addParams.checkLimit = true;
                        if (invoker === true || invoker === 'filter') {
                            param.addParams.incAccessCount = true;
                            loggerUiSrv.sendLog('PRICE_ANALYSIS_GENERATE');
                        }
                    }
                    return propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        return httpSrv.requestXHR($scope, {
                            method: 'POST',
                            url: host + '/rest/other-purchases/proc/'+ ($scope.analysisView == 'contracts'?'statPriceContracts':'statPrice'),
                            data: param
                        }).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.dataTable = response.data.items;
                                $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                                $scope.total = response.data.total;
                            } else {
                                $scope.dataTable = null;
                                $scope.totalPages = 0;
                                $scope.total = 0;
                                var errorMsg = response.data.message;
                                if (response.data.errorCode === '995')
                                    errorMsg = 'Достигнуто максимальное количество пробных использований сервиса "Анализ цен" — 5. Для восстановления доступа к сервису необходимо подключить тарифный план "Безопасность закупок" или "Годовой".';
                                $scope.alertPlanAnalysis = alertSrv.getErrorMsg(errorMsg);
                            }
                            $scope.modelView.loadingView = false;
                            $rootScope.$emit("searchInProcess", false);
                        }, function errorCallback(response) {
                            console.log(response);
                            $scope.modelView.loadingView = false;
                            $rootScope.$emit("searchInProcess", false);
                        });

                    });
                }, reason => {
                    dialogSrv.openModal('_dialogMessage.html', {message: reason.messageTxt}).then(function () {
                        $state.go(reason.state);
                    })
                })
            });
        }

        function filterPriceAnalysisFn() {
            return priceAnalysisTableLoadFn("filter");
        }

        function openPriceAnalysisSubTableFn(index, data) {
            $scope.activeIndex = index;
            var param = {};
            param.searchByField = angular.copy($scope.paginator.searchByField);
            if (data.okpd2)
                param.searchByField['items.okpd2_code'] = data.okpd2.id;
            if (data.okei)
                param.searchByField['items.okei_code'] = data.okei.id;
            if (data.ktru)
                param.searchByField['items.ktru_code'] = data.ktru.id;
            if (data.regnum)
                param.searchByField['customers.region'] = data.regnum.slice(0, 2) + '0';
            $scope.subparam = param;
            paginationSrv.clearPaginationStateConfig('pagination', 'priceAnalysisSub');
            $scope.priceAnalysisSubTableLoad();
        }

        function closePriceAnalysisSubTableFn() {
            $scope.activeIndex = null;
            $scope.contractsData = null;
            paginationSrv.clearPaginationStateConfig('pagination', 'priceAnalysisSub');
        }

        function priceAnalysisSubTableLoadFn() {
            if (!$scope.subparam) {
                $state.go('priceAnalysisTable', {clearConfig: false});
                return;
            }
            $scope.modelView.loadingSubView = true;
            const config = paginationSrv.getPaginationStateConfig('pagination', 'priceAnalysisSub');
            $scope.paginatorSub = config || {
                itemsPerPage: 20,
                page: 0
            };

            var param = angular.copy($scope.paginatorSub);

            param.searchByField = angular.copy($scope.subparam.searchByField);
            param.addParams = {
                withNestedCodes: 'false'
            };
            timeout($scope);
            propertiesService.getProperty("otherProcedures.url").then(function (host) {
                httpSrv.requestXHR(null, {
                    method: 'POST',
                    url: host + '/rest/other-purchases/proc/statPriceContracts',
                    data: param
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.modelView.loadingSubView = false;
                        $scope.totalPagesSub = Math.ceil(response.data.total / $scope.paginatorSub.itemsPerPage);
                        $scope.contractsData = response.data.items;
                    }
                }, function errorCallback(response) {
                    console.log(response);
                    $scope.modelView.loadingSubView = false;
                });

            });
        }
        
 		function publicProceduresLoadFn() {
            filterService.initModelView('Поиск по заказчику, наименованию и номеру закупки', 'Процедуры');
            prepareTableParams('filterProcedures');
            var param = angular.copy($scope.paginator);
            if (param) {
                if (!param.orderBy) {
                    param.orderBy = "startDate";
                    param.orderAsc = false;
                    var startDate = {
                        "activate": true,
                        "orderAsc": false,
                        "tag": "desc"
                    }
                    $scope.sortByObject.startDate = startDate;
                }
                if (param.searchByField) {
                    desktopSrv.removeKeys(param, 'deff__');
                    paramToStr(param.searchByField);
                }
                if (constants.configProject.type != 'etp') {
                    if (!param.searchByField) param.searchByField = {};
                    param.searchByField.portalType = constants.configProject.type
                }
                $scope.currentPaginator = angular.copy(param);
            }

            if(!param.searchByField) {
	            param.searchByField={};
            }
            delete param.searchByField.lots__items__okpd2Code;
            if(param.searchByField.customer == ''){
	            delete param.searchByField.customer;  
            }
            param.searchByField.indexes = 'purchase_uetp_223';
            propertiesService.getProperty("market.url").then(function (host) {
                    httpSrv.requestXHR($scope, {
                        method: 'POST',
                        url: host + '/rest/elsearch/api/v1/searchPurchase/uetp',
                        data: param
                    }).then(function (response) {
                       if (response.data.success) {
                          $scope.proceduresList = response.data.items;
                          $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                          $scope.total = response.data.total;
                          if (!!param.search || (!!param.searchByField && Object.keys(param.searchByField).length > 0)
                                || (!!param.filter && Object.keys(param.filter).length > 0)) {
                                scService.setCounter(response.data.total);
                          } else {
                                scService.clearCounter();
                          }
                          if('participant' == $scope.cabType){
                               participantSrv.markFavorites($scope.proceduresList);
                          }
                       }else{
	                       $scope.alertSearchProcedure = alertSrv.getErrorMsg(response.data.errorMessage||'Ошибка при выполнении запроса');
                           console.log(response.data);
                       }
                    }, function (reason) {
                        console.log(reason);
                     });
             }, function (reason) {
                   console.log(reason);
             });
        }

        /*
        * Специальные счета
        * */
        function specialAccountFn() {
            //$scope.showInvoiceRequestForm = false;
            // todo в будущем вынести все в один компонет и форму, и таблицу
            // filterService.initModelView('Поиск по заказчику, наименованию и номеру закупки', 'Процедуры');
            // prepareTableParams('filterSpecialAccount');
            //var param = angular.copy($scope.paginator);
            httpSrv.http({
                method: 'GET',
                url: '/specialaccount/specacc/browseAccountReservation',
                //data: param,
                // showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.result;
                    // $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function commercialProceduresLoadFn() {
            filterService.initModelView('Поиск по заказчику, наименованию и номеру закупки', 'Процедуры');
            prepareTableParams('filterProcedures');
            var param = angular.copy($scope.paginator);
            if (param) {
                if (!param.orderBy) {
                    param.orderBy = "startDate";
                    param.orderAsc = false;
                    var startDate = {
                        "activate": true,
                        "orderAsc": false,
                        "tag": "desc"
                    }
                    $scope.sortByObject.startDate = startDate;
                }
                if (param.searchByField) {
                    desktopSrv.removeKeys(param, 'deff__');
                    paramToStr(param.searchByField);
                }
                if (constants.configProject.type != 'etp') {
                    if (!param.searchByField) param.searchByField = {};
                    param.searchByField.portalType = constants.configProject.type
                }
                $scope.currentPaginator = angular.copy(param);
            }

            if(!param.searchByField) {
	            param.searchByField={};
            }
            delete param.searchByField.lots__items__okpd2Code;
            if(param.searchByField.customer == ''){
	            delete param.searchByField.customer;  
            }
            param.searchByField.indexes = 'purchase_uetp_commercial';
            propertiesService.getProperty("market.url").then(function (host) {
                    httpSrv.requestXHR($scope, {
                        method: 'POST',
                        url: host + '/rest/elsearch/api/v1/searchPurchase/uetp',
                        data: param
                    }).then(function (response) {
                       if (response.data.success) {
                          $scope.proceduresList = response.data.items;
                          $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                          $scope.total = response.data.total;
                          if (!!param.search || (!!param.searchByField && Object.keys(param.searchByField).length > 0)
                                || (!!param.filter && Object.keys(param.filter).length > 0)) {
                                scService.setCounter(response.data.total);
                          } else {
                                scService.clearCounter();
                          }
                          if('participant' == $scope.cabType){
                               participantSrv.markFavorites($scope.proceduresList);
                          }
                       }else{
	                       $scope.alertSearchProcedure = alertSrv.getErrorMsg(response.data.errorMessage||'Ошибка при выполнении запроса');
                           console.log(response.data);
                       }
                    }, function (reason) {
                        console.log(reason);
                     });
             }, function (reason) {
                   console.log(reason);
             });
        }


        function publicProcedures44LoadFn() {
            filterService.initModelView('Поиск', 'Процедуры');
            prepareTableParams('filterProcedures44');
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                method: 'POST',
                url: '/site/procedures/44', // listFree
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.proceduresList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function publicProceduresSOCLoadFn() {
            filterService.initModelView('Поиск по заказчику, наименованию и номеру закупки', 'Процедуры');
            prepareTableParams('filterProceduresSOC');
            var param = angular.copy($scope.paginator);
            if (param) {
                if (!param.searchByField) {
                    param.searchByField = {};
                }

                if (!param.orderBy) {
                    param.orderBy = "startDate";
                    param.orderAsc = false;
                    var startDate = {
                        "activate": true,
                        "orderAsc": false,
                        "tag": "desc"
                    }
                    $scope.sortByObject.startDate = startDate;
                }
                if (param.searchByField) {
                    desktopSrv.removeKeys(param, 'deff__');
                    paramToStr(param.searchByField);
                }
                if (constants.configProject.type != 'etp') {
                    if (!param.searchByField) param.searchByField = {};
                    param.searchByField.portalType = constants.configProject.type
                }
                $scope.currentPaginator = angular.copy(param);
            }

            if(!param.searchByField) {
	            param.searchByField={};
            }
            delete param.searchByField.lots__items__okpd2Code;
            if(param.searchByField.customer == ''){
	            delete param.searchByField.customer;  
            }
            param.searchByField.indexes = 'purchase_uetp_*';
            param.searchByField.customerType = 'simplecontest';
            propertiesService.getProperty("market.url").then(function (host) {
                    httpSrv.requestXHR($scope, {
                        method: 'POST',
                        url: host + '/rest/elsearch/api/v1/searchPurchase/uetp',
                        data: param
                    }).then(function (response) {
                       if (response.data.success) {
                          $scope.proceduresList = response.data.items;
                          $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                          $scope.total = response.data.total;
                          if (!!param.search || (!!param.searchByField && Object.keys(param.searchByField).length > 0)
                                || (!!param.filter && Object.keys(param.filter).length > 0)) {
                                scService.setCounter(response.data.total);
                          } else {
                                scService.clearCounter();
                          }
                          if('participant' == $scope.cabType){
                               participantSrv.markFavorites($scope.proceduresList);
                          }
                       }else{
	                       $scope.alertSearchProcedure = alertSrv.getErrorMsg(response.data.errorMessage||'Ошибка при выполнении запроса');
                           console.log(response.data);
                       }
                    }, function (reason) {
                        console.log(reason);
                     });
             }, function (reason) {
                   console.log(reason);
             });
        }

        function checkOtherProceduresAccess() {
            var deferred = $q.defer();
            if (!authSrv.canActiveOtherPurchase($scope.cabType, $scope.user))
                deferred.reject({
                    messageTxt: 'Доступ к Реестру закупок ЕИС закрыт.',
                    state: $scope.cabType === 'participant' ? 'myProcedures' : 'home'
                });
            else {
                if (authSrv.checkTariffsZ04_D01($scope.cabType, $scope.user)) {
                    propertiesService.getProperty("otherProcedures.accessWithoutTariff").then(function (accessWithoutTariff) {
                        if (accessWithoutTariff === "false")
                            tariffsSrv.getNeedTariffForServiceMsg().then(function(msg){
                                deferred.reject({
                                    messageTxt: msg,
                                    state: 'tariffsMenu'
                                });
                            });
                        else deferred.resolve();
                    });
                } else deferred.resolve();
            }
            return deferred.promise;
        }

        function otherProceduresLoadFn() {
            return authSrv.reloadOrgInfo().then(function (user) {
                $scope.registryPlaceholder = true;
                $scope.user = user;
                return checkOtherProceduresAccess().then(function () {
                    filterService.initModelView('Наименование закупки, код/наименование номенклатуры по ОКПД2, ОКВЭД2, КТРУ', 'Иные закупки');
                    prepareTableParams('filterProceduresOther');
                    var param = angular.copy($scope.paginator);
                    if (param) {
                        if (!param.orderBy) {
                            param.orderBy = "publish_date_first";
                            param.orderAsc = false;
                            var startDate = {
                                "activate": true,
                                "orderAsc": false,
                                "tag": "desc"
                            };
                            $scope.sortByObject.publish_date_first = startDate;
                        }
                        if (param.searchByField) {
                            desktopSrv.removeKeys(param, 'deff__');
                            desktopSrv.removeKeys(param, 'lots__lotItems__');
                            desktopSrv.removeKeys(param, 'customers__region');
                            desktopSrv.removeKeys(param, 'customer__inn');
                            paramToStr(param.searchByField);
                        }

                        $scope.currentPaginator = angular.copy(param);
                    }
                    return proceduresSrv.searchPurchases($scope, param, true).then(function (response) {
                        if (response.data.success) {
                            $scope.proceduresList = response.data.items;
                            $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                            $scope.total = response.data.total;
                            $scope.registryPlaceholder = false;
                            if (!!param.search || (!!param.searchByField && Object.keys(param.searchByField).length > 0)
                                || (!!param.filter && Object.keys(param.filter).length > 0)) {
                                scService.setCounter(response.data.total);
                            } else {
                                scService.clearCounter();
                            }
                        }
                    }, function (reason) {
                        console.log(reason);
                    });
                }, function (reason) {
                    dialogSrv.openModal('_dialogMessage.html', {message: reason.messageTxt}).then(function () {
                        $state.go(reason.state);
                    })
                });
            });
        }

        function purchasePlanLoadFn() {
            $scope.registryPlaceholder = true;
            filterService.initModelView('Наименование закупки, код/наименование номенклатуры по ОКПД2, ОКВЭД2, КТРУ', 'Иные закупки');
            prepareTableParams('filterPurchasePlan');
            var param = angular.copy($scope.paginator);
            if (param) {
                if (!param.orderBy) {
                    param.orderBy = "publish_date_first";
                    param.orderAsc = false;
                    var startDate = {
                        "activate": true,
                        "orderAsc": false,
                        "tag": "desc"
                    };
                    $scope.sortByObject.publish_date_first = startDate;
                }
                if (param.searchByField) {
                    desktopSrv.removeKeys(param, 'deff__');
                    desktopSrv.removeKeys(param, 'lots__lotItems__');
                    desktopSrv.removeKeys(param, 'customers__region');
                    paramToStr(param.searchByField);
                }
                $scope.currentPaginator = angular.copy(param);
            }

            if (!param.searchByField)
                param.searchByField = {};

            if(param.searchByField.hiddenyear === 'empty') {
                delete param.searchByField.hiddenyear;
            } else if (!param.searchByField.year) {
                param.searchByField.year = (getServerTimeMsk() || new Date()).getFullYear().toString();
            }
            //получаем заказчика из фильтра или из списка всех назначених этом организатору
            if (param.searchByField.customersOfOrganizer) {
                //заказчики из фильтра
                param.searchByField.section = '223';
                param.searchByGroup = {};
                param.searchByGroup.customer = getCustomer(param.searchByField.customersOfOrganizer);
                delete param.searchByField.customersOfOrganizer;
                return doPurchasePlanLoad(param)
            } else {
                //заказчики из списка
                if(!$stateParams.allCustomers && $stateParams.headDzo223Customers && ['admincab', 'support'].includes($scope.cabType)){
                    return getEmptyLoad();
                }
                return ($stateParams.allCustomers ? organizatorSrv.getAllCustomers() : organizatorSrv.getCustomers()).then(function (response) {
                    if (response.data.success) {
                        param.searchByField.section = '223';
                        param.searchByGroup = {};
                        param.searchByGroup.customer = getCustomer(response.data.result);
                        return doPurchasePlanLoad(param)
                    }
                });
            }


            /*
            * Получаем объект для группировки в searchByGroup
            * */
            function getCustomer(data) {
                var result = [];
                angular.forEach(data, function (item) {
                    var obj = {};
                    if (item.inn) obj.inn = item.inn;
                    if (item.kpp) obj.kpp = item.kpp;
                    if (item.ogrn) obj.ogrn = item.ogrn;
                    if (obj.inn || obj.kpp || obj.ogrn)
                        result.push(obj);
                });
                return result;
            }

            /*
            * Выполнить запрос
            * */
            function doPurchasePlanLoad(param) {
                return proceduresSrv.searchPurchases($scope, param).then(function (response) {
                    if (response.data.success) {
                        $scope.proceduresList = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        $scope.total = response.data.total;
                        $scope.registryPlaceholder = false;
                        if (!!param.search || (!!param.searchByField && Object.keys(param.searchByField).length > 0)
                            || (!!param.filter && Object.keys(param.filter).length > 0)) {
                            scService.setCounter(response.data.total);
                        } else {
                            scService.clearCounter();
                        }
                    }
                }, function (reason) {
                    console.log(reason);
                });
            }
            function getEmptyLoad() {
                $scope.proceduresList = [];
                $scope.totalPages = 0;
                $scope.total = 0;
                $scope.registryPlaceholder = false;
                scService.clearCounter();
            }

        }

        function explanationReqsLoadFn() {
            filterService.initModelView('Поиск по номеру закупки, ИНН/УИН и Наименованию участника, приславшего запрос, теме', '');
            prepareTableParams('filterExplanationRequests');
            if (!$scope.paginator.orderBy) {
                var defaultSortElement = document.getElementById('createdTitle');
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
            }
            var param = angular.copy($scope.paginator);
            httpSrv.http({
                method: 'POST',
                url: '/organizer/explreqs/list',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                    $rootScope.$emit('UPDATE:EXPLREQS');
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        $scope.getStateDesc = (data) => {
            if(data.state.id==='created') return "Создано";
            if(data.state.id==='published') return "Опубликовано";
            if(data.state.id==='sent_participant') return "Направлено в ЛК \n"+((data.participant.typeOrg === 'nr' ? "УИН " + data.participant.uin : "ИНН "+ data.participant.inn)+ ", " + data.participant.fullName);
        }

        function explanationsLoadFn() {
            filterService.initModelView('Поиск по номеру закупки, ИНН/УИН и Наименованию участника, приславшего запрос, теме', '');
            prepareTableParams('filterExplanations');
            if (!$scope.paginator.orderBy) {
                var defaultSortElement = document.getElementById('createdTitle');
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
                tablesSrv.changeSort($scope.paginator, defaultSortElement);
            }
            const param = angular.copy($scope.paginator);
            httpSrv.http({
                method: 'POST',
                url: '/organizer/expls/list',
                data: param,
                showCounter: true
            }).then((response) => {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, (error) => {
                console.log(error);
            });
        }


        /**
         * Загрузка всех процедур
         */
        function allProceduresFn() {
            filterService.initModelView('Поиск по заказчику, наименованию и номеру закупки', 'Закупки');
            prepareTableParams('filterProceduresAll');
            var param = angular.copy($scope.paginator);
            if (param) {
                if (!param.orderBy) {
                    param.orderBy = "startDate";
                    param.orderAsc = false;
                    var startDate = {
                        "activate": true,
                        "orderAsc": false,
                        "tag": "desc"
                    }
                    $scope.sortByObject.startDate = startDate;
                }
                if(!param.searchByField) {
	                param.searchByField={};
                }

                delete param.searchByField.lots__items__okpd2Code;
                if(param.searchByField.customer == ''){
	                delete param.searchByField.customer;  
                }

                if (param.searchByField && param.searchByField.typeProcedure) {
                    //фильтр по секции
                    if (param.searchByField.typeProcedure === 'all') {
	                    param.searchByField.indexes = 'purchase_uetp_*';
                    } else if (param.searchByField.typeProcedure === 'commercial') {
                        param.searchByField.indexes = 'purchase_uetp_commercial';
                    } else if (param.searchByField.typeProcedure === '223') {
	                    param.searchByField.indexes = 'purchase_uetp_223';
                    } else if (param.searchByField.typeProcedure === '44') {
	                    param.searchByField.indexes = 'purchase_uetp_44';
                    } else if (param.searchByField.typeProcedure === 'simplecontest') {
                    	param.searchByField.indexes = 'purchase_uetp_*';
                        param.searchByField.customerType = 'simplecontest';
                    }
                }else{
	               param.searchByField.indexes = 'purchase_uetp_*';
                   param.searchByField.typeProcedure = 'all';
                }

                if (param.searchByField) {
                    desktopSrv.removeKeys(param, 'deff__');
                    paramToStr(param.searchByField);
                }
                if (constants.configProject.type != 'etp') {
                    if (!param.searchByField) param.searchByField = {};
                    param.searchByField.portalType = constants.configProject.type
                }
                $scope.currentPaginator = angular.copy(param);
            }

            propertiesService.getProperty("market.url").then(function (host) {
                    httpSrv.requestXHR($scope, {
                        method: 'POST',
                        url: host + '/rest/elsearch/api/v1/searchPurchase/uetp',
                        data: param
                    }).then(function (response) {
                       if (response.data.success) {
                          $scope.proceduresList = response.data.items;
                          $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                          $scope.total = response.data.total;
                          if (!!param.search || (!!param.searchByField && Object.keys(param.searchByField).length > 0)
                                || (!!param.filter && Object.keys(param.filter).length > 0)) {
                                scService.setCounter(response.data.total);
                          } else {
                                scService.clearCounter();
                          }
                          if('participant' == $scope.cabType){
                               participantSrv.markFavorites($scope.proceduresList);
                          }
                       }else{
	                       $scope.alertSearchProcedure = alertSrv.getErrorMsg(response.data.errorMessage||'Ошибка при выполнении запроса');
                           console.log(response.data);
                       }
                    }, function (reason) {
                        console.log(reason);
                     });
             }, function (reason) {
                   console.log(reason);
             });
        }

        /**
         * Загрузка списка моих процедур у участника
         */
        function myProceduresFn() {
            filterService.initModelView('Поиск по заказчику, наименованию и номеру закупки', 'Мои закупки');
            prepareTableParams('filterCabProcedures');
            var param = angular.copy($scope.paginator);
            if (param.searchByField) {
                desktopSrv.removeKeys(param, 'deff__');
                paramToStr(param.searchByField);
            }
            httpSrv.http({
                method: 'POST',
                url: '/participant/procedures',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.proceduresList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        $scope.testExplanation = function (data) {
            $state.go('createReqExplanation', {procType: data.type.id, procId: data.id})
        }

        $scope.setCategory = function (value, type) {
            if (type == 'questionAnswer') {
                questionAnswerSrv.setSelectedCategory(value);
                $scope.paginator.page = 0;
                questionAnswerPublishedLoadFn();
            }
        };

        /**
         * Загрузка списка заказчиков
         */
        function customerLoadFn() {
            filterService.initModelView('Поиск по наименованию и ИНН организации', 'Заказчики');
            prepareTableParams('filterCustomer');
            var param = angular.copy($scope.paginator);
            if (!param.orderBy) {
                param.orderBy = "name";
                param.orderAsc = true;
                var sortObj = {
                    "activate": true,
                    "orderAsc": false,
                    "tag": "desc"
                };
                $scope.sortByObject.name = sortObj;
            }
            httpSrv.http({
                url: '/admin/customer/customerList',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка счетов
         */
        // function invoiceLoadFn() {
        //     filterService.initModelView('Поиск по плательщику, номеру', 'Реестр счетов');
        //     prepareTableParams('filterInvoice');
        //     var param = angular.copy($scope.paginator);
        //
        //
        //     invoiceSrv.getInvoices(param);
        //
        //     // httpSrv.http({
        //     //     url: '/auth/tariff/invoices',
        //     //     data: param,
        //     //     showCounter: true
        //     // }).then(function successCallback(response) {
        //     //     if (response.data.success) {
        //     //         $scope.dataTable = response.data.items;
        //     //         $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
        //     //     }
        //     // }, function errorCallback(response) {
        //     //     console.log(response);
        //     // });
        // }

        /**
         * Загрузка Авторизованных УЦ
         */

        function ucLoadFn() {
            filterService.initModelView('Поиск по наименованию УЦ, краткому наименованию, ИНН, ОГРН', 'Доверенные УЦ');
            prepareTableParams('filterUC');
            var param = angular.copy($scope.paginator);
            // param.state = "Действует";
            // if (!isSortEvent && !tablesService.getTableSort(state.name)) {
            //     param.orderBy = "authorizationState, ranking";
            //     param.orderAsc = false;
            // }
            var http = $injector.get('httpSrv');
            http.http({
                url: '/cert/rest/reestruc/list',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.tableData = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function getdsFn() {
            filterService.initModelView('Поиск по наименованию и ИНН', 'Доверенные УЦ');
            window.scrollTo(0, 0);
            prepareTableParams('filterUC');
            var param = angular.copy($scope.paginator);
            param.orderBy = "ranking";
            param.orderAsc = false;
            param.state = "AccreditationState_WORK";
            httpSrv.http({
                url: '/cert/rest/reestruc/list', // listFree
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.reMyStore = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка заявлений на аккредитацию
         */
        function accreditationLoadFn() {
            filterService.initModelView('Поиск по наименованию и ИНН/УИН организации', 'Заявления на аккредитацию');
            if (!$scope.paginator.orderBy) {
                $scope.paginator.orderBy  = 'created';
                $scope.paginator.orderAsc  = true;
            }
            // if ($scope.paginator.state==undefined) {
            //     filterService.resetCurrent('filterAccreditation', $state.current.name);
            // }
            prepareTableParams('filterAccreditation');

            $scope.paginator.state = $stateParams.notApproved ? "sent,processing,deferred" : "";
            var accredState = localStorage.getItem("accredState");
            if (accredState === 'accred') {
                $scope.alertObjAccred = alertSrv.getSuccessMsg('Участник успешно аккредитован.');
            } else if (accredState === 'notaccred') {
                $scope.alertObjAccred = alertSrv.getErrorMsg('Участнику отказано в аккредитации.');
            }
            localStorage.setItem("accredState", null);
            httpSrv.http({
                url: '/admin/browseAllAccreditations',
                data: $scope.paginator,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Загрузка списка заявлений на регистрацию заказчиков
         */
        function customerRegistrationTableLoadFn() {
            filterService.initModelView('Поиск по наименованию и ИНН организации', 'Заявления на регистрацию заказчиков');
            if (!$scope.paginator.orderBy) {
                $scope.paginator.orderBy  = 'created';
                $scope.paginator.orderAsc  = true;
            }
            prepareTableParams('filterCustomerRegistration');

            $scope.paginator.state = $stateParams.notApproved ? "waiting,processing" : "";
            const regState = localStorage.getItem("customerRegistrationState");
            if (regState === 'registered') {
                $scope.alertObjCustomerReg = alertSrv.getSuccessMsg('Регистрация подтверждена.');
            } else if (regState === 'notregistered') {
                $scope.alertObjCustomerReg = alertSrv.getErrorMsg('Отказано в регистрации.');
            }
            localStorage.setItem("customerRegistrationState", null);
            httpSrv.http({
                url: '/admin/browseCustomerRegistrations',
                data: $scope.paginator,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.dataTable = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function offersLoadFn() {
            filterService.initModelView('Поиск по поставщику и наименованию, номеру, составу предложения', 'Предложения');
            // prepareTableParams('filterOffers');
            // var XHR = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
            // var xhr = new XHR();
            //
            // xhr.open('POST', $scope.webmarketURL + '/rest/ccwe/purchase/browseOffers', true);
            // xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            // xhr.setRequestHeader('Type-Shop', 'rzd');
            $rootScope.placeholderMin = 'Минимум 3 символа';
            $rootScope.somePlaceholder = 'Поиск';
            $rootScope.formName = 'Предложения';
            prepareTableParams('filterOffers');
            var param = angular.copy($scope.paginator);

            if (!param.orderBy) {
                param.orderBy = "publicDate";
                param.orderAsc = false;
                $scope.sortByObject.publicDate = {
                    activate: true,
                    orderAsc: false,
                    tag: "-alt"
                };
            }
            param.state = "home";

            if (!param.searchByField) param.searchByField = {};
            param.searchByField.site = constants.configProject.type;
            if (param.searchByField.s_endDate) {
                param.searchByField.s_endDate = _getDateByString(param.searchByField.s_endDate).getTime();
            }
            if (param.searchByField.e_endDate) {
                var endDate = _getDateByString(param.searchByField.e_endDate);
                var tomorrow = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
                param.searchByField.e_endDate = tomorrow.getTime();
            }
            if (param.searchByField.s_publicDate) {
                param.searchByField.s_publicDate = _getDateByString(param.searchByField.s_publicDate).getTime();
            }
            if (param.searchByField.e_publicDate) {
                var endDate = _getDateByString(param.searchByField.e_publicDate);
                var tomorrow = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
                param.searchByField.e_publicDate = tomorrow.getTime();
            }
            if (param.searchByField.number) {
                param.searchByField.number = parseInt(param.searchByField.number);
            }
            propertiesService.getProperty("market.url").then(function (host) {
                httpSrv.requestXHR($scope, {
                    url: host + '/rest/elsearch/api/v1/searchOffer',
                    data: param,
                    showCounter: true
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.offersList = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);

                        processCounter(response, param);
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            });
        }

        function _getDateByString(dateStr) {
            if (!dateStr) return {};
            if (dateStr.indexOf(" ") !== -1)
                dateStr = dateStr.substring(0, dateStr.indexOf(" "));
            var parts = dateStr.split(".");
            return new Date(parts[2], parts[1] - 1, parts[0])
        }

        function offersMyLoadFn() {
            $rootScope.somePlaceholder = 'Поиск по наименованию, номеру, составу предложения';
            filterService.initModelView('Поиск по наименованию, номеру, составу предложения', 'Мои предложения');
            $rootScope.formName = 'Мои предложения';
            prepareTableParams('filterMyOffers');
            var param = angular.copy($scope.paginator);

            if (!param.searchByField) param.searchByField = {};
            param.searchByField.site = constants.configProject.type;
            if (param.searchByField.s_endDate) {
                param.searchByField.s_endDate = _getDateByString(param.searchByField.s_endDate).getTime();
            }
            if (param.searchByField.e_endDate) {
                var endDate = _getDateByString(param.searchByField.e_endDate);
                var tomorrow = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
                param.searchByField.e_endDate = tomorrow.getTime();
            }
            if (param.searchByField.s_publicDate) {
                param.searchByField.s_publicDate = _getDateByString(param.searchByField.s_publicDate).getTime();
            }
            if (param.searchByField.e_publicDate) {
                var endDate = _getDateByString(param.searchByField.e_publicDate);
                var tomorrow = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
                param.searchByField.e_publicDate = tomorrow.getTime();
            }

            httpSrv.http({
                url: '/orders/api/v1/offer/browse',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.myOffers = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function ordersLoadFn() {
            $rootScope.somePlaceholder = 'Поиск по номеру, наименованию заказа и поставщика';
            $rootScope.formName = 'Входящие заказы';
            filterService.initModelView($rootScope.somePlaceholder, $rootScope.formName);
            prepareTableParams('filterOrder');
            $scope.paginator.state = "incoming";
            var param = angular.copy($scope.paginator);
            if (!param.searchByField) param.searchByField = {};
            if (param.searchByField.s_createDate) {
                param.searchByField.s_createDate = _getDateByString(param.searchByField.s_createDate).getTime();
            }
            if (param.searchByField.e_createDate) {
                var createDate = _getDateByString(param.searchByField.e_createDate);
                var tomorrow = new Date(createDate.getFullYear(), createDate.getMonth(), createDate.getDate() + 1);
                param.searchByField.e_createDate = tomorrow.getTime();
            }
            httpSrv.http({
                url: '/orders/api/v1/order/browse',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.incomingOrdersList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function ordersMyLoadFn() {
            $rootScope.somePlaceholder = 'Поиск по номеру, наименованию заказа и поставщика';
            $rootScope.formName = 'Мои заказы';
            filterService.initModelView($rootScope.somePlaceholder, $rootScope.formName);
            prepareTableParams("filterOrder");
            var param = angular.copy($scope.paginator);
            if ($stateParams.param) {
                param.searchByField = $stateParams.param;
            }
            if (!param.searchByField) param.searchByField = {};
            if (param.searchByField.s_createDate) {
                param.searchByField.s_createDate = _getDateByString(param.searchByField.s_createDate).getTime();
            }
            if (param.searchByField.e_createDate) {
                var createDate = _getDateByString(param.searchByField.e_createDate);
                var tomorrow = new Date(createDate.getFullYear(), createDate.getMonth(), createDate.getDate() + 1);
                param.searchByField.e_createDate = tomorrow.getTime();
            }
            $scope.paginator.state = "my";
            httpSrv.http({
                url: '/orders/api/v1/order/browse',
                data: param,
                showCounter: true
            }).then(function successCallback(response) {
                if (response.data.success) {
                    $scope.myOrdersList = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                }
            }, function errorCallback(response) {
                console.log(response);

            });
        }

        function positionsLoadFn() {
            $rootScope.somePlaceholder = 'Поиск по поставщику и наименованию, номеру, составу позиции предложения';
            $rootScope.formName = 'Позиции';
            filterService.initModelView($rootScope.somePlaceholder, $rootScope.formName);
            prepareTableParams('filterPosition');
            var param = angular.copy($scope.paginator);
            if (!param.orderBy) {
                param.orderBy = "publicDate";
                param.orderAsc = false;
                $scope.sortByObject.publicDate = {
                    activate: true,
                    orderAsc: false,
                    tag: "-alt"
                };
                // $scope.sortByObject.publicDate.activate = true;
                // $scope.sortByObject.publicDate.orderAsc = false;
                // $scope.sortByObject.publicDate.tag = "-alt";
            }
            if (!param.searchByField) param.searchByField = {};
            param.searchByField.site = constants.configProject.type;

            if (param.searchByField.s_endDate) {
                param.searchByField.s_endDate = _getDateByString(param.searchByField.s_endDate).getTime();
            }

            if (param.searchByField.e_endDate) {
                var endDate = _getDateByString(param.searchByField.e_endDate);
                var tomorrow = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
                param.searchByField.e_endDate = tomorrow.getTime();
            }
            propertiesService.getProperty("market.url").then(function (host) {
                httpSrv.requestXHR($scope, {
                    url: host + '/rest/elsearch/api/v1/searchOfferPosition',
                    data: param,
                    showCounter: true
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.offersMain = response.data.items;
                        $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                        processCounter(response, param);
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            });
        }

        function processCounter(response, param) {
            if (!!param.search || (!!param.searchByField && Object.keys(param.searchByField).length > 1)
                || (!!param.filter && Object.keys(param.filter).length > 0)) {
                scService.setCounter(response.data.total);
            } else {
                scService.clearCounter();
            }
        }

        function getPositionsDetails(id) {
            if ($scope.positionsDetailsId.id === id) {
                $scope.positionsDetailsId.id = ''
            } else {
                $scope.positionsDetailsId.id = '';

                var XHR = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
                var xhr = new XHR();

                xhr.open('GET', $scope.webmarketURL + '/rest/ccwe/purchase/getOfferPosition/' + id, true);
                xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
                xhr.setRequestHeader('Type-Shop', 'rzd');

                var param = angular.copy($scope.paginator);
                xhr.send(JSON.stringify(param));

                xhr.onload = function () {
                    var response = JSON.parse(xhr.response);
                    if (response.success) {
                        $scope.positionsDetailsId.id = id;
                        $scope.positionsDetails.position = response.result;
                    } else {
                        console.log(response);
                        $scope.positionsDetails.position = {};
                    }
                    $scope.$apply();
                };

                xhr.onError = function () {
                    console.log(this);
                };
            }
        }

        function companiesLoadFn() {
            filterService.initModelView('Поиск по наименованию, региону, категории', 'Список компаний');
            prepareTableParams('filterCompanies');

            var XHR = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
            var xhr = new XHR();
            xhr.open('POST', $scope.webmarketURL + '/rest/ccwe/publicity/supplierscatalog/browseOpen', true);
            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            xhr.setRequestHeader('Type-Shop', 'rzd');

            var param = angular.copy($scope.paginator);
            param.state = "home";
            xhr.send(JSON.stringify(param));

            xhr.onload = function () {
                var response = JSON.parse(xhr.response);
                if (response.success) {
                    $scope.tableData = response.items;
                    $scope.totalPages = Math.ceil(response.total / $scope.paginator.itemsPerPage);
                } else {
                    console.log(response);
                }
                $scope.$apply();
            };

            xhr.onError = function () {
                console.log(this);
            };
        }

        function openCompanyFn(id) {
            window.open($scope.webmarketUEM + '/companies/' + id, "_blank");
        }

        function getFullNameFn(item) {
            if (item) {
                if (angular.isUndefined(item.fullName)) {
                    return item.lastName + ' ' + item.firstName + ' ' + item.middleName;
                } else {
                    return item.fullName;
                }
            }
        }

        function isVipAccreditationFn(accreditation) {
            return accreditation.owner.fastAccreditation == true;
        }

        $scope.clickRow = function (row, stateName) {
            if (stateName === 'openMessage' && row.messageType === 7) {
                $state.go('openReqExplanationOrg', {procType: row.purchaseType, procId: row.purchaseId, explReqId: row.id});
            } else if (stateName === 'openMessage' && row.messageType === 8) {
                $state.go('openAnswerExplanationOrg', {procType: row.purchaseType, procId: row.purchaseId, explId: row.id});
            } else if (stateName === 'positions') {
                getPositionsDetails(row.id);
            } else if (stateName === 'offer') {
                if (["etp", "ppk"].includes((row.positions[0] || {}).site)) {
                    $state.go("offers.view", {id: row.id, params: prepareFormParams()})
                } else {
                    propertiesService.getProperty("uem.url").then(function (host) {
                        window.open(host + '/offers/' + row.id, "_blank");
                    });
                }

            } else if (stateName) {
                $state.go(stateName, {id: row.id, params: prepareFormParams()});
            } else {
                $state.go(state.name.replace("My", "") + ".view", {id: row.id, params: prepareFormParams()})
            }
        };

        function prepareFormParams() {
            var dataObj = {clearConfig: true};
            if (state.name == "accreditation" || state.name == "customerRegistrationTable" )
                dataObj.notApproved = $stateParams.notApproved;
            return dataObj;
        }

        function prepareTableParams(filterType) {
            tablesSrv.prepareTableParams($scope.paginator, filterType);
        }


        function changeFavoriteFn(procedure) {
            if (!procedure) {
                return;
            }
            httpSrv.http({
                method: 'GET',
                url: '/site/procedures/' + (!procedure.favorite ? 'addProcedureToFavourites' : 'removeProcedureFromFavourites') + '/' + procedure.id
            }).then(function successCallback(response) {
                if (response.data.success) {
                    procedure.favorite = !procedure.favorite;
                    if (state.name == 'myProcedures') {
                        myProceduresFn();
                    }
	                participantSrv.updateFavorite(procedure);
                } else {
                    dialogSrv.showDialogMsg($scope, response.data.message);
                }
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function changeSortFn($event) {
            var th = $event.currentTarget;

            [].forEach.call(document.querySelectorAll(".sortButton"), function (button) {
                button.classList.remove("boldSort");
            });
            th.classList.add("boldSort");

            if (tablesSrv.changeSort($scope.paginator, th)) {
                loadScopeMth();
            }
        }

        /**
         * Сменить сортировку и вызвать метод перегрузки
         * @param by - кого сортируем
         */
        function sortByFn(by) {
            tablesSrv.sortBy(by, $scope.sortByObject, $scope.paginator, loadScopeMth);
        }

        /**
         * Сменить сортировку на выбранную из выпадающего списка вызвать метод перегрузки
         * @param by - кого сортируем
         * @param orderAsc - направление сортировки
         */
        function sortByDropdownFn($event, orderBy, orderAsc) {
            [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                column.classList.remove("sortDesc");
                column.classList.remove("sortAsc");
            });
            const th = $event.target.closest("th");
            [].forEach.call(th.querySelectorAll(".sortColumn"), function (el) {
               el.classList.add(orderAsc ? "sortAsc" : "sortDesc");
            })
            tablesSrv.sortByOrder(orderBy, orderAsc, $scope.sortByObject, $scope.paginator, loadScopeMth);
        }

        /**
         * Двойной клик по ячейке таблицы
         * @param data - данные строки
         * @param param - параметры клика
         */
        function dbClickRowFn(data, param) {
            if (param === 'notice') {
                noticeSrv.setState('procedure');
                noticeSrv.setShowPanel('procedure');
                $state.go(param, {type: data.type.id, id: data.id, clearConfig: true});
            } else if (param === 'openProcedure') {
                $state.go(param, {procType: data?.type?.id||data?.procedure?.type?.id, procId: !data?.type ? data?.procedure?.id : data.id, clearConfig: true});
            } else if (param === 'subscription') {
                $state.go('subscription', {id: data.id, clearConfig: true});
            } else if (param === 'bankGuaranteeAppOpen') {
                var isUserAgent = authSrv.isUserAgent();
                if (data.type && data.type === 'primary') {
                    if (isUserAgent) {
                        $state.go('bankGuaranteePrimaryAppAgentOpen', {id: data.id, participantId: data.orgId, participantSelected: !!$scope.modelView.bankGuaranteesParticipant, clearConfig: true});
                    } else {
                        $state.go('bankGuaranteePrimaryAppOpen', {id: data.id, participantId: data.orgId, clearConfig: true});
                    }
                } else {
                    if (isUserAgent) {
                        $state.go('bankGuaranteeAppAgentOpen', {id: data.id, participantId: data.orgId, participantSelected: !!$scope.modelView.bankGuaranteesParticipant, clearConfig: true});
                    } else {
                        $state.go('bankGuaranteeAppOpen', {id: data.id, participantId: data.orgId, clearConfig: true});
                    }

                }
            } else if (param === 'bankGuaranteeAppLokoOpen') {
                const isUserAgent = authSrv.isUserAgent();
                if (isUserAgent) {
                    $state.go('bankGuaranteeAppLokoAgentOpen', {id: data.id, participantId: data.orgId, participantSelected: !!$scope.modelView.bankGuaranteesParticipant, clearConfig: true});
                } else {
                    $state.go('bankGuaranteeAppLokoOpen', {id: data.id, clearConfig: true});
                }

            } else if (param === 'bankGuaranteeAppVTBOpen') {
                const isUserAgent = authSrv.isUserAgent();
                if (isUserAgent) {
                    $state.go('bankGuaranteeAppVTBAgentOpen', {id: data.id, participantId: data.orgId, participantSelected: !!$scope.modelView.bankGuaranteesParticipant, clearConfig: true});
                } else if($scope.cabType === 'admincab'){
                    $state.go('bankGuaranteeAppVTBAdminOpen', {id: data.id, participantId: data.orgId, clearConfig: true});
                } else {
                    $state.go('bankGuaranteeAppVTBOpen', {id: data.id, clearConfig: true});
                }

            } else if (param === 'rfGuaranteeAppOpen') {
                $state.go('rfGuaranteeAppOpen', {id: data.id, clearConfig: true, participant: $scope.modelView.rfGuaranteesParticipant});
            }
        }

        /**
         * Создать процедуру
         * @param type - тип процедуры
         */
        function createNoticeFn(type, isCommercial, section) {
            noticeSrv.setState('procedure');
            noticeSrv.setShowPanel('procedure');
            $state.go(isCommercial ? 'createCommercialNotice' : (section == '44' ? 'createNotice44' : 'createNotice'), {type: type});
        }

        /**
         * название customer по его типу
         * @param type
         */
        function getCustomerByTypeFn(type) {
            var val;
            angular.forEach($scope.typeCustomer, function (value) {
                if (value.id == type) val = value.name;
            });
            return val;

        }

        /**
         * название типа портала по его коду
         * @param code
         */
        function getPortalTypeByCodeFn(code) {
            var val;
            angular.forEach($scope.typePortal, function (value) {
                if (value.id == code) val = value.name;
            });
            return val;

        }

        /**
         * название типа портала по его коду
         * @param code
         */
        function getVersionInfoTypeByCodeFn(code) {
            return ($scope.versionInfoTypes.find(value => value.id===code)||{"name":"Все ЛК"}).name;
        }

        function initMessFn(mess) {
            if (new RegExp('(Заявка № )\\d+( по лоту № )\\d+( не\\sдопущена|notAdmitted)').test(mess)) {
                mess = mess.replace(new RegExp('не допущена\\.', 'g'), '<b class="red">не допущена</b>.');
                mess = mess.replace(new RegExp('не допущена к следующему этапу\\.', 'g'), '<b class="red">'+'не допущена'+'</b> к следующему этапу.');
                mess = mess.replace(new RegExp('не допущена к подаче предложений\\.', 'g'), '<b class="red">'+'не допущена'+'</b> к подаче предложений.');
                mess = mess.replace(new RegExp('не допущена к торгам аукциона\\.', 'g'), '<b class="red">'+'не допущена'+'</b> к торгам аукциона.');
            }
            if (new RegExp('(Заявка № )\\d+( по лоту № )\\d+( допущена)').test(mess)) {
                mess = mess.replace(new RegExp('допущена\\.', 'g'), '<b class="green">'+'допущена'+'</b>.');
                mess = mess.replace(new RegExp('допущена к следующему этапу\\.', 'g'), '<b class="green">'+'допущена'+'</b> к следующему этапу.');
                mess = mess.replace(new RegExp('допущена к подаче предложений\\.', 'g'), '<b class="green">'+'допущена'+'</b> к подаче предложений.');
                mess = mess.replace(new RegExp('допущена к торгам аукциона\\.', 'g'), '<b class="green">'+'допущена'+'</b> к торгам аукциона.');
            }
            if (new RegExp('(Заявка № )\\d+( по лоту № )\\d+( не рассматривалась)').test(mess)) {
                mess = mess.replace(new RegExp('не рассматривалась', 'g'), '<b class="red">'+'не рассматривалась'+'</b>');
            }
            return mess
        }

        function toggleCheckedItemFn($event, item, items) {
            checkItems($event.target.checked, item.id);
            $scope.bulkChBox = items.length == $scope.checkedItems.length;
            timeout($scope);
        }

        function checkItems(checked, id) {
            if (!Array.isArray($scope.checkedItems)) $scope.checkedItems = [];
            var pos = $scope.checkedItems.indexOf(id);
            if (pos === -1 && checked) {
                $scope.checkedItems.push(id);
            }
            if (pos !== -1 && !checked) {
                $scope.checkedItems.splice(pos, 1);
            }
        }

        function sentPaymentOrdersFn(ids) {
            if (ids && ids.length > 0) {
                paymentOrderSrv.sentPaymentOrders(ids).then(function (response) {
                    if (response.data.success) {
                        paymentOrdersLoadFn();
                    } else {
                        console.log(response.data.message);
                    }
                })
            }
        }

        function sentAllPaymentOrdersFn(elements) {
            if (elements && elements.length > 0) {
                paymentOrderSrv.sentAllPaymentOrders().then(function (response) {
                    if (response.data.success) {
                        paymentOrdersLoadFn();
                    } else {
                        console.log(response.data.message);
                    }
                })
            }
        }

        function bulkCheckboxFn($event) {
            var checked = $event.target.checked;
            [].forEach.call(document.querySelectorAll(".itemCheck"), function (el) {
                el.checked = checked;
                checkItems(checked, el.id);
            })

        }

        /**
         * Получить статус банковских гарантий локо банка для лк ЛОКОБАНК
         * @param data - статус заявки
         */
        function getStateGuaranteesLokoFn(data) {
            return stateGuaranteesLoko[data.id] || '';
        }

        /**
         * Получить статус банковских гарантий для лк ВТБ
         * @param data - статус заявки
         */
        function getStateGuaranteesVTBFn(data) {
            return stateGuaranteesVtb[data] || '';
        }

        function alertObjImportEvent(data) {
            dialogSrv.openModal('_dialogMessage.html',
                {message: data.mess}).then(function () {
                loadScopeMth()
            })
        }

        function downloadFileEisFn(url, fileName, data, useXhr) {
            httpSrv.downloadFile(url, fileName, data, useXhr, $scope, 'Информация об ответе ЕИС отсутствует');
        }

        /*
        * Найти подходящую процедуру по подписке
        * */
        function findOtherProceduresFn(model) {
            //преобразуем из плоской структуры
            var row = subscriptionSrv.getModelFromView(model);
            var data = {};
            //подготовливаем объект для фильтра
            data.s_fullprice_cur = row.startPrice;
            data.e_fullprice_cur = row.endPrice;
            data.smp = row.msp;
            if (row.customer && row.customer.length > 0) {
                data.customer__inn = row.customer[0];
            }
            data.customers__region = row.regnum;
            data.lots__lotItems__okpd2_code = row.deff__okpd2;
            data.lots__lotItems__okved2_code = row.deff__okved2;
            data.lots__lotItems__ktru_code = row.deff__ktru;
            $state.go('otherProcedures', {filterParams: data, filterType: 'filterProceduresOther', clearConfig: true})
        }

        function exportExcelFn(type) {
            var maxCount = 500;
            if (type === 'otherProcedures' || type === 'priceAnalysis') maxCount = 1000;
            if ($scope.total > maxCount) {
                needConfirmSrv.call(doExportExcelFn, [type], 'Результаты поиска превышают допустимое количество записей для выгрузки.\n' +
                    'В случае продолжения в файл будут выгружены первые ' + maxCount + ' найденных записей.');
            } else {
                doExportExcelFn(type);
            }
        }

        function doExportExcelFn(type) {
            var url = '/site/procedures/xlsx/' + type;
            if (type === 'otherProcedures') {
                prepareTableParams('filterProceduresOther');
            } else if (type === 'all') {
                prepareTableParams('filterProceduresAll');
            } else if (type === 'simplecontest') {
                prepareTableParams('filterProceduresSOC');
                url = '/site/procedures/xlsx/all';
            } else if (type === 'priceAnalysis') {
                prepareTableParams('filterPriceAnalysis');
            } else
                prepareTableParams('filterProcedures');
            var param = angular.copy($scope.currentPaginator);

            if (['all', '223', 'commercial', 'simplecontest'].includes(type)) {
                if (!param.orderBy) {
                    param.orderBy = "startDate";
                    param.orderAsc = false;
                }
            }
            if (type === 'simplecontest') {
                if (!param.searchByField) {
                    param.searchByField = {};
                }
                param.searchByField.customerType = 'simplecontest';
            }

            if (param)
                if (param.searchByField && param.searchByField.typeProcedure) {
                    //фильтр по секции
                    if (type !== 'simplecontest' && ['all', 'commercial', '223'].includes(param.searchByField.typeProcedure)) {
                        url = '/site/procedures/xlsx/' + param.searchByField.typeProcedure;
                    }
                }
            if (constants.configProject.type != 'etp' && type !== 'otherProcedures') {
                if (!param.searchByField) param.searchByField = {};
                param.searchByField.portalType = constants.configProject.type
            }


            if (param.searchByField) {
                desktopSrv.removeKeys(param, 'deff__');
                paramToStr(param.searchByField);
                if (param.searchByField["customers.region"]) {
                    if (param.searchByField["customers.region"].indexOf(',') !== -1) {
                        var okatos = param.searchByField["customers.region"].split(',');
                        var okatoNames = '';
                        angular.forEach(okatos, function (value) {
                            if (type === 'priceAnalysis') {
                                okatoNames += value.slice(0, 2) + '|'
                            }
                            okatoNames += $scope.getRegion(undefined, true, value) + ',';
                        });
                        okatoNames = okatoNames.slice(0, okatoNames.length - 1);
                        param.searchByField.okatoName = okatoNames;
                    } else {

                        param.searchByField.okatoName = (type === 'priceAnalysis' ? (param.searchByField["customers.region"].slice(0, 2) + '|') : "") + $scope.getRegion(undefined, true, param.searchByField["customers.region"]);
                    }
                }
            }
            if (type === 'otherProcedures') {
                propertiesService.getProperty("otherProcedures.url").then(function (host) {
                    httpSrv.downloadFile(host + '/rest/other-purchases/proc/downloadXlsx', '', param);
                });
            } else if (type === 'priceAnalysis') {
                propertiesService.getProperty("otherProcedures.url").then(function (host) {
                    httpSrv.downloadFile(host + '/rest/other-purchases/proc/downloadStatPriceXlsx', '', param);
                });
            } else {

                httpSrv.downloadFile(url, '', param);
            }


        }

        /**
         * Объекты, массимы в строку
         * массивы через запятую
         * */
        function paramToStr(data) {
            if (data.purchaseStatus) {
                getObj('state', data.purchaseStatus.state);
                getObj('auctionsState', data.purchaseStatus.auctionsState);
                getObj('lotsState', data.purchaseStatus.lotsState);
                delete data.purchaseStatus;
            }
            getObj('customer', data.customer);

            function getObj(name, obj) {
                if (obj) {
                    if (typeof obj === "object") {
                        data[name] = getStr(obj);
                    } else if (typeof obj === "string" || typeof obj === "number") {
                        data[name] = obj;
                    }
                }
            }

            function getStr(data) {
                var value = "";
                angular.forEach(data, function (item) {
                    value += item + ",";
                });
                return value.substring(0, value.length - 1);
            }
        }

        $scope.getPurchaseStatusEIS = function (procedure) {
            var arr = dictionarySrv.getStaticDictByType('purchaseStatusEIS');
            var status = '';
            angular.forEach(arr, function (value) {
                if (value.state === procedure.state) {
                    status = value.deff__name
                }
            });
            return status;
        };

        $scope.getRegion = function (customer, needCode, okato) {
            var region = 'не определен';
            var code = '';
            var okatoCode = okato || customer.region;
            if (okatoCode) {
                angular.forEach($scope.dictionary.fias_region, function (value) {
                    if (value && value.okato.slice(0, okato ? 3 : 2) === okatoCode) {
                        code = needCode ? value.code + " " : "";
                        region = code + value.formalname + ' ' + value.shortname + '.';
                    }
                });
            }
            return region;
        };

        $scope.closeEISInfo = function () {
            authSrv.setEISInfo(true);
            $scope.EISInfo = false;
        };

        $scope.onEisOpen = function (purchase_number) {
            loggerUiSrv.sendLog('PROCEDURE_EIS_OPEN', {
                targetId: purchase_number
            });
        };

        $scope.getGisnrRequestTitle = (data) => {
            return {
                'sended': 'Отправлен в ГИС НР ' + $filter('date')(data.gisnrRequestSended, 'dd.MM.yyyy HH:mm:ss'),
                'timeout': 'Время отправки в ГИС НР истекло',
                'created': 'Готов к отправке в ГИС НР',
                'send_failed': 'Повторные попытки отправки в ГИС НР'
            }[data.gisnrRequestState] || '';
        }

        /**
         * получение цвета для вывода площадок участника
         */
        function getAccreditationColorFn(isAccreditation) {
            return isAccreditation ? 'accreditation-color' : 'none-accreditation-color';
        }

        /**
         * Открытие модального окна регистрации
         * */
        function loginModalFn(state, param) {
            authSrv.loginModal(state, param);
        }

        /*
        * Статус по state
        * */
        function getStateFn(state) {
            var text_info = specialAccountStates[state].text_info;
            var i = '';
            if (text_info) {
                //i = '<i uib-popover-html="\'<p>' + text_info + '</p>\'" popover-trigger="\'mouseenter\'" popover-placement="bottom-left" type="button" popover-class="invoiceRequestForm-phone-title" class="fas fa-info-circle" style="color: #0066a1; font-size: 14px"></i>';
                i = '<i  class="fas fa-info-circle" style="color: #0066a1; font-size: 14px" title ="' + text_info + '"></i>'
            }
            return '<span class="' + specialAccountStates[state].text_class + '">' +
                specialAccountStates[state].text + ' ' + i + '</span>';
        }

        function enterSpecialAccountFn(state, param) {
            if ($scope.cabType === 'participant' || $scope.cabType === 'home')
                loginModalFn(state, param);
            else {
                var messageTxt = 'Функционал по онлайн резервированию и открытию счетов в банках-партнёрах доступен только участникам КОМИТА ЭТП.';
                dialogSrv.openModal('_dialogMessage.html',
                    {message: messageTxt}).then(function () {
                    $state.go('financialService')
                })
            }
        }

        $scope.$on('setShowThrobber', function (event, data) {
            $scope.showThrobber = data;
            timeout($scope);
        });

        function isDemoFn() {
            return $rootScope.properties && $rootScope.properties['demo.mode'] === "true";
        }

        function getOfferRefFn(row) {
            return ["etp", "ppk"].includes((row.positions[0] || {}).site) ?
                (("etp"===(row.positions[0] || {}).site ? $rootScope.properties['main.url.uetp'] : $rootScope.properties['main.url.ppk']) + '/offers/' + row.id) :
                ($rootScope.properties['uem.url'] + '/offers/' + row.id);
        }

        function goLinkFn(link) {
            window.open(link, '_blank');
        }

        function isAdminBGChatViewFn() {
            return authSrv.isAdminBGChatView($scope.cabType, $scope.user);
        }

        $scope.isSimpleContest44 = function () {
            return $stateParams.type === 'simplecontest' && $scope.procedure.section === '44';
        }

        $scope.isSimpleContest = function () {
            return $stateParams.type === 'simplecontest';
        }

        function getCurrencyNameFn(procedure){
	        var currencyName = '';
	        if(procedure && procedure.lots && procedure.lots.length > 0){
	            const lotCurrency = procedure.lots[0].currencyCode;
                angular.forEach(dictionarySrv.getStaticDictByType('currency'), function (item) {
                   if (item.code === lotCurrency) {
	                   currencyName = item.pattern;
                   }
                });
            }
	        return currencyName;
        }

        function addFilterParamFn(param, filterType){
            let filterParams = filterService.getFilterNoModifyParams(filterType);
            angular.extend(filterParams,param);
            filterService.setFilterNoModifyParams(filterParams, filterType);
            $scope.$broadcast('filterSearchOutside', {filterType: filterType}, true);
        }

        function goBackFn() {
            history.back();
        }

        function openModalFn(modal, docId, docType, data) {
            $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                size: 'lg',
                templateUrl: modal,
                backdrop: 'static',
                controller: ['$scope', '$uibModalInstance', 'alertSrv', function ($scope, $uibModalInstance, alertSrv) {
                    $scope.uploadSuccess = uploadSuccessFn;
                    $scope.storeEisXml = storeEisXmlFn;
                    $scope.filesList = [];
                    $scope.deleteUploadFile = deleteUploadFileFn;
                    $scope.ctrl = {};
                    $scope.stored = false;
                    $scope.alertEisXml = {instance: {}};

                    $scope.closeModal = function () {
                        $uibModalInstance.close();
                    };

                    $scope.onSizeEmpty = function () {
                        $scope.alertEisXml.instance = alertSrv.getErrorMsgWithBtn('Добавление пустого файла невозможно. Выберите другой файл');
                    };

                    function uploadSuccessFn(result) {
                        angular.forEach(result, function (doc) {
                            $scope.filesList.push(doc);
                        })
                    }

                    function deleteUploadFileFn(list, index) {
                        $scope[list].splice(index, 1)
                    }

                    function storeEisXmlFn(fileId, fileName) {
                        httpSrv.http({
                            url: data.multipartStates ? `/admin/eis/storeEisPartXml/${docId}/${fileId}`: `/admin/eis/storeEisXml/${docId}/${docType}/${fileId}`,
                            method: 'GET'
                        }).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.stored = true;
                                $scope.alertEisXml.instance = alertSrv.getSuccessMsg('xml-файл загружен');
                                if(data && fileName)
                                    data.fileName=fileName;
                                setTimeout(function () {
                                    $scope.closeModal();
                                }, 4000);
                            } else {
                                $scope.alertEisXml.instance = alertSrv.getErrorMsgWithBtn(response.data.message ? response.data.message : 'Ошибка при зарузке документа');
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }
                }]
            });
        }
    }]);