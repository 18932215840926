/**
 * @author: sergeyu
 * Сервис для работы по справочникам
 */
angular.module('uetp').service('dictionarySrv', ['$http', '$injector', '$state', '$rootScope', 'httpSrv', '$stateParams', '$filter', 'propertiesService', 'authSrv',
    function ($http, $injector, $state, $rootScope, httpSrv, $stateParams, $filter, propertiesService, authSrv) {
        var service = this,
            staticData = {
                mspCategory: [
                    {id: 'micro', name: 'Микропредприятие'},
                    {id: 'small', name: 'Малое предприятие'},
                    {id: 'middle', name: 'Среднее предприятие'}
                ],
                typeCustomer_1: [
                    {id: 'other', name: 'прочее'},
                    {id: 'ckz', name: 'ЦКЗ'},
                    {id: 'rzds', name: 'РЖДС'},
                    {id: 'rkz', name: 'РКЗ'},
                    {id: 'dmto', name: 'ДМТО'},
                    {id: 'dzo', name: 'ДЗО'}
                ],
                typeOrg: [
                    {id: 'ul', name: 'Юридическое лицо'},
                    {id: 'fl', name: 'Физическое лицо'},
                    {id: 'ip', name: 'Индивидуальный предприниматель'},
                    {id: 'nr', name: 'Нерезидент'}
                ],
                /**
                 * на сервере в документах другой энумератор
                 */
                typeOrgDocument: [
                    {id: 'ul', name: 'Юридическое лицо'},
                    {id: 'fl', name: 'Физическое лицо'},
                    {id: 'ip', name: 'Индивидуальный предприниматель'},
                    {id: 'nr', name: 'Нерезидент'}
                ],
                typeOrgUlIp: [
                    {id: 'ul', name: 'Юридическое лицо'},
                    {id: 'ip', name: 'Индивидуальный предприниматель'}
                ],
                typeCustomer: [
                    {id: 'head', name: 'Головной заказчик'},
                    {id: 'division', name: 'Подразделение'},
                    {id: 'dzo', name: 'ДЗО'}
                ],
                typePortal: [
                    {id: 'etp', name: 'КОМИТА ЭТП'},
                    {id: 'ppk', name: 'ППК'}
                ],
                versionInfoTypes: [
                    {id: 'all', name: 'Все ЛК'},
                    {id: 'uch', name: 'ЛК Участников'},
                    {id: 'org', name: 'ЛК Организаторов'}
                ],
                purchaseMethod: [
                    {id: 'opencontest', name: 'Открытый конкурс'},
                    {id: 'openauction', name: 'Открытый аукцион'},
                    {id: 'saleauction', name: 'Доходный аукцион'},
                    {id: 'openpublicoffer', name: 'Открытое публичное предложение'},
                    {id: 'auctionsmb', name: 'Аукцион (МСП)'},
                    {id: 'requestquotations', name: 'Запрос котировок'},
                    {id: 'offerplacement', name: 'Размещение оферты'},
                    {id: 'prequalification', name: 'Предварительный квалификационный отбор'},
                    {
                        id: 'unlimitedprequalification',
                        name: 'Предварительный квалификационный отбор с неограниченным сроком'
                    },
                    {id: 'limitedcontest', name: 'Конкурс с ограниченным участием'},
                    {id: 'limitedauction', name: 'Аукцион с ограниченным участием'},
                    {id: 'limitedrequestquotations', name: 'Запрос котировок с ограниченным участием'},
                    // {id: 'opencontestsmb', name: 'Открытый конкурс (МСП)'},
                    {id: 'open2stagecontest', name: 'Открытый двухэтапный конкурс'},
                    // {id: 'openauctionsmb', name: 'Открытый аукцион (МСП)'},
                    // {id: 'estateTransactions', name: 'Сделки с недвижимостью'},
                    {id: 'requestquotationssmb', name: 'Запрос котировок (МСП)'},
                    {id: 'requestproposals', name: 'Запрос предложений'},
                    {id: 'limitedrequestproposals', name: 'Запрос предложений с ограниченным участием'},
                    {id: 'requestproposalssmb', name: 'Запрос предложений (МСП)'},
                    {
                        id: 'limitedcontestqualification',
                        name: 'Конкурс среди организаций, прошедших предварительный квалификационный отбор'
                    },
                    {id: 'simplecontest', name: 'Закупка малого объема'}
                    // {id: 'offerplacementsmb', name: 'Размещение оферты (МСП)'},
                ],
                purchaseMethod44: [
                    {id: '1', name: 'Открытый конкурс'},
                    {id: '2', name: 'Конкурс с ограниченным участием'},
                    {id: '3', name: 'Двухэтапный конкурс'},
                    {id: '4', name: 'Электронный аукцион'},
                    {id: '5', name: 'Запрос котировок'},
                    {id: '6', name: 'Запрос предложений'}
                ],
                purchaseStatus: [
                    {deff__id: '1', deff__name: 'Подача заявок', state: 'claiming'},
                    {
                        deff__id: '4',
                        deff__name: 'Работа комиссии',
                        state: ['opening', 'ratingFirstClaimParts', 'summarizing']
                    },
                    {deff__id: '5', deff__name: 'Ожидание торгов', state: 'preBiddings', auctionsState: 'created'},
                    {deff__id: '6', deff__name: 'Проведение торгов', auctionsState: 'current'},
                    {deff__id: '7', deff__name: 'Ожидание переторжки', lotsState: 'preRebidding'},
                    {deff__id: '8', deff__name: 'Проведение переторжки', lotsState: ['rebidding', 'postrebidding']},
                    {deff__id: '2', deff__name: 'Закупка завершена', state: 'finished'},
                    {deff__id: '3', deff__name: 'Закупка прекращена', state: 'reject'}
                ],
                purchaseStatusNoClaiming: [
                    {deff__id: '4', deff__name: 'Работа комиссии', state: ['opening', 'ratingFirstClaimParts', 'summarizing']},
                    {deff__id: '5', deff__name: 'Ожидание торгов', state: 'preBiddings', auctionsState: 'created'},
                    {deff__id: '6', deff__name: 'Проведение торгов', auctionsState: 'current'},
                    {deff__id: '7', deff__name: 'Ожидание переторжки', lotsState: 'preRebidding'},
                    {deff__id: '8', deff__name: 'Проведение переторжки', lotsState: ['rebidding', 'postrebidding']},
                    {deff__id: '2', deff__name: 'Закупка завершена', state: 'finished'},
                    {deff__id: '3', deff__name: 'Закупка прекращена', state: 'reject'}
                ],
                purchaseStatusEIS: [
                    {deff__id: '1', deff__name: 'Планируется', state: 'PLANNED'},
                    {deff__id: '2', deff__name: 'Подача заявок', state: 'SUBMISSION_OF_APPLICATION'},
                    {deff__id: '3', deff__name: 'Работа комисcии', state: 'COMMISSION_WORK'},
                    {deff__id: '4', deff__name: 'Опубликована', state: 'PUBLISHED'},
                    {deff__id: '5', deff__name: 'Отменена', state: 'CANCELLED'},
                    {deff__id: '6', deff__name: 'Завершена', state: 'COMPLETED'}
                ],
                purchaseStatus44: [
                    {id: '1', name: 'Подача заявок'},
                    {id: '2', name: 'Рассмотрение первоначальных заявок'},
                    {id: '3', name: 'Заявки рассмотрены'},
                    {id: '4', name: 'Прием окончательных предложений'},
                    {id: '5', name: 'Рассмотрение вторых частей заявок'},
                    {id: '6', name: 'Идут торги'},
                    {id: '7', name: 'Торги завершены'},
                    {id: '8', name: 'Подведение итогов'},
                    {id: '9', name: 'Закупка завершена'},
                    {id: '10', name: 'Закупка признана несостоявшейся'}
                ],
                purchaseStatusSOC: [
                    {deff__id: '1', deff__name: 'Подача заявок', state: 'claiming'},
                    {
                        deff__id: '4',
                        deff__name: 'Работа комиссии',
                        state: ['opening', 'ratingFirstClaimParts', 'summarizing']
                    },
                    {deff__id: '2', deff__name: 'Закупка завершена', state: 'finished'},
                    {deff__id: '3', deff__name: 'Закупка прекращена', state: 'reject'}
                ],
                roles: {
                    admincab: [
                        {id: 'operator_admin', name: 'Администратор'},
                        {id: "operator_accountant", name: "Бухгалтер"},
                        {id: "operator_inspector", name: "Контролер"},
                        {id: "operator_support_ro", name: "Поддержка RO"},
                        {id: "operator_support_rw", name: "Поддержка RW"},
                        {id: "operator_manager", name: "Менеджер"}
                    ],
                    participant: [
                        {id: 'participant_admin', name: 'Администратор'},
                        {id: 'user', name: 'Пользователь'}
                    ],
                    organizer: [
                        {id: 'organizer_admin', name: 'Администратор'},
                        {id: 'organizer_user', name: 'Пользователь'},
                        {id: 'organizer_cbz', name: 'Сотрудник ЦБЗ'}
                    ],
                    agent: [
                        {id: 'bg_agent_admin', name: 'Администратор агента'},
                        {id: 'bg_agent_user', name: 'Пользователь агента'}
                    ],
                    bank: [
                        {id: 'bank_admin', name: 'Администратор банка'},
                        {id: 'bank_user', name: 'Пользователь банка'}
                    ],
                    all: [
                        {id: 'operator_admin', name: 'Администратор'},
                        {id: "operator_accountant", name: "Бухгалтер"},
                        {id: "operator_support_ro", name: "Поддержка RO"},
                        {id: "operator_inspector", name: "Контролер"},
                        {id: 'participant_admin', name: 'Администратор'},
                        {id: 'user', name: 'Пользователь'},
                        {id: 'organizer_admin', name: 'Администратор'},
                        {id: 'organizer_user', name: 'Пользователь'},
                        {id: 'bg_agent_admin', name: 'Администратор агента'},
                        {id: 'bg_agent_user', name: 'Пользователь агента'},
                        {id: 'bank_admin', name: 'Администратор банка'},
                        {id: 'bank_user', name: 'Пользователь банка'},
                        {id: "operator_manager", name: "Менеджер"}
                    ]
                },

                currency: [
                    {code: 643, name: 'Российский рубль', pattern: 'RUB', sign: '₽'},
                    {code: 156, name: 'Юань', pattern: 'CNY', sign: '¥'},
                    {code: 840, name: 'Доллар США', pattern: 'USD', sign: '$'},
                    {code: 978, name: 'Евро', pattern: 'EUR', sign: '€'}
                ],
                currencyLot: [
                    {digitalCode: 643, name: 'Российский рубль', code: 'RUB'},
                    {digitalCode: 156, name: 'Юань', code: 'CNY'},
                    {digitalCode: 840, name: 'Доллар США', code: 'USD'},
                    {digitalCode: 978, name: 'Евро', code: 'EUR'}
                ],
                typeSum: [
                    {id: 'MAX_PRICE', name: 'максимальная'},
                    {id: 'MIN_PRICE', name: 'минимальная'},
                    {id: 'NO_PRICE', name: 'без цены'}
                ],
                typeSumNoMin: [
                    {id: 'MAX_PRICE', name: 'максимальная'},
                    {id: 'NO_PRICE', name: 'без цены'}
                ],
                whoPublishVariants: [
                    {id: 'customers', name: 'Заказчик'},
                    {id: 'org', name: 'Организатор'}
                ],
                tariffTypes: [
                    {id: 'winnerPays', name: 'Победа в процедуре'},
                    {id: 'tariff', name: 'Подача заявки'}
                ],
                deliveryPlaceIndicationVariants: [
                    {id: 'SINGLE_PLACE', name: 'Единое место поставки лотов'},
                    {id: 'DIFFERENT_PLACE', name: 'Различные места поставки лотов'}
                ],
                deliveryPlaceIndicationLotVariants: [
                    {id: 'GL', name: 'Указывается общее для лота'},
                    {id: 'ELP', name: 'Указывается для каждой позиции лота'}
                ],
                methodPriceSetLotVariants: [
                    {id: 'method1', name: 'Сведения о начальной (максимальной) цене договора (цене лота)'},
                    {id: 'method2', name: 'Формула цены и максимальное значение цены договора (цены лота)'},
                    {
                        id: 'method3',
                        name: 'Цена единицы товара, работы, услуги и максимальное значение цены договора (цены лота)'
                    }
                ],
                method3ChoiceLotVariants: [
                    {id: 'method3_bullet1', name: 'Цена товара, работы, услуги (группы товаров, работ, услуг)'},
                    {id: 'method3_bullet2', name: 'Цена единицы товара, работы услуги'}
                ],
                lotPlanPositionVariants: [
                    {id: 'COMMODITY', name: 'Обычный план'},
                    {id: 'INNOVATION', name: 'Инновационный план'}
                ],
                typeSumAuction: [
                    {id: 'MAX_PRICE', name: 'максимальная'},
                    {id: 'MIN_PRICE', name: 'минимальная'}
                ],
                priceModes: [
                    {id: 'currency', name: 'валюта'},
                    {id: 'percent', name: 'процент/коэффициент'}
                ],
                showpricends: [
                    {id: 'no', name: 'без НДС'},
                    {id: 'with', name: 'с НДС'}
                ],
                percentModes: [
                    {id: 'MAX_PERCENT', name: 'снижаться'},
                    {id: 'MIN_PERCENT', name: 'повышаться'}
                ],
                protocolAccepted: [
                    {code: 'T', name: 'Допущен'},
                    {code: 'F', name: 'Не допущен'},
                    {code: 'N', name: 'Не указывается в данном протоколе'}
                ],
                protocolRussianPriority: [
                    {code: 1, name: 'Предоставлены'},
                    {code: 0, name: 'Не представлены'}
                ],
                protocolFinal: [
                    {code: 'F', name: 'Победитель'},
                    {code: 'S', name: 'Второе место'},
                    {code: 'T', name: 'Третье место'},
                    {code: 'B', name: 'Ниже третьего'},
                    {code: 'N', name: 'Не указывается в данном протоколе'}
                ],
                timeZone: [
                    {code: -1, name: 'MSK -1'},
                    {code: 0, name: 'MSK'},
                    {code: 1, name: 'MSK +1'},
                    {code: 2, name: 'MSK +2'},
                    {code: 3, name: 'MSK +3'},
                    {code: 4, name: 'MSK +4'},
                    {code: 5, name: 'MSK +5'},
                    {code: 6, name: 'MSK +6'},
                    {code: 7, name: 'MSK +7'},
                    {code: 8, name: 'MSK +8'},
                    {code: 9, name: 'MSK +9'}
                ],
                typeRebidding: [
                    {id: 'online', name: 'Переторжка в режиме реального времени'},
                    {id: 'offline', name: 'Переторжка в заочной форме'}
                ],
                protocolClaimRejectReason: [
                    {id: 'NO_APPLICATIONS', is223: false, name: 'На участие в закупке не подано ни одной заявки'},
                    {id: 'ONE_APPLICATION', is223: false, name: 'На участие в закупке была подана только одна заявка'},
                    {id: 'ACCESS_DENIED_ALL', is223: false, name: 'По итогам рассмотрения отказано в допуске всем участникам закупки'},
                    {id: 'ACCESS_GRANTED_ONE', is223: false, name: 'По итогам рассмотрения допущен только один участник закупки'},
                    {id: 'OTHER', is223: false, name: 'Иная'},
                    {id: 'NO_APPLICATIONS_SUBMITTED', is223: true, name: 'Конкурентная закупка признана несостоявшейся в связи с тем, что не подано ни одной заявки на участие в закупке'},
                    {id: 'ALL_APPLICATIONS_CANCELED', is223: true, name: 'Конкурентная закупка признана несостоявшейся в связи с тем, что по результатам ее проведения все заявки на участие в закупке отклонены'},
                    {id: 'ONE_APPLICATION_SUBMITTED', is223: true,name: 'Конкурентная закупка признана несостоявшейся в связи с тем, что на участие в закупке подана только одна заявка'},
                    {id: 'ALL_APPLICATIONS_CANCELED_EXCEPT_ONE', is223: true, name: 'Конкурентная закупка признана несостоявшейся в связи с тем, что по результатам ее проведения отклонены все заявки, за исключением одной заявки на участие в закупке'},
                    {id: 'ALL_PARTICIPANTS_AVOIDED_CONTRACT', is223: true, name: 'Конкурентная закупка признана несостоявшейся в связи с тем, что по результатам ее проведения от заключения договора уклонились все участники закупки'}
                ],
                protocolAuctionRejectReason: [
                    {id: 'STRUCTURE_CONTENT_DESIGN', name: 'Несоответствие заявки по составу, содержанию и оформлению'},
                    {id: 'PARTICIPATOR', name: 'Несоответствие участника закупки требованиям документации'},
                    {
                        id: 'SUBCONTRACTOR',
                        name: 'Несоответствие субподрядчиков (соисполнителей), изготовителей требованиям документации'
                    },
                    {
                        id: 'PRODUCT',
                        name: 'Несоответствие продукции, указанной в заявке на участие в закупке, требованиям документации'
                    },
                    {
                        id: 'CONDITIONS',
                        name: 'Несоответствие договорных условий, указанных в заявке на участие в закупке, требованиям документации'
                    },
                    {
                        id: 'SUPPLY',
                        name: 'Несоответствие размера, формы, условий или порядка предоставления обеспечения заявки'
                    },
                    {id: 'OTHER', name: 'Иное основание в соответствии с положением о закупке'}
                ],
                typeProcedure: [
                    {id: 'all', name: 'Все закупки'},
                    {id: '223', name: '223-ФЗ'},
                    {id: '44', name: '44-ФЗ'},
                    {id: 'commercial', name: 'Коммерческие торги'}
                ],
                typeProcedureSCO: [
                    {id: 'all', name: 'Все закупки'},
                    {id: '223', name: '223-ФЗ'},
                    {id: 'simplecontest', name: 'Закупки малого объема'},
                    {id: 'commercial', name: 'Коммерческие торги'}
                ],
                typeProcedureEIS: [
                    {id: '44', name: '44-ФЗ'},
                    {id: '223', name: '223-ФЗ'},
                    {id: '615', name: 'ПП РФ 615'}
                ],
                utensilsMSP: [
                    {spm: true, name: 'Закупки МСП'},
                    {spm: false, name: 'Закупки не МСП'}
                ],
                dateMonth: [
                    {id: '0', name: ''}, // поиск по индексу чтоб индекс с датами начинался с 1 (без использования фильтра по id)
                    {id: '1', name: 'январь'},
                    {id: '2', name: 'февраль'},
                    {id: '3', name: 'март'},
                    {id: '4', name: 'апрель'},
                    {id: '5', name: 'май'},
                    {id: '6', name: 'июнь'},
                    {id: '7', name: 'июль'},
                    {id: '8', name: 'август'},
                    {id: '9', name: 'сентябрь'},
                    {id: '10', name: 'октябрь'},
                    {id: '11', name: 'ноябрь'},
                    {id: '12', name: 'декабрь'}
                ],
                placementTypes: [

                    {
                        id: 'purchaseNoticeKESMBO',
                        name: 'Конкурс в электронной форме, участниками которого могут являться только субъекты малого и среднего предпринимательства',
                        type: '223'
                    },
                    {
                        id: 'purchaseNoticeAESMBO',
                        name: 'Аукцион в электронной форме, участниками которого могут являться только субъекты малого и среднего предпринимательства',
                        type: '223'
                    },
                    {
                        id: 'purchaseNoticeZKESMBO',
                        name: 'Запрос котировок в электронной форме, участниками которого могут являться только субъекты малого и среднего предпринимательства',
                        type: '223'
                    },
                    {
                        id: 'purchaseNoticeZPESMBO',
                        name: 'Запрос предложений в электронной форме, участниками которого могут являться только субъекты малого и среднего предпринимательства',
                        type: '223'
                    },
                    {id: 'purchaseNoticeOK', name: 'Открытый конкурс', type: '223'},
                    {id: 'purchaseNoticeAE94,purchaseNoticeOA', name: 'Открытый аукцион', type: '223'},
                    {id: 'purchaseNoticeAE', name: 'Электронный аукцион', type: '223'},
                    {id: 'purchaseNoticeZK', name: 'Запрос котировок', type: '223'},
                    {
                        id: 'purchaseNoticeEP',
                        name: 'Закупка у единственного поставщика (исполнителя, подрядчика)',
                        type: '223'
                    },
                    {id: 'purchaseNotice', name: 'Закупка иного типа', type: '223'},

                    {id: 'epNotificationEOK,epNotificationEOK2020', name: 'Открытый конкурс в электронной форме', type: '44'},
                    {id: 'epNotificationEZK,epNotificationEZK2020', name: 'Запрос котировок в электронной форме', type: '44'},
                    {id: 'epNotificationEZP', name: 'Запрос предложений в электронной форме', type: '44'},
                    {
                        id: 'epNotificationEOKOU',
                        name: 'Конкурс с ограниченным участием в электронной форме',
                        type: '44'
                    },
                    {id: 'epNotificationEOKD', name: 'Двухэтапный конкурс в электронной форме', type: '44'},
                    {id: 'fcsNotificationOK', name: 'Открытый конкурс', type: '44'},
                    {id: 'fcsNotificationEF,epNotificationEF2020', name: 'Электронный аукцион', type: '44'},
                    {id: 'fcsNotificationZK', name: 'Запрос котировок', type: '44'},
                    {id: 'fcsNotificationPO', name: 'Предварительный отбор', type: '44'},
                    {
                        id: 'fcsNotificationEP',
                        name: 'Закупка у единственного поставщика (исполнителя, подрядчика)',
                        type: '44'
                    },
                    {id: 'fcsNotificationOKOU', name: 'Конкурс c ограниченным участием', type: '44'},
                    {id: 'fcsNotificationOKD', name: 'Двухэтапный конкурс', type: '44'},
                    {id: 'fcsNotificationZakK', name: 'Закрытый конкурс', type: '44'},
                    {id: 'fcsNotificationZakKOU', name: 'Закрытый конкурс с ограниченным участием', type: '44'},
                    {id: 'fcsNotificationZakKD', name: 'Закрытый двухэтапный конкурс', type: '44'},
                    {id: 'fcsNotificationZakA', name: 'Закрытый аукцион', type: '44'},
                    {id: 'fcsNotificationZP', name: 'Запрос предложений', type: '44'},
                    {
                        id: 'fcsNotification111',
                        name: 'Способ определения поставщика (подрядчика, исполнителя), установленный' +
                            ' Правительством Российской Федерации в соответствии со статьей 111 Федерального закона № 44-ФЗ',
                        type: '44'
                    },
                    {
                        id: 'pprf615NotificationPO',
                        name: 'ПП РФ 615 Предварительный отбор квалифицированных подрядных организаций',
                        type: '615'
                    },
                    {
                        id: 'pprf615NotificationEF',
                        name: 'ПП РФ 615 Электронный аукцион на оказание услуг или выполнение работ по капитальному ремонту общего имущества в многоквартирном доме',
                        type: '615'
                    }
                ],
                claimStates: [
                    {state: 'created', stateText: 'Черновик'},
                    {state: 'sent', stateText: 'Отправлена'},
                    {state: 'admitted', stateText: 'Допущена'},
                    {state: 'notAdmitted', stateText: 'Не допущена'},
                    {state: 'revoked', stateText: 'Отозвана'},
                    {state: 'returned', stateText: 'Возвращена заказчиком'}
                ],
                bankGuaranteeTypeLoko: [
                    {code: 'participation', name: 'Участие'},
                    {code: 'execution', name: 'Исполнение'},
                    {code: 'executionCapital', name: 'Исполнение по капитальному ремонту'}
                ],
                sellerDocStatus: [
                    {code:'sent', name:'Выставлен'},
                    {code:'confirmed', name:'Принят'}
                ],
                bankGuaranteeTypeVTB: [
                    {code: 'participation', name: 'Участие'},
                    {code: 'execution', name: 'Исполнение'},
                    {code: 'returnAdvance', name: 'Обеспечение на возврат аванса'},
                    {code: 'period', name: 'Банковская гарантия на обеспечение гарантийных обязательств'}
                ],
                customerRegistrationStates: [
                    {code:'waitEmailConfirm', name:'Ожидает подтверждение электронной почты', notApproved:false},
                    {code:'waiting', name:'Ожидает обработки', notApproved:true},
                    {code:'processing', name:'В работе', notApproved:true},
                    {code:'registered', name:'Заказчик зарегистрирован', notApproved:false},
                    {code:'notregistered', name:'Заявка отклонена', notApproved:false}
                ],
                noticeStates: [
                    {id: "created", name:"Создан"},
                    {id: "published", name:"Действующая"},
                    {id: "obsolete", name:"Недействующая"}
				],
                explanationStates: [
                    {id: "created", name:"Создано"},
                    {id: "published", name:"Опубликовано"},
                    {id: "sent_participant", name:"Направлено в ЛК"}
                ],
                applicationSupply:[
                    {code:'operatorSupply', name:'на лицевой счет участника на счете Оператора КОМИТА ЭТП'},
                    {code:'customerSupply', name:'на расчетный счет Заказчика'}
                ],
                applicationDeposit:[
                    {code:'notRequired', name:'не требуется'},
                    {code:'operator', name:'на лицевой счет участника на счете Оператора КОМИТА ЭТП'},
                    {code:'customer', name:'на расчетный счет Заказчика'}
                ],
                customEisEntities: [
                    {code:'purchasePlan', name:'план закупки'},
                    {code:'purchaseNotice', name:'извещение об осуществлении закупки'},
                    {code:'purchaseRejection', name:'отмена закупки'},
                    {code:'purchaseLotCancellation', name:'отмена лота закупки'},
                    {code:'explanation', name:'разъяснения документации'},
                    {code:'explanationRequest', name:'запрос заказчика о разъяснении положений заявки'},
                    {code:'purchaseProtocol', name:'протоколы'},
                    {code:'purchaseProtocolCancellation', name:'сведения об отмене протокола'},
                    {code:'contract', name:'сведения о договоре'},
                    {code:'contractCompleting', name:'сведения об исполнении договора'},
                    {code:'contractTerminate', name:'сведения о расторжении договора'},
                    {code:'subcontractor', name:'сведения о договоре с субподрядчиком'},
                ]
            },
            remoteData = {
                okcm: [],
                okei: [],
                fias_region: [],
                pasport_type: [],
                orgNamesDict: [],
                okved2: [],
                okved2EIS: [],
                oktmo2EIS: [],
                okopf: [],
                customers: [],
                suppliersTass: [],
                customersTass: [],
                customersOfOrganizer: [],
                headDzoCustomers: [],
                headDzoCustomers223: [],
                organizers: [],
                participants: [],
                inspection: [],
                skmtr: [],
                CATEGORY_doc: [],
                CATEGORY_question: [],
                categoryDict: [],
                purchaseCategory: [],
                operatorReq: [],
                personalAccounts: [],
                mailinglists: [],
                participantList: [],
                orgParticipantList: [],
                customEisEntities: []

            },
            /**
             * Справочники работающие на основе постраничной загрузки
             */
            scrollingData = {
                "rBIK": {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'bic',
                    isSearch: false
                },
                rOKPD2: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'code',
                    isSearch: false
                },
                rSKMTR: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'name',
                    isSearch: false
                },
                orgName: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'name',
                    isSearch: false
                },
                rOrg: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'name',
                    isSearch: false
                },
                customers: {
                    itemsPerPage: 20,
                    page: 0,
                    orderAsc: true,
                    orderBy: 'name',
                    isSearch: false
                },
                headDzoCustomers: {
                    itemsPerPage: 20,
                    page: 0,
                    orderAsc: true,
                    orderBy: 'name',
                    isSearch: false,
                    addParams: {isHeadDzo:'true'}
                },
                headDzoCustomers223: {
                    itemsPerPage: 20,
                    page: 0,
                    orderAsc: true,
                    orderBy: 'name',
                    isSearch: false,
                    addParams: {isHeadDzo:'true',allowed223:'true'}
                },
                suppliersTass: {
                    itemsPerPage: 20,
                    page: 0,
                    orderAsc: true,
                    orderBy: 'name',
                    isSearch: false
                },
                customersTass: {
                    itemsPerPage: 20,
                    page: 0,
                    orderAsc: true,
                    orderBy: 'name',
                    isSearch: false
                },
                customersEIS: {
                    itemsPerPage: 20,
                    page: 0,
                    // orderAsc: true,
                    // orderBy: 'inn',
                    isSearch: false
                },
                suppliersEIS: {
                    itemsPerPage: 20,
                    page: 0,
                    // orderAsc: true,
                    // orderBy: 'inn',
                    isSearch: false
                },
                rKTRU: {
                    itemsPerPage: 20,
                    page: 0,
                    // orderAsc: true,
                    // orderBy: 'inn',
                    isSearch: false
                },
                rOKPD2EIS: {
                    itemsPerPage: 20,
                    page: 0,
                    // orderAsc: true,
                    // orderBy: 'inn',
                    isSearch: false
                },
                rOKPD2KTRU: {
                    itemsPerPage: 20,
                    page: 0,
                    // orderAsc: true,
                    // orderBy: 'inn',
                    isSearch: false
                },
                organizers: {
                    itemsPerPage: 20,
                    page: 0,
                    orderAsc: true,
                    orderBy: 'fullName',
                    isSearch: false
                },
                participants: {
                    itemsPerPage: 20,
                    page: 0,
                    orderAsc: true,
                    orderBy: 'fullName',
                    isSearch: false
                },
                rOKEI: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'fullname',
                    isSearch: false
                },
                rOKVED2: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'id',
                    isSearch: false
                },
                rOKVED2EIS: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'id',
                    isSearch: false
                },
                positionPlanNumbers: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'lots.lot_number',
                    isSearch: false
                },
                rBANKDIVISION: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'code'
                },
                personalAccounts: {
                    itemsPerPage: 20,
                    page: 0,
                    isSearch: false
                },
                mailingLists: {
                    itemsPerPage: 20,
                    page: 0,
                    isSearch: false
                },
                participantList: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'kpp',
                    isSearch: false
                },
                orgParticipantList: {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'kpp',
                    isSearch: false
                },
                rFIAS_AREA: {
                    itemsPerPage: 50,
                    page: 0,
                    orderBy: 'code',
                    isSearch: false
                },
            },
            loadingDict = {},
            lastSearch = {},
            loadTime = {},
            userRolesList = {
                participant_admin: 'Участник',
                user: 'Участник',
                organizer_cbz: 'Организатор',
                organizer_admin: 'Организатор',
                organizer_user: 'Организатор',
                operator_admin: 'Администратор',
                operator_accountant: 'Бухгалтер',
                operator_inspector: 'Оператор-контролер',
                operator_support_ro: 'Поддержка RO',
                operator_support_rw: 'Поддержка RW',
                operator_manager: 'Менеджер',
                bg_agent_user: 'Агент БГ',
                bg_agent_admin: 'Агент БГ'
            };

        service.setStaticDictByType = setStaticDictByTypeFn;
        service.getStaticDictByType = getStaticDictByTypeFn;
        service.getUserRoleDesc = getUserRoleDescFn;
        service.getCurrencyPatternByCode = (code) => (staticData['currency'].find(c => c.code === code) || {}).pattern;

        function getUserRoleDescFn(type) {
            return userRolesList[type]
        }

        /**
         * Получение справочника по его типу (статчиеские данные staticData)
         * @param type
         */
        function getStaticDictByTypeFn(type) {
            return staticData[type];
        }

        /**
         * Уставнока справочника по его типу (статчиеские данные staticData)
         * Для "кеширования" справочника
         * @param type
         * @param data
         */
        function setStaticDictByTypeFn(type, data) {
            if (!staticData[type]) staticData[type] = [];
            staticData[type] = data;
        }


        /**
         * Получить справочники которые можно загрузить целиком
         * @param name - имя справочника
         * @param reload - перегружать при обращении к нему по имени
         * @returns {*}
         */
        this.getDictionaryRemote = function (name, reload) {
            if (!reload && remoteData[name] && remoteData[name].length > 0) {
                return Promise.resolve(remoteData[name]);
            }
            var pagination = {
                itemsPerPage: 10000000,
                page: 0
            };
            var method = 'POST';
            var url = '/dict/browseDict/' + name;
            if (name === 'fias_region') {
                pagination.searchByField = {livestatus: 1};
                pagination.orderBy = 'code';
            }
            if (name === 'purchase_category') {
                pagination.orderBy = 'code';
            }
            if (name === 'CATEGORY_question') {
                url = '/dict/browseDict/CATEGORY';
                pagination.searchByField = {type: 'question'};
            }
            if (name === 'CATEGORY_doc') {
                url = '/dict/browseDict/CATEGORY';
                pagination.searchByField = {type: 'doc'};
            }
            if (name === 'customers') {
                pagination = scrollingData[name];
                pagination.searchByField = {};
                url = '/site/obtainOrgList/customer'
            }
            if (['headDzoCustomers','headDzoCustomers223'].includes(name)) {
                pagination = scrollingData[name];
                pagination.searchByField = {};
                pagination.addParams = {isHeadDzo: 'true'}
                if('headDzoCustomers223'===name) pagination.addParams.allowed223 = 'true';
                url = '/site/obtainOrgList/customer'
            }
            if (name === 'customersOfOrganizer') {
                pagination = null;
                url = '/organizer/customers/getCustomersPurchasePlan';
                method = 'GET'
            }
            if (name === 'organizers') {
                pagination = scrollingData[name];
                pagination.searchByField = {};
                url = '/site/obtainOrgList/organizer'
            }
            if (name === 'participants') {
                pagination = scrollingData[name];
                pagination.searchByField = {};
                url = '/admin/getParticipantListByBgAgent/' + authSrv.getAuth().orgId
            }
            if (name === 'categoryDict') {
                loadCategories(pagination);
                return Promise.resolve(remoteData[name]);
            }
            if (name === 'operatorReq') {
                pagination = null;
                url = '/admin/getOperatorRequisites';
                method = 'GET'
            }
            return httpSrv.http({
                url: url,
                data: pagination,
                method: method
            }).then(function (result) {
                if (result.data.success) {
                    remoteData[name] = result.data.items || result.data.result;
                    return Promise.resolve(result.data.items || result.data.result);
                } else {
                    return Promise.reject(result);
                }
            });

            function loadCategories(pagination) {

                var XHR = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
                var xhr = new XHR();

                xhr.open('POST', 'https://eshoprzd.ru/rest/ccwe/publicity/supplierscatalog/browseCategories', true);
                xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
                xhr.setRequestHeader('Type-Shop', 'rzd');

                var param = angular.copy(pagination);
                param.state = "home";
                param.block = true;
                xhr.send(JSON.stringify(param));

                xhr.onload = function () {
                    var response = JSON.parse(xhr.response);
                    if (response.success) {
                        remoteData.categoryDict = response.items;
                    } else {
                        console.log(response);
                    }
                    //$scope.$apply();
                };

                xhr.onError = function () {
                    console.log(this);
                };

            }
        };
        /**
         * Получитт список справочникв
         */
        this.getRemoteData = function () {
            return remoteData;
        };

        this.loadPaginatedDict = loadPaginatedDictFn;
        this.refreshDictionary = refreshDictionaryFn;
        this.resetPaginatedDict = resetPaginatedDictFn;
        this.dictClearSearchByField = dictClearSearchByFieldFn;
        this.infiniteReqScroll = infiniteReqScrollFn;
        this.autoFillSkmtr = autoFillSkmtrFn;
        this.autoFillOkpd2 = autoFillOkpd2Fn;                   //ф-л предложений
        this.autoFillMeasure = autoFillMeasureFn;               //ф-л предложений
        this.emptySKMTR = emptySKMTRFn;                         //ф-л предложений
        this.emptyOKPD2 = emptyOKPD2Fn;                         //ф-л предложений
        this.onOpenClose = onOpenCloseFn;                       //ф-л предложений
        this.getRegionNames = getRegionNamesFn;                 //ф-л предложений
        this.autoFillByTypeDict = autoFillByTypeDictFn;
        this.getProcedureList = getProcedureListFn;
        this.getFzbyProcType = getFzbyProcTypeFn;
        this.getStaticSectionName = getStaticSectionNameFn;

        /**
         * Обновление справочника. Дозаполнение данными
         * @param $scope - область видимости компонента
         * @param object - тип обновляемого справочника
         * @param value - значение которая введено в поиск по справочнику
         * @param refreshScrolling - обновление по скролингу
         * @param searchByField -
         * @param needToAdd - необходимо ли загрузить данные (fix переоткрытия спрочников)
         * @returns {PromiseLike<T> | Promise<T> | *}
         */
        function refreshDictionaryFn($scope, object, value, refreshScrolling, searchByField, needToAdd) {
            if (loadingDict[object] && refreshScrolling) return;
            if (Date.now() - loadTime[object] < 40) return;
            if (needToAdd && (value === "" || value == null)) return;
            if (['rfGuarantees', 'bankGuarantees', 'lokobank'].includes($scope.state)) scrollingData[object].page = 0;
            //поиск
            if (value && value.length > 0 && !refreshScrolling) {
                scrollingData[object].isSearch = true;
                scrollingData[object].page = 0;
                $scope.infiniteReqScroll.setCounter(object, $scope.infiniteReqScroll.numToAdd);
                if (scrollingData[object].searchByField) delete scrollingData[object].searchByField;
                if (searchByField) {
                    scrollingData[object].searchByField = searchByField;
                }
            }
            //скролл с введенным поиском
            else if (value && value.length > 0 && refreshScrolling) {
                scrollingData[object].isSearch = false;
            }
            /**
             * Если в запросе поиска ничего нет. То искать как при скроллинге.
             */
            else if (!refreshScrolling) {
                if (!$scope.dictPaginated) $scope.dictPaginated = {};
                if(object!=='participants') {
                    $scope.dictPaginated[object] = [];
                    refreshScrolling = true;
                }
                $scope.infiniteReqScroll.setCounter(object, $scope.infiniteReqScroll.numToAdd);
                scrollingData[object].page = 0;
                scrollingData[object].isSearch = false;
            }
            scrollingData[object].search = value;
            if ($scope.infiniteReqScroll.getCounter(object) > $scope.infiniteReqScroll.numToAdd)
                scrollingData[object].page = $scope.infiniteReqScroll.getCounter(object) / $scope.infiniteReqScroll.numToAdd - 1;
            const finalPaginator = angular.copy(scrollingData[object]),
                timestamp = new Date().getTime();
            lastSearch[object] = finalPaginator.page === 0 && !refreshScrolling ? timestamp : 0;
            //получаем id загруженой компании в Проверке компании
            const id = (object === "suppliersTass" || object === "customersTass") ? $stateParams.idCompany : '';
            let url = ["headDzoCustomers","headDzoCustomers223", "customers"].includes(object)
                ? '/site/obtainOrgList/customer'
                : object === "customersEIS" ? '/rest/other-purchases/proc/dict/qref_organization'
                : object === "suppliersEIS" ? '/rest/other-purchases/proc/dict/qref_suppliers*'
                : object === "rKTRU" ? '/rest/other-purchases/proc/dict/qref_krtu'
                : object === "rOKPD2EIS" ? '/rest/other-purchases/proc/dict/qref_okpd2'
                : object === "rOKPD2KTRU" ? '/rest/other-purchases/proc/dictOkpdKtru'
                : object === "rOKVED2EIS" ? '/rest/other-purchases/proc/dict/qref_okved2'
                : object === "positionPlanNumbers" ? '/rest/other-purchases/proc/searchPurchases'
                : object === "rBIK" ? '/rest/bic/rest/bank/list'
                : object === "suppliersTass" ? '/rest/tassValid/search/getSuppliers/' + id
                : object === "customersTass" ? '/rest/tassValid/search/getCustomers/' + id
                : object === "operatorReq" ? '/rest/admin/getOperatorRequisites'
                : object === "organizers" ? '/site/obtainOrgList/organizer'
                : object === "personalAccounts" ? '/accounts/paymentOrder/personalAccountsList'
                : object === "mailingLists" ? '/organizer/mailinglists/getList'
                : object === "participantList" ? ('/' + $stateParams.type + '/getParticipantByInn')
                : object === "orgParticipantList" ? ('/organizer/mailinglists/getParticipantByInn')
                : (object === "participants" ? '/admin/getParticipantListByBgAgent/' + authSrv.getAuth().orgId
                : ('/dict/browseDict/' + object.substring(1)));

            //UETP-4141 исключение дублирования записей
            if (object === "rKTRU") {
                finalPaginator.searchByField ? finalPaginator.searchByField.isActual = true : finalPaginator.searchByField = {isActual: true};
            } else if (object === 'positionPlanNumbers') {
                if (searchByField) {
                    finalPaginator.searchByField = searchByField;
                    finalPaginator.isSearch = false;
                    if (finalPaginator.search) {
                        delete finalPaginator.search;
                    }
                    //поиск по заказчику
                    if (searchByField.searchByGroup) {
                        finalPaginator.searchByGroup = searchByField.searchByGroup;
                        delete finalPaginator.searchByField.searchByGroup
                    }
                } else {
                    //для positionPlanNumbers обязателен заказчик в searchByGroup
                    return;
                }
            } else if (object === 'mailingLists') {
                if (searchByField && searchByField.orgCode) {
                    url = url + '/' + searchByField.orgCode;
                    delete finalPaginator.searchByField.orgCode;
                }
            } else if (['participantList','orgParticipantList'].includes(object)) {
                if (searchByField && searchByField.id) {
                    url = url + '/' + searchByField.id;
                    delete finalPaginator.searchByField.id;
                }
            } else if(object === 'rFIAS_AREA') {
                finalPaginator.searchByField = searchByField;
            }

            return doRequest(url).then((response) => {
                if (response.config && response.config.data.page === 0 && response.config.headers.timestamp < lastSearch[object].timestamp) return response;
                if (!$scope.dictPaginated) $scope.dictPaginated = {};
                $scope.dictPaginated[object] || ($scope.dictPaginated[object] = []);
                if (!refreshScrolling) {
                    $scope.dictPaginated[object] = response.data.data ? response.data.data.items : response.data.items;
                    $rootScope.$emit("refreshStart");
                    return response;
                }
                if (response.data.items && response.data.items.length !== 0) {
                    Array.prototype.push.apply($scope.dictPaginated[object], response.data.items);
                    scrollingData[object].page += 1;
                }
                if (response.data.data && response.data.data.items && response.data.data.items.length !== 0) {
                    Array.prototype.push.apply($scope.dictPaginated[object], response.data.data.items);
                    scrollingData[object].page += 1;
                }
                loadTime[object] = Date.now();
                return response;
            });

            function doRequest(url) {
                if (url.indexOf('other-purchases') != -1 || object === "rBIK") {
                    let prop = "api.url";
                    if (url.indexOf('other-purchases') != -1) prop = "otherProcedures.url";

                    return new Promise(function (resolve, reject) {
                        propertiesService.getProperty(prop).then(function (host) {
                            httpSrv.requestXHR($scope, {
                                method: 'POST',
                                url: host + url,
                                data: finalPaginator
                            }).then(function (response) {
                                var resp = {};
                                resp.data = response;
                                resolve(resp);
                            }, function (reason) {
                                reject(reason);
                            })

                        }, function (reason) {
                            reject(reason);
                        });
                    });
                } else {
                    return httpSrv.http({
                        url: url,
                        data: finalPaginator
                    })
                }
            }
        }

        function resetPaginatedDictFn($scope) {
            $scope.dictPaginated = {
                rOKPD2: [],
                rSKMTR: [],
                rBIK: [],
                rKTRU: [],
                customers: [],
                headDzoCustomers: [],
                headDzoCustomers223: [],
                suppliersTass: [],
                customersTass: [],
                customersEIS: [],
                suppliersEIS: [],
                organizers: [],
                participants: [],
                rOrgs: [],
                rOKEI: [],
                rOKVED2: [],
                rOKVED2EIS: [],
                positionPlanNumbers: [],
                rOKPD2EIS: [],
                rOKPD2KTRU: [],
                rBANKDIVISION: [],
                personalAccounts: [],
                rFIAS_AREA: []
            };
            for (var el in scrollingData) {
                if (scrollingData[el].searchByField) delete scrollingData[el].searchByField;
                if (scrollingData[el].search) delete scrollingData[el].search;
            }
        }

        function dictClearSearchByFieldFn(object) {
            scrollingData[object].searchByField = {};
        }

        /**
         * Загрузка справочников с постраничной загрузкой
         * @param $scope - область видимости контроллера где они используются
         * @param dictName - имя справочника
         * @param param - параметры запроса
         * @param object -
         * @returns {PromiseLike<T> | Promise<T> | *}
         */
        function loadPaginatedDictFn($scope, dictName, param, object) {
            if (["rOKPD2", "rSKMTR", "rOKEI"].indexOf(object) !== -1) {
                scrollingData[object].searchByField = param.searchByField ? param.searchByField : {};
            }
            //получаем id загруженой компании в Проверке компании
            const id = (object === "suppliersTass" || object === "customersTass") ? $stateParams.idCompany : '';
            const url = ["headDzoCustomers", "headDzoCustomers223","customers"].includes(object) ? '/site/obtainOrgList/customer'
                : object === "customersEIS" ? '/rest/other-purchases/proc/dict/qref_organization'
                : object === "suppliersEIS" ? '/rest/other-purchases/proc/dict/qref_suppliers*'
                : object === "rKTRU" ? '/rest/other-purchases/proc/dict/qref_krtu'
                : object === "rOKPD2EIS" ? '/rest/other-purchases/proc/dict/qref_okpd2'
                : object === "rOKPD2KTRU" ? '/rest/other-purchases/proc/dict/dictOkpdKtru'
                : object === "rOKVED2EIS" ? '/rest/other-purchases/proc/dict/qref_okved2'
                : object === "positionPlanNumbers" ? '/rest/other-purchases/proc/searchPurchases'
                : object === "suppliersTass" ? '/rest/tassValid/search/getSuppliers/' + id
                : object === "customersTass" ? '/rest/tassValid/search/getCustomers/' + id
                : object === "participants" ? '/admin/getParticipantListByBgAgent/' + authSrv.getAuth().orgId
                : (object === "organizers" ? '/site/obtainOrgList/organizer' : ('/dict/browseDict/' + dictName));
            loadingDict[object] = true;
            return doRequest().then(function (response) {
                if (response.data.success) {
                    if (!$scope.dictPaginated) $scope.dictPaginated = {};
                    $scope['dictPaginated'][object] || ($scope['dictPaginated'][object] = []);
                    $scope['dictPaginated'][object] = response.data.data ? response.data.data.items : response.data.items;
                }
                loadingDict[object] = false;
                return response.data;
            });

            function doRequest() {
                //запросы через XHR
                if (object === "rBIK") {
                    return new Promise(function (resolve, reject) {
                        propertiesService.getProperty("api.url").then(function (host) {
                            httpSrv.requestXHR($scope, {
                                method: 'POST',
                                url: host + '/rest/bic/rest/bank/list',
                                data: param
                            }).then(function (response) {
                                resolve(response);
                            }, function (reason) {
                                reject(reason);
                            })

                        }, function (reason) {
                            reject(reason);
                        });
                    });
                } else if (url.indexOf('other-purchases') != -1) {
                    return new Promise(function (resolve, reject) {
                        propertiesService.getProperty("otherProcedures.url").then(function (host) {
                            httpSrv.requestXHR($scope, {
                                method: 'POST',
                                url: host + url,
                                data: param
                            }).then(function (response) {
                                loadingDict[object] = false;
                                resolve(response);
                            }, function (reason) {
                                reject(reason);
                            })

                        }, function (reason) {
                            reject(reason);
                        });
                    });
                }
                return httpSrv.http({
                    url: url,
                    data: param
                })
            }
        }

        function infiniteReqScrollFn($scope) {
            return {
                numToAdd: 20,
                currentItems: {},
                resetInfScroll: function (type) {
                    $scope.infiniteReqScroll.currentItems[type] = $scope.infiniteReqScroll.numToAdd;
                },
                addMoreItems: function (type, search, searchByField) {
                    if (angular.isDefined($scope.infiniteReqScroll.currentItems[type])) {
                        $scope.infiniteReqScroll.currentItems[type] += $scope.infiniteReqScroll.numToAdd;
                        refreshDictionaryFn($scope, type, search, true, searchByField);
                    } else {
                        $scope.infiniteReqScroll.currentItems[type] += $scope.infiniteReqScroll.numToAdd;
                    }
                },
                getCounter: function (type) {
                    if ($scope.infiniteReqScroll.currentItems[type] == undefined) {
                        $scope.infiniteReqScroll.currentItems[type] = 20;
                    }
                    return $scope.infiniteReqScroll.currentItems[type];
                },
                setCounter: function (type, count) {
                    $scope.infiniteReqScroll.currentItems[type] = count;
                }
            }
        }

        function autoFillSkmtrFn($scope, object, select) {
            var param = {
                itemsPerPage: 20,
                page: 0
            };
            if (!select.$select.selected && object == "filter" || !select.$select.selected) {
                return;
            }
            $scope[object].skmtr = select.$select.selected.skmtr;
            if (select.$select.selected.okpd2) {
                param.searchByField = {
                    code: select.$select.selected.okpd2
                };
                loadPaginatedDictFn($scope, 'OKPD2', param, 'rOKPD2').then(function (okpd2Data) {
                    $scope[object].okpd2 = select.$select.selected.okpd2;
                    if (object != "filter" && okpd2Data.total)
                        $scope[object].okpd2Name = okpd2Data.items[0].name;
                });
            }

            if (object != "filter") {
                $scope[object].name = select.$select.selected.name;
                $scope[object].skmtrName = select.$select.selected.name;
                $scope[object].mark = select.$select.selected.mark;
                $scope[object].gost = select.$select.selected.gost;
                $scope[object].params = select.$select.selected.params;
                $scope[object].measure = select.$select.selected.measure;

                var paramOkei = {
                    itemsPerPage: 20,
                    page: 0,
                    searchByField: {
                        code: select.$select.selected.measure,
                        actual: true
                    }
                };
                loadPaginatedDictFn($scope, 'OKEI', paramOkei, 'rOKEI').then(function (okeiData) {
                    if (okeiData && okeiData.items)
                        for (var i = 0; i < okeiData.items.length; i++)
                            if (angular.equals(okeiData.items[i].code, $scope[object].measure))
                                $scope[object].measureName = okeiData.items[i].localSymbol ? okeiData.items[i].localSymbol : okeiData.items[i].fullname;
                });
            }
        }

        function onOpenCloseFn($scope, isOpen) {
            if ($scope.formSelect["SKMTR"]) {
                var ctrl = $scope.formSelect["SKMTR"];
                if (!isOpen && ctrl.items && ctrl.items.length === 0 && !ctrl.selected) {
                    emptySKMTRFn($scope, 'offerItem', undefined, true)
                }
            }
        }

        /*
       * очистка скмтр
        */
        function emptySKMTRFn($scope, object, $event, onClose) {
            if (!$scope[object]) $scope[object] = {};
            $scope[object].skmtr = '';
            scrollingData.rSKMTR.page = 0;
            $scope.infiniteReqScroll.currentItems.rSKMTR = $scope.infiniteReqScroll.numToAdd;
            $scope[object].skmtrName = '';
            $scope[object].mark = '';
            $scope[object].gost = '';
            //событие clear для селекта
            if ($scope.formSelect["SKMTR"]) {
                if (!onClose) {
                    $scope.formSelect["SKMTR"].search = "";
                    !$scope.formSelect["SKMTR"].selected || $scope.formSelect["SKMTR"].clear($event);
                }
            }
            //загружаем первые 20 записей окпд
            var param = {
                itemsPerPage: 20,
                page: 0,
                orderBy: 'name'
            };
            //selectService.loadPaginatedDict($scope, 'OKPD2', param, 'rOKPD2').then(function(){
            if ($scope[object].okpd2) {
                param.searchByField = {okpd2: $scope[object].okpd2};
            } else if ($scope.planData && $scope.planData.okpd2) {
                param.searchByField = {okpd2: $scope.planData.okpd2};
            }
            loadPaginatedDictFn($scope, 'SKMTR', param, 'rSKMTR');
            if ($scope.form.mainForm) {
                $scope.form.mainForm.$setDirty();
            }
            //})
        }

        /*
        * очистка окпд
         */
        function emptyOKPD2Fn($scope, object, $event) {
            if (!$scope[object]) $scope[object] = {};
            $scope[object].okpd2 = '';
            $scope[object].name = '';
            if ($scope[object].type == 'ESHOP_PRODUCT') {
                $scope[object].params = '';
                $scope[object].measure = '';
            }
            if ($scope.formSelect["OKPD2"]) {
                $scope.formSelect["OKPD2"].search = "";
                !$scope.formSelect["OKPD2"].selected || $scope.formSelect["OKPD2"].clear($event);
            }
            var param;

            if (object = 'purchaseItem' && !!$scope.planData && !!$scope.planData.okpd2) {
                param = {
                    searchByField: {code: $scope.planData.okpd2},
                    itemsPerPage: 20,
                    page: 0
                };
            } else {
                param = {
                    itemsPerPage: 20,
                    page: 0,
                    orderBy: 'code'
                };
            }
            loadPaginatedDictFn($scope, 'OKPD2', param, 'rOKPD2').then(function () {
                emptySKMTRFn($scope, object, $event);
                if ($scope.form.mainForm) {
                    $scope.form.mainForm.$setDirty();
                }
            })
        }

        /*
        * автозаполнение окпд
         */
        function autoFillOkpd2Fn($scope, object, select) {
            var param = {
                itemsPerPage: 20,
                page: 0
            };
            if (object != "filter" && select.$select.selected) {
                $scope[object].name = select.$select.selected.name;
                $scope[object].okpd2Name = select.$select.selected.name;
                $scope[object].mark = '';
                $scope[object].gost = '';
                $scope[object].params = '';
            }
            $scope[object].skmtr = '';
            //очистка окпд в фильтре - перезаполнение скмтр 1й страницей справочника
            if (!select.$select.selected && object == "filter") {
                loadPaginatedDictFn($scope, 'OKPD2', param, 'rOKPD2');
                loadPaginatedDictFn($scope, 'SKMTR', param, 'rSKMTR');
                return;
            }
            if (select.$select.selected && select.$select.selected.code) {
                param.searchByField = {
                    okpd2: select.$select.selected.code
                };
                loadPaginatedDictFn($scope, 'SKMTR', param, 'rSKMTR');
            }
        }

        /*
         * заполнение единиц измерения
         */
        function autoFillMeasureFn($scope, object, select) {
            var param = {
                itemsPerPage: 20,
                page: 0
            };

            if (object !== "filter") {
                $scope[object].measureName = select.$select.selected ? (select.$select.selected.localSymbol ? select.$select.selected.localSymbol : select.$select.selected.fullname) : '';
            }

            if (select.$select.selected && select.$select.selected.code) {
                param.searchByField = {
                    code: select.$select.selected.code,
                    actual: true
                };
                loadPaginatedDictFn($scope, 'OKEI', param, 'rOKEI');
            }
        }

        /**
         * Автозаполнение полей
         * @param object - объект в модели
         * @param select - выбранное значение - объект
         */
        function autoFillByTypeDictFn($scope, model, object, select) {
            var param = {
                itemsPerPage: 20,
                page: 0
            };
            var objModel = $scope[model];
            if (object === "currency") {
                objModel[object] = {};
                objModel[object].name = select ? select.name : "";
                objModel[object].digitalCode = select ? select.code : "";
                objModel[object].code = select ? select.pattern : "";
            } else if (object === "applicationSupplyCurrency") {
                if (select) {
                    objModel.lotData[object] = {};
                    objModel.lotData[object].name = select ? select.name : "";
                    objModel.lotData[object].digitalCode = select ? select.code : "";
                    objModel.lotData[object].code = select ? select.pattern : "";
                } else delete objModel.lotData[object];
            } else if (object === "okpd2") {
                delete objModel.skmtr;
                if (select) {
                    objModel[object] = select;
                    delete scrollingData["rOKPD2EIS"].searchByField;
                    scrollingData["rSKMTR"].searchByField = {okpd2: objModel[object].code};
                } else {
                    delete objModel[object];
                    delete scrollingData["rOKPD2EIS"].searchByField;
                    delete scrollingData["rSKMTR"].searchByField;
                }
                objModel.name = select ? select.name : "";
            } else if (object === "okved2") {
                objModel[object].name = select ? select.description : "";
                objModel[object].code = select ? select.id : "";
            } else if (object === "okei") {
                objModel[object].name = select ? select.fullname : "";
                objModel[object].code = select ? select.code : "";
            } else if (object === "skmtr") {
                if (!select) {
                    delete objModel[object];
                    delete objModel.name;
                    delete scrollingData["rSKMTR"].searchByField;
                } else {
                    var prevObjModel = angular.copy(objModel[object]);
                    objModel[object] = {};
                    objModel.name = select ? select.name : "";
                    objModel[object].gost = angular.isDefined(prevObjModel) && select ? prevObjModel.gost : (select ? select.gost : "");
                    objModel[object].code = select ? select.skmtr : "";
                    if (angular.isDefined(prevObjModel)) {
                        objModel[object].property = prevObjModel.params || prevObjModel.property;
                        objModel[object].vendorCode = prevObjModel.mark || prevObjModel.vendorCode;
                    }
                }
                if (select) {
                    if (select.okpd2) {
                        param.searchByField = {
                            code: select.okpd2
                        };
                        loadPaginatedDictFn($scope, 'OKPD2', param, 'rOKPD2EIS').then(function (okpd2Data) {
                            objModel.okpd2 = {};
                            objModel.okpd2.code = select.okpd2;
                            if (okpd2Data.total)
                                objModel.okpd2.name = okpd2Data.items[0].name;
                        });
                    }
                }
            } else if (object === "customer") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.name;
                    objModel[object].id = select.id;
                } else {
                    delete objModel[object];
                }
            } else if (object === "customersEIS") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.name;
                    objModel[object].id = select.regnum;
                } else {
                    delete objModel[object];
                }
            } else if (object === "suppliersEIS") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.name;
                    objModel[object].id = select.regnum;
                } else {
                    delete objModel[object];
                }
            } else if (object === "rKTRU") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.name;
                    objModel[object].id = select.code;
                } else {
                    delete objModel[object];
                }
            } else if (object === "rOKPD2EIS" || object === "rOKPD2KTRU") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.name;
                    objModel[object].id = select.code;
                } else {
                    delete objModel[object];
                }
            } else if (object === "rOKVED2EIS") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.name;
                    objModel[object].id = select.code;
                } else {
                    delete objModel[object];
                }
            } else if (object === "positionPlanNumbers") {
                objModel[object] = {};
                if (select) {
                    objModel[object].lot_name = select.lot_name;
                    objModel[object].lot_number = select.lot_number;
                } else {
                    delete objModel[object];
                }
            } else if (object === "rBANKDIVISION") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.name;
                    objModel[object].code = select.code;
                } else {
                    delete objModel[object];
                }
            } else if (object === "organizer") {
                objModel[object] = {};
                if (select) {
                    objModel[object].name = select.fullName;
                    objModel[object].id = select.id;
                } else {
                    delete objModel[object];
                }
            } else if (object === "participant") {
                objModel[object] = {};
                if (select) {
                    objModel[object].inn = select.inn;
                    objModel[object].name = select.fullName;
                    objModel[object].ogrn = select.ogrn;
                } else {
                    delete objModel[object];
                }
            } else if (object === "purchaseCategory") {
                if (select) {
                    objModel.lotData[object].name = select.name;
                } else {
                    delete objModel.lotData[object];
                }
            } else if (object == "deliveryPlace" || object == "lotDeliveryPlace" || object == "deliveryPlacePurchase") {
                if ($scope.getActivePanel() == 'procedure') {
                    if (select) {
                        if (!$scope.notice.document.deliveryPlacePurchase) $scope.notice.document.deliveryPlacePurchase = {};
                        $scope.notice.document.deliveryPlacePurchase.state = select.federalokrug;
                        $scope.notice.document.deliveryPlacePurchase.regionOkato = select.okato;
                        objModel[object].state = select.federalokrug;
                        objModel[object].regionOkato = select.okato;
                    } else {
                        delete objModel[object].state;
                        delete objModel[object].regionOkato;
                        delete objModel[object].region;
                    }
                } else if ($scope.getActivePanel() == 'lot' || $scope.getActivePanel() == 'lots') {
                    if (object == "lotDeliveryPlace") {
                        if (!$scope.model.lotData.deliveryPlace) $scope.model.lotData.deliveryPlace = {};
                        if (select) {
                            $scope.model.lotData.deliveryPlace.state = select.federalokrug;
                            $scope.model.lotData.deliveryPlace.regionOkato = select.okato;
                            objModel.lotData["deliveryPlace"].state = select.federalokrug;
                            objModel.lotData["deliveryPlace"].regionOkato = select.okato;
                        } else {
                            delete objModel.lotData["deliveryPlace"].state;
                            delete objModel.lotData["deliveryPlace"].regionOkato;
                            delete objModel.lotData["deliveryPlace"].region;
                        }
                    } else {
                        if (!$scope.model.deliveryPlace) $scope.model.deliveryPlace = {};
                        if (select) {
                            $scope.model.deliveryPlace.state = select.federalokrug;
                            $scope.model.deliveryPlace.regionOkato = select.okato;
                            objModel[object].state = select.federalokrug;
                            objModel[object].regionOkato = select.okato;
                        } else {
                            delete objModel[object].state;
                            delete objModel[object].regionOkato;
                            delete objModel[object].region;
                        }
                    }
                }
                $scope[object] = select ? select : "";
            } else {
                $scope[object] = select ? select : "";
            }
        }

        /**
         * Получить список процедур
         */
        function getProcedureListFn(fz) {
            if (!fz) {
                fz = '223';
            }
            var dictName = (fz === '223' ? 'procedureList' : 'procedure44List');
            return new Promise(function (resolve, reject) {
                var dict = service.getStaticDictByType(dictName);
                if (dict) {
                    resolve(dict)
                } else {
                    return httpSrv.http({
                        method: 'GET',
                        url: '/site/' + dictName
                    }).then(function (result) {
                        if (result.data.success) {
                            service.setStaticDictByType(dictName, result);
                            resolve(result)
                        } else {
                            console.log(result);
                            reject(result);
                        }
                    }, function (reason) {
                        console.log(reason);
                        reject(reason);
                    });
                }
            });
        }

        /**
         * Перечесление всех типов процедур
         * id - тип процедуры
         * name - Имя процедуры
         * fz - ФЗ процедуры
         */
            //todo перечислить все типы процедур для 44-ФЗ и 223-ФЗ
        var procedureTypes = [
                /**
                 * 223-ФЗ
                 */
                {id: 'opencontest', name: 'Открытый конкурс', fz: '223'},
                {id: 'openauction', name: 'Открытый аукцион', fz: '223'},
                {id: 'saleauction', name: 'Доходный аукцион', fz: '223'},
                {id: 'openpublicoffer', name: 'Открытое публичное предложение', fz: '223'},
                {id: 'auctionsmb', name: 'Аукцион (МСП)', fz: '223'},
                {id: 'requestquotations', name: 'Запрос котировок', fz: '223'},
                {id: 'offerplacement', name: 'Размещение оферты', fz: '223'},
                {id: 'prequalification', name: 'Предварительный квалификационный отбор', fz: '223'},
                {
                    id: 'unlimitedprequalification',
                    name: 'Предварительный квалификационный отбор с неогрниченным сроком',
                    fz: '223'
                },
                {id: 'limitedcontest', name: 'Конкурс с ограниченным участием', fz: '223'},
                {id: 'limitedauction', name: 'Аукцион с ограниченным участием', fz: '223'},
                {id: 'limitedrequestquotations', name: 'Запрос котировок с ограниченным участием', fz: '223'},
                {id: 'opencontestsmb', name: 'Открытый конкурс (МСП)', fz: '223'},
                {id: 'open2stagecontest', name: 'Открытый двухэтапный конкурс', fz: '223'},
                {id: 'openauctionsmb', name: 'Открытый аукцион (МСП)', fz: '223'},
                {id: 'estateTransactions', name: 'Сделки с недвижимостью', fz: '223'},
                {id: 'requestquotationssmb', name: 'Запрос котировок (МСП)', fz: '223'},
                {id: 'requestproposals', name: 'Запрос предложений', fz: '223'},
                {id: 'limitedrequestproposals', name: 'Запрос предложений с ограниченным участием', fz: '223'},
                {id: 'requestproposalssmb', name: 'Запрос предложений (МСП)', fz: '223'},
                {
                    id: 'limitedcontestqualification',
                    name: 'Конкурс среди организаций, прошедших предварительный квалификационный отбор', fz: '223'
                },
                {id: 'offerplacementsmb', name: 'Размещение оферты (МСП)', fz: '223'},
                {id: 'simplecontest', name: 'Закупка малого объема', fz: '223'},
                {id: 'requestprices', name: 'Запрос цен', fz: '223'},
                /**
                 * 44-ФЗ
                 */
                {id: 'simplecontest44', name: 'Закупка малого объема', fz: '44'}

            ];

        /**
         * Определить Фз по типу процедуры
         * @param procType
         */
        function getFzbyProcTypeFn(procType) {
            var type = procType.toLowerCase();
            var fz;
            angular.forEach(procedureTypes, function (value) {
                if (value.id === type) {
                    fz = value.fz;
                    return 0;
                }
            });
            return fz;
        }

        /*
        получение названий регионов из справочника
         */
        function getRegionNamesFn(regionCodes) {
            if (!regionCodes || !(remoteData || {}).fias_region) return "";
            var deliveryRegionName = [];
            var rCodes = Array.isArray(regionCodes) ? regionCodes : regionCodes.split(',');
            if (rCodes.length == remoteData.fias_region.length)
                return "Все регионы";
            remoteData.fias_region.forEach(function (region) {
                if (rCodes.includes(region.code)) deliveryRegionName.push(region.formalname + ' ' + region.shortname);
            });
            return deliveryRegionName.join(', ');
        }

        //$rootScope.$on('cabTypeChange', function ($event, cabType) {});

        function getStaticSectionNameFn(section) {
            var sectionName;
            angular.forEach(service.getStaticDictByType('typeProcedure'), function (value) {
                if (value.id === section) {
                    sectionName = value.name;
                    return 0;
                }
            });
            return sectionName;
        }

        //$rootScope.$on('cabTypeChange', function ($event, cabType) {});
    }]);
