/**
 * @author: sergeyu
 * Просмотр закупки
 */
angular.module('uetp')
    .component('proceduresView', {
        template: '<div ng-include="ctrl.templateUrl">',
        bindings: {
            procedureData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal', 'authSrv', 'httpSrv', 'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv','dictionarySrv', 'dialogSrv', 'contractSrv','paginationSrv', 'constants', 'propertiesService', 'permissionSrv', 'titleSrv', 'organizatorSrv', 'loggerUiSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal, authSrv, httpSrv, storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, dialogSrv, contractSrv, paginationSrv, constants, propertiesService, permissionSrv, titleSrv, organizatorSrv, loggerUiSrv) {
                var ctrl = this;
                var pathViews = 'app/components/procedures/views/';
                $scope.ctrl = ctrl;
                $scope.ctrl.templateUrl = '';
                $scope.projectType = constants.configProject.type;
                $scope.viewSupply = false;
                $scope.viewDeposits = false;
                $scope.depositInfo = '';
                $scope.getEisState = proceduresSrv.getEisState;
                $scope.noticeStates = dictionarySrv.getStaticDictByType('noticeStates');
                $scope.currencyDict = dictionarySrv.getStaticDictByType('currency');
                $scope.showConfirm = true;
                $scope.viewSupplyUserCheck = viewSupplyUserCheckFn;
                $scope.showAlertPanel = showAlertPanelFn;
                $scope.user = authSrv.getUser();
                $scope.getCurrencyPatternByCode = (code) => dictionarySrv.getCurrencyPatternByCode(code);
                $scope.refreshDict = refreshDictFn;

                $rootScope.$on("userDataChanged", function () {
                    $scope.user = authSrv.getUser();
                });

                this.$onInit = function () {
                    if ($stateParams.errorMessage) {
                        dialogSrv.showDialogMsgHtml($scope, $stateParams.errorMessage);
                    }
                    $scope.stateConfig = $stateParams.clearConfig ?
                        storageSrv.clearStateConfig($stateParams.procId, 'showPanel') : storageSrv.getStateConfig($stateParams.procId, 'showPanel');

                    $scope.cabType = desktopSrv.getCabType;
                    $scope.auth = authSrv.getAuth();
                    $scope.userId = authSrv.getUser().id;
                    $scope.user = authSrv.getUser();

                    $scope.proceduresSrv = proceduresSrv;
                    proceduresSrv.setProcedureData(ctrl.procedureData);
                    $scope.procedure = ctrl.procedureData.data.result;
                    $scope.isWinnerPays = isWinnerPaysFn();
                    if ($scope.cabType() === 'manager') {
                        organizatorSrv.getAssignedUserCustomers($scope.auth.userId).then(function (response) {
                            if (response.data.success) {
                                $scope.userCustomers = response.data.result.assignedCustomer;
                                if ($scope.isManagerCustomer()) {
                                    defineTabs();
                                    _viewContracts();
                                }
                            }
                        })
                    }

                    $scope.changeProtocolsView = $scope.procedure && !['unlimitedprequalification', 'prequalification', 'offerplacement', 'requestprices'].includes($scope.procedure.type.id) &&
                        $scope.procedure.customer.inn === $scope.user.inn && $scope.procedure.organizer.id !== $scope.auth.orgId && $scope.hasSectionPermission($scope.procedure.section);

                    $scope.procedure.procedureStates = updateStateDescriptions();

                    function updateStateDescriptions() {
                        var states = [];
                        if (!$scope.procedure)
                            return states;
                        angular.forEach($scope.procedure.procedureStates, function (state) {
                            let name = proceduresSrv.getProcedureName(state.id, $scope.procedureNameType($scope.procedure), $scope.procedure.docStatusCode, $scope.procedure.type.id, $scope.procedure.stage, $scope.procedure.submissionClosePKODateTimeEnds, $scope.procedure);
                            states.push({id: state.id, name: name ? name : state.name});
                        });
                        return states;
                    }

                    $scope.model = {
                        showPanel: 'lots',
                        typeProcedure: $scope.procedure.type.id,
                        stateProcedure: $scope.procedure.state.id,
                        openPartsCount: undefined
                    };
                    $scope.pf = vfSrv.getVisibleProcFields($scope.model.typeProcedure);
                    $scope.sortType = 'time';
                    $scope.sortReverse = false;
                    $scope.sortTypeObj = {
                        docs: 'crated',
                        expls: 'crated',
                        protocols: 'state.id',
                        notification: 'crated',
                        notices: 'created'
                    };
                    $scope.sortReverseObj = {
                        docs: false,
                        expls: false,
                        protocols: true,
                        notification: false,
                        notices: true
                    };
                    $scope.dataTableDocs = $scope.procedure.files;
                    $scope.dataTableLots = $scope.procedure.lots;

                    _initDepositInfo();

                    $scope.dataLot = $scope.procedure.lots[0];

                    $scope.dataPurchasingDocs = [];
                    $scope.showAll = false;
                    if ($scope.procedure.explanations) {
                        $scope.dataTableExplanations = $scope.procedure.explanations.filter(e=>!['sent_participant','created'].includes(e.state.id));
                    } else {
                        $scope.dataTableExplanations = {};
                    }
                    if ($scope.procedure.protocols) {
                        $scope.dataTableProtocols = [];
                        angular.forEach($scope.procedure.protocols, function (protocol) {
                            if (protocol.type && protocol.type.id !== 'RETURN_CLAIMS' && protocol.state.id !== 'created') {
                                $scope.dataTableProtocols.push(protocol);
                            }
                        })
                    } else {
                        $scope.dataTableProtocols = {};
                    }
                    if ($scope.procedure.participants) {
                        $scope.dataTableParticipants = $scope.procedure.participants;
                    }
                    $scope.dataTableNotifications = {};
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        itemsPerPage: 50,
                        page: 0
                    };
                    $scope.contractsPaginator = {
                        itemsPerPage: 20,
                        page: 0
                    };
                    var config = paginationSrv.getPaginationStateConfig('pagination', $state.current.name); // память пагинации
                    if (config && $scope.model.showPanel == 'lots') {
                        $scope.contractsPaginator.page = config.page;
                        $scope.contractsPaginator.itemsPerPage = config.itemsPerPage;
                    }
                    _initTemplate();
                    _joinPurchaseParseModel();
                    _lotOkpdParseModel();
                    _initShowPanel();
                    _viewOpenParts();
                    _viewContracts();
                    _filterProtocolActions();
                    _initDictionaryBase();

                    if ($scope.stateConfig) {
                        setActivePanelFn($scope.stateConfig.typePanel)
                    }
                    if ($stateParams.toAuction) {
                        setActivePanelFn('auction')
                    }
                    $scope.participantInn = {search: ''};
                    $scope.fz = $scope.procedure.commercial ? "Коммерческие торги" : $scope.procedure.section + "-ФЗ";

                    $scope.contractActions = [];
                    $scope.isClaimingStage1 = $scope.procedure.state.id === 'claiming' && !($scope.procedure.stage && $scope.procedure.stage.id == 'stage2');
                    if ($scope.model.typeProcedure === 'simplecontest') {
                        propertiesService.getProperty("sendClaimFreeEnable").then(function(value){
                            $scope.tariffsAlertPanel = (value && value === 'true') ? false :
                                 (!$scope.procedure.sendClaimFree || (($scope.viewDeposits || $scope.viewSupply) && $scope.depositInfo.length))
                                    && !['prequalification', 'unlimitedprequalification'].includes($scope.model.typeProcedure)
                                    && ($scope.procedure.section !== '44' || $scope.model.typeProcedure === 'simplecontest')
                                    && $scope.isClaimingStage1 && !$scope.isWinnerPays;
                        });
                    } else {
                        $scope.tariffsAlertPanel = (!$scope.procedure.sendClaimFree || (($scope.viewDeposits || $scope.viewSupply) && $scope.depositInfo.length))
                            && !['prequalification', 'unlimitedprequalification'].includes($scope.model.typeProcedure)
                            && ($scope.procedure.section !== '44' || $scope.model.typeProcedure === 'simplecontest')
                            && $scope.isClaimingStage1 && !$scope.isWinnerPays;
                    }

                    if ($stateParams.setActivePanel) {
                        setActivePanelFn($stateParams.setActivePanel);
                    }
                    if ($scope.procedure.number && $scope.procedure.type.name) {
                        titleSrv.setTitle($scope.procedure.number + ' | ' + $scope.procedure.type.name);
                    }
                    if($stateParams.openFactoring===true && (['participant'].includes($scope.user.orgRole) && ['ul','ip'].includes($scope.user.typeOrg))) {
                        $scope.showFactoringPanel();
                        delete $stateParams.openFactoring;
                    }
                };
                $scope.setActivePanel = setActivePanelFn;
                $scope.changeSort = changeSortFn;
                $scope.openModal = openModalFn;
                $scope.openPage = openPageFn;
                $scope.openLogin = openLoginFn;
                $scope.deletePurchasingDoc = deletePurchasingDocFn;
                $scope.totalPagesCheck = totalPagesCheckFn;
                $scope.procedureNameType = proceduresSrv.procedureNameType;
                $scope.findParticipant = findParticipantFn;
                $scope.addParticipant = addParticipantFn;
                $scope.deleteParticipant = deleteParticipantFn;
                $scope.notifyParticipant = notifyParticipantFn;
                $scope.hasItemPrices = hasItemPricesFn;
                $scope.focusLotPanel = focusLotPanelFn;
                $scope.viewContractLink = viewContractLinkFn;
                $scope.conclusionContract = conclusionContractFn;
                $scope.contractActionName = contractActionNameFn;
                $scope.contractsLoad = contractsLoadFn;
                $scope.getCurrencyCode = getCurrencyCodeFn;
                $scope.downloadPaymentOrder = proceduresSrv.downloadPaymentOrderFn;

                $scope.hasPermission = permissionSrv.hasPermission;
                $scope.hasSectionPermission = permissionSrv.hasSectionPermission;

                function conclusionContractFn(contract) {
                    if(contract.contractId){
                        $state.go('contract', {id: contract.contractId})
                    } else {
                        $state.go('contract', {id: contract.lotId, contract: contract, procedure: $scope.procedure})
                    }
                }

                function viewContractLinkFn(contract) {
                    var action = false;
                    if(contract.actions){
                        contract.actions.forEach(function (item) {
                            if (!action) {
                                action = item.id === 'initSendToParticipant'
                            }

                        });
                    }

                    return action
                }

                function totalPagesCheckFn(data) {
                    return Math.ceil(data.length / $scope.paginator.itemsPerPage);
                }

                /**
                 * Показать/Скрыть текст
                 */
                $scope.showHideText = function () {
                    $scope.showAll = !$scope.showAll;
                };

                /**
                 * Показать/Скрыть кнопку "Получить банковскую гарантию"
                 */
                $scope.showHideBtnBankGuarantees = function () {
                    return ($scope.cabType() === 'home' || ($scope.auth.userRole && ($scope.auth.userRole.includes('user') || $scope.auth.userRole.includes('participant_admin')))) &&
                        $scope.model.typeProcedure !== 'simplecontest' && $scope.procedure.state.id !== 'reject' && desktopSrv.isBGEnabled() && !viewSupplyUserCheckFn();
                };

                /**
                 * Подготовка информации о задатках или обеспечении
                 */
                function _initDepositInfo(){
                    let arr = [];
                    $scope.dataTableLots.forEach(function(el){
                        if(el.applicationDepositSumm) {
                            $scope.viewDeposits = true;
                            if(el.applicationDepositNeeded === 'operator') {
                                arr.push(el.ordinalNumber);
                            }

                        }else if(el.applicationSupplySumm) {
                            $scope.viewSupply = true;
                            if (el.applicationDepositNeeded === 'operatorSupply') {
                                arr.push(el.ordinalNumber);
                            }
                        }
                        if(arr.length){
                            if(arr.length === 1) {
                                $scope.depositInfo = 'лоте № ' + arr[0]
                            } else if(arr.length > 1) {
                                $scope.depositInfo = 'лотах №№ ' + arr.join(', ')
                            }
                        }
                    });
                }

                /**
                 * Инициализация шаблона
                 */
                function _initTemplate() {
                    var cabType = desktopSrv.getCabType;
                    if ('cbz' === cabType()) {
                        $scope.ctrl.templateUrl = pathViews + 'proceduresForCbz.html'
                    } else {
                        var fz = $scope.procedure.section;
                        if (fz === '44') {
                            /**
                             * 44-ФЗ
                             */
                            $scope.ctrl.templateUrl = pathViews + 'procedures.html' // шаблон используем 223
                        } else if (fz === '223' || fz === 'commercial') {
                            /**
                             * 223-ФЗ
                             */
                            $scope.ctrl.templateUrl = pathViews + 'procedures.html'
                        }
                    }
                }

                /**
                 * Разбираем данные если это совместная закупка
                 * @private
                 */
                function _joinPurchaseParseModel() {
                    if($scope.procedure.jointPurchase) {
                        var caseCustomers = desktopSrv.casesOfwords()(['заказчика', 'заказчика', "заказчиков"]);
                        $scope.procedure.lots.forEach(function (lot) {
                            if (lot.customers && lot.customers.lotCustomers &&
                                lot.customers.lotCustomers.lotCustomer && lot.customers.lotCustomers.lotCustomer.length > 0) {
                                lot.deff__customerCaseWords = caseCustomers(lot.customers.lotCustomers.lotCustomer.length-1);
                                lot.deff__customersList = [];
                                lot.customers.lotCustomers.lotCustomer.forEach(function (customer) {
                                    lot.deff__customersList.push(customer)
                                })

                            }
                        });
                    }
                }

                /**
                 * Разбираем данные по окпд лота
                 * @private
                 */
                function _lotOkpdParseModel() {
                    $scope.procedure.lots.forEach(function (lot) {
                        if (lot.items && lot.items.length > 0) {
                            let okpdList  = lot.items.map(({ okpd2Code, okpd2Name }) => ({okpd2Code, okpd2Name}));
                            //сортируем и убираем дубликаты
                            lot.deff__okpdList = okpdList.sort((a, b) => {return a.okpd2Code > b.okpd2Code ? 1 : -1}).filter(function(item, pos, ary) {
                                return !pos || item.okpd2Code != ary[pos - 1].okpd2Code;
                            });
                        }
                    });
                }


                /**
                 * Инициализация панели профиля по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    var cabType = desktopSrv.getCabType;
                    if (['preBiddings', 'biddings', 'summarizing', 'finished', 'rebidding', 'preRebidding'].indexOf($scope.model.stateProcedure) >= 0) {
                        if (['openpublicoffer','openauction','openauctionsmb','limitedauction','saleauction','auctionsmb'].includes($scope.model.typeProcedure)) {
                            $scope.model.showAuction = true;
                        } else if (['requestquotations','limitedrequestquotations','opencontest','limitedcontest','requestproposals'].includes($scope.model.typeProcedure)) {
                            $scope.model.showRebiddings = true;
                        }
                    }

                    $scope.model.simplifiedProcedure=$scope.procedure.simplifiedProcedure;

                    if ($scope.model.typeProcedure === 'EAP44') {
                        $scope.model.showAuction = true;
                    }
                    
                    if (['admincab','organizer'].includes(cabType()) || $scope.isManagerCustomer() || checkAvailableCbz() || ($scope.auth?.userRole||[]).includes('operator_support_ro')) {
                        defineTabs();
                    } else if (cabType() === 'participant') {
                        _fillPurchasingDocs();
                        $scope.model.showReqExp = $scope.procedure.explanationRequests && $scope.procedure.explanationRequests.length>0;
                        $scope.model.purchasing = $scope.dataPurchasingDocs && $scope.dataPurchasingDocs.length>0;
                        messagesLoadFn();
                    }
                    if($scope.model.typeProcedure==='simplecontest' && $scope.model.stateProcedure !== 'reject'){
                        $scope.model.showPriceOffers = true;
                    }
                }

                function defineTabs(){
                    if($scope.user.userProperties && $scope.procedure.section && $scope.user.userProperties["allowed_"+$scope.procedure.section]==="false"){
                        return false;
                    }
                    if ($scope.procedure.notices && $scope.procedure.notices.length > 0) {
                        $scope.model.showNotices = true;
                    }
                    $scope.model.showReqExp = true;
                    $scope.model.showReqExpOrg = true;
                    $scope.model.showProtocol = true;
                    $scope.model.showExpls = ['admincab'].includes($scope.cabType()) ||
                        ('organizer'===$scope.cabType() && $scope.auth.orgId===$scope.procedure.organizer.id) || $scope.isManagerCustomer() || checkAvailableCbzRzdProc();
                    
                    if (['limitedcontest','limitedauction','limitedrequestquotations','limitedrequestproposals','limitedcontestqualification'].includes($scope.model.typeProcedure) &&
                        ($scope.auth.orgId===$scope.procedure.organizer.id || (['admincab','manager'].includes($scope.cabType()) || checkAvailableCbzRzdProc()))) {
                        $scope.model.showParticipants = true;
                    }
                    if($scope.procedure.organizer.id !== authSrv.getUser().id && !checkAvailableCbzRzdProc() && !['admincab','manager'].includes($scope.cabType())){
                        $scope.model.showProtocol = false;
                        $scope.model.showReqExp = false;
                        $scope.model.showReqExpOrg = false;
                    }
                }
                function messagesLoadFn() {
                    var param = angular.copy($scope.paginator);
                    param.searchByField={
                        purchaseId: $scope.procedure.id
                    };
                    httpSrv.http({
                        url: '/message/api/v2/showMessage/7',
                        data: param,
                        showCounter: true
                    }).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.model.showReqExpOrg = response.data.items && response.data.items.length>0;
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }
                function checkAvailableCbz(){
                    return $scope.cabType() === 'cbz' && proceduresSrv.isRZDOrChildRZD($scope.procedure);
                }

                function checkAvailableCbzRzdProc(){
                    return $scope.cabType() === 'cbz' && (($scope.procedure.customer.customerType === 'head' && $scope.procedure.customer.shortName === 'ОАО «РЖД»') ||
                           ($scope.procedure.customer.customerType === 'division' && $scope.procedure.customer.parent.shortName === 'ОАО «РЖД»'));
                }

                $scope.isManagerCustomer = function(){
                    return $scope.cabType() === 'manager' && $scope.procedure && $scope.procedure.customer &&
                        $scope.userCustomers && $scope.userCustomers.map(c => c.id).includes($scope.procedure.customer.id);
                }

                $scope.showClaimsPanel = function(){
                    if('admincab'===$scope.cabType() || $scope.isManagerCustomer())
                        return true;
                    if(checkAvailableCbzRzdProc())
                        return !(['reject', 'published'].includes($scope.procedure.state.id) || ('claiming' === $scope.procedure.state.id && $scope.procedure.stage?.id !== 'stage2'));
                    if($scope.isOrganizer()){
                        if(['reject', 'published'].includes($scope.procedure.state.id)
                            || ('claiming' === $scope.procedure.state.id && $scope.procedure.stage?.id !== 'stage2')
                            || !($scope.user.userProperties && $scope.user.userProperties.procedureWorkflow === "true" || $scope.cabType() === 'cbz'))
                            return false;
                        // Проверяем торговые секции
                        if($scope.user.userProperties && $scope.procedure.section && $scope.user.userProperties["allowed_"+$scope.procedure.section]==="false"){
                            return false;
                        }

                        //владелец ЛК является организатором процедуры
                        if($scope.procedure.organizer.id === $scope.auth.orgId)
                            return true;
                        //у владельца ЛК Организатора установлен признак "Просмотр заявок в своих процедурах другого организатора"
                        // и он является заказчиком процедуры или лота совместной закупки
                        if($scope.user.otherClaimsView && isOrganizerCustomerFn())
                            return true;
                    }
                    return false;
                }

                $scope.showStatisticPanel = function() {
                    if (['admincab'].includes($scope.cabType()) || $scope.isManagerCustomer() || $scope.procedure.organizer.id === authSrv.getUser().id || checkAvailableCbzRzdProc()) {
                        return true;
                    }
                    // владелец ЛК Организатора является только заказчиком процедуры или лота совместной процедуры и не является организатором процедуры и
                    // у заказчика (владельца ЛК) в справочнике Организаторы установлен признак "Просмотр заявок в своих процедурах другого организатора" и
                    // если у пользователя ЛК Организатора установлен признак "Документооборот при проведении процедур" или пользователь имеет роль "Сотрудник ЦБЗ"
                    else if($scope.isOrganizer() && ['organizer','cbz'].includes($scope.cabType()) && ($scope.user.userProperties && $scope.user.userProperties.procedureWorkflow === "true" || $scope.cabType() === 'cbz') &&
                            hasUserSectionAllowed() && $scope.procedure.organizer.id !== $scope.auth.orgId && $scope.user.otherClaimsView && isOrganizerCustomerFn()) {
                        return true;
                    } else {
                        return false;
                    }
                }

                function hasUserSectionAllowed() {
                    return $scope.procedure.section === '223' && $scope.user.userProperties.allowed_223 === "true" ||
                        $scope.procedure.section === '44' && $scope.user.userProperties.allowed_44 === "true" ||
                        $scope.procedure.section === 'commercial' && $scope.user.userProperties.allowed_commercial === "true";
                }

                function isOrganizerCustomerFn() {
                    return $scope.isOrganizer() && ($scope.procedure.customer.inn === $scope.user.inn
                        || ($scope.procedure.jointPurchase && $scope.procedure.lots && $scope.procedure.lots.find(lot => {
                            if (lot.customers && lot.customers.lotCustomers &&
                                lot.customers.lotCustomers.lotCustomer && lot.customers.lotCustomers.lotCustomer.length > 0) {
                                return lot.customers.lotCustomers.lotCustomer.find(lc => lc.customerInfo && lc.customerInfo.inn === $scope.user.inn) != null;
                            }
                        })));
                }

                function _fillPurchasingDocs() {
                    if ($scope.dataTableLots) {
                        angular.forEach($scope.dataTableLots, function (item) {
                            if (item.claims) {
                                angular.forEach(item.claims, function (claim) {
                                        var obj = angular.copy(claim);
                                        obj.lotName = item.name;
                                        obj.stateName = obj.state.name;
                                        obj.lot = item;
                                        if (obj.stage) obj.stageName = obj.stage.name;
                                        $scope.sortReverse = true;
                                        $scope.sortType = 'created';
                                        $scope.dataPurchasingDocs.push(obj);
                                })
                            }
                        })
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    storageSrv.setStateConfig($stateParams.procId, 'showPanel', {typePanel: type});
                    $scope.model.showPanel = type;
                }

                function loadScopeMth() {
                    var mth = $scope.startMth || $state.name + "Load";
                    if (typeof $scope[mth] === "function") $scope[mth](true);
                }

                function changeSortFn($event) {
                    var th = $event.currentTarget,
                        orderBy = th.getAttribute('st-sort'),
                        orderAsc = th.classList.contains("sortAsc");

                    [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                        column.classList.remove("sortDesc");
                        column.classList.remove("sortAsc");
                    });
                    orderAsc = !orderAsc;
                    th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
                    if (orderBy) {
                        //$scope.paginator.page = 0;   // если надо, то вернуть
                        $scope.paginator.orderBy = orderBy;
                        $scope.paginator.orderAsc = orderAsc;
                        proceduresSrv.setTableSort($state.name, {orderBy: orderBy, orderAsc: orderAsc});
                        loadScopeMth();
                    }
                }

                function openLoginFn(url, procType, procId, id) {
                    authSrv.checkedAuth().then(function (auth) {
                        var param = {
                            url: url,
                            procType: procType,
                            procId: procId,
                            id: id
                        };
                        if (auth.key) {
                            $state.go(param.url, {procType: param.procType, procId: param.procId, id: param.id})
                        } else {
                            proceduresSrv.openLogin(param)
                        }

                    })

                }

                function openModalFn(modal, items, number) {
                    $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        size: 'lg',
                        templateUrl: modal,
                        controller:['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                            $scope.nomenclatureItems = items;
                            $scope.lotNumber = number;
                            $scope.closeModal = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }]
                    }).result['catch'](function (res) {
                        if (!(res === 'cancel' || res === 'escape key press')) {
                            throw res;
                        }
                    });

                }

                function openPageFn(data, page) {
                    $state.go(page, {id: data.id});
                }

                $scope.downloadFile = function (file) {
                    const url = '/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.fileName);
                };
                $scope.downloadDocumentationFile = function (file) {
                    const url = '/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/documentation/' + file.id;
                    httpSrv.downloadFile(url, file.fileName);
                };
                $scope.downloadExplFile = function (file, expId) {
                    const url = '/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/explanations/' + expId + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.fileName);
                };
                $scope.downloadOpenPartFile = function (file, params, type) {
                    let url = '';
                    if (type === 'open') {
                        if ($scope.procedure.type.id === 'requestproposals' && !params.part.rebiddingNumber || $scope.procedure.type.id === 'limitedrequestproposals' || $scope.procedure.type.id === 'open2stagecontest') {
                            url = '/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/lots/' + params.lot.id + '/claims/' + params.part.number + '/stage/' + (params.part.stage ? params.part.stage : 'stage1') + '/openParts';
                        } else if (['opencontest', 'requestquotations', 'limitedrequestquotations', 'limitedcontest'].includes($scope.procedure.type.id) || $scope.procedure.type.id === 'requestproposals' && params.part.rebiddingNumber) {
                            url = '/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/lots/' + params.lot.id + '/claims/' + params.part.number + '/rebiddingNumber/' + (params.part.rebiddingNumber ? params.part.rebiddingNumber : 0) + '/openParts';
                        } else {
                            url = '/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id + '/lots/' + params.lot.id + '/claims/' + params.part.number + '/openParts';
                        }
                    } else if (type === 'pdf') {
                        url = '/fs/file/' + $scope.procedure.type.id + '/' + params.part.id + '/downloadPdf';
                    }
                    httpSrv.downloadFile(url);
                };
                $scope.downloadOpenPartArchive = function () {
                    httpSrv.downloadFile('/fs/file/' + $scope.procedure.type.id + '/' + $scope.procedure.id+'/openParts');
                }

                $scope.downloadProtocolFile = function (file, protocolId) {
                    const url = '/fs/file/' + $stateParams.procType + '/' + $stateParams.procId + '/protocols/' + protocolId + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.fileName);
                };

                function deletePurchasingDocFn(index) {
                    $scope.dataPurchasingDocs.splice(index, 1);
                }

                function findParticipantFn() {
                    if($scope.participantInn.search.id){
                        $scope.addParticipant();
                    }
                }
                function addParticipantFn() {
                    if ($scope.participantInn.search) {
                         const innExists = $scope.dataTableParticipants.filter((el) => el.inn === $scope.participantInn.search.inn) || [];

                         if (innExists.length > 0) {
                             $scope.alertFindParticipant = alertSrv.getErrorMsg("Участник с таким ИНН/УИН уже присутствует в документе.");
                         } else {
                             proceduresSrv.addParticipant($scope.participantInn.search?.inn ? $scope.participantInn.search?.inn : $scope.participantInn.search, $scope.participantInn.search?.id).then( (response) => {
                                 if (response.data.success) {
                                     $scope.dataTableParticipants = response.data.result;
                                     $scope.alertFindParticipant = alertSrv.getSuccessMsg('Организация добавлена!');
                                     $scope.participantInn.search = "";
                                 } else {
                                     $scope.alertFindParticipant = alertSrv.getErrorMsg(response.data.message);
                                 }
                             })
                         }
                         $scope.participantInn = {search:''};
                    }
                    $scope.updatePaginator();
                    $scope.dictPaginated.participantList = [];
                }

                function deleteParticipantFn(inn) {
                	if(inn){
           	    	  proceduresSrv.deleteParticipant(inn).then(function (response) {
                          if (response.data.success) {
                            $scope.dataTableParticipants = response.data.result;
                            $scope.alertFindParticipant = alertSrv.getSuccessMsg('Организация удалена!');
                          }
                      });
                        $scope.updatePaginator();
                	}
                }
               function notifyParticipantFn(id, inn) {
                	if(inn){
           	    	  proceduresSrv.notifyParticipant(id, inn).then(function (response) {
                          if (response.data.success) {
                            $scope.dataTableParticipants = response.data.result;
                            $scope.alertFindParticipant = alertSrv.getSuccessMsg('Уведомление выслано!');
                          }
                      });
                	}
                }

               /**
                * Смотрим есть ли открытые части у документа для отображения во вкладке Открытая часть
                * @private
                */
               function _viewOpenParts() {

                   if($scope.procedure.section==='223' && $scope.procedure.customer.customerType && $scope.procedure?.procOp!=='1'){
                      const date = ['head', 'dzo'].includes($scope.procedure.customer.customerType) ? $scope.procedure.customer.hide223OpenPartAfter :
                          ('division'===$scope.procedure.customer.customerType ? $scope.procedure.customer.parent.hide223OpenPartAfter : undefined);
                      if(date && $scope.procedure.created>=date)
                          return;
                   }

                   const isRZDTeam = proceduresSrv.isRZDOrChildRZD($scope.procedure);

                   if($scope.procedure.commercial && $scope.procedure.customer.customerType && $scope.procedure?.procOp!=='1'){
                       const date = ['head', 'dzo'].includes($scope.procedure.customer.customerType) ? $scope.procedure.customer.hideCommercialOpenPartAfter :
                           ('division'===$scope.procedure.customer.customerType ? $scope.procedure.customer.parent.hideCommercialOpenPartAfter : undefined);
                       if(date && $scope.procedure.created>=date)
                           return;
                   }

                   if (!isRZDTeam || ['finished','reject'].includes($scope.procedure.state.id)) {
                       angular.forEach($scope.procedure.lots, function (lot) {
                           if (angular.isDefined(lot.openParts)) {
                               if ($scope.model.openPartsCount === undefined) {
                                   $scope.model.openPartsCount = 0;
                               }
                               if (lot.openParts.length > 0) {
                                   $scope.model.openPartsCount += 1;
                               }
                           }
                       })
                   }
               }

                function isCommerialSimpleContest(){
                    return $scope.procedure.commercial && $scope.model.typeProcedure==='simplecontest';
                }

                function _viewContracts() {
                    if (['participant','organizer','admincab','cbz'].includes($scope.cabType()) || $scope.isManagerCustomer()) {
                        if($scope.cabType()==='cbz' && !checkAvailableCbzRzdProc()){
                            $scope.contractView = false;
                            return;
                        }
                        contractSrv.viewContractsTab($scope.procedure.id).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.contractView = response.data.result;
                                if ($scope.contractView && $scope.procedure.state.id==='finished') {
                                    contractSrv.getContractsList($scope.procedure.id, $scope.contractsPaginator).then(function successCallback(response) {
                                        if (response.data.success) {
                                            $scope.contracts = response.data.items;
                                            $scope.contractsTotalPages = Math.ceil(response.data.total / $scope.contractsPaginator.itemsPerPage);
                                            _initContractActionsBtn($scope.contracts);
                                        }
                                    }, function errorCallback(response) {
                                        console.log(response);
                                    });
                                }
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });

                    }
                }

                function _filterProtocolActions() {
                    if($scope.cabType() === 'organizer') {
                        const protocols = ['OPEN','PART1','PARTS','REZ','REBIDDING_NOTICE','REBIDDING_OPEN','REBIDDING_PART1','CHANGE_CONTRACT_ADDITIONAL'];
                        const hasPublication = $scope.procedure?.protocols?.filter(p=>protocols.includes(p.type.id))?.some(p => p.actions.filter(a => a.id.endsWith('.publishProtocol'))) ||
                                               $scope.procedure?.protocolChanges?.filter(p=>protocols.includes(p.type.id))?.some(p => p.actions = p.actions.filter(a => !a.id.endsWith('.publishProtocol')));
                        // Список лотов, с номерами текущих переторжек у которых нужно убрать публикацию
                        const rebiddingLots = $scope.procedure?.protocols?.filter(p=>'REBIDDING_OPEN'===p.type.id && p.actions.filter(a => a.id.endsWith('.publishProtocol')))?.map(p => p.lotNumber);

                        $scope.procedure?.protocols?.filter(p=>protocols.includes(p.type.id))?.forEach(p => p.actions = p.actions.filter(a => !a.id.endsWith('.publishProtocol')));
                        $scope.procedure?.protocolChanges?.filter(p=>protocols.includes(p.type.id))?.forEach(p => p.actions = p.actions.filter(a => !a.id.endsWith('.publishProtocol')));
                        if(hasPublication && $scope.procedure?.actions) {
                            $scope.procedure.actions = $scope.procedure?.actions?.filter(a => !a.id.contains('.publish'));
                        }
                        if(rebiddingLots) {
                            $scope.procedure?.lots?.filter(l => rebiddingLots.includes(l.ordinalNumber) && l.actions)?.forEach(l => l.actions = l.actions.filter(a => !a.id.endsWith('.publishRebiddingOpeningProtocolOnLot')));
                        }
                    }
                }

                function contractsLoadFn() {
                    contractSrv.getContractsList($scope.procedure.id, $scope.contractsPaginator).then(function successCallback(response) {
                        if (response.data.success) {
                        	$scope.contractActions = [];
                            $scope.contracts = response.data.items;
                            $scope.contractsTotalPages = Math.ceil(response.data.total / $scope.contractsPaginator.itemsPerPage);
                            _initContractActionsBtn($scope.contracts);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                $scope.getContractActionsKey = function(contract){
                    return `${contract.lotId}_${contract.participant?.id}_${contract.customer?.id}`;
                }

                function _initContractActionsBtn(contracts) {
                   if (contracts && contracts.length > 0) {
                	   contracts.forEach(function (contract) {
                		 const isActions = contract.actions && contract.actions.length > 0;
                		 const actionsKey = $scope.getContractActionsKey(contract);
                		 if (!['winner_not_found', 'lot_canceled', 'bidding_canceled'].includes(contract.lotInfoState.id) &&
                				 (!isActions || contract.actions[0].id !== 'initSendToParticipant')) {
                			 if(!$scope.contractActions[actionsKey]){
                    			 $scope.contractActions[actionsKey] = [];
                    		 }
                             if(!$scope.contractActions[actionsKey].find(a=>['base.initSendToParticipant', 'base.contractOpen'].includes(a.id)))
                			    $scope.contractActions[actionsKey].push({id:'base.contractOpen', name:getContractOpenBtnNameFn(contract)});
                         }
                		 if (isActions) {
                        	 contract.actions.forEach(function (action) {
                        		 if(!$scope.contractActions[actionsKey]){
                        			 $scope.contractActions[actionsKey] = [];
                        		 }
                                 if(!$scope.contractActions[actionsKey].find(a=>a.id==='base.' + action.id))
                        		    $scope.contractActions[actionsKey].push({id:'base.' + action.id, name:action.name});
                        	 })
                          }
                	   })
                   }
                }

                function getContractOpenBtnNameFn(contract) {
                    if($scope.isParticipant())
                        return contract.contractState && contract.contractState.id && ['finished','finishedPaper','finishedRejectByOrganizer','finishedRejectByCustomer'].includes(contract.contractState.id) ? 'Информация о договоре' : 'Перейти к договору в электронной форме';
                    if($scope.isOrganizer()) {
                        //для процедур, которые не являются совместными
                        if(!$scope.procedure.jointPurchase) {
                            //организатор не является заказчиком процедуры
                            if ($scope.procedure.customer.inn !== $scope.user.inn)
                                return 'Информация о договоре';
                            else {
                                if (!contract.contractState || contract.contractState.id === 'none') {
                                    return 'Перейти к заключению договора в электронной форме'
                                } else if (contract.contractState && contract.contractState.id && !['finished', 'finishedPaper', 'finishedRejectByOrganizer', 'finishedRejectByCustomer'].includes(contract.contractState.id)) {
                                    return 'Перейти к договору в электронной форме';
                                }
                            }
                        } else {
                            //для совместных процедур (в зависимости от лота)
                            const lot = $scope.procedure.lots?.find(lot => contract.lotId === lot.id);
                            if(lot && contract.lotId === lot.id && $scope.user.inn === contract.customer.inn && lot.customers?.lotCustomers?.lotCustomer?.find(lc => lc.customerInfo?.inn === contract.customer.inn)) {
                                if (!contract.contractState || contract.contractState.id === 'none') {
                                    return 'Перейти к заключению договора в электронной форме'
                                } else if (contract.contractState && contract.contractState.id && !['finished', 'finishedPaper', 'finishedRejectByOrganizer', 'finishedRejectByCustomer'].includes(contract.contractState.id)) {
                                    return 'Перейти к договору в электронной форме';
                                }
                            }

                        }
                    }
                        
                    return 'Информация о договоре';
                }

                function contractActionNameFn(contract) {
                   if (contract) {
                       if (contract.lotInfoState) {
                           if (['winner_not_found', 'lot_canceled', 'bidding_canceled'].includes(contract.lotInfoState.id)) {
                               return ''
                           }
                       }
                       if (!checkAvailableCbz() && contract.actions && contract.actions.length > 0 && contract.actions[0].id === 'initSendToParticipant') {
                           return 'Перейти к заключению договора в электронной форме'
                       }
                       return 'Информация о договоре';
                   }
                }
                
               function hasItemPricesFn(){
                   var hasItemPrices=false;
                   angular.forEach($scope.procedure.lots, function (lot) {
                        if(lot.priceForItem) hasItemPrices=true;
                   });
                   return hasItemPrices;
               }
               function focusLotPanelFn() {
                   setActivePanelFn('lots');
                   setTimeout(function () {
                       desktopSrv.scrollIntoViewFn(document.querySelectorAll('#_lolNumber0')[0], {
                           behavior: "smooth",
                           block: "end"
                       });
                   })
               }

               $scope.getPortalLink = function (procType) {
                   return !procType || procType==='uetp' ? $rootScope.properties['main.url'] : $rootScope.properties[procType+'.url'];
               };
               $scope.isSimpleContest44 = function () {
                    return $scope.model.typeProcedure === 'simplecontest' && $scope.procedure.section==='44';
               };
               $scope.isSimpleContest223Commercial = function () {
                    return $scope.model.typeProcedure === 'simplecontest' && ['commercial', '223'].includes($scope.procedure.section);
               };
               $scope.isOrganizer = function(){
                    return ["organizer", "organizer_cbz"].includes($scope.cabType());
               };
               $scope.isOperator = function(){
                    return "admincab"===$scope.cabType();
               };
               $scope.isSupportRO = () => ($scope.auth?.userRole||[]).includes('operator_support_ro');
               $scope.isParticipant = function(){
                    return ["participant"].includes($scope.cabType());
               };
               $scope.openEisError = function(eisError) {
                   needConfirmSrv.openModal('_dialogMessage.html', {message: !eisError.endsWith('.') ? eisError + '.': eisError});
               }

               $scope.getCurrency = function(currencyCode) {
                   return $scope.currencyDict.find(c=>c.code === currencyCode)?.pattern;
               }

                function getProcedureState(items, id){
                    return items.filter(function (item) { return item.id===id; })[0];
                }

                $scope.showLotPrice = function(){
                   return $scope.pf.price && $scope.procedure.priceMode !== 'percent';
                }

                $scope.showWholeLotPrice = function(lotData){
                   return lotData.priceForItem || $scope.procedure.priceMode === 'percent';
                }

                $scope.changeState = function (el, prevState, selector) {
                    if(prevState!=='' && prevState!==selector.$select.selected.id)
                        needConfirmSrv.callVariants(changeStateProcess, function (){
                            selector.$select.selected = getProcedureState(selector.$select.items, prevState);
                            $scope.procedure.state = selector.$select.selected;
                            },['admin/changeProcedureState/'+el.id+'/'+selector.$select.selected.id], 'Статус закупки будет изменен на "'+el.state.name+'". Продолжить?');
                }

                function changeStateProcess(url){
                    return proceduresSrv.changeState(url);
                }

                $scope.changeNoticeState = (el, prevState, selector) => {
                    if(!$scope.showConfirm) {
                        $scope.showConfirm = true;
                        return;
                    }
                    if(prevState!=='' && prevState!==selector.$select.selected.id)
                        needConfirmSrv.callVariants(changeStateProcess, () => {
                            selector.$select.selected = getProcedureState(selector.$select.items, prevState);
                            $scope.showConfirm = false;
                            },['admin/changeNoticeState/'+el.id+'/'+selector.$select.selected.id], 'Статус извещения будет изменен на "'+el.state.name+'". Продолжить?');
                }

                $scope.stateFilter = function(el){
                    return el.id!=='created' && el.id!=='biddingCanceled';
                }
                $scope.bank = function() {
                    window.open(['home','support'].includes($scope.cabType()) ? $state.href('bank') : $state.href('bg'),'_blank');
                }
                $scope.isPercentMode = function(){
                    return $scope.procedure && $scope.procedure.priceMode==='percent';
				}
                $scope.hasBiddingStartDate = function(){
                    return $scope.model && ['openpublicoffer','openauction','saleauction','openauctionsmb','limitedauction','auctionsmb'].includes($scope.model.typeProcedure);
                }
                $scope.isDifferentDeliveryPlace = function(){
                    return $scope.model.deliveryPlaceIndication==='DIFFERENT_PLACE';
                }
                $scope.hasStages = function() {
                    return ['requestproposals','limitedrequestproposals','open2stagecontest'].includes($scope.model?.typeProcedure) && !$scope.model?.simplifiedProcedure;
                }

                function getCurrencyCodeFn(data, field) {
                   if(field)
                       return $scope.getCurrency(field);
                   return $scope.getCurrency(data.applicationSupplyCurrencyCode ? data.applicationSupplyCurrencyCode : data.currencyCode);
                }
                $scope.getStartLotPrice = (data) => {
                    return (data.priceForItem || $scope.isPercentMode() ? data.wholeLotPriceNoNDS : ($scope.isSimpleContest223Commercial() ? data.price : (data.priceNoNDS || data.price)));
                }
                $scope.getStartLotTail = (data) => {
                    if(($scope.model.typeProcedure !== 'simplecontest' && (data.priceNoNDS || data.price || $scope.isPercentMode())) && !$scope.isSimpleContest44()) {
                        return data.priceNoNDS || $scope.isPercentMode() ? ', без НДС' : ', с НДС';
                    }
                    return '';
                }

                function viewSupplyUserCheckFn() {
                    return ['nr','fl'].includes($scope.user?.typeOrg);
                }
                function showAlertPanelFn() {
                    return $scope.tariffsAlertPanel && (!['admin','organizer'].includes($scope.auth.orgRole) && !($scope.auth?.userRole||[]).includes('operator_accountant', 'operator_manager') || ($scope.auth?.userRole||[]).includes('operator_support_ro'));
                }

                function isWinnerPaysFn() {
                    return $scope.procedure?.tariffType == 'winnerPays' || $scope.procedure?.lots?.some(lot => lot?.tariffType == 'winnerPays');
                }

                $scope.showSubscribePanel = () => {
                    $rootScope.$emit('toggleSubscribePanelEvent');
                    $scope.subscribePanelVisible = !$scope.subscribePanelVisible;
                }

                $rootScope.$on("closeSubscribePanelEvent", function () {
                    $scope.subscribePanelVisible = !$scope.subscribePanelVisible;
                });
               
                $scope.showFactoringPanel = () => {
                    loggerUiSrv.sendLog('WANNAFACTORING',{
                        targetId: $scope.procedure.id
                    });

                    $state.go('factoring');
                }

                $scope.hasActualDocumentation = () => [$scope.procedure.files.length, $scope.procedure.explanations?.length, $scope.dataTableProtocols.length].find(num=>!isNaN(num) && Number(num)>0);

                $scope.getClaimNumber = (claims, claimId) => {
                    return claims && claims.length>0 && claims.find(item=>item.id===claimId)?.number!== 0 ? claims.find(item=>item.id===claimId)?.number : '-'
                }
                $scope.isOrgOrAdmin = () => ['operator_admin', 'operator_manager'].includes($scope.user.userRole)  || $scope.cabType() === 'organizer';
                $scope.isOrgOrAdminSupport = () => ['operator_admin', 'operator_manager', 'operator_support_ro'].includes($scope.user.userRole)  || $scope.cabType() === 'organizer';

                /**
                 * Участники. Обновление пагинатора
                 */
                $scope.updatePaginator = () => {
                    $scope.totalPages = ($scope.dataTableParticipants.length) ? Math.ceil($scope.dataTableParticipants.length / $scope.paginator.itemsPerPage) : null;
                }

                function _initDictionaryBase() {
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                }

                function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                    if (!value) {
                        dictionarySrv.resetPaginatedDict($scope);
                        delete $scope.hasInnError;
                    } else if(value?.length>=8) {
                        if(!$stateParams.type) {
                            $stateParams.type = $scope.procedure.type.id;
                        }
                        dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd).then((response)=>{
                            if(!response.data.items || response.data.items.length === 0) {
                                $scope.alertFindParticipant = alertSrv.getErrorMsg("Организация с таким ИНН/УИН не найдена.");
                                $scope.hasInnError = true;
                            } else {
                                delete $scope.hasInnError;
                            }
                        });
                    } else {
                        $scope.dictPaginated.participantList = [];
                        $scope.totalPages = 0;
                        delete $scope.hasInnError;
                    }
                }

                $scope.getParticipantsDoc = () => {
                    if(!$scope.dataTableParticipants || $scope.dataTableParticipants.length===0) {
                        if(!$stateParams.type) {
                            $stateParams.type = $scope.procedure.type.id;
                        }
                        proceduresSrv.getParticipantsDoc($stateParams.id, $stateParams.type).then(function (response) {
                            if (response.data.success) {
                                $scope.dataTableParticipants = response.data.result;
                                $scope.totalPages = Math.ceil(response.data.result.length / $scope.getPager().itemsPerPage);
                            }
                        });
                    }

                }
                $scope.getPager = () => {
                    $stateParams.clearConfig = false;
                    return paginationSrv.getPaginationStateConfig('pagination', 'participantsDoc');
                }
            }]
    });

