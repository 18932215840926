/**
 * Страница "Получить сведения из ЕИС"
 */
angular.module('uetp')
    .component('customEisPacket', {
        templateUrl: 'app/components/customEisPacket/customEisPacket.html',
        bindings: {
        },
        controller: ['$scope', '$state', '$stateParams', 'propertiesService', 'dictionarySrv', 'httpSrv', '$filter', 'timeout',
            function ($scope, $state, $stateParams, propertiesService, dictionarySrv, httpSrv, $filter, timeout) {
                $scope.model = {};
                
                $scope.$stateParams = $stateParams;

                this.$onInit = () => {
                    $scope.customEisEntities = dictionarySrv.getStaticDictByType('customEisEntities');
                }

                $scope.sendEisPacket = () => {
	                if (!$scope.model.entityType) {
		              console.log('Не задан Тип запрашиваемых сведений!');
                      return;
	                }
	                if (!$scope.model.regNumberList) {
		              console.log('Не заполнен Список реестровых номеров!');
                      return;
	                }
                    $scope.submitted = true;
                    $scope.alertWarn = true;

                    propertiesService.getProperty("otherProcedures.url").then((host) => {
                        httpSrv.downloadFile(`${host}/rest/other-purchases/plan/customEisKisRequest`, `customEisResponse_${$filter('date')(getServerTimeMsk(), 'dd-MM-yyyy_HH:mm:ss','UTC+3')}.xml`,
                            { 'entityType': $scope.model.entityType.code, 'regNumberList': $scope.model.regNumberList});
                    });
                }
                $scope.$on('setShowThrobber', (event, data) => {
                   $scope.showThrobber = data;
                   if (!$scope.showThrobber) {
                       $scope.submitted = false;
                   }
                   timeout($scope);
                });

                $scope.goBack = () => history.back();
            }]
    });
