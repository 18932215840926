/*global getServerTimeMskPromise*/

/**
 * @author dmitrys
 */
angular.module('uetp')
    .component('notice', {
        templateUrl: 'app/components/notice/notice.html',
        bindings: {
            procData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'noticeSrv', 'proceduresSrv',
            'needConfirmSrv', 'alertSrv', 'extendFieldSrv', 'titleSrv', 'paginationSrv', 'dictionarySrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, noticeSrv, proceduresSrv,
                      needConfirmSrv, alertSrv, extendFieldSrv, titleSrv, paginationSrv, dictionarySrv) {

                $scope.setActivePanel = setActivePanelFn;
                $scope.getActivePanel = getActivePanelFn;
                $scope.isLoadTemplate = isLoadTemplateFn;
                $scope.getState = getStateFn;
                $scope.showMenu = showMenuFn;
                $scope.getForm = getFormFn;
                $scope.focusFirstInvalid = focusFirstInvalidFn;
                $scope.findParticipant = findParticipantFn;
                $scope.addParticipant = addParticipantFn;
                $scope.setParticipantList = setParticipantListFn;
                $scope.getTitleTab = getTitleTabFn;
                $scope.isShowExtendedFields = noticeSrv.isShowExtendedFields;
                $scope.isNoticeExtFields = noticeSrv.isNoticeExtFields;
                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;
                $scope.getSectionName = noticeSrv.getSectionName;
                $scope.getSection = noticeSrv.getSection;
                $scope.getParticipantsDoc = getParticipantsDocFn;
                $scope.getPager = getPagerFn;
                $scope.refreshDict = refreshDictFn;

                this.$onInit = function () {
                    var ctrl = this;
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.submitted = noticeSrv.isSubmitted;

                    $scope.model = {
                        showPanel: noticeSrv.getShowPanel(),
                        showProcedure: true, // Показать создание или изменение процедцры
                        showLots:true,//Показывать лоты
                        showDocumentation: true, //Показать  Документация
                        showParticipants: false, //Показать список участников
                        showExtendFields: true //Вкладка "Расширеные поля"
                    };
                    if(['limitedcontest','limitedauction','limitedrequestquotations','limitedrequestproposals','limitedcontestqualification'].includes($stateParams.type))
                    	$scope.model.showParticipants = true;
                    
                    extendFieldSrv.clearNoticeExtFieldTemplate();
                    extendFieldSrv.setLoadTemplate(false);
                    //создание или изменение процедцры
                    noticeSrv.setReadOnlyForm(false);
                    /**
                     * Пагинатор
                     */
                    $scope.totalPages = 0;
                    $scope.paginator = {
                        itemsPerPage: 10,
                        page: 0
                    };
                    if (ctrl.procData) {
                        if (!ctrl.procData.data.result.actions || ctrl.procData.data.result.actions.length === 0) {
                            $scope.typeProcedure = 'Просмотр';
                            noticeSrv.setReadOnlyForm(true);
                        } else {
                            $scope.typeProcedure = 'Изменение';
                        }
                        noticeSrv.setEditForm(true);
                        $scope.dataTableParticipants = ctrl.procData.data.result?.participantsDoc?.document?.participants;
                        $scope.updatePaginator();
                    } else {
                        $scope.typeProcedure = 'Создание';
                        noticeSrv.setEditForm(false);
                        noticeSrv.setNoticeInfo({
                            document: noticeSrv.defaultModel($stateParams.type),
                            type: {
                                id: $stateParams.type,
                                name: noticeSrv.initProcNameByType($stateParams.type)
                            }
                        });

                        let date = getServerTimeMsk();
                        if (date) {
                            date.setHours(-3 - new Date().getTimezoneOffset() / 60, 0, 0, 0);
                            noticeSrv.getNoticeInfo().created = date;
                            noticeSrv.getNoticeInfo().document.claimBegins = date;
                        } else {
                            getServerTimeMskPromise().then((date) => {
                                if (date)
                                    date.setHours(-3 - new Date().getTimezoneOffset() / 60, 0, 0, 0);
                                noticeSrv.getNoticeInfo().created = date;
                                noticeSrv.getNoticeInfo().document.claimBegins = date;
                            });
                        }
                        $scope.dataTableParticipants = [];
                    }
                    if ('cbz' === $scope.cabType)
                        noticeSrv.setReadOnlyForm(true);
                    
                    $scope.notice = noticeSrv.getNoticeInfo();
                    $scope.participantInn = {search:''};

                    if(!(noticeSrv.isNew223() || noticeSrv.isInitedNotice()) || $scope.notice.type.id === 'simplecontest') {
                        $scope.model.showExtendFields = false;
                        noticeSrv.setShowExtendedFields(false);
                    } else {
                     	$scope.model.showExtendFields = true;
                        noticeSrv.setShowExtendedFields(true);
                    }

                    $stateParams.clearConfig = false;
                    paginationSrv.setPaginationStateConfig('pagination', 'participantsDoc', $scope.paginator);
                    
                    if($scope.model.showParticipants && noticeSrv.getEditForm()){
                        getParticipantsDocFn();
                    }
                    _initShowPanel();
                    _refreshView();

                    $scope.isReadOnly = noticeSrv.getReadOnlyForm();
                    if ($scope.notice.document.number && $scope.notice.type.name) {
                        titleSrv.setTitle($scope.notice.document.number + ' | ' + $scope.notice.type.name);
                    }

                    if(['limitedcontest','limitedauction','limitedrequestquotations','limitedrequestproposals','limitedcontestqualification'].includes($stateParams.type)) {
                        _initDictionaryBase();
                    }
                };

                /**
                 * Инициализация панели по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    var cabType = desktopSrv.getCabType();
                    if (cabType === 'admincab') {
                    }

                }
               
                /**
                 * Обновление состояния при перегрузке страницы по F5 или другим способом
                 * @private
                 */
                function _refreshView() {
                    var activePanel = $stateParams.setActivePanel;
                    if (activePanel && $scope.model.showPanel !== activePanel) {
                        setActivePanelFn(activePanel);
                    } else if(angular.isUndefined(activePanel) && $scope.model.showPanel==='extendField'){
                        setActivePanelFn('procedure');
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    if(type==='extendField' && !extendFieldSrv.isLoadTemplate()) return;
                    noticeSrv.setShowPanel(type);
                    $scope.$broadcast('uetp.notice', {data:null, dataType: type, type: 'refresh'});
                }

                /**
                 * Получить активную панель
                 */
                function getActivePanelFn() {
                    return noticeSrv.getShowPanel()
                }
                function isLoadTemplateFn() {
                    return extendFieldSrv.isLoadTemplate()
                }
                function getStateFn() {
                    return noticeSrv.getState();
                }
                function showMenuFn() {
                    var state = getStateFn();
                    if(state=='nomenclature'||state=='lot') return false;
                    return true;
                }
                function getFormFn(state) {
                    return noticeSrv.getForm(state);

                }
                function focusFirstInvalidFn(form, tab) {
                    noticeSrv.focusFirstInvalid({form:form, tab:tab});
                }
                function getParticipantsDocFn() {
                    if(!$scope.dataTableParticipants || $scope.dataTableParticipants.length===0) {
                        proceduresSrv.getParticipantsDoc($stateParams.id, $stateParams.type).then(function (response) {
                            if (response.data.success) {
                                $scope.dataTableParticipants = response.data.result;
                                $scope.totalPages = Math.ceil(response.data.result.length / $scope.getPager().itemsPerPage);
                            }
                        });
                    }

                }
                function getPagerFn(){
                    $stateParams.clearConfig = false;
                    return paginationSrv.getPaginationStateConfig('pagination', 'participantsDoc');
                }
                function findParticipantFn() {
                	if($scope.participantInn.search.id){
                        $scope.addParticipant();
                  	}
                }
                function addParticipantFn() {
                	if ($scope.participantInn.search) {
                   		 const innExists = $scope.dataTableParticipants.filter((el) => el.inn === $scope.participantInn.search.inn) || [];
                		 
                		 if (innExists.length > 0) {
                             $scope.alertFindParticipant = alertSrv.getErrorMsg("Участник с таким ИНН/УИН уже присутствует в документе.");
                		 } else {

                             $scope.dataTableParticipants.push({
                                 id: angular.copy($scope.participantInn.search.id),
                                 inn: angular.copy($scope.participantInn.search.inn),
                                 kpp: angular.copy($scope.participantInn.search.kpp),
                                 name: angular.copy($scope.participantInn.search.name),
                                 statusName: 'не отправлялось',
                                 statusCode: 'notSended'
                			 })
                		 }
                		 $scope.participantInn = {search:''};
                	}
                    $scope.updatePaginator();
                    $scope.dictPaginated.participantList = [];
                }

                function setParticipantListFn(participants) {
                    $scope.dataTableParticipants=[];
                    angular.forEach(participants, (p) => {
                        $scope.dataTableParticipants.push({
                            id: p.id,
                            inn: p.inn,
                            kpp: p.kpp,
                            name: p.name,
                            statusName: 'не отправлялось',
                            statusCode: 'notSended'
                        });
                    });
                	$scope.participantInn = {search:''};
                    $scope.updatePaginator();
                }

                $scope.deleteParticipant = (inn) => {
                    if (inn) {
               	        $scope.dataTableParticipants = $scope.dataTableParticipants.filter(el => el.inn !== inn);

                        if ($scope.dataTableParticipants.length <= $scope.paginator.itemsPerPage) {
                            $scope.paginator.page = 0;
                        }
                     $scope.updatePaginator();
                     paginationSrv.setPaginationStateConfig('pagination', 'participantsDoc', $scope.paginator);
                    }
                }
                
                function getTitleTabFn(type) {
                    var value = '';
                    if (type === 'extendField') {
                        //шаблон не загружен -> проверяем наличие заказчика
                        if (!extendFieldSrv.isLoadTemplate())
                            if ($scope.notice.document && $scope.notice.document.customer && $scope.notice.document.customer.id) {
                                value = 'Расширенных полей нет';
                            } else {
                                value = 'Необходимо выбрать заказчика';
                            }
                    }
                    return value;
                }

                /**
                 * Обновление пагинатора
                 */
                $scope.updatePaginator = () => {
                    $scope.totalPages = ($scope.dataTableParticipants?.length) ? Math.ceil($scope.dataTableParticipants.length / $scope.paginator.itemsPerPage) : null;
                }

                function _initDictionaryBase() {
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                }

                function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                    if (!value) {
                        dictionarySrv.resetPaginatedDict($scope);
                        delete $scope.hasInnError;
                    } else if(value?.length>=8) {
                        dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, null, needToAdd).then((response)=>{
                            if(!response.data.items || response.data.items.length === 0) {
                                $scope.alertFindParticipant = alertSrv.getErrorMsg("Организация с таким ИНН/УИН не найдена.");
                                $scope.hasInnError = true;
                            } else {
                                delete $scope.hasInnError;
                            }
                        });
                    } else {
                        $scope.dictPaginated.participantList = [];
                        if (object !== 'participantList') {
                            $scope.totalPages = 0;
                        }
                        delete $scope.hasInnError;
                    }
                }

                $scope.isOrganizer = () => ["organizer", "organizer_cbz"].includes($scope.cabType);
            }]
    });
