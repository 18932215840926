angular.module('uetp').constant('postedInfo', {
    title    : 'Отчёт о размещённых сведениях',
    access   : ['organizer'],
    url      : '/auth/org/downloadPostInfoReport',
    template : 'app/components/reports/types/postedInfoReport.html',
    data     : {
        endDate   : null,
        startDate : null,
        procTypes:[],
        extraOptions:[
            {id:'notices', message:'Извещения',selected:false},
            {id:'explanations', message:'Разъяснения',selected:false},
            {id:'changes', message:'Изменения',selected:false},
            {id:'notifications', message:'Уведомления',selected:false},
            {id:'protocols', message:'Протоколы',selected:false}
        ]
    },
    settings:{

        paramTitles:{
            period:"Дата публикации",
            procTypes:"Тип процедуры"
        }
    },

    toMSK    : ['startDate', 'endDate'],
    fileName : function () {
        return "Отчёт о размещённых сведениях.xlsx";
    }
});