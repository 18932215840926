import { sentryMsg } from '../utils/sentry.es6';

/**
 * @author: sergeyu
 * clearConfig - параметр для сьроса параметров активных вкладок, номера страниц, положения скрола. Подробнее в storageSrv
 * stateSendLog - передача на сервер логированный переход пользователя loggerUiSrv (необходимо прописать константы под переходы)
 * directTransitionAuth -  Параметр для прямого перехода после авторизации. Если вход был по прямой ссылке.
 *                         (true|false[optional]) Если указать параметр с authRequired true,
 *                          то будет выводится окно авторизации и после авторизации будет прямой переход по ссылке.
 */
angular.module('uetp').config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
    'use strict';
    $urlRouterProvider.otherwise('/');



    $stateProvider.stateService.defaultErrorHandler(function (err) {
        // подумать надо ли выводить чтонить тут
        sentryMsg(err);
    });
    /**
     * Домашняя страница
     */
    var home = {
            url: '/',
            params: {
                clearConfig: true
            },
            component: 'home'
        },
        about = {
            url: '/about',
            params: {
                clearConfig: true
            },
            component: 'about'
        },
        becomeCustomer = {
            url: '/becomeCustomer',
            stateSendLog: 'BECOME_CUSTOMER',
            params: {
                clearConfig: true
            },
            component: 'becomeCustomer'
        },
        becomeSupplier = {
            url: '/becomeSupplier',
            stateSendLog: 'BECOME_SUPPLIER',
            params: {
                clearConfig: true
            },
            component: 'becomeSupplier'
        },
        contacts = {
            url: '/contacts',
            params: {
                clearConfig: true
            },
            component: 'contacts'
        },
        documents = {
            url: '/documents',
            params: {
                clearConfig: true,
                docTab: '1'
            },
            templateUrl: 'app/components/documents/documents.html',
            controller: 'tablesCtrl'
        },
        video = {
            url: '/video',
            stateSendLog: 'VIDEO',
            params: {
                clearConfig: true,
            },
            templateUrl: 'app/components/video/video.html',
            controller: 'tablesCtrl'
        },
        /**
         * Профиль пользователя
         */
        profile = {
            name: 'profile',
            url: '/profile',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user",
                "operator_accountant", "operator_support_ro", "operator_support_rw", "operator_inspector",
                "operator_manager", "bg_agent_admin", "bg_agent_user", "bank_admin", "bank_user"],
            params: {
                activeProfileTab: null,
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'profile',
                    bindings: {hasFiliations: 'hasFiliations'}
                },
                'pwdChange@profile': {
                    component: 'pwdChange'
                },
                'versionInfoTab@profile': {
                    component: 'versionInfoTab'
                },
                'requisites@profile': {
                    component: 'requisitesProfile'
                },
                'customersProfile@profile': {
                    component: 'customersProfile'
                },
                'actionsProfile@profile': {
                    component: 'actionsProfile'
                },
                'agents@profile': {
                    templateUrl: 'app/components/profile/agents/agents.html'
                },
                'userInfo@profile': {
                    component: 'userInfo'
                },
                'personalAccount@profile': {
                    component: 'personalAccount',
                    bindings: {personalAccountData: 'personalAccountData', personalAccountView: 'personalAccountView', personalAccountList: 'personalAccountList'}
                },
                'agentList@profile': {
                    component: 'agentList'
                },
                'accreditation@profile': {
                    templateUrl: 'app/components/profile/accreditation/accreditation.html'
                },
                'accreditationList@profile': {
                    component: 'accreditationList'
                },
                'filiation@profile': {
                    templateUrl: 'app/components/profile/filiation/filiation.html'
                },
                'filiationList@profile': {
                    component: 'filiationList'
                }
            },

            resolve: {
                personalAccountData: ['$stateParams', 'authSrv', function ($stateParams, authSrv) {
                    authSrv.reloadOrgInfo().then(function (user) {
                        return user;
                    });
                }],
                personalAccountView: ['desktopSrv', function (desktopSrv) {
                    var cabType = desktopSrv.getCabType();
                    if (cabType === 'accountant') {
                        return 'app/components/profile/personalAccount/view/personalAccountAccountant.html'
                    } else {
                        return 'app/components/profile/personalAccount/view/personalAccountDefault.html'
                    }
                }],
                personalAccountList: ['$stateParams', 'authSrv', 'profileSrv', 'desktopSrv', function ($stateParams, authSrv, profileSrv,desktopSrv) {
                    if(desktopSrv.getCabType() === 'accountant'){
                        return profileSrv.loadAccountsList(authSrv.getAuth().orgId).then(function successCallback(response) {
                            if (!response.data.success) {
                                console.log(response);
                            }
                            return response.data.result;
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }

                }],
                hasFiliations: ['desktopSrv', 'profileSrv', (desktopSrv, profileSrv) => {
                    if (desktopSrv.getCabType() === 'participant') {
                        return profileSrv.loadFiliationList();
                    }
                }],
            }
        },
        tariffsMenu = {
            name: 'tariffsMenu',
            url: '/tariffsMenu/:activeProfileTab',
            authRequired: true,
            params: {
                activeProfileTab: null,
                isTable: false,
                clearConfig: false,
                param: {}
            },
            views: {
                '@': {
                    component: 'tariffsMenu'
                },
                'tariffsProfile@tariffsMenu': {
                    component: 'tariffs'
                },
                'personalAccount@tariffsMenu': {
                    component: 'personalAccount',
                    bindings: {personalAccountData: 'personalAccountData', personalAccountList: 'personalAccountList', personalAccountView: 'personalAccountView'}
                },
                'invoiceTable@tariffsMenu': {
                    component: 'invoiceTable',
                    bindings: {invoicesData: 'invoicesData'}
                },
                'paymentOrdersTable@tariffsMenu': {
                    component: 'paymentOrdersTable'
                },
                'completedWorksTable@tariffsMenu': {
                    component: 'completedWorksTable'
                }
            },

            resolve: {
                personalAccountData: ['$stateParams', 'authSrv', function ($stateParams, authSrv) {
                    return authSrv.reloadOrgInfo().then(function (user) {
                        return user;
                    });
                }],
                personalAccountList: ['$stateParams', 'authSrv', 'profileSrv', function ($stateParams, authSrv, profileSrv) {
                    return profileSrv.loadAccountsList(authSrv.getAuth().orgId).then(function successCallback(response) {
                        if (!response.data.success) {
                            console.log(response);
                        }
                        return response.data.result;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }],
                personalAccountView: ['desktopSrv', function (desktopSrv) {
                    var cabType = desktopSrv.getCabType();
                    if (cabType === 'accountant') {
                        return 'app/components/profile/personalAccount/view/personalAccountAccountant.html'
                    } else {
                        return 'app/components/profile/personalAccount/view/personalAccountDefault.html'
                    }
                }]
            }
        },
        /**
         * Личный кабинет
         */
        lk = {
            name: 'lk',
            url: '/lk',
            authRequired: true,
            views: {
                '@': {
                    component: 'lk'
                }
            }
        },

        // Отчеты //
        reports = {
            url: '/reports',
            authRequired: true,
            secured: ["operator_admin", "operator_accountant"],
            params: {
                clearConfig: true
            },
            component: 'reports'
        },
        // Списки адресатов//
        mailingLists = {
            url: '/mailingLists',
            authRequired: true,
            permissionScope: 'workflow',
            secured: ["organizer_admin", "organizer_user"],
            params: {
                clearConfig: true
            },
            component: 'mailingLists'
        },
        // Отчеты раздела "Аналитика" //
        analyticReports = {
            url: '/analyticReports',
            authRequired: true,
            secured: ["organizer_admin", "organizer_user"],
            isSecuredFn: function (authSrv) {
                return authSrv.getUser().analyticAccess;
            },
            params: {
                clearConfig: true
            },
            component: 'analyticReports'
        },
        // Отчеты "Локо-Банка" //
        reportsLokoBank = {
            url: '/reportsLokoBank',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'reportsLokoBank'
        },
        // Выгрузки для ФАС
        fasArchive = {
            url: '/fasArchive',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: true
            },
            component: 'fasArchive'
        },
        //
        fasListArchive = {
            url: '/fasListArchive',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: true
            },
            component: 'fasListArchive'
        },
        /**
         * Новости
         */
        news = {
            url: '/showNews',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                "operator_support_ro", "operator_support_rw"],
            params: {
                clearConfig: false
            },
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/newsTable.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        newsCreate = {
            url: '/newsCreate',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'newsCreate'
        },
        newsView = {
            url: '/{id}',
            authRequired: true,
            params: {
                clearConfig: true,
                isViewOnly: true
            },
            views: {
                '@': {
                    component: 'newsCreate'
                },
                'news': {
                    template: '<span></span>',
                }
            }
        },
        homeNewsBlock = {
            url: '/homeNews/:id',
            params: {
                id: {squash: true, value: null},
                listModel: null,
                forceLoadAll: true
            },
            views: {
                '@': 'news'
            }
        },

        /**
         * Версии
         */
        versionInfo = {
            url: '/versionInfo',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: false
            },
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/versionTable.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        versionInfoCreate = {
            url: '/versionInfoCreate',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: true
            },
            component: 'versionInfoCreate'
        },
        versionInfoUpdate = {
            url: '/{id}',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'versionInfoCreate'
                },
                'versionInfo': {
                    template: '<span></span>',
                }
            }
        },

        /*
         * Рассылки
         */
        newsletters = {
            url: '/showNewsletters',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                "operator_support_ro", "operator_support_rw"],
            params: {
                clearConfig: true
            },
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/newsletters.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        newsletterCreate = {
            url: '/newsletterCreate',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'newsletterCreate'
        },
        newslettersView = {
            url: '/{id}',
            authRequired: true,
            params: {
                clearConfig: true,
                successSave: null
            },
            views: {
                '@': {
                    component: 'newsletterCreate',
                    bindings: {newsletterData: 'newsletterData'}
                },
                'newsletters': {
                    template: '<span></span>',
                }
            },
            resolve: {
                newsletterData: ['$stateParams', 'newsletterCreateSrv', function ($stateParams, newsletterCreateSrv) {
                    return newsletterCreateSrv.loadNewslettersItem($stateParams.id)
                }]
            }
        },
        /**
         * создание пользователей организации
         */
        createAgent = {
            url: '/createAgent',
            authRequired: true,
            params: {
                setActivePanel: 'agents',
                clearConfig: true
            },
            views: {
                'agentEditor': {
                    component: 'agentEditor'

                },
                'agentList@profile': {
                    template: '<span></span>'

                }
            }
        },
        /**
         * редактирование пользователей организации
         */
        openEditor = {
            url: '/agent/{id}',
            authRequired: true,
            params: {
                setActivePanel: 'agents',
                clearConfig: true,
                list_count: null
            },
            views: {
                'agentEditor': {
                    component: 'agentEditor'

                },
                'agentList@profile': {
                    template: '<span></span>'

                }
            }
        },
        /**
         * создание заявления на аккредитацию
         */
        newAccreditation = {
            url: '/newAccreditation',
            authRequired: true,
            params: {
                setActivePanel: 'accreditation',
                clearConfig: true
            },
            views: {
                'accreditationEditor@profile': {
                    component: 'accreditationEditor'
                },
                'accreditationList@profile': {
                    template: '<span></span>'
                },
                'requisites@profile': {
                    component: 'accreditationEditor'
                }
            }
        },
        /**
         * создание заявления на ускоренную аккредитацию
         */
        accelAccEdit = {
            url: '/accelAccEdit',
            authRequired: true,
            params: {
                clearConfig: true
            },
            bindings: {
                tariffsData: 'tariffsData'
            },
            component: 'accelerationAccreditation',
            resolve: {
                tariffsData: ['$stateParams', 'tariffsSrv', function ($stateParams, tariffsSrv) {
                    return tariffsSrv.getAccredTariffs().then(function successCallback(response) {
                        if (response.data.success) {
                            return response;
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    })
                }]
            }
        },
        /**
         * открытие заявления на аккредитацию
         */
        openAccreditation = {
            url: '/accreditation/{id}',
            authRequired: true,
            secured: ["operator_admin","operator_accountant","operator_inspector","operator_support_ro","operator_support_rw",
                "operator_manager","participant_admin"],
            params: {params: null, clearConfig: true},
            component: 'accreditationEditor'
        },
        /**
         * создание филиала организации
         */
        createFiliation = {
            url: '/createFiliation',
            authRequired: true,
            params: {
                setActivePanel: 'filiation',
                clearConfig: true
            },
            views: {
                'filiationEditor@profile': {
                    component: 'filiationEditor'

                },
                'filiationList@profile': {
                    template: '<span></span>'

                }
            }
        },
        /**
         * редактирование филиала организации
         */
        openFiliation = {
            url: '/filiation/{id}',
            authRequired: true,
            params: {
                setActivePanel: 'filiation',
                isViewOnly: false,
                clearConfig: true
            },
            views: {
                'filiationEditor@profile': {
                    component: 'filiationEditor'

                },
                'filiationList@profile': {
                    template: '<span></span>'

                }
            }
        },
        /**
         * Форма регистрации
         */
        registration = {
            url: '/registration',
            params: {
                clearConfig: true
            },
            component: 'registration'
        },
        /**
         * Форма регистрации Заказчика
         */
        customerRegistrationCreate = {
            url: '/customerRegistration',
            params: {
                clearConfig: true
            },
            component: 'customerRegistration'
        },

        customerRegistrationTable = {
            url: '/customerRegistrationTable',
            authRequired: true,
            secured: ["operator_admin", "operator_support_ro"],
            params: {
                notApproved: true,
                clearConfig: false,
                filterType: null
            },
            templateUrl: 'app/controllers/tables/viewsTable/customerRegistrationTable.html',
            controller: 'tablesCtrl'
        },

        /**
         * открытие заявления на регистрацию заказчика
         */
        customerRegistrationOpen = {
            url: '/customerRegistrationOpen/{id}',
            authRequired: true,
            secured: ["operator_admin", "operator_support_ro"],
            params: {params: null, clearConfig: true},
            component: 'customerRegistrationOpen',
            bindings: {
                customerRegData: 'customerRegData'
            },
            resolve: {
                customerRegData: ['$stateParams', 'customerRegSrv', function ($stateParams, customerRegSrv) {
                    return customerRegSrv.getRegistrationCustomerById($stateParams.id).then(function successCallback(response) {
                        if (response.data.success) {
                            return response;
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    })
                }]
            }
        },

        /**
         * Таблица Участники
         */
        participant = {
            url: '/participants',
            authRequired: true,
            params: {
                clearConfig: false,
                isAccreditationDelayed: null
            },
            secured: ["operator_admin","operator_accountant","operator_inspector","operator_support_ro","operator_support_rw"],
            templateUrl: 'app/controllers/tables/viewsTable/participants.html',
            controller: 'tablesCtrl'
        },
        accreditation = {
            url: '/accreditation',
            authRequired: true,
            secured: ["operator_admin","operator_accountant","operator_inspector","operator_support_ro",
                "operator_support_rw","participant_admin"],
            params: {notApproved: true, clearConfig: false},
            templateUrl: 'app/controllers/tables/viewsTable/accreditation.html',
            controller: 'tablesCtrl'
        },
        /**
         * Участник форма просмотра
         */
        participantView = {
            url: '/participantView/{id}',
            authRequired: true,
            params: {
                clearConfig: true,
                isInvoiceParticipantView: true,
                activeProfileTab: null,
                params: {}
            },
            views: {
                '@': {
                    component: 'participant',
                    bindings: {orgData: 'orgById', hasFiliations: 'hasFiliations'}
                },
                'participantRequisites@participantView': {
                    component: 'participantRequisites'

                },
                'participantAgents@participantView': {
                    component: 'participantAgents'
                },
                'participantAccreditation@participantView': {
                    component: 'participantAccreditation'
                },
                'participantPersonalAccount@participantView': {
                    component: 'personalAccount',
                    bindings: {personalAccountData: 'personalAccountData',personalAccountList: 'personalAccountList', personalAccountView: 'personalAccountView'}
                },
                'tariffView@participantView': {
                    component: 'tariffs'
                },
                'invoiceTable@participantView': {
                    component: 'invoiceTable'
                },
                'paymentOrdersTable@participantView': {
                    component: 'paymentOrdersTable'
                },
                'participationInPurchasesTable@participantView': {
                    component: 'participationInPurchases'
                },
                'filiation@participantView': {
                    component: 'filiationList'
                },
                'bgAgents@participantView': {
                    component: 'bgAgents'
                },
                'completedWorks@participantView': {
                    component: 'completedWorksTable'
                }
            },

            resolve: {
                orgById: ['$stateParams', 'participantSrv', function ($stateParams, participantSrv) {
                    return participantSrv.getOrgById($stateParams.id)
                }],
                personalAccountData: ['$stateParams', 'participantSrv', function ($stateParams, participantSrv) {
                    return participantSrv.getOrgById($stateParams.id).then(function successCallback(response) {
                        if (!response.data.success) {
                            console.log(response);
                        }
                        return response.data.result;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }],
                personalAccountList: ['$stateParams','profileSrv', function ($stateParams, profileSrv) {
                    return profileSrv.loadAccountsList($stateParams.id).then(function successCallback(response) {
                        if (!response.data.success) {
                            console.log(response);
                        }
                        return response.data.result;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }],
                personalAccountView: [function () {
                    return 'app/components/profile/personalAccount/view/personalAccountDefault.html'
                }],
                hasFiliations: ['$stateParams', 'profileSrv', ($stateParams, profileSrv) => {
                    return profileSrv.loadFiliationsByParticipant($stateParams.id);
                }],

            }
        },
        /**
         * Просмототр представителей организатора в адмике
         */
        participantAgentView = {
            url: '/participantAgentView/{id}',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'participantAgentView'
        },
        /**
         * Реестр УЦ
         */
        uc = {
            url: '/uc',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant", "operator_support_ro", "operator_support_rw"],
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/uc.html',
            controller: 'tablesCtrl'
        },
        ucView = {
            url: '/{id}',
            authRequired: true,
            params: {
                clearConfig: true
            },
            resolve: {
                getUCData: ['$stateParams', 'ucSrv', function ($stateParams, ucSrv) {
                    return ucSrv.getUCById($stateParams.id)
                }]
            },
            views: {
                '@': {
                    templateUrl: 'app/controllers/uc/uc-base.html',
                    controller: 'ucController'
                },
                'infoAUC@uc.view': {
                    templateUrl: 'app/controllers/uc/uc-infoAUC-edit.html'

                },
                'pak@uc.view': {
                    templateUrl: 'app/controllers/uc/uc-PAK-table.html'
                }
            }
        },
        getds = {
            url: '/getds',
            startMth: 'getds',
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/getds.html',
            controller: 'tablesCtrl'
        },
        /**
         * Таблица Заказчики
         */
        customer = {
            url: '/customer',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant", "operator_support_ro",
                "operator_support_rw"],//для примера
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/customer.html',
            controller: 'tablesCtrl'
        },
        /**
         * Таблица Банковские гарантии
         */
        bgParticipants = {
                name: 'bgParticipants',
                url: '/bgParticipants',
                authRequired: true,
                secured: ["operator_admin", "operator_support_ro"],
                params: {
                    activeBgParticipantsTab: null,
                    clearConfig: false
                },
                views: {
                    '@': {
                        component: 'bgParticipants'
                    }
                }
            },
        /**
         * Таблица Организаторов
         */
        organizators = {
            url: '/organizators',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                "operator_support_ro", "operator_support_rw"],//для примера
            startMth: 'organizatorLoad',
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/organizators.html',
            controller: 'tablesCtrl'
        },
        /**
         * Создание Организатора
         */
        createOrganizator = {
            url: '/newOrganizator',
            params: {
                clearConfig: true
            },
            authRequired: true,
            views: {
                '@': {
                    component: 'organizator'
                },
                'reqOrganizator@newOrganizator': {
                    component: 'reqOrganizator'
                },
                'customersOrg@newOrganizator': {
                    component: 'customersOrg'
                },
                'agentOrg@newOrganizator': {
                    component: 'agentListOrganizer'
                }
            }
        },
        /**
         * Редактирование Организатора
         */
        openOrganizator = {
            url: '/organizator/{id}',
            authRequired: true,
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'organizator',
                    bindings: {orgData: 'orgById'}

                },
                'reqOrganizator@openOrganizator': {
                    component: 'reqOrganizator'
                },
                'customersOrg@openOrganizator': {
                    component: 'customersOrg'
                },
                'agentOrg@openOrganizator': {
                    component: 'agentListOrganizer'
                }
            },
            resolve: {
                orgById: ['$stateParams', 'organizatorSrv', function ($stateParams, organizatorSrv) {
                    return organizatorSrv.getOperatorById($stateParams.id)
                }]
            }
        },
        /**
         * Создание представителя Организатора
         */
        createAgentOrg = {
            url: '/createOrganizatorAgent',
            authRequired: true,
            params: {
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'agentEditorOrganizer'
                }
            }
        },
        /**
         * Редактирование представителя Организатора
         */
        openEditorAgentOrg = {
            url: '/organizatorAgent/{id}',
            authRequired: true,
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'agentEditorOrganizer'
                }
            },
            resolve: {
                agentData: ['$stateParams', 'profileSrv', '$state', function ($stateParams, profileSrv) {
                    return profileSrv.getAgentById($stateParams.id);
                }]
            }
        },
        restorePassword = {
            url: '/restorePassword',
            templateUrl: 'app/controllers/restorePassword/restorePassword.html',
            params: {
                clearConfig: true
            },
            controller: 'restorePasswordCtrl'
        },
        changePassword = {
            url: '/changePassword/{id}',
            params: {
                clearConfig: true
            },
            views: {
                '@': {
                    templateUrl: 'app/controllers/restorePassword/changePassword.html',
                    controller: 'restorePasswordCtrl'
                }
            }
        },
        newCustomer = {
            url: '/newCustomer',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'customerRequisites'
        },
        customerRequisites = {
            url: '/customer/{id}',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'customerRequisites'
        },
        /**
         * Участник форма просмотра
         */
        customerView = {
            url: '/customerView/{id}',
            authRequired: true,
            params: {
                clearConfig: true,
                // activeProfileTab: null,
                params: {}
            },
            views: {
                '@': {
                    component: 'customer',
                    bindings: {customerData: 'customerById'}
                },
                'customerRequisites@customerView': {
                    component: 'customerRequisites'

                },
                'customerEisIntegration@customerView': {
                    component: 'customerEisIntegration',
                    bindings: {customerData: 'customerById'}
                },
                'customerBranches@customerView': {
                    component: 'customerBranches',
                    bindings: {customerData: 'customerById'}
                }
            },
            resolve: {
                customerById: ['$stateParams', 'customerSrv', function ($stateParams, customerSrv) {
                    return customerSrv.getCustomerById($stateParams.id).then(function (response) {
                        if (response.data.success) {
                            customerSrv.setCustomerInfo(response.data.result);
                        }
                        return response;
                    })
                }]
            }
        },
        createMethod = {
            url: '/createMethod/{customerId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: {}
            },
            views: {
                  '@': {
                      component: 'methodEditor',
                      bindings: {methodData: 'methodById', customerData: 'selectedCustomer'}
                  }
            },
            resolve: {
                methodById: ['$stateParams', 'customerSrv', function ($stateParams) {
                    return $stateParams.customerId;
                }],
                selectedCustomer: ['customerSrv', function (customerSrv) {
                    return customerSrv.getCustomerInfo();
                }]
            }
        },
        openMethod = {
            url: '/openMethod/{customerId}/{id}',
            authRequired: true,
            params: {
                clearConfig: true,
                successSaveMsg: null
            },
            views: {
                '@': {
                    component: 'methodEditor',
                    bindings: {methodData: 'methodById', customerData: 'selectedCustomer'}
                }
            },
            resolve: {
                methodById: ['$stateParams', 'customerSrv', function ($stateParams, customerSrv) {
                    return customerSrv.getMethodById($stateParams.id);
                }],
                selectedCustomer: ['customerSrv', function (customerSrv) {
                    return customerSrv.getCustomerInfo();
                }]
            }
        },
        createCustomerBranch = {
            url: '/createCustomerBranch/{customerId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: {}
            },
            views: {
                  '@': {
                      component: 'branchEditor',
                      bindings: {branchData: 'customerBranchById', customerData: 'selectedCustomer'}
                  }
            },
            resolve: {
                customerBranchById: ['$stateParams', function ($stateParams) {
                    return $stateParams.customerId;
                }],
                selectedCustomer: ['customerSrv', function (customerSrv) {
                    return customerSrv.getCustomerInfo();
                }]
            }
        },
        openCustomerBranch = {
            url: '/openCustomerBranch/{customerId}/{id}',
            authRequired: true,
            params: {
                clearConfig: true,
                successSaveMsg: null
            },
            views: {
                '@': {
                    component: 'branchEditor',
                    bindings: {branchData: 'customerBranchById', customerData: 'selectedCustomer'}
                }
            },
            resolve: {
                customerBranchById: ['$stateParams', 'customerSrv', function ($stateParams, customerSrv) {
                    return customerSrv.getCustomerBranch($stateParams.id);
                }],
                selectedCustomer: ['customerSrv', function (customerSrv) {
                    return customerSrv.getCustomerInfo();
                }]
            }
        },
        allProcedures = {
            url: '/allProcedures',
            authRequired: false,
            params: {
                clearConfig: false
            },
            startMth: 'allProcedures',
            templateUrl: 'app/controllers/tables/viewsTable/allProcedures.html',
            controller: 'tablesCtrl'
        },
        myProcedures = {
            url: '/myProcedures',
            authRequired: true,
            params: {
                clearConfig: false
            },
            startMth: 'myProcedures',
            templateUrl: 'app/controllers/tables/viewsTable/myProcedures.html',
            controller: 'tablesCtrl'
        },
        specialAccount = {
            url: '/specialAccount',
            authRequired: true,
            stateSendLog: 'SPECIAL_ACCOUNT',
            params: {
                clearConfig: false
            },
            startMth: 'specialAccount',
            templateUrl: 'app/controllers/tables/viewsTable/specialAccount.html',
            controller: 'tablesCtrl'
        },
        /**
         * Запрос на разъяснение
         * Создание
         */
        createReqExplanation = {
            url: '/createReqExplanation/{procType}/{procId}',
            bindings: {
                createData: 'createData'
            },
            authRequired: true,
            params: {
                clearConfig: true
            },
            resolve: {
                createData: ['$stateParams', 'explanationSrv', '$state', function ($stateParams, explanationSrv, $state) {
                    return explanationSrv.createExplanation($stateParams.procType, $stateParams.procId, 'explanationRequests').then(function successCallback(response) {
                        if (!response.data.success) {
                            $state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId,
                                errorMessage: response.data.errorMessage
                            }, {reload: true});
                        } else {
                            return response;
                        }
                    }, function errorCallback(response) {
                        if (response.status === 403) {
                            return {error: 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа'}
                        }
                    })
                }]
            },
            component: 'reqExplanation'
        },
        /**
         * Запрос на разъяснение
         * Просмотр
         */
        openReqExplanation = {
            url: '/openReqExplanation/{procType}/{procId}/{explReqId}',
            bindings: {
                openData: 'openData'
            },
            params: {
                successSend: null,
                clearConfig: true
            },
            authRequired: true,
            resolve: {
                openData: ['$stateParams', 'explanationSrv', function ($stateParams, explanationSrv) {
                    return explanationSrv.getReqExplanation($stateParams.procType, $stateParams.procId, $stateParams.explReqId)
                }]
            },
            component: 'reqExplanation'
        },

        createAnswerExplanationFromReq = {
            url: '/createExplanations/{procType}/{procId}/{explReqId}',
            bindings: {
                createData: 'createData'
            },
            params: {
                clearConfig: true,
                isPersonal: false
            },
            authRequired: true,
            resolve: {
                createData: ['$stateParams', 'explanationSrv', function ($stateParams, explanationSrv) {
                    return explanationSrv.getReqExplanation($stateParams.procType, $stateParams.procId, $stateParams.explReqId)
                }]
            },
            component: 'answerExplanation'
        },
        createAnswerExplanationEmailFromReq = {
            url: '/createExplanationsEmail/{procType}/{procId}/{explReqId}',
            bindings: {
                createData: 'createData'
            },
            params: {
                clearConfig: true
            },
            authRequired: true,
            resolve: {
                createData: ['$stateParams', 'explanationSrv', function ($stateParams, explanationSrv) {
                    return explanationSrv.getReqExplanationEmail($stateParams.procType, $stateParams.procId, $stateParams.explReqId)
                }]
            },
            component: 'answerExplanationEmail'
        },

        openAnswerExplanation = {
            url: '/openExplanations/{procType}/{procId}/{explId}',
            bindings: {
                openData: 'openData'
            },
            authRequired: true,
            params: {
                successSend: null,
                clearConfig: true
            },
            resolve: {
                openData: ['$stateParams', 'explanationSrv', function ($stateParams, explanationSrv) {
                    return explanationSrv.getExplanation($stateParams.procType, $stateParams.procId, $stateParams.explId)
                }],
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then(function (response) {
                        if (response.data.success) {
                            return response.data.result;
                        }
                    })
                }]
            },
            component: 'answerExplanation'
        },
        createExplanation = {
            url: '/createExplanations/{procType}/{procId}/{explId}',
            bindings: {
                createData: 'createData'
            },
            params: {
                clearConfig: true,
                isPersonal: false
            },
            authRequired: true,
            resolve: {
                createData: ['$stateParams', 'explanationSrv', function ($stateParams, explanationSrv) {
                    return explanationSrv.getExplanation($stateParams.procType, $stateParams.procId, $stateParams.explId)
                }]
            },
            component: 'answerExplanation'
        },
        openExplanation = {
            url: '/openExplanations/{procType}/{procId}/{explId}',
            bindings: {
                openData: 'openData'
            },
            authRequired: true,
            params: {
                successSend: null,
                clearConfig: true
            },
            resolve: {
                openData: ['$stateParams', 'explanationSrv', function ($stateParams, explanationSrv) {
                    return explanationSrv.getExplanation($stateParams.procType, $stateParams.procId, $stateParams.explId)
                }],
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then(function (response) {
                        if (response.data.success) {
                            return response.data.result;
                        }
                    })
                }]
            },
            component: 'answerExplanation'
        },
        answerReadyExplanation = {
            url: '/readyExplanation/{procType}/{procId}/{explReqId}',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'answerReady'
        },
        /**
         * Запрос участнику
         * Создание
         */
        createReqExplanationOrg = {
            url: '/createReqExplanationOrg/{procType}/{procId}',
            bindings: {
                createData: 'createData',
                procedureInfo: 'procedureInfo'
            },
            authRequired: true,
            params: {
                clearConfig: true
            },
            resolve: {
                createData: ['$stateParams', 'explanationOrgSrv', '$state', function ($stateParams, explanationOrgSrv, $state) {
                    return explanationOrgSrv.createExplanation($stateParams.procType, $stateParams.procId, 'explanationOrgRequests').then(function successCallback(response) {
                        if (!response.data.success) {
                            $state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId,
                                errorMessage: response.data.errorMessage
                            }, {reload: true});
                        } else {
                            return response;
                        }
                    }, function errorCallback(response) {
                        if (response.status === 403) {
                            return {error: 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа'}
                        }
                    })
                }],
                procedureInfo: ['$stateParams', 'explanationOrgSrv', '$state', function ($stateParams, explanationOrgSrv, $state) {
                    return explanationOrgSrv.getProcedureInfo($stateParams.procType, $stateParams.procId).then(function successCallback(response) {
                        if (!response.data.success) {
                            $state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId,
                                errorMessage: response.data.errorMessage
                            }, {reload: true});
                        } else {
                            return response;
                        }
                    }, function errorCallback(response) {
                        if (response.status === 403) {
                            return {error: 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа'}
                        }
                    })
                }]
            },
            component: 'reqExplanationOrg'
        },
        createAnswerExplanationOrgFromReq = {
            url: '/createExplanationOrg/{procType}/{procId}/{explReqId}',
            bindings: {
                createData: 'createData',
                answerExplReqOrg: 'answerExplReqOrg',
                procedureInfo: 'procedureInfo'
            },
            params: {
                clearConfig: true,
                isPersonal: false
            },
            authRequired: true,
            resolve: {
                createData: ['$stateParams', 'explanationOrgSrv', function ($stateParams, explanationOrgSrv) {
                    return explanationOrgSrv.getReqExplanation($stateParams.procType, $stateParams.procId, $stateParams.explReqId)
                }],
                answerExplReqOrg: ['$stateParams', 'explanationOrgSrv', function ($stateParams, explanationOrgSrv) {
                    return explanationOrgSrv.getExplanationReqOrg($stateParams.procType, $stateParams.procId, $stateParams.explReqId)
                }],
                procedureInfo: ['$stateParams', 'explanationOrgSrv', '$state', function ($stateParams, explanationOrgSrv, $state) {
                    return explanationOrgSrv.getProcedureInfo($stateParams.procType, $stateParams.procId).then(function successCallback(response) {
                        if (!response.data.success) {
                            $state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId,
                                errorMessage: response.data.errorMessage
                            }, {reload: true});
                        } else {
                            return response;
                        }
                    }, function errorCallback(response) {
                        if (response.status === 403) {
                            return {error: 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа'}
                        }
                    })
                }]
            },
            component: 'answerExplanationOrg'
        },
        /**
         * Запрос участнику
         * Просмотр
         */
        openReqExplanationOrg = {
            url: '/openReqExplanationOrg/{procType}/{procId}/{explReqId}',
            bindings: {
                openData: 'openData',
                procedureInfo: 'procedureInfo'
            },
            params: {
                successSend: null,
                clearConfig: true
            },
            authRequired: true,
            directTransitionAuth: true,
            resolve: {
                openData: ['$stateParams', 'explanationOrgSrv', function ($stateParams, explanationOrgSrv) {
                    return explanationOrgSrv.getReqExplanation($stateParams.procType, $stateParams.procId, $stateParams.explReqId)
                }],
                procedureInfo: ['$stateParams', 'explanationOrgSrv', '$state', function ($stateParams, explanationOrgSrv, $state) {
                    return explanationOrgSrv.getProcedureInfo($stateParams.procType, $stateParams.procId).then(function successCallback(response) {
                        if (!response.data.success) {
                            $state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId,
                                errorMessage: response.data.errorMessage
                            }, {reload: true});
                        } else {
                            return response;
                        }
                    }, function errorCallback(response) {
                        if (response.status === 403) {
                            return {error: 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа'}
                        }
                    })
                }]
            },
            component: 'reqExplanationOrg'
        },
        openAnswerExplanationOrg = {
            url: '/openExplanationsOrg/{procType}/{procId}/{explId}',
            bindings: {
                openData: 'openData',
                procedureInfo: 'procedureInfo'
            },
            authRequired: true,
            secured: ["organizer_admin", "organizer_user", "organizer_cbz", "participant_admin", "user", "operator_admin", "operator_manager"],
            params: {
                successSend: null,
                clearConfig: true
            },
            resolve: {
                openData: ['$stateParams', 'explanationOrgSrv', function ($stateParams, explanationOrgSrv) {
                    return explanationOrgSrv.getExplanation($stateParams.procType, $stateParams.procId, $stateParams.explId)
                }],
                procedureInfo: ['$stateParams', 'explanationOrgSrv', '$state', function ($stateParams, explanationOrgSrv, $state) {
                    return explanationOrgSrv.getProcedureInfo($stateParams.procType, $stateParams.procId).then(function successCallback(response) {
                        if (!response.data.success) {
                            $state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId,
                                errorMessage: response.data.errorMessage
                            }, {reload: true});
                        } else {
                            return response;
                        }
                    }, function errorCallback(response) {
                        if (response.status === 403) {
                            return {error: 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа'}
                        }
                    })
                }]
            },
            component: 'answerExplanationOrg'
        },
        /**
         * Открыть извещение для изменения
         * {id} - id процедуры
         */
        openNotice = {
            url: '/notice/{type}/{id}',
            name: 'notice',
            authRequired: true,
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'notice',
                    bindings: {procData: 'procByTypeAndId'}
                },
                'procedure@notice': {
                    component: 'procedure'
                },
                'documentation@notice': {
                    component: 'documentation'
                },
                'lot@notice': {
                    component: 'lot'
                },
                'lots@notice': {
                    component: 'lots'
                },
                'nomenclature@notice': {
                    component: 'nomenclature'
                },
                'extendFields@notice': {
                    component: 'extendNoticeField'
                }
            },
            resolve: {
                procByTypeAndId: ['$stateParams', 'noticeSrv', function ($stateParams, noticeSrv) {
                    return noticeSrv.getProcedure($stateParams.type, $stateParams.id);
                }]
            }
        },
        /**
         * Создать новое извещение
         */
        createNotice = {
            url: '/createNotice/{type}',
            name: 'createNotice',
            params: {
                clearConfig: true
            },
            authRequired: true,
            views: {
                '@': {
                    component: 'notice'
                },
                'procedure@createNotice': {
                    component: 'procedure'
                },
                'documentation@createNotice': {
                    component: 'documentation'
                },
                'lot@createNotice': {
                    component: 'lot'
                },
                'lots@createNotice': {
                    component: 'lots'
                },
                'nomenclature@createNotice': {
                    component: 'nomenclature'
                },
                'extendFields@createNotice': {
                    component: 'extendNoticeField'
                }
            }
        },
        /**
         * Создать новое извещение для коммерческих торгов
         */
        createCommercialNotice = {
            url: '/createCommercialNotice/{type}',
            name: 'createCommercialNotice',
            params: {
                clearConfig: true
            },
            authRequired: true,
            views: {
                '@': {
                    component: 'notice'
                },
                'procedure@createCommercialNotice': {
                    component: 'procedure'
                },
                'documentation@createCommercialNotice': {
                    component: 'documentation'
                },
                'lot@createCommercialNotice': {
                    component: 'lot'
                },
                'lots@createCommercialNotice': {
                    component: 'lots'
                },
                'nomenclature@createCommercialNotice': {
                    component: 'nomenclature'
                },
                'extendFields@createCommercialNotice': {
                    component: 'extendNoticeField'
                }
            }
        },
        /**
         * Создать новое извещение для 44 фз
         */
        createNotice44 = {
            url: '/createNotice44/{type}',
            name: 'createNotice44',
            params: {
                clearConfig: true
            },
            authRequired: true,
            views: {
                '@': {
                    component: 'notice'
                },
                'procedure@createNotice44': {
                    component: 'procedure'
                },
                'documentation@createNotice44': {
                    component: 'documentation'
                },
                'lot@createNotice44': {
                    component: 'lot'
                },
                'lots@createNotice44': {
                    component: 'lots'
                },
                'nomenclature@createNotice44': {
                    component: 'nomenclature'
                },
                'extendFields@createNotice44': {
                    component: 'extendNoticeField'
                }
            }
        },
        /**
         * Создать новое предзаполненное извещение
         */
        createInitedNotice = {
            url: '/createInitedNotice/{type}',
            name: 'createInitedNotice',
            params: {
                clearConfig: true,
                dataProc: null
            },
            authRequired: true,
            views: {
                '@': {
                    component: 'notice'
                },
                'procedure@createInitedNotice': {
                    component: 'procedure'
                },
                'documentation@createInitedNotice': {
                    component: 'documentation'
                },
                'lot@createInitedNotice': {
                    component: 'lot'
                },
                'lots@createInitedNotice': {
                    component: 'lots'
                },
                'nomenclature@createInitedNotice': {
                    component: 'nomenclature'
                },
                'extendFields@createInitedNotice': {
                    component: 'extendNoticeField'
                }
            }
        },
        openProcedure = {
            url: '/openProcedure/{procType}/{procId}',
            bindings: {
                procedureData: 'procedureData'
            },
            params: {
                clearConfig: false,
                toAuction: false,
                errorMessage: null,
                setActivePanel: 'lots',
                openFactoring: null
            },
            component: 'proceduresView',
            resolve: {
                procedureData: ['$stateParams', '$state', 'proceduresSrv', function ($stateParams, $state, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then(function successCallback(response) {
                        if (!response.data.success) {
                            console.log(response);
                            $state.go('home');
                        }
                        return response;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }]
            }
        },
        openProcedureEIS = {
            url: '/procedureEIS/{id}/{eisType}/{canCreateNotice}',
            authRequired: true,
            directTransitionAuth: true,
            bindings: {
                procedureData: 'procedureData',
                personalAccountData: 'personalAccountData'
            },
            params: {
                clearConfig: false,
                canCreateNotice: 'false',
                eisType: {
                    value: null,
                    squash: true
                }
            },
            component: 'proceduresEISView',
            resolve: {
                personalAccountData: ['$stateParams', 'authSrv', function ($stateParams, authSrv) {
                    return authSrv.reloadOrgInfo();
                }],

                procedureData: ['$stateParams', 'proceduresSrv', 'desktopSrv', 'personalAccountData', 'authSrv', function ($stateParams, proceduresSrv, desktopSrv, user, authSrv) {

                    if (authSrv.canActiveOtherPurchase(desktopSrv.getCabType(), user)) {
                        return proceduresSrv.getProcEISByNumber($stateParams.id)
                            .then(function (response) {
                                if(response.data.success && response.data.items && response.data.items.length==0 && $stateParams.eisType){
                                    authSrv.setDisableBeforeUnload(true);
                                    if($stateParams.eisType == '44')
                                        window.location.href = `https://zakupki.gov.ru/epz/order/notice/view/common-info.html?regNumber=${$stateParams.id}`;
                                    else
                                        window.location.href = `https://zakupki.gov.ru/epz/order/extendedsearch/results.html?searchString=${$stateParams.id}`;
                                    
                                    return false;
                                }
                                else{
                                    return response;
                                }
                            })
                    } else {
                        return false;
                    }
                }]

            }
        },
        openPlanEIS = {
            url: '/planEIS',
            authRequired: true,
            bindings: {
                procedureData: 'procedureData'
            },
            params: {
                clearConfig: false,
                dataProc: null
            },
            component: 'proceduresEISView',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams) {
                    return $stateParams.dataProc;
                }]
            }
        },
        procedures = {
            url: '/procedures',
            authRequired: true,
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/procedures.html',
            controller: 'tablesCtrl'
        },
        jointProcedures = {
                url: '/jointProcedures',
                authRequired: true,
                params: {
                    clearConfig: false
                },
                templateUrl: 'app/controllers/tables/viewsTable/jointProcedures.html',
                controller: 'tablesCtrl'
        },
        explanationReqList = {
            url: '/explanationReqs',
            startMth: 'explanationReqsLoad',
            authRequired: true,
            secured: ["organizer_admin", "organizer_user"],
            permissionScope: 'workflow',
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/explanationReqList.html',
            controller: 'tablesCtrl'
        },
        explanationList = {
            url: '/explanations',
            startMth: 'explanationLoad',
            authRequired: true,
            secured: ["organizer_admin", "organizer_user"],
            permissionScope: 'workflow',
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/explanationList.html',
            controller: 'tablesCtrl'
        },
        clarification = {
            url: '/clarification',
            authRequired: true,
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/clarification.html',
            controller: 'tablesCtrl'
        },
        notices = {
            url: '/notices',
            authRequired: true,
            secured: ["organizer_admin", "organizer_user"],
            permissionScope: 'workflow',
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/notices.html',
            controller: 'tablesCtrl'
        },
        publicProcedures = {
            url: '/publicProcedures',
            authRequired: false,
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/publicProcedures.html',
            controller: 'tablesCtrl'
        },
        publicProcedures44 = {
            url: '/publicProcedures44',
            authRequired: false,
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/publicProcedures44.html',
            controller: 'tablesCtrl'
        },
        publicProceduresSOC = {
                url: '/publicProceduresSOC',
                authRequired: false,
                params: {
                    clearConfig: false
                },
                templateUrl: 'app/controllers/tables/viewsTable/publicProceduresSOC.html',
                controller: 'tablesCtrl'
        },
        commercialProcedures = {
            url: '/commercialProcedures',
            authRequired: false,
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/commercialProcedures.html',
            controller: 'tablesCtrl'
        },
        otherProcedures = {
            url: '/otherProcedures',
            authRequired: true,
            directTransitionAuth: true,
            stateSendLog: 'OTHER_PROCEDURES',
            params: {
                clearConfig: false,
                filterParams: null,
                filterType: null,
                searchParams: null
            },
            templateUrl: 'app/controllers/tables/viewsTable/otherProcedures.html',
            controller: 'tablesCtrl'
        },
        purchasePlan = {
            url: '/purchasePlan',
            authRequired: true,
            params: {
                clearConfig: false,
                allCustomers: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/purchasePlan.html',
            controller: 'tablesCtrl'
        },
        purchasePlanAdmin = {
            url: '/purchasePlanAdmin',
            authRequired: true,
            startMth: 'purchasePlanLoad',
            params: {
                clearConfig: false,
                headDzo223Customers: true,
            },
            templateUrl: 'app/controllers/tables/viewsTable/purchasePlan.html',
            controller: 'tablesCtrl'
        },
        purchasePlanUpdate = {
            url: '/purchasePlanUpdate',
            authRequired: true,
            params: {
                clearConfig: false
            },
            component: 'purchasePlanUpdate'
        },
        purchasePlanUpdateAdmin = {
            url: '/purchasePlanUpdateAdmin',
            authRequired: true,
            params: {
                clearConfig: false,
                allCustomers: true
            },
            component: 'purchasePlanUpdate'
        },
        customEisPacket = {
            url: '/customEisPacket',
            authRequired: true,
            params: {
                clearConfig: false
            },
            component: 'customEisPacket'
        },
        bank ={
            url: '/bank',
            component: 'bank',
            stateSendLog: 'BANK'
        },
        bg ={
            url: '/bg',
            component: 'bank',
            authRequired: true
        },
        raiffeisen = {
            url: '/raiffeisen',
            component: 'bankRaiffeisen',
            stateSendLog: 'RAIFFEISEN'
        },
        bankVTBLanding = {
            url: '/vtb',
            component: 'bankVTBLanding',
            stateSendLog: 'VTB'
        },
        guarantees = {
            url: '/guarantees',
            component: 'bankGuarantees',
            stateSendLog: 'BANK_GUARANTEE_LINK'
        },
        lokobg = {
            url: '/lokobg',
            component: 'bankLoko',
            stateSendLog: 'LOKOBG'
        },
        vtbbg = {
            url: '/vtbbg',
            // TODO: компонент для vtb
            component: 'bankLoko',
            stateSendLog: 'LOKOBG'
        },
        bankGuarantees = {
            url: '/bankGuarantees',
            authRequired: true,
            directTransitionAuth: true,
            stateSendLog: 'BANK_GUARANTEE',
            params: {
                checkLimit: null,
                clearConfig: false,
                purchasData: null,//данные закупки для создания анкеты
                purchasDataType: null,//тип откуда взяли данные закупки
                // UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
                // EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
                saveParam:null, // при успешной отправке вывести сообщение с параметрами анкеты
                participantInfo: null, // Данные о участнике (при закрытии формы проставлять выбраного участника)
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            templateUrl: 'app/controllers/tables/viewsTable/bankGuarantees.html',
            controller: 'tablesCtrl'
        },
        bankGuaranteesAdmin = {
                url: '/bankGuarantees/{participantId}',
                authRequired: true,
                directTransitionAuth: true,
                stateSendLog: 'BANK_GUARANTEE',
                params: {
                    checkLimit: null,
                    clearConfig: false,
                    purchasData: null,//данные закупки для создания анкеты
                    purchasDataType: null,//тип откуда взяли данные закупки
                    // UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
                    // EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
                    saveParam:null // при успешной отправке вывести сообщение с параметрами анкеты
                },
                templateUrl: 'app/controllers/tables/viewsTable/bankGuarantees.html',
                controller: 'tablesCtrl',
                resolve: {
                	participantData: ['$stateParams', 'participantSrv', function ($stateParams, participantSrv) {
                        return participantSrv.getOrgById($stateParams.participantId).then(function successCallback(response) {
                            if (response.data.success) {
                            	participantSrv.setOrgInfo(response.data.result);
                            }
                        });
                    }]
                }
            },

        bankGuaranteeAppCreate = {
            url: '/createBankGuaranteeApp',
            authRequired: true,
            stateSendLog: 'CREATE_BANK_GUARANTEE_APP',
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            params: {
                clearConfig: false,
                purchasData:null,//данные закупки для создания анкеты
                purchasDataType:null//тип данных закупки
            },
            views: {
                '@': {
                    component: 'bankGuaranteeApp'
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppCreate': {
                    component: 'bankGuaranteeFormCreator'
                }
            }
        },
        bankGuaranteeAppOpen = {
            url: '/bankGuaranteeApp/{id}/{participantId}',
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
            },
            views: {
                '@': {
                    component: 'bankGuaranteeApp',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppOpen': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppOpen': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getBankGuarantee($stateParams.id);
                }]
            }
        },
        bankGuaranteePrimaryAppCreate = {
            url: '/createBankGuaranteePrimaryApp',
            authRequired: true,
            stateSendLog: 'CREATE_BANK_GUARANTEE_PRIMARY_APP',
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            params: {
                clearConfig: false,
                purchasData:null,//данные закупки для создания анкеты
                purchasDataType:null//тип данных закупки
            },
            component: 'bankGuaranteeApp'
        },
        bankGuaranteePrimaryAppOpen = {
            url: '/bankGuaranteePrimaryApp/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
            },
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            component: 'bankGuaranteeApp',
            bindings: {
                bankGuaranteeAppData: 'bankGuaranteeAppData'
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getBankGuarantee($stateParams.id);
                }]
            }
        },
        bankGuaranteeAppCopy = {
            url: '/bankGuaranteeAppCopy/{id}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null
            },
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeApp',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppCopy': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppCopy': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getBankGuarantee($stateParams.id, true);
                }]
            }
        },
        bankGuaranteeAppAgentCreate = {
            url: '/createBankGuaranteeAppAgent/{participantId}',
            authRequired: true,
            stateSendLog: 'CREATE_BANK_GUARANTEE_APP',
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            params: {
                clearConfig: false,
                purchasData:null,//данные закупки для создания анкеты
                purchasDataType:null, //тип данных закупки
                participantSelected: true // Признак, что открыли анкету с выбранным участником
            },
            views: {
                '@': {
                    component: 'bankGuaranteeApp',
                    bindings: {
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppAgentCreate': {
                    component: 'bankGuaranteeFormCreator'
                }
            },
            resolve: {
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteeAppAgentOpen = {
            url: '/bankGuaranteeAppAgent/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeApp',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData',
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppAgentOpen': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppAgentOpen': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getBankGuarantee($stateParams.id);
                }],
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteePrimaryAppAgentCreate = {
            url: '/createBankGuaranteePrimaryAppAgent/{participantId}',
            authRequired: true,
            stateSendLog: 'CREATE_BANK_GUARANTEE_PRIMARY_APP',
            params: {
                clearConfig: false,
                purchasData:null,//данные закупки для создания анкеты
                purchasDataType:null, //тип данных закупки
                participantSelected: true // Признак, что открыли анкету с выбранным участником
            },
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            component: 'bankGuaranteeApp',
            bindings: {
                participantInfoData: 'participantInfoData'
            },
            resolve: {
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteePrimaryAppAgentOpen = {
            url: '/bankGuaranteePrimaryAppAgent/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            component: 'bankGuaranteeApp',
            bindings: {
                bankGuaranteeAppData: 'bankGuaranteeAppData',
                participantInfoData: 'participantInfoData'
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getBankGuarantee($stateParams.id);
                }],
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteeAppAgentCopy = {
            url: '/bankGuaranteeAppAgentCopy/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            needConfirm:true,
            needConfirmState:'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeApp',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData',
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppAgentCopy': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppAgentCopy': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getBankGuarantee($stateParams.id, true);
                }],
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        lokobank  = {
            url: '/lokobank',
            authRequired: true,
            startMth: 'bankGuaranteesLokoLoad',
            params: {
                // checkLimit: null,
                clearConfig: false,
                purchasData: null,//данные закупки для создания анкеты
                purchasDataType: null,//тип откуда взяли данные закупки
                // UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
                // EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
                saveParam: null, // при успешной отправке вывести сообщение с параметрами анкеты
                participantInfo: null, // Данные о участнике (при закрытии формы проставлять выбраного участника)
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            templateUrl: 'app/controllers/tables/viewsTable/bankGuaranteesLoko.html',
            controller: 'tablesCtrl',
            stateSendLog: 'LOKO_BANK'
        },
        bankvtb  = {
            url: '/bankvtb',
            authRequired: true,
            startMth: 'bankGuaranteesVTBLoad',
            params: {
                // checkLimit: null,
                clearConfig: false,
                purchasData: null,//данные закупки для создания анкеты
                purchasDataType: null,//тип откуда взяли данные закупки
                // UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
                // EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
                saveParam: null, // при успешной отправке вывести сообщение с параметрами анкеты
                participantInfo: null, // Данные о участнике (при закрытии формы проставлять выбраного участника)
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            templateUrl: 'app/controllers/tables/viewsTable/bankGuaranteesVTB.html',
            controller: 'tablesCtrl',
            stateSendLog: 'VTB_BANK'
        },
        bankGuaranteeAppLokoCreate = {
            url: '/createBankGuaranteeAppLoko',
            authRequired: true,
            stateSendLog: 'CREATE_BANK_GUARANTEE_APP',
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            params: {
                clearConfig: false,
                purchasData: null,//данные закупки для создания анкеты
                purchasDataType: null//тип данных закупки
            },
            views: {
                '@': {
                    component: 'bankGuaranteeAppLoko'
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppLokoCreate': {
                    component: 'bankGuaranteeFormCreator'
                }
            }
        },
        bankGuaranteeAppLokoOpen = {
            url: '/bankGuaranteeAppLoko/{id}',
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
            },
            views: {
                '@': {
                    component: 'bankGuaranteeAppLoko',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppLokoOpen': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppLokoOpen': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeLokoSrv', function ($stateParams, bankGuaranteeLokoSrv) {
                    return bankGuaranteeLokoSrv.getBankGuarantee($stateParams.id);
                }]
            }
        },
        bankGuaranteeAppLokoCopy = {
            url: '/bankGuaranteeAppLokoCopy/{id}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null
            },
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeAppLoko',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppLokoCopy': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppLokoCopy': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeLokoSrv', function ($stateParams, bankGuaranteeLokoSrv) {
                    return bankGuaranteeLokoSrv.getBankGuarantee($stateParams.id, true);
                }]
            }
        },
        bankGuaranteeAppLokoAgentCreate = {
            url: '/createBankGuaranteeAppLokoAgent/{participantId}',
            authRequired: true,
            // stateSendLog: 'CREATE_BANK_GUARANTEE_APP',
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            params: {
                clearConfig: false,
                purchasData: null, //данные закупки для создания анкеты
                purchasDataType: null, //тип данных закупки
                participantSelected: true // Признак, что открыли анкету с выбранным участником
            },
            views: {
                '@': {
                    component: 'bankGuaranteeAppLoko',
                    bindings: {
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppLokoAgentCreate': {
                    component: 'bankGuaranteeFormCreator'
                }
            },
            resolve: {
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteeAppLokoAgentOpen = {
            url: '/bankGuaranteeAppLokoAgent/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeAppLoko',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData',
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppLokoAgentOpen': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppLokoAgentOpen': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeLokoSrv', function ($stateParams, bankGuaranteeLokoSrv) {
                    return bankGuaranteeLokoSrv.getBankGuarantee($stateParams.id);
                }],
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteeAppLokoAgentCopy = {
            url: '/bankGuaranteeAppLokoAgentCopy/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeAppLoko',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData',
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppLokoAgentCopy': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppLokoAgentCopy': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeLokoSrv', function ($stateParams, bankGuaranteeLokoSrv) {
                    return bankGuaranteeLokoSrv.getBankGuarantee($stateParams.id, true);
                }],
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteeLokoChat = {
            url: '/bankGuaranteeLokoChat/{id}',
            authRequired: true,
            params: {message: null},
            component: 'bankGuaranteeLokoChat',
            bindings: {
                bankGuaranteeChatData: 'bankGuaranteeChatData'
            },
            resolve: {
                bankGuaranteeChatData: ['$stateParams', 'bankGuaranteeLokoSrv', function ($stateParams, bankGuaranteeLokoSrv) {
                    return bankGuaranteeLokoSrv.getOrderProcess($stateParams.id);
                }]
            }
        },
        bankGuaranteeChat = {
            url: '/bankGuaranteeChat/{id}',
            authRequired: true,
            params: {
                message: null,
                participantInfo: null, // Данные о участнике (при закрытии формы проставлять выбраного участника)
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            component: 'bankGuaranteeChat',
            bindings: {
                bankGuaranteeChatData: 'bankGuaranteeChatData'
            },
            resolve: {
                bankGuaranteeChatData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getRequests($stateParams.id);
                }],
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getBankGuarantee($stateParams.id);
                }]
            }
        },
        rfGuarantees = {
                url: '/raiffeisenGuarantees',
                authRequired: true,
                directTransitionAuth: true,
                stateSendLog: 'RAIFFEISENGUARANTEES',
                params: {
                    clearConfig: false,
                    saveParam: null,
                    participant: null
                },
                templateUrl: 'app/controllers/tables/viewsTable/rfGuarantees.html',
                controller: 'tablesCtrl'
        },
        rfGuaranteeAppCreate = {
                url: '/raiffeisenGuarantee',
                authRequired: true,
                directTransitionAuth: true,
                params: {
                    clearConfig: false,
                    participant: null

                },
                component: 'rfGuaranteeApp'
        },
        rfGuaranteeAppOpen = {
                url: '/raiffeisenGuarantee/{id}',
                authRequired: true,
                bindings: {
                    rfGuaranteeAppData: 'rfGuaranteeAppData'
                },
                params: {
                    clearConfig: false,
                    params: null,
                    data: null,
                    saveParam: null,
                    participant: null
                },
                component: 'rfGuaranteeApp',
                resolve: {
                    rfGuaranteeAppData: ['$stateParams', 'rfGuaranteeAppSrv', 'desktopSrv', '$state', function ($stateParams, rfGuaranteeAppSrv, desktopSrv, $state) {
                    	 var cabType = desktopSrv.getCabType();
                         if (["participant", "agent", "admincab"].includes(cabType)) {
                            return rfGuaranteeAppSrv.getRfGuarantee($stateParams.id);
                         }else{
                        	 console.log("Отсутствуют права для доступа к разделу");
                        	 $state.go('home');
                         }
                    }]
                }
        },
        rfGuaranteeAppCopy = {
                url: '/raiffeisenGuaranteeCopy/{id}',
                authRequired: true,
                bindings: {
                    rfGuaranteeAppData: 'rfGuaranteeAppData'
                },
                params: {
                    clearConfig: false,
                    params: null,
                    data: null,
                    participant: null
                },
                component: 'rfGuaranteeApp',
                resolve: {
                    rfGuaranteeAppData: ['$stateParams', 'rfGuaranteeAppSrv', 'desktopSrv', '$state', function ($stateParams, rfGuaranteeAppSrv, desktopSrv, $state) {
                   	 var cabType = desktopSrv.getCabType();
                   	    if (["participant", "agent"].indexOf(cabType) != -1) {
                           return rfGuaranteeAppSrv.getRfGuarantee($stateParams.id, true);
                        }else{
                       	 console.log("Отсутствуют права для доступа к разделу");
                       	 $state.go('home');
                        }
                   }]
                }
            },
        /**
         * Список анкет ВТБ для участника
         */
        bankGuaranteesVTB = {
            url: '/bankGuaranteesVTB',
            authRequired: true,
            directTransitionAuth: true,
            stateSendLog: 'BANK_GUARANTEE_VTB',
            params: {
                checkLimit: null,
                clearConfig: false,
                purchasData: null,//данные закупки для создания анкеты
                purchasDataType: null,//тип откуда взяли данные закупки
                // UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
                // EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
                saveParam:null, // при успешной отправке вывести сообщение с параметрами анкеты
                participantInfo: null, // Данные о участнике (при закрытии формы проставлять выбраного участника)
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            templateUrl: 'app/controllers/tables/viewsTable/bankGuaranteesVTB.html',
            controller: 'tablesCtrl'
        },

        /**
         * Список анкет ВТБ для участника, ЛК оператора
         */
        bankGuaranteesVTBAdmin = {
            url: '/bankGuaranteesVTB/{participantId}',
            authRequired: true,
            directTransitionAuth: true,
            stateSendLog: 'BANK_GUARANTEE',
            params: {
                checkLimit: null,
                clearConfig: false,
                purchasData: null,//данные закупки для создания анкеты
                purchasDataType: null,//тип откуда взяли данные закупки
                // UETP - закупки КОМИТА ЭТП (из БД КОМИТА ЭТП)
                // EIS - из Реестра закупок ЕИС (из ElasticSearch purchase_notice*, purchase_notification*)
                saveParam:null // при успешной отправке вывести сообщение с параметрами анкеты
            },
            templateUrl: 'app/controllers/tables/viewsTable/bankGuaranteesVTB.html',
            controller: 'tablesCtrl',
            resolve: {
                participantData: ['$stateParams', 'participantSrv', function ($stateParams, participantSrv) {
                    return participantSrv.getOrgById($stateParams.participantId).then(function successCallback(response) {
                        if (response.data.success) {
                            participantSrv.setOrgInfo(response.data.result);
                        }
                    });
                }]
            }
        },

        /**
         * Форма создания анкеты ВТБ
         */
        bankGuaranteeAppVTBCreate = {
            url: '/createBankGuaranteeAppVTB',
            authRequired: true,
            directTransitionAuth: true,
            stateSendLog: 'CREATE_BANK_GUARANTEE_APP_VTB',
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            params: {
                clearConfig: false,
                purchasData: null, // Данные закупки для создания анкеты
                purchasDataType: null // Тип данных закупки
            },
            views: {
                '@': {
                    component: 'bankGuaranteeAppVTB'
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppVTBCreate': {
                    component: 'bankGuaranteeFormCreator'
                }
            }
        },
        /**
         * Форма редактирования/просмотра анкеты ВТБ
         */
        bankGuaranteeAppVTBOpen = {
            url: '/bankGuaranteeAppVTB/{id}',
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
            },
            views: {
                '@': {
                    component: 'bankGuaranteeAppVTB',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppVTBOpen': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppVTBOpen': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeVTBSrv', function ($stateParams, bankGuaranteeVTBSrv) {
                    return bankGuaranteeVTBSrv.getBankGuarantee($stateParams.id);
                }]
            }
        },
        /**
         * Форма просмотра анкеты ВТБ для оператора
         */
        bankGuaranteeAppVTBAdminOpen = {
            url: '/bankGuaranteeAppVTB/{id}/{participantId}',
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null
            },
            views: {
                '@': {
                    component: 'bankGuaranteeAppVTB',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData',
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppVTBAdminOpen': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppVTBAdminOpen': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeVTBSrv', function ($stateParams, bankGuaranteeVTBSrv) {
                    return bankGuaranteeVTBSrv.getBankGuarantee($stateParams.id);
                }],
                participantInfoData: ['$stateParams', 'participantSrv', function ($stateParams, participantSrv) {
                    return participantSrv.getOrgById($stateParams.participantId);
                }]
            }
        },
        /**
         * Форма копирования анкеты ВТБ
         */
        bankGuaranteeAppVTBCopy = {
            url: '/bankGuaranteeAppVTBCopy/{id}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null
            },
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeAppVTB',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppVTBCopy': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppVTBCopy': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeVTBSrv', function ($stateParams, bankGuaranteeVTBSrv) {
                    return bankGuaranteeVTBSrv.getBankGuarantee($stateParams.id, true);
                }]
            }
        },
        /**
         * Форма создания анкеты ВТБ агентом
         */
        bankGuaranteeAppVTBAgentCreate = {
            url: '/createBankGuaranteeAppVTBAgent/{participantId}',
            authRequired: true,
            // stateSendLog: 'CREATE_BANK_GUARANTEE_APP',
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            params: {
                clearConfig: false,
                purchasData: null, //данные закупки для создания анкеты
                purchasDataType: null, //тип данных закупки
                participantSelected: true // Признак, что открыли анкету с выбранным участником
            },
            views: {
                '@': {
                    component: 'bankGuaranteeAppVTB',
                    bindings: {
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppVTBAgentCreate': {
                    component: 'bankGuaranteeFormCreator'
                }
            },
            resolve: {
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        /**
         * Форма редактирования/просмотра анкеты ВТБ агентом
         */
        bankGuaranteeAppVTBAgentOpen = {
            url: '/bankGuaranteeAppVTBAgent/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении}
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeAppVTB',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData',
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppVTBAgentOpen': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppVTBAgentOpen': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeVTBSrv', function ($stateParams, bankGuaranteeVTBSrv) {
                    return bankGuaranteeVTBSrv.getBankGuarantee($stateParams.id);
                }],
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        /**
         * Форма копирования анкеты ВТБ агентом
         */
        bankGuaranteeAppVTBAgentCopy = {
            url: '/bankGuaranteeAppVTBAgentCopy/{id}/{participantId}',
            authRequired: true,
            params: {
                clearConfig: false,
                params: null,
                data: null,
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            needConfirm: true,
            needConfirmState: 'bankGuaranteeApp',
            views: {
                '@': {
                    component: 'bankGuaranteeAppVTB',
                    bindings: {
                        bankGuaranteeAppData: 'bankGuaranteeAppData',
                        participantInfoData: 'participantInfoData'
                    }
                },
                'bankGuaranteeFormCreator@bankGuaranteeAppVTBAgentCopy': {
                    component: 'bankGuaranteeFormCreator'
                },
                'bankGuaranteeDocuments@bankGuaranteeAppVTBAgentCopy': {
                    component: 'bankGuaranteeDocuments'
                }
            },
            resolve: {
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeVTBSrv', function ($stateParams, bankGuaranteeVTBSrv) {
                    return bankGuaranteeVTBSrv.getBankGuarantee($stateParams.id, true);
                }],
                participantInfoData: ['$stateParams', 'bankGuaranteeSrv', function ($stateParams, bankGuaranteeSrv) {
                    return bankGuaranteeSrv.getOrganizationInfoForAgent($stateParams.participantId);
                }]
            }
        },
        bankGuaranteeVTBChat = {
            url: '/bankGuaranteeVTBChat/{id}',
            authRequired: true,
            params: {
                message: null,
                participantInfo: null, // Данные о участнике (при закрытии формы проставлять выбраного участника)
                participantSelected: false // Признак, что открыли анкету с выбранным участником
            },
            component: 'bankGuaranteeVTBChat',
            bindings: {
                bankGuaranteeChatData: 'bankGuaranteeChatData',
                bankGuaranteeAppData: 'bankGuaranteeAppData'
            },
            resolve: {
                bankGuaranteeChatData: ['$stateParams', 'bankGuaranteeVTBSrv', function ($stateParams, bankGuaranteeVTBSrv) {
                    return bankGuaranteeVTBSrv.getRequests($stateParams.id);
                }],
                bankGuaranteeAppData: ['$stateParams', 'bankGuaranteeVTBSrv', function ($stateParams, bankGuaranteeVTBSrv) {
                    return bankGuaranteeVTBSrv.getBankGuarantee($stateParams.id);
                }]
            }
        },
        bgAgents = {
            url: '/bgAgents',
            authRequired: true,
            secured: ["operator_admin", "operator_accountant", "operator_inspector", "operator_support_ro", "operator_support_rw"],
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/bgAgents.html',
            controller: 'tablesCtrl'
        },
        /**
         * Редактирование пользователя Агента
         */
        openBgAgent = {
            url: '/bgAgent/{id}',
            authRequired: true,
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'bgAgent',
                    bindings: {orgData: 'orgById'}
                },
                'reqBgAgent@openBgAgent': {
                    component: 'reqBgAgent'
                },
                'agentOrg@openBgAgent': {
                    component: 'bgAgentListOrganizer'
                }
            },
            resolve: {
                orgById: ['$stateParams', 'bgAgentSrv', function ($stateParams, bgAgentSrv) {
                    return bgAgentSrv.getOperatorById($stateParams.id)
                }]
            }
        },
        /**
         * Страница "Приглашаем агентов по выпуску банковских гарантий"
         */
        becomeBgAgent = {
            url: '/becomeBgAgent',
            params: {
                clearConfig: true
            },
            component: 'becomeBgAgent'
        },
        /**
         * Страница "Приглашаем агентов по выпуску банковских гарантий"
         */
        bgAdvertising = {
            url: '/getbg_old',
            params: {
                clearConfig: true
            },
            component: 'bgAdvertising'
        },
        /**
         * Создание нового Агента для банковских гарантий
         */
        createNewBgAgent = {
            url: '/newBgAgent',
            params: {
                clearConfig: true
            },
            authRequired: true,
            views: {
                '@': {
                    component: 'bgAgent'
                },
                'reqBgAgent@newBgAgent': {
                    component: 'reqBgAgent'
                },
                'agentOrg@newBgAgent': {
                    component: 'bgAgentListOrganizer'
                }
            }
        },
        /**
         * Создание представителя Агента для банковских гарантий
         */
        createBgAgent = {
            url: '/createOrganizatorBgAgent',
            authRequired: true,
            params: {
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'bgAgentEditor'
                }
            }
        },
        /**
         * Редактирование представителя Организатора
         */
        openEditorBgAgentOrg = {
            url: '/organizatorBgAgent/{id}',
            authRequired: true,
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'bgAgentEditor'
                }
            },
            resolve: {
                agentData: ['$stateParams', 'profileSrv', '$state', function ($stateParams, profileSrv) {
                    return profileSrv.getAgentById($stateParams.id);
                }]
            }
        },
        /**
         * реестр банков
         */
        bgBanks = {
                url: '/bgBanks',
                authRequired: true,
                secured: ["operator_admin", "operator_accountant", "operator_inspector", "operator_support_ro", "operator_support_rw"],
                params: {
                    clearConfig: false
                },
                templateUrl: 'app/controllers/tables/viewsTable/bgBanks.html',
                controller: 'tablesCtrl'
        },
        /**
         * создание банка
         */
        createBgBank = {
                url: '/createBgBank',
                params: {
                    clearConfig: true
                },
                authRequired: true,
                views: {
                    '@': {
                        component: 'bgBank'
                    },
                    'bgBankReq@createBgBank': {
                        component: 'bgBankReq'
                    },
                    'bgBankAgents@createBgBank': {
                        component: 'bgBankAgents'
                    }
                }
        },
        /**
         * вывод сведений по банку
         */
        openBgBank = {
                url: '/bgBank/{id}',
                authRequired: true,
                params: {
                    successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                    successSend: null,
                    clearConfig: true
                },
                views: {
                    '@': {
                        component: 'bgBank',
                        bindings: {orgData: 'orgById'}
                    },
                    'bgBankReq@openBgBank': {
                        component: 'bgBankReq'
                    },
                    'bgBankAgents@openBgBank': {
                        component: 'bgBankAgents'
                    }
                },
                resolve: {
                    orgById: ['$stateParams', 'bgBankSrv', function ($stateParams, bgBankSrv) {
                        return bgBankSrv.getOperatorById($stateParams.id)
                    }]
                }
        },
        /**
         * создание представителя банка
         */
        createBgBankAgent = {
                url: '/createBgBankAgent',
                authRequired: true,
                params: {
                    clearConfig: true
                },
                views: {
                    '@': {
                        component: 'bgBankAgentEditor'
                    }
                }
        },
        /**
         * вывод сведений по представителю банку
         */
        openBgBankAgent = {
                url: '/bgBankAgent/{id}',
                authRequired: true,
                params: {
                    successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                    successSend: null,
                    clearConfig: true
                },
                views: {
                    '@': {
                        component: 'bgBankAgentEditor'
                    }
                },
                resolve: {
                    agentData: ['$stateParams', 'profileSrv', function ($stateParams, profileSrv) {
                        return profileSrv.getAgentById($stateParams.id);
                    }]
                }
        },
        factoring = {
            url: '/factoring',
            component: 'factoring',
            stateSendLog: 'FACTORING_BANNER'
        },
        participate = {
            url: '/participate/{procType}/{procId}/{lotId}',
            authRequired: true,
            bindings: {
                createData: 'createData',
                procedure: 'procedure'
            },
            params: {
                clearConfig: false
            },
            component: 'participate',
            resolve: {
                createData: ['$stateParams', 'participateSrv', function ($stateParams, participateSrv) {
                    return participateSrv.getPreCreateClaim($stateParams.procType, $stateParams.procId, $stateParams.lotId).then(function successCallback(response) {
                            if (response.data.success) {
                                return response
                            } else {
                                return {error: response.data.errorMessage}
                            }
                        }, function errorCallback(response) {
                            if (response.status === 403) {
                                return {error: 'Доступ к методу закрыт. Видимо ваш пользователь не обладает достаточными правами доступа'}
                            }
                        }
                    )
                }],
                procedure: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then(function (response) {
                        if (response.data.success) {
                            return response.data.result;
                        }
                    })
                }]
            }
        },
        openParticipate = {
            url: '/openParticipate/{procType}/{procId}/{lotId}/{claimId}',
            authRequired: true,
            bindings: {
                updateData: 'updateData',
                procedure: 'procedure'
            },
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: false
            },
            component: 'participate',
            resolve: {
                updateData: ['$stateParams', 'participateSrv', function ($stateParams, participateSrv) {
                    return participateSrv.getClaim($stateParams)
                }],
                procedure: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then(function (response) {
                        if (response.data.success) {
                            return response.data.result;
                        }
                    })
                }]
            }
        },
        viewParticipate = {
            url: '/viewParticipate/{procType}/{procId}/{claimId}',
            authRequired: true,
            bindings: {
                updateData: 'updateData',
                procedure: 'procedure'
            },
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: false,
                viewOnly: true
            },
            component: 'participate',
            resolve: {
                updateData: ['$stateParams', 'participateSrv', function ($stateParams, participateSrv) {
                    return participateSrv.getClaimView($stateParams)
                }],
                procedure: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then(function (response) {
                        if (response.data.success) {
                            return response.data.result;
                        }
                    })
                }]
            }
        },
        // @deprecate
        createOpeningProtocol = {
            url: '/createOpeningProtocol/{procType}/{procId}',
            authRequired: true,
            bindings: {
                createData: 'createData'
            },
            params: {
                clearConfig: true
            },
            component: 'protocol',
            resolve: {
                createData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForCreateOpeningProtocol($stateParams)
                }]
            }
        },
        // @deprecate
        createRatingFirstClaimPartsProtocol = {
            url: '/createRatingFirstClaimPartsProtocol/{procType}/{procId}',
            authRequired: true,
            bindings: {
                createData: 'createData'
            },
            params: {
                clearConfig: true
            },
            component: 'protocol',
            resolve: {
                createData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForCreateRatingFirstClaimPartsProtocol($stateParams)
                }]
            }
        },
        // @deprecate
        createRatingSecondClaimPartsProtocol = {
            url: '/createRatingSecondClaimPartsProtocol/{procType}/{procId}',
            authRequired: true,
            bindings: {
                createData: 'createData'
            },
            params: {
                clearConfig: true
            },
            component: 'protocol',
            resolve: {
                createData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForCreateRatingSecondClaimPartsProtocol($stateParams)
                }]
            }
        },
        // @deprecate
        createSummarizingProtocol = {
            url: '/createSummarizingProtocol/{procType}/{procId}',
            authRequired: true,
            bindings: {
                createData: 'createData'
            },
            params: {
                clearConfig: true
            },
            component: 'protocol',
            resolve: {
                createData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForCreateSummarizingProtocol($stateParams)
                }]
            }
        },
        // @deprecate
        createEditPublishedProtocol = {
            url: '/createEditPublishedProtocol/{procType}/{procId}/protocols/{protocolId}',
            authRequired: true,
            bindings: {
                createData: 'createData',
                procData: 'procData'
            },
            params: {
                clearConfig: true
            },
            component: 'protocol',
            resolve: {
                createData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForEditPublishedProtocol($stateParams)
                }],
                procData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }]
            }
        },
        createRebiddingNotice = {
            url: '/createRebiddingNotice/{procType}/{procId}/lot/{lotId}',
            authRequired: true,
            bindings: {
                createData: 'createData',
                procData: 'procData'
            },
            params: {
                clearConfig: true
            },
            component: 'protocol',
            resolve: {
                createData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForCreateRebiddingNotice($stateParams)
                }],
                procData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }]
            }
        },
        openProtocol = {
            url: '/openProtocol/{procType}/{procId}/protocols/{protocolId}',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                "operator_support_ro", "operator_support_rw", "operator_manager"],
            bindings: {
                editData: 'editData',
                procData: 'procData'
            },
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: true,
                fromCreate: false
            },
            component: 'protocol',
            resolve: {
                editData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }],
                procData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }]
            }
        },
        openProtocolView = {
            url: '/openProtocolView/{procType}/{procId}/protocols/{protocolId}',
            authRequired: true,
            bindings: {
                editData: 'editData',
                procData: 'procData'
            },
            params: {
                successSave: null, // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
                successSend: null,
                clearConfig: true,
                isViewOnly: true
            },
            component: 'protocol',
            resolve: {
                editData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }],
                procData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }]
            }
        },
        systemMessages = {
            url: '/systemMessages',
            authRequired: true,
            secured: ["organizer_admin", "organizer_user", "participant_admin", "user"],
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/systemMessages.html',
            controller: 'tablesCtrl'
        },
        protocolNotifications = {
            url: '/protocolNotifications',
            authRequired: true,
            secured: ["organizer_admin", "organizer_user"],
            params: {
                clearConfig: false
            },
            templateUrl: 'app/controllers/tables/viewsTable/protocolNotifications.html',
            controller: 'tablesCtrl'
        },
        openMessage = {
            url: '/openMessage/{id}',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                "operator_support_ro", "operator_support_rw", "operator_manager"],
            bindings: {
                messageData: 'messageData'
            },
            params: {
                clearConfig: false
            },
            component: 'systemMessage',
            resolve: {
                messageData: ['$stateParams', 'sysMessageSrv', function ($stateParams, sysMessageSrv) {
                    return sysMessageSrv.getMessage($stateParams.id)
                }]
            }
        },
        openMessageNoRead = {
            url: '/openMessage/{id}',
            authRequired: true,
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                "operator_support_ro", "operator_support_rw", "operator_manager"],
            bindings: {
                messageData: 'messageData'
            },
            params: {
                clearConfig: false
            },
            component: 'systemMessage',
            resolve: {
                messageData: ['$stateParams', 'sysMessageSrv', function ($stateParams, sysMessageSrv) {
                    return sysMessageSrv.getMessage($stateParams.id)
                }]
            }
        },
        /**
         * Таблица Реестр платежей (с фильтром и поиском)
         * */
        paymentOrders = {
            url: '/paymentOrders/list/{type}',
            authRequired: true,
            params: {
                clearConfig: true,
                isTable: true,
                type: 'all',
                resetPaginationPage: false
            },
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                        "operator_support_ro", "operator_support_rw"],
            component: 'paymentOrdersTable'
        },
        /**
         * Вкладка Реестр платежей
         */
        paymentOrdersTab = {
            url: '/paymentOrdersTab',
            authRequired: true,
            params: {
                clearConfig: true,
                isTable: false,
                type: 'all'
            },
            component: 'paymentOrdersTable'
        },
        /**
         * Панель платежа. наименование состояния менять нельзя - используется в ссылках в уведомлениях
         */
        paymentOrderView = {
            url: '/paymentOrders/{id}',
            authRequired: true,
            params: {
                params: null,
                clearConfig: true
            },
            bindings: {
                paymentOrderData: 'paymentOrderData'
                //coveringLetterData: 'coveringLetterData'
            },
            resolve: {
                paymentOrderData: ['$stateParams','$state', 'paymentOrderSrv', 'desktopSrv', function ($stateParams,$state, paymentOrderSrv, desktopSrv) {
                    var cabType = desktopSrv.getCabType();
                    return paymentOrderSrv.getPaymentOrder($stateParams.id, cabType).then(function successCallback(response) {
                        if (!response.data.success) {
                            //errorCode 421 - Запрашиваемый объект не доступен"
                            if(response.data.errorCode && response.data.errorCode==="421")
                                $state.go('home');
                            console.log(response);
                        }
                        return response.data.result;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }]
            },
            component: 'paymentOrder'
        },
        tabletLogin = {
            url: '/tabletLogin',
            params: {
                clearConfig: false,
                type: 'all'
            },
            templateUrl: 'views/tabletLogin.html'
        },
        webmarketLogin = {
            url: '/webmarketLogin',
            params: {
                clearConfig: false
            },
            component: 'webmarketLogin'
        },
        /**
         * Вопрос-ответ. Оператор. Таблица
         */
        questionAnswer = {
            url: '/questionAnswer',
            authRequired: true,
            params: {
                clearConfig: false
            },
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                    "operator_support_ro", "operator_support_rw"],
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/questionAnswerTable.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        /**
         * Вопрос-Ответ. Создание
         */
        questionAnswerCreate = {
            url: '/questionAnswerCreate',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'questionAnswerCreate'
        },
        /**
         * Вопрос-овтет. Просмотр
         */
        questionAnswerView = {
            url: '/questionAnswerView/{id}',
            authRequired: true,
            params: {
                clearConfig: true
            },
            views: {
                '@': {
                    component: 'questionAnswerCreate',
                    bindings: {bindingsData: 'questionAnswerById'}
                },
                'questionAnswer': {
                    template: '<span></span>',
                }
            },
            resolve: {
                questionAnswerById: ['$stateParams', 'questionAnswerSrv', function ($stateParams, questionAnswerSrv) {
                    return questionAnswerSrv.load($stateParams.id)
                }]
            }
        },
        /**
         * Вопрос-ответ. Просмотр ОЧ
         */
        questionAnswerHomeView = {
            url: '/questionAnswerHome/{id}',
            bindings: {
                bindingsData: 'questionAnswerById'
            },
            authRequired: false,
            component: 'questionAnswerHomeView',
            resolve: {
                questionAnswerById: ['$stateParams', 'questionAnswerSrv', function ($stateParams, questionAnswerSrv) {
                    return questionAnswerSrv.loadPublished($stateParams.id)
                }]
            }
        },

        /**
         * Удаленная поддержка
         */
        remoteSupport = {
            url: '/remoteSupport',
            authRequired: false,
            params: {
                clearConfig: false
            },
            templateUrl: 'app/components/help/remoteSupport/remoteSupport.html'
        },

        /**
         * Образцы документов Таблица
         */
        documentSamples = {
            url: '/documentSamples',
            authRequired: true,
            params: {
                clearConfig: false
            },
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/documentSamplesTable.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        /**
         * Образцы документов создание
         */
        documentSamplesCreate = {
            url: '/documentSamplesCreate',
            authRequired: true,
            params: {
                clearConfig: true
            },
            component: 'documentSamplesCreate'
        },
        /**
         * Вопрос-ответ ОЧ
         */
        questionAnswerTable = {
            url: '/questionAnswerTable',
            authRequired: false,
            params: {
                clearConfig: true
            },
            startMth: 'questionAnswerPublishedLoad',
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/questionAnswerHomeTable.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        /**
         * Образцы документов ОЧ
         */
        documentSamplesTable = {
            url: '/documentSamplesTable',
            authRequired: false,
            startMth: 'documentSamplesPublishedLoad',
            params: {
                clearConfig: true
            },
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/documentSamplesHomeTable.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        /**
         * Информация по ускоренной аккредитации
         */
        acceleratedAccreditation = {
            url: '/acceleratedAccreditation',
            authRequired: false,
            params: {
                clearConfig: true
            },
            bindings: {
                tariffsData: 'tariffsData'
            },
            component: 'accelAccred',
            resolve: {
                tariffsData: ['$stateParams', 'tariffsSrv', function ($stateParams, tariffsSrv) {
                    return tariffsSrv.getAccredTariffs().then(function successCallback(response) {
                        if (response.data.success) {
                            return response;
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    })
                }]
            }
        },
        /**
         * Тарифы на главной странице
         */
        tariffsHome = {
            url: '/tariffs',
            authRequired: false,
            stateSendLog: 'TARIFFS',
            bindings: {
                tariffsData: 'tariffsData'
            },
            params: {
                clearConfig: true,
                isHiddenTariffHistory: true
            },
            component: 'tariffs',
            resolve: {
                tariffsData: ['$stateParams', 'tariffsSrv', '$state', function ($stateParams, tariffsSrv) {
                    return tariffsSrv.getTariffs().then(function successCallback(response) {
                        if (response.data.success) {
                            response.data.items = response.data.items.filter(function (item) {
                                return item.group === 'tariff' || item.group === 'service';
                            });
                            return response;
                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    })
                }]
            }
        },
        /**
         * Тарифы на главной странице
         */
        securingZmo = {
            url: '/securingZmo',
            authRequired: false,
            params: {
                clearConfig: true,
            },
            component: 'securingZmo'
        },
        /**
         * Информация по финансовым услугам
         */
        financialService = {
            url: '/financialService',
            authRequired: false,
            params: {
                clearConfig: true
            },
            templateUrl: 'app/components/tariffsServices/financialService.html',
            controller: 'tablesCtrl'
        },

        kinoandpravo = {
            url: '/kinoandpravo',
            component: 'feedbackForm'
        },

        feedback = {
            url: '/feedback',
            params: {theme: "Реклама на сайте"},
            component: 'feedbackForm',
        },
        feedbackSent = {
            url: '/feedbackSent',
            component: 'feedbackForm',
        },

        freshdoc = {
            url: '/freshdoc',
            stateSendLog: 'FRESCHDOC',
            params: {theme: "Реклама на сайте"},
            views: {
                '@': {
                    templateUrl: 'views/freshdoc.html',
                    controller: 'freshdoc'
                }
            }
        },


        rekl = {
            url: '/advertisement',
            params: {
                clearConfig: true
            },
            component: 'rekl'
        },

        privacy = {
            url: '/privacy',
            component: 'privacy'
        },

        /**
         * Счета
         */
        invoice = {
            url: '/invoice',
            authRequired: true,
            params: {
                clearConfig: false,
                isTable: false,
                type: 'all'
            },
            component: 'invoiceTable'
        },
        /**
         * Просмотр счета
         */
        invoiceView = {
            url: '/invoice/{id}/{isOperator}',
            authRequired: true,
            params: {clearConfig: true, params: null, isOperator: 'false'},
            bindings: {
                invoiceData: 'invoiceData'
            },
            resolve: {
                invoiceData: ['$stateParams', 'invoiceSrv', 'participantSrv', 'authSrv', function ($stateParams, invoiceSrv, participantSrv, authSrv) {
                    return invoiceSrv.getInvoice($stateParams.id).then(function successCallback(response) {
                        if (!response.data.success) {
                            console.log(response);
                        }
                        if ($stateParams.isOperator === 'true') {
                            return participantSrv.getOrgById(response.data.result.participant.id).then(function (responseOrg) {
                                if (responseOrg.data.success) {
                                    return {
                                        invoice: response.data.result,
                                        orgData: responseOrg.data.result
                                    }
                                }
                            }, function errorCallback(response) {
                                console.log(response);
                            })
                        } else {
                            return {
                                invoice: response.data.result,
                                orgData: authSrv.getUser()
                            }
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }]
            },
            component: 'invoice'
        },

        invoiceTable = {
            url: '/invoiceTable',
            authRequired: true,
            params: {
                clearConfig: false,
                isTable: true,
                type: 'all'
            },
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                    "operator_support_ro", "operator_support_rw"],
            component: 'invoiceTable'
            //todo пока не делаем resolve
        },

        /**
         * Просмотр акта по оплаченному счету
         */
        completedWorkView = {
            url: '/completedWork/{id}',
            authRequired: true,
            params: {clearConfig: true, params: null},
            bindings: {
                completedWorkData: 'completedWorkData'
            },
            resolve: {
                completedWorkData: ['$stateParams', 'completedWorksSrv', '$state', function ($stateParams, completedWorksSrv, $state) {
                    return completedWorksSrv.getCompletedWork($stateParams.id).then(function successCallback(response) {
                        if (!response.data.success) {
                            console.log(response);
                            if(response.data.errorCode && response.data.errorCode==="402")
                                $state.go('home');
                        }
                        return response.data.result;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }]
            },
            component: 'completedWork'
        },

        completedWorksTable = {
            url: '/completedWorksTable',
            authRequired: true,
            params: {
                clearConfig: false,
                isTable: true,
                type: 'all'
            },
            component: 'completedWorksTable'
        },

        /**
         * Выписки
         */
        statement = {
            url: '/statement',
            authRequired: true,
            params: {
                clearConfig: false,
                isTable: false,
                type: 'all'
            },
            component: 'statementTable'
        },

        statementTable = {
            url: '/statementTable',
            authRequired: true,
            params: {
                clearConfig: false,
                isTable: true,
                type: 'all'
            },
            secured: ["operator_admin", "organizer_admin", "organizer_user", "participant_admin", "user", "operator_accountant",
                        "operator_support_ro", "operator_support_rw"],
            component: 'statementTable'
            //todo пока не делаем resolve
        },
        /*
        * страница "Проверка компании"
        * строка поиска
        * */
        companyValidationTass = {
            url: '/companyValidation',
            authRequired: true,
            stateSendLog: 'COMPANY_VALIDATION',
            params: {
                clearConfig: false,
                showError: null
            },
            component: 'companyViewTass'
        },
        /*
        * Просмотр компании по inn
        * */
        companyViewTass = {
            url: '/companyInfo/{id}',
            authRequired: true,
            bindings: {
                companyInfo: 'companyInfo'
            },
            params: {
                clearConfig: false
            },
            resolve: {
                companyInfo: ['$stateParams', 'tassBusinessSrv','propertiesService', function ($stateParams, tassBusinessSrv,propertiesService) {
                    return new Promise(function (resolve, reject) {
                        propertiesService.getProperty("api.url").then(function (host) {
                            resolve(tassBusinessSrv.getOrganizationInfo(null, {id: $stateParams.id}, host))
                        }, function (reason) {
                            reject(reason);
                        });
                    })
                }]
            },
            component: 'companyViewTass'
        },
        subscriptionTable = {
            url: '/subscriptionTable',
            authRequired: true,
            params: {
                clearConfig: false
            },
            startMth: 'subscriptionLoad',
            templateUrl: 'app/controllers/tables/viewsTable/subscriptionTable.html',
            controller: 'tablesCtrl'
        },
        subscription = {
            url: '/subscription/{id}',
            authRequired: true,
            bindings: {
                subscriptionData: 'subscriptionData',
                personalAccountData: 'personalAccountData'
            },
            params: {
                clearConfig: true,
                successSave: null // при переходе из формы создания вывести в форме редактирования данные об успешном сохранении
            },
            component: 'subscription',
            resolve: {
                personalAccountData: ['$stateParams', 'authSrv', function ($stateParams, authSrv) {
                    return authSrv.reloadOrgInfo();
                }],
                subscriptionData: ['$stateParams', 'subscriptionSrv', 'personalAccountData', 'desktopSrv', 'authSrv', 'propertiesService', function ($stateParams, subscriptionSrv, user, desktopSrv, authSrv, propertiesService) {
                    if (authSrv.canActiveOtherPurchase(desktopSrv.getCabType(), user)) {
                        //return subscriptionSrv.getSubscribe($stateParams.id);
                        return propertiesService.getProperty("otherProcedures.url").then(function (host) {
                            return subscriptionSrv.getSubscribe($stateParams.id, host)
                                .then(function (response) {
                                    return response;
                                })
                        })
                    } else {
                        return false;
                    }
                }],
                subscriptionLimit: ['propertiesService', 'tassBusinessSrv', 'authSrv', function (propertiesService, tassBusinessSrv, authSrv) {
                    const haveTariffsFormService = authSrv.haveTariffsFormService();
                    if (haveTariffsFormService) {
                        return {
                            data: {
                                result: 1
                            }
                        };
                    }
                    return propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        return tassBusinessSrv.subscribeParticipantLimit(host);
                    })
                }],
            }
        },
        createSubscription = {
            url: '/createSubscription',
            authRequired: true,
            bindings: {
                personalAccountData: 'personalAccountData'
            },
            params: {
                clearConfig: true
            },
            component: 'subscription',
            resolve: {
                personalAccountData: ['$stateParams', 'authSrv', function ($stateParams, authSrv) {
                    return authSrv.reloadOrgInfo();
                }]
            }
        },
        createSubscriptionPLANNED = {
            url: '/createSubscription/{procId}',
            authRequired: true,
            bindings: {
                procedureData: 'procedureData',
                personalAccountData: 'personalAccountData'
            },
            params: {
                clearConfig: true
            },
            component: 'subscription',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcEISByNumber($stateParams.procId)
                        .then(function (response) {
                            return response;
                        })
                }],
                personalAccountData: ['$stateParams', 'authSrv', function ($stateParams, authSrv) {
                    return authSrv.reloadOrgInfo();
                }]
            }
        },
        informationAboutRejectProcedure = {
            url: '/informationAboutRejectProcedure/{procType}/{procId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true
            },
            component: 'informationAboutRejectProcedure',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', '$state', function ($stateParams, protocolSrv, $state) {
                    return protocolSrv.getDataForPurchaseRejection($stateParams).then(function (protocolData) {
                        if (protocolData.data.success) {
                            return protocolData;
                        } else {
                            $state.go('openProcedure', {
                                procType: $stateParams.procType,
                                procId: $stateParams.procId,
                                errorMessage: protocolData.data.errorMessage
                            }, {reload: true});
                        }
                    });
                }]

            }

        },

        informationAboutRejectProcedureView = {
            url: '/informationAboutRejectProcedureView/{procType}/{procId}/{protocolId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true,
                isViewOnly: true
            },
            component: 'informationAboutRejectProcedure',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }]
            }
        },

        noticePostponementProcedure = {
            url: '/noticePostponementProcedure/{procType}/{procId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true
            },
            component: 'noticePostponementProcedure',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', '$q', function ($stateParams, protocolSrv, $q) {
                    var defered = $q.defer();
                    return protocolSrv.getDataForCreateNoticePostponementProtocol($stateParams).then(function successCallback(response) {
                            if (response.data.success) {
                                defered.resolve(response);
                                return defered.promise;
                            } else {
                                defered.reject(response);
                                return defered.promise;
                            }
                        }
                    )
                }]
            }

        },

        noticePostponementProcedureView = {
            url: '/noticePostponementProcedureView/{procType}/{procId}/{protocolId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true,
                isViewOnly: true
            },
            component: 'noticePostponementProcedure',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }]
            }
        },

        notificationProtocol = {
            url: '/notificationProtocol/{procType}/{procId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true
            },
            component: 'notificationProtocol',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForNotificationProtocol($stateParams)
                }]
            }

        },
        notificationProtocolView = {
            url: '/notificationProtocolView/{procType}/{procId}/protocols/{protocolId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true,
                isViewOnly: true
            },
            component: 'notificationProtocol',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }]
            }

        },
        returnClaimsProtocol = {
            url: '/returnClaimsProtocol/{procType}/{procId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true
            },
            component: 'returnClaimsProtocol',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForReturnClaimsProtocol($stateParams)
                }]
            }

        },
        returnClaimsProtocolView = {
            url: '/returnClaimsProtocolView/{procType}/{procId}/protocols/{protocolId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true,
                isViewOnly: true
            },
            component: 'returnClaimsProtocol',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }]
            }

        },
        informationAboutCancelLots = {
            url: '/informationAboutCancelLots/{procType}/{procId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true
            },
            component: 'informationAboutCancelLots',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForCancelLotsProtocol($stateParams)
                }]
            }
        },
        informationAboutCancelLotsView = {
            url: '/informationAboutCancelLotsView/{procType}/{procId}/{protocolId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true,
                isViewOnly: true
            },
            component: 'informationAboutCancelLots',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }]
            }
        },
        additionalProtocol = {
            url: '/additionalProtocol/{procType}/{procId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true
            },
            component: 'additionalProtocol',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getDataForAdditionalProtocol($stateParams)
                }]
            }
        },
        additionalProtocolView = {
            url: '/additionalProtocolView/{procType}/{procId}/protocols/{protocolId}',
            bindings: {
                procedureData: 'procedureData',
                protocolData: 'protocolData'
            },
            params: {
                clearConfig: true,
                isViewOnly: true
            },
            component: 'additionalProtocol',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId)
                }],
                protocolData: ['$stateParams', 'protocolSrv', function ($stateParams, protocolSrv) {
                    return protocolSrv.getProtocolById($stateParams)
                }]
            }
        },

        /**
         * Форма создания запроса подтвержения платежа
         */
        confirmPayment = {
            url: '/confirmPayment/{id}',
            authRequired: true,
            params: {params: null},
            component: 'confirmPayment',
            bindings: {
                coveringLetterData: 'coveringLetterData'
            },
            resolve: {
                coveringLetterData: ['$state', '$stateParams', 'coveringLetterSrv', 'dialogSrv', function ($state, $stateParams, coveringLetterSrv, dialogSrv) {
                    return coveringLetterSrv.getCoveringLetter($stateParams.id).then(function (response) {
                        if (response.data.errorCode === '421') {
                            $state.go('home')
                            dialogSrv.openModal('_dialogMessage.html', {message: 'Доступ запрещен!'})
                        } else {
                            return response;
                        }
                    });
                }]
            }
        },
        /**
         * Форма создания запроса подтвержения платежа с ЛС
         */
        coveringLetterAcc = {
            url: '/coveringLetter/{id}/{acc}',
            bindings: {
                orgData: 'orgData',
                paymentOrderData: 'paymentOrderData'
            },
            authRequired: true,
            component: 'coveringLetter',
            resolve: {
                orgData: ['$stateParams', 'coveringLetterSrv', function ($stateParams, coveringLetterSrv) {
                    return coveringLetterSrv.getOrganizationInfo($stateParams.acc)
                }],
                paymentOrderData: ['$stateParams','$state', 'paymentOrderSrv', 'desktopSrv', function ($stateParams,$state, paymentOrderSrv, desktopSrv) {
                    var cabType = desktopSrv.getCabType();
                    return paymentOrderSrv.getPaymentOrder($stateParams.id, cabType).then(function successCallback(response) {
                        if (!response.data.success) {
                            //errorCode 421 - Запрашиваемый объект не доступен"
                            if(response.data.errorCode && response.data.errorCode==="421")
                                $state.go('home');
                            console.log(response);
                        }
                        return response.data.result;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }]
            }
        },
        /**
         * Форма создания запроса подтвержения платежа без ЛС
         */
        coveringLetter = {
            url: '/coveringLetter/{id}',
            authRequired: true,
            params: {acc: null},
            bindings: {
                paymentOrderData: 'paymentOrderData'
            },
            component: 'coveringLetter',
            resolve: {
                paymentOrderData: ['$stateParams','$state', 'paymentOrderSrv', 'desktopSrv', function ($stateParams,$state, paymentOrderSrv, desktopSrv) {
                    var cabType = desktopSrv.getCabType();
                    return paymentOrderSrv.getPaymentOrder($stateParams.id, cabType).then(function successCallback(response) {
                        if (!response.data.success) {
                            //errorCode 421 - Запрашиваемый объект не доступен"
                            if(response.data.errorCode && response.data.errorCode==="421")
                                $state.go('home');
                            console.log(response);
                        }
                        return response.data.result;
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }]
            }
        },
        priceAnalysisTable = {
            url: '/priceAnalysis',
            authRequired: true,
            params: {
                clearConfig: false,
                filterParams: null,
                filterType: null,
                searchParams: null
            },
            stateSendLog: 'PRICE_ANALYSIS',
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/priceAnalysisTable.html',
                    controller: 'tablesCtrl'
                },
                'contracts@priceAnalysisTable': {
                    templateUrl: 'app/controllers/tables/viewsTable/priceAnalysisSubTable.html'
                }
            }
        },
        eisIntegrationProc = {
            url: '/eisIntegrationProcedures',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: false
            },
            startMth: 'eisIntegrationProc',
            templateUrl: 'app/controllers/tables/viewsTable/eisIntegrationsProc.html',
            controller: 'tablesCtrl'
        },
        eisIntegrationProcEdit = {
            url: '/eisIntegrationProcedures/{procType}/{procId}',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: false
            },
            bindings: {
                procedureData: 'procedureData'
            },
            component: 'eisIntegrationProcEdit',
            resolve: {
                procedureData: ['$stateParams', 'proceduresSrv', function ($stateParams, proceduresSrv) {
                    return proceduresSrv.getProcById($stateParams.procType.toLowerCase(), $stateParams.procId).then(function (response) {
                        if (response.data.success) {
                            return response.data.result;
                        }
                    })
                }]
            }
        },
        eisMonitoring = {
            url: '/eisMonitoring',
            authRequired: true,
            secured: ["operator_admin", "operator_support_ro"],
            params: {
                clearConfig: false
            },
            startMth: 'eisMonitoring',
            templateUrl: 'app/controllers/tables/viewsTable/eisMonitoring.html',
            controller: 'tablesCtrl'
        },
        eisAddMethod = {
            url: '/addEisMethod',
            authRequired: true,
            secured: ["operator_admin"],
            params: {
                clearConfig: false
            },
            component: 'addEisMethod'
        },
        offers = {
            url: '/offers',
            authRequired: false,
            params: {
                clearConfig: true
            },
            templateUrl: 'app/controllers/tables/viewsTable/offers.html',
            controller: 'tablesCtrl'
        },
        myOffers = {
            url: '/myOffers',
            authRequired: true,
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/offersMy.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        positions = {
            url: '/positions',
            authRequired: false,
            params: {
                clearConfig: true
            },
            templateUrl: 'app/controllers/tables/viewsTable/positions.html',
            controller: 'tablesCtrl'
        },
        offersView = {
            url: '/{id}',
            params: {params: null},
            resolve: {
                offerData: ['$stateParams', 'offerSrv', function ($stateParams, offerSrv) {
                    //return cartService.loadSecured(true).then(function () {
                        return offerSrv.loadOfferById($stateParams.id);
                    //})
                }]
            },
            views: {
                '@': {
                    component: 'offer'
                }
            }
        },
        offersCreate = {
            url: '/create',
            authRequired: true,
            secured: ["organizer_admin", "organizer_user", "participant_admin", "user"],
            resolve: {getOfferData: angular.noop},
            views: {
                '@': {
                    component: 'offer'
                }
            }
        },
        cart = {
            url: '/cart',
            authRequired: true,
            views: {
                '@': {
                    component:'cart'
                }
            }
        },
        orderView = {
            url: '/{id}',
            params: {params: null, orderDoc: null, successSave: null},
            resolve: {
                orderData: ['$stateParams', 'orderSrv', function ($stateParams, orderSrv) {
                    var editMode = ($stateParams.params && $stateParams.params.editMode) || null;
                    return orderSrv.loadOrderById($stateParams.id, editMode)
                }]
            },
            authRequired: true,
            views: {
                '@': {
                    component:'order'
                }
            }
        },
        ordersMy = {
            url: '/myOrders',
            authRequired: true,
            params: {param: null},
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/ordersMy.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        orders = {
            url: '/orders',
            authRequired: true,
            views: {
                '@': {
                    templateUrl: 'app/controllers/tables/viewsTable/orders.html',
                    controller: 'tablesCtrl'
                }
            }
        },
        orderCreate = {
            url: '/create',
            authRequired: true,
            resolve: {orderData: angular.noop},
            params: {orderDoc: null},
            views: {
                '@': {
                    component: 'order'
                }
            }
        },
        docHistory = {
            url: '/docHistory/{typeHistory}/{id}',
            authRequired: true,
            params: {typeHistory: null},
            component: 'docHistory',
            resolve: {
                metaData: ['$stateParams', 'docsHistorySrv', function ($stateParams, docsHistorySrv) {
                    return docsHistorySrv.loadMetaDataById($stateParams.typeHistory, $stateParams.id)
                }],
                historyData: ['$stateParams', 'docsHistorySrv', function ($stateParams, docsHistorySrv) {
                    return docsHistorySrv.loadLogListByIdDoc($stateParams.typeHistory, $stateParams.id)
                }]
            }
        },
        services = {
            url: '/services',
            authRequired: true,
            params: {
                clearConfig: true
            },
            resolve: {
                subscriptionLimit: ['propertiesService', 'tassBusinessSrv', 'authSrv', function (propertiesService, tassBusinessSrv, authSrv) {
                    const haveTariffsFormService = authSrv.haveTariffsFormService();
                    if (haveTariffsFormService) {
                        return {
                            data: {
                                result: 1
                            }
                        };
                    }
                    return propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        return tassBusinessSrv.subscribeParticipantLimit(host);
                    })
                }],
                priceAnalysisLimit: ['propertiesService', 'tassBusinessSrv', 'authSrv', function (propertiesService, tassBusinessSrv, authSrv) {
                    const haveTariffsFormService = authSrv.haveTariffsFormService();
                    if (haveTariffsFormService) {
                        return {
                            data: {
                                result: 1
                            }
                        };
                    }
                    return propertiesService.getProperty("otherProcedures.url").then(function (host) {
                        return tassBusinessSrv.statPriceParticipantLimit(host);
                    })
                }],
                searchCompanyLimit: ['propertiesService', 'tassBusinessSrv', 'authSrv', function (propertiesService, tassBusinessSrv, authSrv) {
                    const haveTariffsFormService = authSrv.haveTariffsFormService();
                    if (haveTariffsFormService) {
                        return {
                            data: {
                                result: 1
                            }
                        };
                    }
                    return propertiesService.getProperty("api.url").then(function (host) {
                        return tassBusinessSrv.getParticipantLimit(host);
                    })
                }]
            },
            views: {
                '@': {
                    component: 'servicesPage'
                }
            }
        },
        contract = {
            url: '/contract/{id}',
            authRequired: true,
            directTransitionAuth: true,
            params: {
                contract: null,
                procedure: null,
                id: null,
                isHideNonActive: true
            },
            component: 'contract'
        },
        contractPaper = {
            url: '/contractPaper/{id}',
            params: {
                contract: null,
                procedure: null,
                id: null
            },
            component: 'contract'
        },
        contractReject = {
            url: '/contractReject/{id}',
            params: {
                contract: null,
                procedure: null,
                id: null
            },
            component: 'contract'
        };


    $stateProvider
    //Отчеты
        .state('reports', reports)

        .state('fasArchive', fasArchive)

        .state('fasListArchive', fasListArchive)

        //Отчеты раздела "Аналитика"
        .state('analyticReports', analyticReports)

        //Отчеты "Локо-Банка" Раздел находится в разработке
        .state('reportsLokoBank', reportsLokoBank)

        //cписки адресатов
        .state('mailingLists', mailingLists)

        //контакты
        .state('contacts', contacts)

        //о нас
        .state('about', about)

        //Стать заказчиком
        .state('becomeCustomer', becomeCustomer)
        //Стать участником
        .state('becomeSupplier', becomeSupplier)

        //новости
        .state('news', news)
        .state('news.view', newsView)
        .state('newsCreate', newsCreate)

        .state('home', home)
        .state('homeNewsBlock', homeNewsBlock)

        //версии
        .state('versionInfo', versionInfo)
        .state('versionInfoUpdate', versionInfoUpdate)
        .state('versionInfoCreate', versionInfoCreate)

        //рассылки
        .state('newsletters', newsletters)
        .state('newsletters.view', newslettersView)
        .state('newsletterCreate', newsletterCreate)

        .state('participant', participant)
        // .state('publicParticipant', publicParticipant)
        .state('accreditation', accreditation)
        .state('customer', customer)
        .state('newCustomer', newCustomer)
        .state('customerRequisites', customerRequisites)
        .state('customerView', customerView)
        .state('createMethod', createMethod)
        .state('createCustomerBranch', createCustomerBranch)
        .state('openMethod', openMethod)
        .state('openCustomerBranch', openCustomerBranch)
        .state('bgParticipants', bgParticipants)
        .state('organizators', organizators)
        .state('newOrganizator', createOrganizator)
        .state('becomeBgAgent', becomeBgAgent)
        .state('bgAdvertising', bgAdvertising)
        .state('newBgAgent', createNewBgAgent)
        .state('createAgentOrg', createAgentOrg)
        .state('createBgAgent', createBgAgent)
        .state('openOrganizator', openOrganizator)
        .state('openAgentOrg', openEditorAgentOrg)
        .state('openBgAgentOrg', openEditorBgAgentOrg)
        .state('bgBanks', bgBanks)
        .state('createBgBank', createBgBank)
        .state('openBgBank', openBgBank)
        .state('openBgBankAgent', openBgBankAgent)
        .state('createBgBankAgent', createBgBankAgent)
        .state('uc', uc)
        .state('uc.view', ucView)
        .state('getds', getds)
        .state('registration', registration)
        .state('customerRegistrationCreate', customerRegistrationCreate)
        .state('customerRegistrationTable', customerRegistrationTable)
        .state('customerRegistrationOpen', customerRegistrationOpen)
        .state('restorePassword', restorePassword)
        .state('changePassword', changePassword)
        .state('profile', profile)
        .state('tariffsMenu', tariffsMenu)
        .state('lk', lk)
        .state('profile.createAgent', createAgent)
        .state('profile.openAgent', openEditor)
        .state('profile.newAccreditation', newAccreditation)
        .state('accelAccEdit', accelAccEdit)
        .state('openAccreditation', openAccreditation)
        .state('profile.createFiliation', createFiliation)
        .state('profile.openFiliation', openFiliation)
        .state('participantView', participantView)
        .state('participantAgentView', participantAgentView)
        .state('notice', openNotice)
        .state('createNotice', createNotice)
        .state('createCommercialNotice', createCommercialNotice)
        .state('createNotice44', createNotice44)
        .state('createInitedNotice', createInitedNotice)
        .state('allProcedures', allProcedures)
        .state('myProcedures', myProcedures)
        .state('specialAccount', specialAccount)
        .state('notices', notices)
        .state('procedures', procedures)
        .state('jointProcedures', jointProcedures)
        .state('clarification', clarification)
        .state('publicProcedures', publicProcedures)
        .state('publicProcedures44', publicProcedures44)
        .state('publicProceduresSOC', publicProceduresSOC)
        .state('commercialProcedures', commercialProcedures)
        .state('otherProcedures', otherProcedures)
        .state('purchasePlan', purchasePlan)
        .state('purchasePlanAdmin', purchasePlanAdmin)
        .state('purchasePlanUpdate', purchasePlanUpdate)
        .state('purchasePlanUpdateAdmin', purchasePlanUpdateAdmin)
        .state('customEisPacket', customEisPacket)
        .state('explanationReqList', explanationReqList)
        .state('explanationList', explanationList)
        .state('bank', bank)
        .state('bg', bg)
        .state('lokobank', lokobank)
        .state('bankvtb', bankvtb)
        .state('raiffeisen', raiffeisen)
        .state('guarantees', guarantees)
        .state('bankVTBLanding', bankVTBLanding)
        .state('lokobg', lokobg)
        .state('vtbbg', vtbbg)
        .state('bankGuarantees', bankGuarantees)
        .state('bankGuaranteesAdmin', bankGuaranteesAdmin)
        .state('bgAgents', bgAgents)
        .state('openBgAgent', openBgAgent)
        .state('bankGuaranteeAppCreate', bankGuaranteeAppCreate)
        .state('bankGuaranteeAppOpen', bankGuaranteeAppOpen)
        .state('bankGuaranteePrimaryAppCreate', bankGuaranteePrimaryAppCreate)
        .state('bankGuaranteePrimaryAppOpen', bankGuaranteePrimaryAppOpen)
        .state('bankGuaranteeAppAgentCreate', bankGuaranteeAppAgentCreate)
        .state('bankGuaranteeAppAgentOpen', bankGuaranteeAppAgentOpen)
        .state('bankGuaranteePrimaryAppAgentCreate', bankGuaranteePrimaryAppAgentCreate)
        .state('bankGuaranteePrimaryAppAgentOpen', bankGuaranteePrimaryAppAgentOpen)
        .state('bankGuaranteeAppAgentCopy', bankGuaranteeAppAgentCopy)
        .state('bankGuaranteeChat', bankGuaranteeChat)
        .state('bankGuaranteeAppCopy', bankGuaranteeAppCopy)
        // Loko bg
        .state('bankGuaranteeAppLokoCreate', bankGuaranteeAppLokoCreate)
        .state('bankGuaranteeAppLokoOpen', bankGuaranteeAppLokoOpen)
        .state('bankGuaranteeAppLokoCopy', bankGuaranteeAppLokoCopy)
        .state('bankGuaranteeAppLokoAgentCreate', bankGuaranteeAppLokoAgentCreate)
        .state('bankGuaranteeAppLokoAgentOpen', bankGuaranteeAppLokoAgentOpen)
        .state('bankGuaranteeAppLokoAgentCopy', bankGuaranteeAppLokoAgentCopy)
        .state('bankGuaranteeLokoChat', bankGuaranteeLokoChat)

        .state('rfGuarantees', rfGuarantees)
        .state('rfGuaranteeAppCreate', rfGuaranteeAppCreate)
        .state('rfGuaranteeAppOpen', rfGuaranteeAppOpen)
        .state('rfGuaranteeAppCopy', rfGuaranteeAppCopy)
        // VTB bank
        .state('bankGuaranteesVTB', bankGuaranteesVTB)
        .state('bankGuaranteesVTBAdmin', bankGuaranteesVTBAdmin)
        .state('bankGuaranteeAppVTBCreate', bankGuaranteeAppVTBCreate)
        .state('bankGuaranteeAppVTBOpen', bankGuaranteeAppVTBOpen)
        .state('bankGuaranteeAppVTBAdminOpen', bankGuaranteeAppVTBAdminOpen)
        .state('bankGuaranteeAppVTBCopy', bankGuaranteeAppVTBCopy)
        .state('bankGuaranteeAppVTBAgentCreate', bankGuaranteeAppVTBAgentCreate)
        .state('bankGuaranteeAppVTBAgentOpen', bankGuaranteeAppVTBAgentOpen)
        .state('bankGuaranteeAppVTBAgentCopy', bankGuaranteeAppVTBAgentCopy)
        .state('bankGuaranteeVTBChat', bankGuaranteeVTBChat)

        .state('subscriptionTable', subscriptionTable)
        .state('subscription', subscription)
        .state('createSubscription', createSubscription)
        .state('createSubscriptionPLANNED', createSubscriptionPLANNED)

        .state('createReqExplanation', createReqExplanation)
        .state('openReqExplanation', openReqExplanation)
        .state('createAnswerExplanationFromReq', createAnswerExplanationFromReq)
        .state('createAnswerExplanationEmailFromReq', createAnswerExplanationEmailFromReq)
        .state('openAnswerExplanation', openAnswerExplanation)
        .state('answerReadyExplanation', answerReadyExplanation)
        .state('createExplanation', createExplanation)
        .state('openExplanation', openExplanation)

        .state('openProcedure', openProcedure)
        .state('openProcedureEIS', openProcedureEIS)
        .state('openPlanEIS', openPlanEIS)
        .state('participate', participate)
        .state('openParticipate', openParticipate)
        .state('viewParticipate', viewParticipate)

        .state('createOpeningProtocol', createOpeningProtocol)
        .state('createRatingFirstClaimPartsProtocol', createRatingFirstClaimPartsProtocol)
        .state('createRatingSecondClaimPartsProtocol', createRatingSecondClaimPartsProtocol)
        .state('createSummarizingProtocol', createSummarizingProtocol)
        .state('createEditPublishedProtocol', createEditPublishedProtocol)
        .state('createRebiddingNotice', createRebiddingNotice)
        .state('openProtocol', openProtocol)
        .state('openProtocolView', openProtocolView)

        .state('systemMessages', systemMessages)
        .state('protocolNotifications', protocolNotifications)
        .state('openMessage', openMessage)
        .state('openMessageNoRead', openMessageNoRead)
        .state('paymentOrders', paymentOrders)
        .state('paymentOrdersView', paymentOrderView)
        .state('paymentOrdersTable', paymentOrdersTab)

        .state('documents', documents)
        .state('video', video)

        .state('tabletLogin', tabletLogin)
        .state('webmarketLogin', webmarketLogin)

        .state('questionAnswer', questionAnswer)
        .state('questionAnswerCreate', questionAnswerCreate)
        .state('questionAnswerView', questionAnswerView)
        .state('questionAnswerHomeView', questionAnswerHomeView)
        .state('remoteSupport', remoteSupport)
        .state('documentSamples', documentSamples)
        .state('documentSamplesCreate', documentSamplesCreate)
        .state('questionAnswerTable', questionAnswerTable)
        .state('documentSamplesTable', documentSamplesTable)
        .state('acceleratedAccreditation', acceleratedAccreditation)
        .state('financialService', financialService)
        .state('tariffsHome', tariffsHome)
        .state('securingZmo', securingZmo)
        .state('kinoandpravo', kinoandpravo)
        .state('feedback', feedback)
        .state('feedbackSent', feedbackSent)

        .state('freshdoc', freshdoc)

        .state('advertisement', rekl)
        .state('privacy', privacy)

        .state('invoice', invoice)
        .state('invoiceView', invoiceView)
        .state('invoiceTable', invoiceTable)
        .state('completedWorksTable', completedWorksTable)
        .state('completedWorkView', completedWorkView)
        .state('statement', statement)
        .state('statementTable', statementTable)
        .state('informationAboutRejectProcedure', informationAboutRejectProcedure)
        .state('informationAboutRejectProcedureView', informationAboutRejectProcedureView)
        .state('noticePostponementProcedure', noticePostponementProcedure)
        .state('noticePostponementProcedureView', noticePostponementProcedureView)
        .state('createReqExplanationOrg', createReqExplanationOrg)
        .state('openReqExplanationOrg', openReqExplanationOrg)
        .state('createAnswerExplanationOrgFromReq', createAnswerExplanationOrgFromReq)
        .state('openAnswerExplanationOrg', openAnswerExplanationOrg)

        .state('notificationProtocol', notificationProtocol)
        .state('notificationProtocolView', notificationProtocolView)
        .state('returnClaimsProtocol', returnClaimsProtocol)
        .state('returnClaimsProtocolView', returnClaimsProtocolView)

        .state('informationAboutCancelLots', informationAboutCancelLots)
        .state('informationAboutCancelLotsView', informationAboutCancelLotsView)
        .state('additionalProtocol', additionalProtocol)
        .state('additionalProtocolView', additionalProtocolView)

        .state('confirmPayment', confirmPayment)
        .state('coveringLetterAcc', coveringLetterAcc)
        .state('coveringLetter', coveringLetter)

        .state('eisIntegrationProc', eisIntegrationProc)
        .state('eisIntegrationProcEdit', eisIntegrationProcEdit)

        .state('eisMonitoring', eisMonitoring)

        .state('eisAddMethod', eisAddMethod)

        .state('priceAnalysisTable', priceAnalysisTable)

        .state('services', services)

        //offers
        .state('offers', offers)
        .state('offersMy', myOffers)
        .state('positions', positions)
        .state('offers.view', offersView)
        .state('offers.create', offersCreate)

        //cart
        .state('cart', cart)
        // orders
        .state('orders', orders)
        .state('ordersMy', ordersMy)
        .state('orders.view', orderView)
        .state('orders.create', orderCreate)
        //history
        .state('docHistory', docHistory)
        .state('companyValidationTass', companyValidationTass)
        .state('companyViewTass', companyViewTass)
        .state('contract', contract)
        .state('contractPaper', contractPaper)
        .state('contractReject', contractReject)
        .state('factoring', factoring)

}]);
