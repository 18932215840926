/**
 * @author: sergeysi
 * Добавление/редактирование нового способа закупки ЕИС
 */
angular.module('uetp')
    .component('methodEditor', {
        bindings: {
            methodData: '<',
            customerData: '<'
        },
        templateUrl: 'app/components/customer/customerEisIntegration/methodEdit/methodEditor.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dictionarySrv', 'alertSrv', 'onlineCheckSrv', 'dialogSrv', 'needConfirmSrv','customerSrv',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dictionarySrv, alertSrv, onlineCheck, dialogSrv, needConfirmSrv, customerSrv) {
                var ctrl = this;
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.form = {methodForm: {}};
                    $scope.methodSaveError = null;
                    // TODO Добавить проверки пользователя и аутентификации!!!
                    $scope.authData = authSrv;
                    $scope.user = authSrv.getUser();
                    $scope.method = {};
                    $scope.defaultUserRole = null;
                    $scope.uetpCodeFullList = dictionarySrv.getStaticDictByType('purchaseMethod').filter(m=>!m.id.contains('smb') &&
                        !['offerplacement','saleauction','openpublicoffer','simplecontest'].includes(m.id)).sort(function(a, b) {
                            if(a['name']>b['name']) return 1;
                            else if(a['name']<b['name']) return -1;
                            else return 0;
                        });
                    if($stateParams.customerId){
                        if(!customerSrv.getUetpCodes() || customerSrv.getUetpCodes().length===0)
                            initMethodListFn();
                    }
                    if ($stateParams.id) {
                        $scope.method = ctrl.methodData.data.result;
                        let uetp = $scope.uetpCodeFullList.filter(c=>c.id===$scope.method.uetpCode)[0];
                        $scope.method.uetp = {code: uetp.id, name: uetp.name};
                        delete $scope.method.uetpCode;
                        delete $scope.method.uetpName;

                        $scope.changeMethod();
                    }

                    // Сформировать список Способов закупки КОМИТА ЭТП
                    $scope.uetpCodeList = getActualUetpCodeList();
                    $scope.customer = customerSrv.getCustomerInfo();

                    if ($stateParams.successSaveMsg) {
                        $scope.alertObjProfileMethodEdit = alertSrv.getSuccessMsg($stateParams.successSaveMsg);
                    }
                };

                function initMethodListFn() {
                    customerSrv.loadMethodList($stateParams.customerId).then(function successCallback(response) {
                        if (response.data.success) {
                            customerSrv.setUetpCodes(response.data.result ? response.data.result.map(m => m.uetpCode) : []);
                            $scope.uetpCodeList = getActualUetpCodeList();
                        } else {
                            customerSrv.setUetpCodes([]);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }
                
                $scope.saveOrUpdateMethod = saveOrUpdateMethodFn;
                $scope.goBack = goBackFn;
                $scope.cancel = cancelFn;
                $scope.protocolList = []
                $scope.rebiddingProtocolList = [];

                $scope.changeMethod = function() {
                    $scope.protocolList = [];
                    $scope.rebiddingProtocolList = [];
                    $scope.additionalProtocolList = [];
                    $scope.uetpCodeList = getActualUetpCodeList();
                    if($scope.method && $scope.method.uetp) {
                        $scope.protocolList = protocolListFn($scope.method);
                        $scope.rebiddingProtocolList = rebiddingProtocolListFn($scope.method);
                        $scope.additionalProtocolList = additionalProtocolListFn($scope.method);
                    }
                }

                function goBackFn() {
                    history.back();
                }

                function getActualUetpCodeList() {
                    return $scope.uetpCodeFullList.filter(el=>'limitedcontestqualification'!==el.id).map((el) => {
                        return {
                            code: el.id,
                            name: el.name
                        }
                    });
                }

                /**
                 * Добавления нового или обновление способа закупки
                 * @param method
                 */
                function saveOrUpdateMethodFn() {
                    if (!$scope.form.methodForm.$valid) {
                        $scope.alertObjProfileMethodEdit = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }
                    // Проверка протоколов

                    if($scope.hasProtocolErrors()){
                        $scope.alertObjProfileMethodEdit = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');
                        return;
                    }

                    const dataObj = angular.copy($scope.method);

                    dataObj.uetpCode = dataObj.uetp.code;
                    dataObj.uetpName = dataObj.uetp.name;
                    delete dataObj.uetp;
                    dataObj.customer = $scope.customer;
                    dataObj.protocols = getProtocols();

                    customerSrv.createOrUpdateMethod(dataObj).then(function successCallback(response) {
                        if(!response.data.success) {
                            $scope.alertObjProfileMethodEdit = alertSrv.getErrorMsg("При сохранении способа закупки произошла ошибка. Попробуйте выполнить запрос позднее или обратитесь в техническую поддержку.");
                        } else {
                            const successSaveMsg = dataObj.id ? 'Изменения успешно сохранены' : 'Способ закупки добавлен';
                            $scope.form.methodForm.$setPristine();
                            $scope.method.id = response.data.result;
                            $state.go('openMethod', {id: $scope.method.id, customerId: $stateParams.customerId, successSaveMsg: successSaveMsg}).then(function(){
                                $scope.alertObjProfileMethodEdit = alertSrv.getSuccessMsg(successSaveMsg);
                            });
                        }
                    }, function errorCallback(response) {
                        $scope.alertObjProfileMethodEdit = alertSrv.getAlertObj(response);
                        console.log(response);
                    });

                    function getProtocols() {
                        let result = [];
                        let list = $scope.protocolList;
                        if($scope.rebiddingProtocolList) list = list.concat($scope.rebiddingProtocolList);
                        if($scope.additionalProtocolList) list = list.concat($scope.additionalProtocolList);
                        angular.forEach(list, function(el){
                            if(el.eisCode && el.eisCode.length>0 && el.eisName && el.eisName.length>0)
                                if(el.uetpStage)
                                    result.push({id: el.id, uetpCode: el.uetpCode, uetpName: el.uetpName, eisCode: el.eisCode, eisName: el.eisName, uetpTemplate: el.uetpTemplate, uetpStage: el.uetpStage});
                                else
                                    result.push({id: el.id, uetpCode: el.uetpCode, uetpName: el.uetpName, eisCode: el.eisCode, eisName: el.eisName, uetpTemplate: el.uetpTemplate});
                        });
                        return result;
                    }
                }

                function cancelFn() {
                    if ($scope.form.methodForm.$dirty) {
                        needConfirmSrv.call(goBackFn, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        if($stateParams.customerId)
                            $state.go('customerView', {id: $stateParams.customerId});
                        else
                            $scope.goBack();
                    }
                }

                function goBackFn() {
                    $stateParams.customerId ? $state.go('customerView', {id: $stateParams.customerId}) : $scope.goBack();
                }
                // TODO убрать текст
                function protocolListFn(purchaseMethod) {
                    let result = [];
                    if(purchaseMethod) {
                        if(['opencontest', 'limitedcontest','requestquotations', 'limitedrequestquotations'].includes(purchaseMethod.uetp.code))
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения и оценки заявок", uetpCode: "PART1", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                                ]
                        else if(['openauction', 'limitedauction'].includes(purchaseMethod.uetp.code))
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения заявок", uetpCode: "PARTS", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол проведения аукциона", uetpCode: "REZ", uetpTemplate: 'other'})
                                ]
                        else if(['requestproposals', 'limitedrequestproposals'].includes(purchaseMethod.uetp.code))
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол запроса предложений", uetpCode: "PART1", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия окончательных предложений", uetpCode: "OPEN", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения и оценки окончательных предложений", uetpCode: "PART1", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                                ]
                        else if('open2stagecontest'===purchaseMethod.uetp.code)
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия первоначальных заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения первоначальных заявок", uetpCode: "PART1", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия окончательных заявок", uetpCode: "OPEN", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения и оценки окончательных заявок", uetpCode: "PART1", uetpStage: 'stage2', uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                                ]
                        else if('prequalification' === purchaseMethod.uetp.code)
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол вскрытия заявок", uetpCode: "OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения заявок", uetpCode: "PARTS", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                                ]
                        else if('unlimitedprequalification' === purchaseMethod.uetp.code)
                            result = [getStored(purchaseMethod, {uetpName: "Уведомление об итогах рассмотрения заявок", uetpCode: "PART1", uetpTemplate: 'other'})]
                        else if('limitedcontestqualification' === purchaseMethod.uetp.code)
                            result = [
                                getStored(purchaseMethod, {uetpName: "Протокол рассмотрения заявок", uetpCode: "PARTS", uetpTemplate: 'other'}),
                                getStored(purchaseMethod, {uetpName: "Протокол конкурсной комиссии", uetpCode: "REZ", uetpTemplate: 'other'})
                                ]
                    }

                    function getStored(purchaseMethod, defaultResult) {
                        if(purchaseMethod.protocols && purchaseMethod.protocols.length>0){
                            let protocol = defaultResult.uetpStage!==null && angular.isDefined(defaultResult.uetpStage) ? purchaseMethod.protocols.filter(p=>p.uetpCode===defaultResult.uetpCode && p.uetpStage===defaultResult.uetpStage) :
                                purchaseMethod.protocols.filter(p=>p.uetpCode===defaultResult.uetpCode);
                            if(protocol && protocol.length>0)
                                return protocol[0];
                        }
                        return defaultResult;
                    }

                    return result;
                }
                function rebiddingProtocolListFn(purchaseMethod) {
                    let result = [];
                    if(purchaseMethod) {
                        if(['opencontest', 'limitedcontest','requestquotations', 'limitedrequestquotations','requestproposals','limitedrequestproposals'].includes(purchaseMethod.uetp.code))
                            return [
                                getStored(purchaseMethod,{uetpName: "Уведомление о переторжке в режиме реального времени", uetpCode: "REBIDDING_NOTICE", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Уведомление о переторжке в заочной форме", uetpCode: "REBIDDING_NOTICE", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Протокол вскрытия переторжки в заочной форме", uetpCode: "REBIDDING_OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Протокол проведения переторжки в режиме реального времени", uetpCode: "REBIDDING_OPEN", uetpTemplate: 'other'}),
                                getStored(purchaseMethod,{uetpName: "Протокол рассмотрения и оценки заявок с учетом переторжек", uetpCode: "REBIDDING_PART1", uetpTemplate: 'other'})
                                ]
                    }

                    function getStored(purchaseMethod, defaultResult) {
                        if(purchaseMethod.protocols && purchaseMethod.protocols.length>0){
                            let protocol = purchaseMethod.protocols.filter(p=>p.uetpCode===defaultResult.uetpCode && p.uetpName===defaultResult.uetpName);
                            if(protocol && protocol.length>0) {
                                return protocol[0];
                            }
                        }
                        return defaultResult;
                    }

                    return result;
                }
                function additionalProtocolListFn(purchaseMethod) {
                    if(purchaseMethod) {
                        if(!['prequalification', 'unlimitedprequalification'].includes(purchaseMethod.uetp.code))
                            return [
                                getStored(purchaseMethod,{uetpName: "Протокол изменений условий договора", uetpCode: "CHANGE_CONTRACT_ADDITIONAL", uetpTemplate: 'other'})
                                ]
                    }

                    function getStored(purchaseMethod, defaultResult) {
                        if(purchaseMethod.protocols && purchaseMethod.protocols.length>0){
                            let protocol = purchaseMethod.protocols.filter(p=>p.uetpCode===defaultResult.uetpCode && p.uetpName===defaultResult.uetpName);
                            if(protocol && protocol.length>0) {
                                return protocol[0];
                            }
                        }
                        return defaultResult;
                    }

                    return [];
                }

                $scope.methodCodeFilter = function(el){
                    return $scope.user.id!= el.id;
                }
                /*
                Проверка заполненности пары значений
                 */
                $scope.hasNoPair = function(val1, val2) {
                    return val1.$modelValue && !val2.$modelValue;
                }
                $scope.hasProtocolErrors = function () {
                    return ($scope.protocolList.filter(function(p, index){
                        return checkPair($scope.form.methodForm['eisCode_'+index].$modelValue, $scope.form.methodForm['eisName_'+index].$modelValue);
                    }) || []).length>0 || ($scope.rebiddingProtocolList.filter(function(p, index){
                        return checkPair($scope.form.methodForm['eisCode_rebidding_'+index].$modelValue, $scope.form.methodForm['eisName_rebidding_'+index].$modelValue);
                    }) || []).length>0 || ($scope.additionalProtocolList.filter(function(p, index){
                        return checkPair($scope.form.methodForm['eisCode_additional_'+index].$modelValue, $scope.form.methodForm['eisName_additional_'+index].$modelValue);
                    }) || []).length>0;

                    function checkPair(val1, val2){
                        return ((val1) && !(val2)) || ((val2) && !(val1));
                    }
                }
                $scope.hasAdditionalProtocols = function(uetp){
                    return uetp && !['prequalification', 'unlimitedprequalification'].includes(uetp.code);
                }
            }]

    });

