import _0 from './client/components/about/about.html';
import _1 from './client/components/bank/bank.html';
import _2 from './client/components/bankGuaranteeApp/bankGuaranteeApp.html';
import _3 from './client/components/bankGuaranteeApp/formCreator/bails.html';
import _4 from './client/components/bankGuaranteeApp/formCreator/bailsCompany.html';
import _5 from './client/components/bankGuaranteeApp/formCreator/bailsPerson.html';
import _6 from './client/components/bankGuaranteeApp/formCreator/bankGuaranteeFormCreator.html';
import _7 from './client/components/bankGuaranteeApp/formCreator/beneficiaries.html';
import _8 from './client/components/bankGuaranteeApp/formCreator/beneficiariesLoko.html';
import _9 from './client/components/bankGuaranteeApp/formCreator/collegialExecutiveBodyMember.html';
import _10 from './client/components/bankGuaranteeApp/formCreator/companyBeneficiaries.html';
import _11 from './client/components/bankGuaranteeApp/formCreator/companyBeneficiariesLoko.html';
import _12 from './client/components/bankGuaranteeApp/formCreator/companyBeneficiariesVTB.html';
import _13 from './client/components/bankGuaranteeApp/formCreator/founders.html';
import _14 from './client/components/bankGuaranteeApp/formCreator/foundersCompanies.html';
import _15 from './client/components/bankGuaranteeApp/formCreator/foundersCompaniesLoko.html';
import _16 from './client/components/bankGuaranteeApp/formCreator/foundersCompaniesVTB.html';
import _17 from './client/components/bankGuaranteeApp/formCreator/foundersLoko.html';
import _18 from './client/components/bankGuaranteeApp/formCreator/foundersPersons.html';
import _19 from './client/components/bankGuaranteeApp/formCreator/foundersPersonsLoko.html';
import _20 from './client/components/bankGuaranteeApp/formCreator/foundersPersonsVTB.html';
import _21 from './client/components/bankGuaranteeApp/formCreator/foundersVTB.html';
import _22 from './client/components/bankGuaranteeApp/formCreator/liabilities.html';
import _23 from './client/components/bankGuaranteeApp/formCreator/mainCustomers.html';
import _24 from './client/components/bankGuaranteeApp/formCreator/mainProviders.html';
import _25 from './client/components/bankGuaranteeApp/formCreator/paymentPlan.html';
import _26 from './client/components/bankGuaranteeApp/formCreator/principalBuhPeriods.html';
import _27 from './client/components/bankGuaranteeApp/formCreator/supervisoryBoardMember.html';
import _28 from './client/components/bankGuaranteeApp/tab/accountant.html';
import _29 from './client/components/bankGuaranteeApp/tab/bankGuarantee.html';
import _30 from './client/components/bankGuaranteeApp/tab/companyBeneficiaries.html';
import _31 from './client/components/bankGuaranteeApp/tab/companyHead.html';
import _32 from './client/components/bankGuaranteeApp/tab/companyInfo.html';
import _33 from './client/components/bankGuaranteeApp/tab/companyManagement.html';
import _34 from './client/components/bankGuaranteeApp/tab/contactPerson.html';
import _35 from './client/components/bankGuaranteeApp/tab/documents.html';
import _36 from './client/components/bankGuaranteeApp/tab/founders.html';
import _37 from './client/components/bankGuaranteeApp/tab/liabilities.html';
import _38 from './client/components/bankGuaranteeApp/tab/mainCustomersProviders.html';
import _39 from './client/components/bankGuaranteeApp/tab/mainInfo.html';
import _40 from './client/components/bankGuaranteeApp/tab/principalBuh.html';
import _41 from './client/components/bankGuaranteeApp/tab/principalSigner.html';
import _42 from './client/components/bankGuaranteeApp/tab/staffInfo.html';
import _43 from './client/components/bankGuaranteeApp/tabPrimary/bankGuarantee.html';
import _44 from './client/components/bankGuaranteeApp/tabPrimary/mainInfo.html';
import _45 from './client/components/bankGuaranteeAppLoko/bankGuaranteeAppLoko.html';
import _46 from './client/components/bankGuaranteeAppLoko/tab/additionalInfo.html';
import _47 from './client/components/bankGuaranteeAppLoko/tab/agreement.html';
import _48 from './client/components/bankGuaranteeAppLoko/tab/bankGuarantee.html';
import _49 from './client/components/bankGuaranteeAppLoko/tab/companyBeneficiaries.html';
import _50 from './client/components/bankGuaranteeAppLoko/tab/companyHead.html';
import _51 from './client/components/bankGuaranteeAppLoko/tab/companyManagement.html';
import _52 from './client/components/bankGuaranteeAppLoko/tab/contactPerson.html';
import _53 from './client/components/bankGuaranteeAppLoko/tab/documents.html';
import _54 from './client/components/bankGuaranteeAppLoko/tab/founders.html';
import _55 from './client/components/bankGuaranteeAppLoko/tab/mainInfo.html';
import _56 from './client/components/bankGuaranteeAppLoko/tab/staffInfo.html';
import _57 from './client/components/bankGuaranteeAppVTB/bankGuaranteeAppVTB.html';
import _58 from './client/components/bankGuaranteeAppVTB/tab/accountant.html';
import _59 from './client/components/bankGuaranteeAppVTB/tab/bankGuarantee.html';
import _60 from './client/components/bankGuaranteeAppVTB/tab/companyBeneficiaries.html';
import _61 from './client/components/bankGuaranteeAppVTB/tab/companyHead.html';
import _62 from './client/components/bankGuaranteeAppVTB/tab/companyInfo.html';
import _63 from './client/components/bankGuaranteeAppVTB/tab/companyManagement.html';
import _64 from './client/components/bankGuaranteeAppVTB/tab/contactPerson.html';
import _65 from './client/components/bankGuaranteeAppVTB/tab/documents.html';
import _66 from './client/components/bankGuaranteeAppVTB/tab/founders.html';
import _67 from './client/components/bankGuaranteeAppVTB/tab/liabilities.html';
import _68 from './client/components/bankGuaranteeAppVTB/tab/mainCustomersProviders.html';
import _69 from './client/components/bankGuaranteeAppVTB/tab/mainInfo.html';
import _70 from './client/components/bankGuaranteeAppVTB/tab/principalBuh.html';
import _71 from './client/components/bankGuaranteeAppVTB/tab/principalSigner.html';
import _72 from './client/components/bankGuaranteeAppVTB/tab/staffInfo.html';
import _73 from './client/components/bankGuaranteeCalc/bankGuaranteeCalc.html';
import _74 from './client/components/bankGuaranteeChat/bankGuaranteeChat.html';
import _75 from './client/components/bankGuaranteeLokoChat/bankGuaranteeLokoChat.html';
import _76 from './client/components/bankGuaranteeVTBChat/bankGuaranteeVTBChat.html';
import _77 from './client/components/bankGuarantees/bankGuarantees.html';
import _78 from './client/components/bankLoko/bankLoko.html';
import _79 from './client/components/bankRaiffeisen/bankRaiffeisen.html';
import _80 from './client/components/bankVTBLanding/bankVTBLanding.html';
import _81 from './client/components/becomeCustomer/becomeCustomer_etp.html';
import _82 from './client/components/becomeCustomer/becomeCustomer_ppk.html';
import _83 from './client/components/becomeSupplier/becomeSupplier_etp.html';
import _84 from './client/components/bgAgent/becomeBgAgent/becomeBgAgent.html';
import _85 from './client/components/bgAgent/bgAdvertising/bgAdvertising.html';
import _86 from './client/components/bgAgent/bgAgent/agentOrg/agentEdit/agentEditor.html';
import _87 from './client/components/bgAgent/bgAgent/agentOrg/agentList.html';
import _88 from './client/components/bgAgent/bgAgent/agentOrg/agents.html';
import _89 from './client/components/bgAgent/bgAgent/bgAgent.html';
import _90 from './client/components/bgAgent/bgAgent/reqOrganizator/reqOrganizator.html';
import _91 from './client/components/bgAgent/consent/bgAgents.html';
import _92 from './client/components/bgBank/bgBank/bgBank.html';
import _93 from './client/components/bgBank/bgBank/bgBankAgents/bgBankAgentEditor/bgBankAgentEditor.html';
import _94 from './client/components/bgBank/bgBank/bgBankAgents/bgBankAgents.html';
import _95 from './client/components/bgBank/bgBank/bgBankReq/bgBankReq.html';
import _96 from './client/components/bgParticipants/bgParticipants.html';
import _97 from './client/components/bgParticipants/limit/limitList.html';
import _98 from './client/components/bgParticipants/order/orderList.html';
import _99 from './client/components/bgParticipants/order/orderListLoko.html';
import _100 from './client/components/bgParticipants/order/orderListRaif.html';
import _101 from './client/components/bgParticipants/order/orderListVtb.html';
import _102 from './client/components/cart/views/cart.html';
import _103 from './client/components/cart/views/cartCounter.html';
import _104 from './client/components/completedWorks/completedWork.html';
import _105 from './client/components/completedWorks/table/completedWorksTable.html';
import _106 from './client/components/contacts/contacts_etp.html';
import _107 from './client/components/contacts/contacts_ppk.html';
import _108 from './client/components/coveringLetter/coveringLetter.html';
import _109 from './client/components/customEisPacket/customEisPacket.html';
import _110 from './client/components/customer/customer.html';
import _111 from './client/components/customer/customerEisIntegration/branches/branchEditor.html';
import _112 from './client/components/customer/customerEisIntegration/customerBranches.html';
import _113 from './client/components/customer/customerEisIntegration/customerEisIntegration.html';
import _114 from './client/components/customer/customerEisIntegration/methodEdit/methodEditor.html';
import _115 from './client/components/customer/customerRequisites/customerRequisites.html';
import _116 from './client/components/customer/departmentEdit/departmentEdit.html';
import _117 from './client/components/customer/functionCustomerEdit/functionCustomerEdit.html';
import _118 from './client/components/customer/registration/customerRegistration.html';
import _119 from './client/components/customer/registration/customerRegistrationForm.html';
import _120 from './client/components/customer/registration/customerRegistrationOpen.html';
import _121 from './client/components/docHistory/docHistoryView.html';
import _122 from './client/components/documents/documents.html';
import _123 from './client/components/documents/documents_etp.html';
import _124 from './client/components/documents/documents_ppk.html';
import _125 from './client/components/explanation/answer/answerExplanation.html';
import _126 from './client/components/explanation/answer/answerReady/answerReady.html';
import _127 from './client/components/explanation/email/answerExplanationEmail.html';
import _128 from './client/components/explanation/request/reqExplanation.html';
import _129 from './client/components/explanation/request/reqExplanationForCbz.html';
import _130 from './client/components/explanationOrg/answer/answerExplanationOrg.html';
import _131 from './client/components/explanationOrg/request/reqExplanationOrg.html';
import _132 from './client/components/factoring/factoring.html';
import _133 from './client/components/fas/fasArchive.html';
import _134 from './client/components/fas/fasListArchive/fasListArchive.html';
import _135 from './client/components/feedbackForm/views/becomeCustomer.html';
import _136 from './client/components/feedbackForm/views/contacts.html';
import _137 from './client/components/feedbackForm/views/createBankGuaranteeApp.html';
import _138 from './client/components/feedbackForm/views/feedback.html';
import _139 from './client/components/feedbackForm/views/feedbackSent.html';
import _140 from './client/components/feedbackForm/views/guarantees.html';
import _141 from './client/components/feedbackForm/views/kinoandpravo.html';
import _142 from './client/components/filter/searchFilter.html';
import _143 from './client/components/filter/viewsFilter/filter.html';
import _144 from './client/components/filter/viewsFilter/filterAccreditation.html';
import _145 from './client/components/filter/viewsFilter/filterBgLimitParticipant.html';
import _146 from './client/components/filter/viewsFilter/filterCabProcedures.html';
import _147 from './client/components/filter/viewsFilter/filterCompanies.html';
import _148 from './client/components/filter/viewsFilter/filterCompanyValidateContracts.html';
import _149 from './client/components/filter/viewsFilter/filterCompletedWorks.html';
import _150 from './client/components/filter/viewsFilter/filterCustomer.html';
import _151 from './client/components/filter/viewsFilter/filterCustomerRegistration.html';
import _152 from './client/components/filter/viewsFilter/filterEisIntegrationProc.html';
import _153 from './client/components/filter/viewsFilter/filterEisMonitoring.html';
import _154 from './client/components/filter/viewsFilter/filterExplanationRequests.html';
import _155 from './client/components/filter/viewsFilter/filterInvoice.html';
import _156 from './client/components/filter/viewsFilter/filterMyOffers.html';
import _157 from './client/components/filter/viewsFilter/filterNotices.html';
import _158 from './client/components/filter/viewsFilter/filterOffers.html';
import _159 from './client/components/filter/viewsFilter/filterOrder.html';
import _160 from './client/components/filter/viewsFilter/filterOrganization.html';
import _161 from './client/components/filter/viewsFilter/filterOrganizator.html';
import _162 from './client/components/filter/viewsFilter/filterParticipant.html';
import _163 from './client/components/filter/viewsFilter/filterParticipateInPurchases.html';
import _164 from './client/components/filter/viewsFilter/filterPaymentOrder.html';
import _165 from './client/components/filter/viewsFilter/filterPosition.html';
import _166 from './client/components/filter/viewsFilter/filterPriceAnalysis.html';
import _167 from './client/components/filter/viewsFilter/filterProcedures.html';
import _168 from './client/components/filter/viewsFilter/filterProcedures44.html';
import _169 from './client/components/filter/viewsFilter/filterProceduresAll.html';
import _170 from './client/components/filter/viewsFilter/filterProceduresOther.html';
import _171 from './client/components/filter/viewsFilter/filterProceduresSOC.html';
import _172 from './client/components/filter/viewsFilter/filterPublicParticipant.html';
import _173 from './client/components/filter/viewsFilter/filterPurchasePlan.html';
import _174 from './client/components/filter/viewsFilter/filterQuestionAnswer.html';
import _175 from './client/components/filter/viewsFilter/filterStatement.html';
import _176 from './client/components/filter/viewsFilter/filterUC.html';
import _177 from './client/components/footer/etp.html';
import _178 from './client/components/footer/footer.html';
import _179 from './client/components/footer/ppk.html';
import _180 from './client/components/header/cabinet/cabinet.html';
import _181 from './client/components/header/cabinet/etp.html';
import _182 from './client/components/header/cabinet/ppk.html';
import _183 from './client/components/header/headerMsg/headerMsg.html';
import _184 from './client/components/header/headerToolbar.html';
import _185 from './client/components/header/headerViews/dialogTemplates.html';
import _186 from './client/components/header/headerViews/etp.html';
import _187 from './client/components/header/headerViews/ppk.html';
import _188 from './client/components/help/documentSamples/documentSamplesCreate.html';
import _189 from './client/components/help/questionAnswer/questionAnswerCreate.html';
import _190 from './client/components/help/questionAnswer/questionAnswerHomeView.html';
import _191 from './client/components/help/remoteSupport/remoteSupport.html';
import _192 from './client/components/home/etp.html';
import _193 from './client/components/home/ppk.html';
import _194 from './client/components/invoice/invoice.html';
import _195 from './client/components/invoice/table/invoiceTable.html';
import _196 from './client/components/invoiceRequestForm/invoiceRequestForm.html';
import _197 from './client/components/lk/lk.html';
import _198 from './client/components/lk/lkinfo/etp.html';
import _199 from './client/components/lk/lkinfo/lkinfo-etp.html';
import _200 from './client/components/lk/lkinfo/ppk.html';
import _201 from './client/components/lk/notification/lknotification.html';
import _202 from './client/components/lk/notification/lknotificationmodal.html';
import _203 from './client/components/login/loginPanel.html';
import _204 from './client/components/login/loginPanelMobile.html';
import _205 from './client/components/login/webmarketLogin.html';
import _206 from './client/components/mailingLists/mailingLists.html';
import _207 from './client/components/news/homeNews/homeNews.html';
import _208 from './client/components/news/homeNews/homeNewsPpk.html';
import _209 from './client/components/news/homeNews/topNewsPpk.html';
import _210 from './client/components/news/news.html';
import _211 from './client/components/news/newsBlock/newsBlock.html';
import _212 from './client/components/news/newsCreate/newsCreate.html';
import _213 from './client/components/newsletters/newsletterCreate/newsletterCreate.html';
import _214 from './client/components/notice/documentation/documentation.html';
import _215 from './client/components/notice/extendNoticeField/extendNoticeField.html';
import _216 from './client/components/notice/lot/depositPaymentDetails.html';
import _217 from './client/components/notice/lot/lot.html';
import _218 from './client/components/notice/lots/lots.html';
import _219 from './client/components/notice/nomenclature/nomenclature.html';
import _220 from './client/components/notice/notice.html';
import _221 from './client/components/notice/procedure/procedure.html';
import _222 from './client/components/notification/notificationForm/notificationPURCHASE_REJECTION.html';
import _223 from './client/components/notification/notificationForm/notificationTRANSFER_TENDERS.html';
import _224 from './client/components/offer/views/offerCreate.html';
import _225 from './client/components/offer/views/offerOpen.html';
import _226 from './client/components/offer/views/offerView.html';
import _227 from './client/components/order/views/orderCreate.html';
import _228 from './client/components/order/views/orderOpen.html';
import _229 from './client/components/order/views/orderView.html';
import _230 from './client/components/order/views/positions.html';
import _231 from './client/components/organizator/agentOrg/agentEdit/agentEditor.html';
import _232 from './client/components/organizator/agentOrg/agentList.html';
import _233 from './client/components/organizator/agentOrg/agents.html';
import _234 from './client/components/organizator/customersOrg/customersOrg.html';
import _235 from './client/components/organizator/organizator.html';
import _236 from './client/components/organizator/reqOrganizator/reqOrganizator.html';
import _237 from './client/components/pagination/pagination.html';
import _238 from './client/components/participant/participant.html';
import _239 from './client/components/participant/participantAccreditation/accreditationList.html';
import _240 from './client/components/participant/participantAgents/participantAgentView/participantAgentView.html';
import _241 from './client/components/participant/participantAgents/participantAgents.html';
import _242 from './client/components/participant/participantRequisites/participantRequisites.html';
import _243 from './client/components/participate/msp223/participate.html';
import _244 from './client/components/participate/participate.html';
import _245 from './client/components/participationInPurchases/participationInPurchases.html';
import _246 from './client/components/paymentOrder/confirmPayment/confirmPayment.html';
import _247 from './client/components/paymentOrder/paymentOrder.html';
import _248 from './client/components/paymentOrder/paymentOrderOperations.html';
import _249 from './client/components/paymentOrder/processingForm/linkedInvoice.html';
import _250 from './client/components/paymentOrder/table/paymentOrdersTable.html';
import _251 from './client/components/paymentOrder/tips.html';
import _252 from './client/components/placeholderCards/placeholderCards.html';
import _253 from './client/components/position/positionOffer.html';
import _254 from './client/components/positionElement/positionElement.html';
import _255 from './client/components/positionsImg/positionsImg.html';
import _256 from './client/components/preview/preview.html';
import _257 from './client/components/preview/previewModal.html';
import _258 from './client/components/privacy/privacy.html';
import _259 from './client/components/procedures/auction/auction.html';
import _260 from './client/components/procedures/cancelLots/informationAboutCancelLots.html';
import _261 from './client/components/procedures/claims/claims.html';
import _262 from './client/components/procedures/contract/contract.html';
import _263 from './client/components/procedures/contract/contractPaper.html';
import _264 from './client/components/procedures/contract/contractReject.html';
import _265 from './client/components/procedures/explanations/explanations.html';
import _266 from './client/components/procedures/noticePostponement/noticePostponementProcedure.html';
import _267 from './client/components/procedures/priceOffers/priceOffers.html';
import _268 from './client/components/procedures/protocol/additional/additionalProtocol.html';
import _269 from './client/components/procedures/protocol/notificationProtocol.html';
import _270 from './client/components/procedures/protocol/protocol.html';
import _271 from './client/components/procedures/protocol/protocolForCbz.html';
import _272 from './client/components/procedures/rebiddings/rebiddings.html';
import _273 from './client/components/procedures/reject/informationAboutRejectProcedure.html';
import _274 from './client/components/procedures/reqExplanations/reqExplanations.html';
import _275 from './client/components/procedures/reqExplanations/reqExplanationsForCbz.html';
import _276 from './client/components/procedures/reqExplanationsOrg/reqExplanationsOrg.html';
import _277 from './client/components/procedures/returnClaims/returnClaims.html';
import _278 from './client/components/procedures/statistics/statistics.html';
import _279 from './client/components/procedures/statistics/statisticsViews.html';
import _280 from './client/components/procedures/views/procedures.html';
import _281 from './client/components/procedures/views/procedures44.html';
import _282 from './client/components/procedures/views/proceduresForCbz.html';
import _283 from './client/components/proceduresEIS/eisAddMethod/addEisMethod.html';
import _284 from './client/components/proceduresEIS/eisIntegrationProcEdit/eisIntegrationProcEdit.html';
import _285 from './client/components/proceduresEIS/procedures.html';
import _286 from './client/components/profile/accreditation/accelerationAccreditation/accelerationAccreditation.html';
import _287 from './client/components/profile/accreditation/accreditation.html';
import _288 from './client/components/profile/accreditation/accreditationEdit/accreditation.html';
import _289 from './client/components/profile/accreditation/accreditationList.html';
import _290 from './client/components/profile/actions/actionsProfile.html';
import _291 from './client/components/profile/actions/cabType/actionsOrganizerCab.html';
import _292 from './client/components/profile/agents/agentEdit/agentEditor.html';
import _293 from './client/components/profile/agents/agentList.html';
import _294 from './client/components/profile/agents/agents.html';
import _295 from './client/components/profile/customers/customersProfile.html';
import _296 from './client/components/profile/filiation/filiation.html';
import _297 from './client/components/profile/filiation/filiationEdit/filiationEditor.html';
import _298 from './client/components/profile/filiation/filiationList.html';
import _299 from './client/components/profile/personalAccount/applicationRefund.html';
import _300 from './client/components/profile/personalAccount/cashFlowInfo.html';
import _301 from './client/components/profile/personalAccount/operationAccountTable.html';
import _302 from './client/components/profile/personalAccount/personalAccount.html';
import _303 from './client/components/profile/personalAccount/revise.html';
import _304 from './client/components/profile/personalAccount/view/personalAccountAccountant.html';
import _305 from './client/components/profile/personalAccount/view/personalAccountDefault.html';
import _306 from './client/components/profile/personalAccount/withdrawalOfFunds.html';
import _307 from './client/components/profile/profile.html';
import _308 from './client/components/profile/pwdChange/pwdChange.html';
import _309 from './client/components/profile/requisites/requisites.html';
import _310 from './client/components/profile/requisites/requisitionCabType/requisitesAccountant.html';
import _311 from './client/components/profile/requisites/requisitionCabType/requisitesAdminCab.html';
import _312 from './client/components/profile/requisites/requisitionCabType/requisitesAgent.html';
import _313 from './client/components/profile/requisites/requisitionCabType/requisitesInspector.html';
import _314 from './client/components/profile/requisites/requisitionCabType/requisitesOrganizerCab.html';
import _315 from './client/components/profile/requisites/requisitionCabType/requisitesParticipant.html';
import _316 from './client/components/profile/userInfo/userInfo.html';
import _317 from './client/components/profile/versionInfo/versionInfo.html';
import _318 from './client/components/protocol/msp223/protocolADDITIONAL.html';
import _319 from './client/components/protocol/msp223/protocolOPEN.html';
import _320 from './client/components/protocol/msp223/protocolPART1.html';
import _321 from './client/components/protocol/msp223/protocolPART2.html';
import _322 from './client/components/protocol/msp223/protocolPARTS.html';
import _323 from './client/components/protocol/msp223/protocolREBIDDING_NOTICE.html';
import _324 from './client/components/protocol/msp223/protocolREZ.html';
import _325 from './client/components/protocol/protocol.html';
import _326 from './client/components/protocol/protocolAll.html';
import _327 from './client/components/protocol/protocolAuction.html';
import _328 from './client/components/purchasePlanUpdate/purchasePlanUpdate.html';
import _329 from './client/components/regionSelector/regionSelector.html';
import _330 from './client/components/registration/registration.html';
import _331 from './client/components/rekl/rekl.html';
import _332 from './client/components/reports/accountTypes.html';
import _333 from './client/components/reports/analyticReports.html';
import _334 from './client/components/reports/data.html';
import _335 from './client/components/reports/orgs.html';
import _336 from './client/components/reports/period.html';
import _337 from './client/components/reports/periodDates.html';
import _338 from './client/components/reports/procTypes.html';
import _339 from './client/components/reports/reportsLokoBank/reportsLokoBank.html';
import _340 from './client/components/reports/types/averageSavingReport.html';
import _341 from './client/components/reports/types/balanceReport.html';
import _342 from './client/components/reports/types/blockingReport.html';
import _343 from './client/components/reports/types/customersReport.html';
import _344 from './client/components/reports/types/declaredLotsReport.html';
import _345 from './client/components/reports/types/openInvoiceReport.html';
import _346 from './client/components/reports/types/participantsReport.html';
import _347 from './client/components/reports/types/paymentOrdersReport.html';
import _348 from './client/components/reports/types/postedInfoReport.html';
import _349 from './client/components/reports/types/ppkTariffReport.html';
import _350 from './client/components/reports/types/socAtcomReport.html';
import _351 from './client/components/reports/types/tariffUsageReport.html';
import _352 from './client/components/reports/types/tariffsReport.html';
import _353 from './client/components/restorePassword/restorePassword.html';
import _354 from './client/components/rfGuaranteeApp/rfGuaranteeApp.html';
import _355 from './client/components/servicesPage/servicesPage.html';
import _356 from './client/components/servicesPage/servicesPageAgent.html';
import _357 from './client/components/startPageSearch/etp.html';
import _358 from './client/components/startPageSearch/ppk.html';
import _359 from './client/components/statement/table/statementTable.html';
import _360 from './client/components/subscription/subscription.html';
import _361 from './client/components/systemMessage/counter.html';
import _362 from './client/components/systemMessage/explreqs.html';
import _363 from './client/components/systemMessage/systemMessage.html';
import _364 from './client/components/tariffsServices/accelAccred/accelAccred_etp.html';
import _365 from './client/components/tariffsServices/accelAccred/accelAccred_ppk.html';
import _366 from './client/components/tariffsServices/financialService.html';
import _367 from './client/components/tariffsServices/securingZmo/securingZmo.html';
import _368 from './client/components/tariffsServices/tariffs/tariffsHomePage_etp.html';
import _369 from './client/components/tariffsServices/tariffs/tariffsHomePage_ppk.html';
import _370 from './client/components/tariffsServices/tariffs/tariffsMenu.html';
import _371 from './client/components/tariffsServices/tariffs/tariffsProfile_etp.html';
import _372 from './client/components/tariffsServices/tariffs/tariffsProfile_ppk.html';
import _373 from './client/components/tender/tender.html';
import _374 from './client/components/versionInfos/versionCreate/versionCreate.html';
import _375 from './client/components/video/video.html';
import _376 from './client/components/widgets/tassBusiness/companyValidate/view/baseView.html';
import _377 from './client/components/widgets/tassBusiness/companyValidate/view/blockedAccounts/blockedAccounts.html';
import _378 from './client/components/widgets/tassBusiness/companyValidate/view/contracts/contracts.html';
import _379 from './client/components/widgets/tassBusiness/companyValidate/view/contracts/template/FZ-223.html';
import _380 from './client/components/widgets/tassBusiness/companyValidate/view/contracts/template/FZ-44.html';
import _381 from './client/components/widgets/tassBusiness/companyValidate/view/customers/customers.html';
import _382 from './client/components/widgets/tassBusiness/companyValidate/view/finReport/finReport.html';
import _383 from './client/components/widgets/tassBusiness/companyValidate/view/providers/providers.html';
import _384 from './client/components/widgets/tassBusiness/companyValidate/view/reporting/reporting.html';
import _385 from './client/components/widgets/tassBusiness/companyValidate/view/taxes/taxes.html';
import _386 from './client/components/widgets/tassBusiness/companyValidate/view/verification/elementVerification/elementVerification.html';
import _387 from './client/components/widgets/tassBusiness/companyValidate/view/verification/verification.html';
import _388 from './client/controllers/restorePassword/changePassword.html';
import _389 from './client/controllers/restorePassword/restorePassword.html';
import _390 from './client/controllers/tables/viewsTable/accreditation.html';
import _391 from './client/controllers/tables/viewsTable/allProcedures.html';
import _392 from './client/controllers/tables/viewsTable/bankGuarantees.html';
import _393 from './client/controllers/tables/viewsTable/bankGuaranteesLoko.html';
import _394 from './client/controllers/tables/viewsTable/bankGuaranteesVTB.html';
import _395 from './client/controllers/tables/viewsTable/bgAgents.html';
import _396 from './client/controllers/tables/viewsTable/bgBanks.html';
import _397 from './client/controllers/tables/viewsTable/commercialProcedures.html';
import _398 from './client/controllers/tables/viewsTable/customer.html';
import _399 from './client/controllers/tables/viewsTable/customerRegistrationTable.html';
import _400 from './client/controllers/tables/viewsTable/documentSamplesHomeTable.html';
import _401 from './client/controllers/tables/viewsTable/documentSamplesTable.html';
import _402 from './client/controllers/tables/viewsTable/eisIntegrationsProc.html';
import _403 from './client/controllers/tables/viewsTable/eisMonitoring.html';
import _404 from './client/controllers/tables/viewsTable/explanationList.html';
import _405 from './client/controllers/tables/viewsTable/explanationReqList.html';
import _406 from './client/controllers/tables/viewsTable/getds.html';
import _407 from './client/controllers/tables/viewsTable/invoiceTable.html';
import _408 from './client/controllers/tables/viewsTable/jointProcedures.html';
import _409 from './client/controllers/tables/viewsTable/myProcedures.html';
import _410 from './client/controllers/tables/viewsTable/newsTable.html';
import _411 from './client/controllers/tables/viewsTable/newsletters.html';
import _412 from './client/controllers/tables/viewsTable/notices.html';
import _413 from './client/controllers/tables/viewsTable/offers.html';
import _414 from './client/controllers/tables/viewsTable/offersMy.html';
import _415 from './client/controllers/tables/viewsTable/orders.html';
import _416 from './client/controllers/tables/viewsTable/ordersMy.html';
import _417 from './client/controllers/tables/viewsTable/organizators.html';
import _418 from './client/controllers/tables/viewsTable/otherProcedures.html';
import _419 from './client/controllers/tables/viewsTable/participants.html';
import _420 from './client/controllers/tables/viewsTable/paymentOrders.html';
import _421 from './client/controllers/tables/viewsTable/positions.html';
import _422 from './client/controllers/tables/viewsTable/positionsImg.html';
import _423 from './client/controllers/tables/viewsTable/positions_old.html';
import _424 from './client/controllers/tables/viewsTable/priceAnalysisSubTable.html';
import _425 from './client/controllers/tables/viewsTable/priceAnalysisTable.html';
import _426 from './client/controllers/tables/viewsTable/procedures.html';
import _427 from './client/controllers/tables/viewsTable/protocolNotifications.html';
import _428 from './client/controllers/tables/viewsTable/publicParticipant.html';
import _429 from './client/controllers/tables/viewsTable/publicProcedures.html';
import _430 from './client/controllers/tables/viewsTable/publicProcedures44.html';
import _431 from './client/controllers/tables/viewsTable/publicProceduresSOC.html';
import _432 from './client/controllers/tables/viewsTable/purchasePlan.html';
import _433 from './client/controllers/tables/viewsTable/questionAnswerHomeTable.html';
import _434 from './client/controllers/tables/viewsTable/questionAnswerTable.html';
import _435 from './client/controllers/tables/viewsTable/rfGuarantees.html';
import _436 from './client/controllers/tables/viewsTable/specialAccount.html';
import _437 from './client/controllers/tables/viewsTable/subscriptionTable.html';
import _438 from './client/controllers/tables/viewsTable/systemMessages.html';
import _439 from './client/controllers/tables/viewsTable/uc.html';
import _440 from './client/controllers/tables/viewsTable/versionTable.html';
import _441 from './client/controllers/uc/uc-PAK-table.html';
import _442 from './client/controllers/uc/uc-base.html';
import _443 from './client/controllers/uc/uc-infoAUC-edit.html';
import _444 from './client/directives/actionsBtn/actionsBtn.html';
import _445 from './client/directives/backBtn/backBtn.html';
import _446 from './client/directives/bunkGuarantees/bunkGuarantees.html';
import _447 from './client/directives/carouselPurchase/carouselPurchase.html';
import _448 from './client/directives/counter300Years/counter300Years.html';
import _449 from './client/directives/dragdropPanel/dragdropPanel.html';
import _450 from './client/directives/extendField/extendField.html';
import _451 from './client/directives/formatDate/formatDate.html';
import _452 from './client/directives/inputfiles/inputfiles.html';
import _453 from './client/directives/subscribe/panel/subscribePanel.html';
import _454 from './client/directives/subscribe/subscribe.html';
import _455 from './client/views/carousel.html';
import _456 from './client/views/dialog/dialogAddress.html';
import _457 from './client/views/dialog/dialogAnswer.html';
import _458 from './client/views/dialog/dialogCloseSession.html';
import _459 from './client/views/dialog/dialogConfirm.html';
import _460 from './client/views/dialog/dialogInfo.html';
import _461 from './client/views/dialog/dialogInfoHtml.html';
import _462 from './client/views/dialog/dialogOperation.html';
import _463 from './client/views/dialog/dialogPrompt.html';
import _464 from './client/views/dialog/dialogReject.html';
import _465 from './client/views/factoringcarousel.html';
import _466 from './client/views/freshdoc.html';
import _467 from './client/views/subscription/subscriptionButtons.html';
import _468 from './client/views/subscription/subscriptionNews.html';
import _469 from './client/views/tabletLogin.html';
import _470 from './getbg/index.html';
import _471 from './vendor/bootstrap/choices.tpl.html';
import _472 from './vendor/bootstrap/match-multiple.tpl.html';
import _473 from './vendor/bootstrap/match.tpl.html';
import _474 from './vendor/bootstrap/no-choice.tpl.html';
import _475 from './vendor/bootstrap/select-multiple.tpl.html';
import _476 from './vendor/bootstrap/select.tpl.html';

export default {
	'app/components/about/about.html' : _0,
	'app/components/bank/bank.html' : _1,
	'app/components/bankGuaranteeApp/bankGuaranteeApp.html' : _2,
	'app/components/bankGuaranteeApp/formCreator/bails.html' : _3,
	'app/components/bankGuaranteeApp/formCreator/bailsCompany.html' : _4,
	'app/components/bankGuaranteeApp/formCreator/bailsPerson.html' : _5,
	'app/components/bankGuaranteeApp/formCreator/bankGuaranteeFormCreator.html' : _6,
	'app/components/bankGuaranteeApp/formCreator/beneficiaries.html' : _7,
	'app/components/bankGuaranteeApp/formCreator/beneficiariesLoko.html' : _8,
	'app/components/bankGuaranteeApp/formCreator/collegialExecutiveBodyMember.html' : _9,
	'app/components/bankGuaranteeApp/formCreator/companyBeneficiaries.html' : _10,
	'app/components/bankGuaranteeApp/formCreator/companyBeneficiariesLoko.html' : _11,
	'app/components/bankGuaranteeApp/formCreator/companyBeneficiariesVTB.html' : _12,
	'app/components/bankGuaranteeApp/formCreator/founders.html' : _13,
	'app/components/bankGuaranteeApp/formCreator/foundersCompanies.html' : _14,
	'app/components/bankGuaranteeApp/formCreator/foundersCompaniesLoko.html' : _15,
	'app/components/bankGuaranteeApp/formCreator/foundersCompaniesVTB.html' : _16,
	'app/components/bankGuaranteeApp/formCreator/foundersLoko.html' : _17,
	'app/components/bankGuaranteeApp/formCreator/foundersPersons.html' : _18,
	'app/components/bankGuaranteeApp/formCreator/foundersPersonsLoko.html' : _19,
	'app/components/bankGuaranteeApp/formCreator/foundersPersonsVTB.html' : _20,
	'app/components/bankGuaranteeApp/formCreator/foundersVTB.html' : _21,
	'app/components/bankGuaranteeApp/formCreator/liabilities.html' : _22,
	'app/components/bankGuaranteeApp/formCreator/mainCustomers.html' : _23,
	'app/components/bankGuaranteeApp/formCreator/mainProviders.html' : _24,
	'app/components/bankGuaranteeApp/formCreator/paymentPlan.html' : _25,
	'app/components/bankGuaranteeApp/formCreator/principalBuhPeriods.html' : _26,
	'app/components/bankGuaranteeApp/formCreator/supervisoryBoardMember.html' : _27,
	'app/components/bankGuaranteeApp/tab/accountant.html' : _28,
	'app/components/bankGuaranteeApp/tab/bankGuarantee.html' : _29,
	'app/components/bankGuaranteeApp/tab/companyBeneficiaries.html' : _30,
	'app/components/bankGuaranteeApp/tab/companyHead.html' : _31,
	'app/components/bankGuaranteeApp/tab/companyInfo.html' : _32,
	'app/components/bankGuaranteeApp/tab/companyManagement.html' : _33,
	'app/components/bankGuaranteeApp/tab/contactPerson.html' : _34,
	'app/components/bankGuaranteeApp/tab/documents.html' : _35,
	'app/components/bankGuaranteeApp/tab/founders.html' : _36,
	'app/components/bankGuaranteeApp/tab/liabilities.html' : _37,
	'app/components/bankGuaranteeApp/tab/mainCustomersProviders.html' : _38,
	'app/components/bankGuaranteeApp/tab/mainInfo.html' : _39,
	'app/components/bankGuaranteeApp/tab/principalBuh.html' : _40,
	'app/components/bankGuaranteeApp/tab/principalSigner.html' : _41,
	'app/components/bankGuaranteeApp/tab/staffInfo.html' : _42,
	'app/components/bankGuaranteeApp/tabPrimary/bankGuarantee.html' : _43,
	'app/components/bankGuaranteeApp/tabPrimary/mainInfo.html' : _44,
	'app/components/bankGuaranteeAppLoko/bankGuaranteeAppLoko.html' : _45,
	'app/components/bankGuaranteeAppLoko/tab/additionalInfo.html' : _46,
	'app/components/bankGuaranteeAppLoko/tab/agreement.html' : _47,
	'app/components/bankGuaranteeAppLoko/tab/bankGuarantee.html' : _48,
	'app/components/bankGuaranteeAppLoko/tab/companyBeneficiaries.html' : _49,
	'app/components/bankGuaranteeAppLoko/tab/companyHead.html' : _50,
	'app/components/bankGuaranteeAppLoko/tab/companyManagement.html' : _51,
	'app/components/bankGuaranteeAppLoko/tab/contactPerson.html' : _52,
	'app/components/bankGuaranteeAppLoko/tab/documents.html' : _53,
	'app/components/bankGuaranteeAppLoko/tab/founders.html' : _54,
	'app/components/bankGuaranteeAppLoko/tab/mainInfo.html' : _55,
	'app/components/bankGuaranteeAppLoko/tab/staffInfo.html' : _56,
	'app/components/bankGuaranteeAppVTB/bankGuaranteeAppVTB.html' : _57,
	'app/components/bankGuaranteeAppVTB/tab/accountant.html' : _58,
	'app/components/bankGuaranteeAppVTB/tab/bankGuarantee.html' : _59,
	'app/components/bankGuaranteeAppVTB/tab/companyBeneficiaries.html' : _60,
	'app/components/bankGuaranteeAppVTB/tab/companyHead.html' : _61,
	'app/components/bankGuaranteeAppVTB/tab/companyInfo.html' : _62,
	'app/components/bankGuaranteeAppVTB/tab/companyManagement.html' : _63,
	'app/components/bankGuaranteeAppVTB/tab/contactPerson.html' : _64,
	'app/components/bankGuaranteeAppVTB/tab/documents.html' : _65,
	'app/components/bankGuaranteeAppVTB/tab/founders.html' : _66,
	'app/components/bankGuaranteeAppVTB/tab/liabilities.html' : _67,
	'app/components/bankGuaranteeAppVTB/tab/mainCustomersProviders.html' : _68,
	'app/components/bankGuaranteeAppVTB/tab/mainInfo.html' : _69,
	'app/components/bankGuaranteeAppVTB/tab/principalBuh.html' : _70,
	'app/components/bankGuaranteeAppVTB/tab/principalSigner.html' : _71,
	'app/components/bankGuaranteeAppVTB/tab/staffInfo.html' : _72,
	'app/components/bankGuaranteeCalc/bankGuaranteeCalc.html' : _73,
	'app/components/bankGuaranteeChat/bankGuaranteeChat.html' : _74,
	'app/components/bankGuaranteeLokoChat/bankGuaranteeLokoChat.html' : _75,
	'app/components/bankGuaranteeVTBChat/bankGuaranteeVTBChat.html' : _76,
	'app/components/bankGuarantees/bankGuarantees.html' : _77,
	'app/components/bankLoko/bankLoko.html' : _78,
	'app/components/bankRaiffeisen/bankRaiffeisen.html' : _79,
	'app/components/bankVTBLanding/bankVTBLanding.html' : _80,
	'app/components/becomeCustomer/becomeCustomer_etp.html' : _81,
	'app/components/becomeCustomer/becomeCustomer_ppk.html' : _82,
	'app/components/becomeSupplier/becomeSupplier_etp.html' : _83,
	'app/components/bgAgent/becomeBgAgent/becomeBgAgent.html' : _84,
	'app/components/bgAgent/bgAdvertising/bgAdvertising.html' : _85,
	'app/components/bgAgent/bgAgent/agentOrg/agentEdit/agentEditor.html' : _86,
	'app/components/bgAgent/bgAgent/agentOrg/agentList.html' : _87,
	'app/components/bgAgent/bgAgent/agentOrg/agents.html' : _88,
	'app/components/bgAgent/bgAgent/bgAgent.html' : _89,
	'app/components/bgAgent/bgAgent/reqOrganizator/reqOrganizator.html' : _90,
	'app/components/bgAgent/consent/bgAgents.html' : _91,
	'app/components/bgBank/bgBank/bgBank.html' : _92,
	'app/components/bgBank/bgBank/bgBankAgents/bgBankAgentEditor/bgBankAgentEditor.html' : _93,
	'app/components/bgBank/bgBank/bgBankAgents/bgBankAgents.html' : _94,
	'app/components/bgBank/bgBank/bgBankReq/bgBankReq.html' : _95,
	'app/components/bgParticipants/bgParticipants.html' : _96,
	'app/components/bgParticipants/limit/limitList.html' : _97,
	'app/components/bgParticipants/order/orderList.html' : _98,
	'app/components/bgParticipants/order/orderListLoko.html' : _99,
	'app/components/bgParticipants/order/orderListRaif.html' : _100,
	'app/components/bgParticipants/order/orderListVtb.html' : _101,
	'app/components/cart/views/cart.html' : _102,
	'app/components/cart/views/cartCounter.html' : _103,
	'app/components/completedWorks/completedWork.html' : _104,
	'app/components/completedWorks/table/completedWorksTable.html' : _105,
	'app/components/contacts/contacts_etp.html' : _106,
	'app/components/contacts/contacts_ppk.html' : _107,
	'app/components/coveringLetter/coveringLetter.html' : _108,
	'app/components/customEisPacket/customEisPacket.html' : _109,
	'app/components/customer/customer.html' : _110,
	'app/components/customer/customerEisIntegration/branches/branchEditor.html' : _111,
	'app/components/customer/customerEisIntegration/customerBranches.html' : _112,
	'app/components/customer/customerEisIntegration/customerEisIntegration.html' : _113,
	'app/components/customer/customerEisIntegration/methodEdit/methodEditor.html' : _114,
	'app/components/customer/customerRequisites/customerRequisites.html' : _115,
	'app/components/customer/departmentEdit/departmentEdit.html' : _116,
	'app/components/customer/functionCustomerEdit/functionCustomerEdit.html' : _117,
	'app/components/customer/registration/customerRegistration.html' : _118,
	'app/components/customer/registration/customerRegistrationForm.html' : _119,
	'app/components/customer/registration/customerRegistrationOpen.html' : _120,
	'app/components/docHistory/docHistoryView.html' : _121,
	'app/components/documents/documents.html' : _122,
	'app/components/documents/documents_etp.html' : _123,
	'app/components/documents/documents_ppk.html' : _124,
	'app/components/explanation/answer/answerExplanation.html' : _125,
	'app/components/explanation/answer/answerReady/answerReady.html' : _126,
	'app/components/explanation/email/answerExplanationEmail.html' : _127,
	'app/components/explanation/request/reqExplanation.html' : _128,
	'app/components/explanation/request/reqExplanationForCbz.html' : _129,
	'app/components/explanationOrg/answer/answerExplanationOrg.html' : _130,
	'app/components/explanationOrg/request/reqExplanationOrg.html' : _131,
	'app/components/factoring/factoring.html' : _132,
	'app/components/fas/fasArchive.html' : _133,
	'app/components/fas/fasListArchive/fasListArchive.html' : _134,
	'app/components/feedbackForm/views/becomeCustomer.html' : _135,
	'app/components/feedbackForm/views/contacts.html' : _136,
	'app/components/feedbackForm/views/createBankGuaranteeApp.html' : _137,
	'app/components/feedbackForm/views/feedback.html' : _138,
	'app/components/feedbackForm/views/feedbackSent.html' : _139,
	'app/components/feedbackForm/views/guarantees.html' : _140,
	'app/components/feedbackForm/views/kinoandpravo.html' : _141,
	'app/components/filter/searchFilter.html' : _142,
	'app/components/filter/viewsFilter/filter.html' : _143,
	'app/components/filter/viewsFilter/filterAccreditation.html' : _144,
	'app/components/filter/viewsFilter/filterBgLimitParticipant.html' : _145,
	'app/components/filter/viewsFilter/filterCabProcedures.html' : _146,
	'app/components/filter/viewsFilter/filterCompanies.html' : _147,
	'app/components/filter/viewsFilter/filterCompanyValidateContracts.html' : _148,
	'app/components/filter/viewsFilter/filterCompletedWorks.html' : _149,
	'app/components/filter/viewsFilter/filterCustomer.html' : _150,
	'app/components/filter/viewsFilter/filterCustomerRegistration.html' : _151,
	'app/components/filter/viewsFilter/filterEisIntegrationProc.html' : _152,
	'app/components/filter/viewsFilter/filterEisMonitoring.html' : _153,
	'app/components/filter/viewsFilter/filterExplanationRequests.html' : _154,
	'app/components/filter/viewsFilter/filterInvoice.html' : _155,
	'app/components/filter/viewsFilter/filterMyOffers.html' : _156,
	'app/components/filter/viewsFilter/filterNotices.html' : _157,
	'app/components/filter/viewsFilter/filterOffers.html' : _158,
	'app/components/filter/viewsFilter/filterOrder.html' : _159,
	'app/components/filter/viewsFilter/filterOrganization.html' : _160,
	'app/components/filter/viewsFilter/filterOrganizator.html' : _161,
	'app/components/filter/viewsFilter/filterParticipant.html' : _162,
	'app/components/filter/viewsFilter/filterParticipateInPurchases.html' : _163,
	'app/components/filter/viewsFilter/filterPaymentOrder.html' : _164,
	'app/components/filter/viewsFilter/filterPosition.html' : _165,
	'app/components/filter/viewsFilter/filterPriceAnalysis.html' : _166,
	'app/components/filter/viewsFilter/filterProcedures.html' : _167,
	'app/components/filter/viewsFilter/filterProcedures44.html' : _168,
	'app/components/filter/viewsFilter/filterProceduresAll.html' : _169,
	'app/components/filter/viewsFilter/filterProceduresOther.html' : _170,
	'app/components/filter/viewsFilter/filterProceduresSOC.html' : _171,
	'app/components/filter/viewsFilter/filterPublicParticipant.html' : _172,
	'app/components/filter/viewsFilter/filterPurchasePlan.html' : _173,
	'app/components/filter/viewsFilter/filterQuestionAnswer.html' : _174,
	'app/components/filter/viewsFilter/filterStatement.html' : _175,
	'app/components/filter/viewsFilter/filterUC.html' : _176,
	'app/components/footer/etp.html' : _177,
	'app/components/footer/footer.html' : _178,
	'app/components/footer/ppk.html' : _179,
	'app/components/header/cabinet/cabinet.html' : _180,
	'app/components/header/cabinet/etp.html' : _181,
	'app/components/header/cabinet/ppk.html' : _182,
	'app/components/header/headerMsg/headerMsg.html' : _183,
	'app/components/header/headerToolbar.html' : _184,
	'app/components/header/headerViews/dialogTemplates.html' : _185,
	'app/components/header/headerViews/etp.html' : _186,
	'app/components/header/headerViews/ppk.html' : _187,
	'app/components/help/documentSamples/documentSamplesCreate.html' : _188,
	'app/components/help/questionAnswer/questionAnswerCreate.html' : _189,
	'app/components/help/questionAnswer/questionAnswerHomeView.html' : _190,
	'app/components/help/remoteSupport/remoteSupport.html' : _191,
	'app/components/home/etp.html' : _192,
	'app/components/home/ppk.html' : _193,
	'app/components/invoice/invoice.html' : _194,
	'app/components/invoice/table/invoiceTable.html' : _195,
	'app/components/invoiceRequestForm/invoiceRequestForm.html' : _196,
	'app/components/lk/lk.html' : _197,
	'app/components/lk/lkinfo/etp.html' : _198,
	'app/components/lk/lkinfo/lkinfo-etp.html' : _199,
	'app/components/lk/lkinfo/ppk.html' : _200,
	'app/components/lk/notification/lknotification.html' : _201,
	'app/components/lk/notification/lknotificationmodal.html' : _202,
	'app/components/login/loginPanel.html' : _203,
	'app/components/login/loginPanelMobile.html' : _204,
	'app/components/login/webmarketLogin.html' : _205,
	'app/components/mailingLists/mailingLists.html' : _206,
	'app/components/news/homeNews/homeNews.html' : _207,
	'app/components/news/homeNews/homeNewsPpk.html' : _208,
	'app/components/news/homeNews/topNewsPpk.html' : _209,
	'app/components/news/news.html' : _210,
	'app/components/news/newsBlock/newsBlock.html' : _211,
	'app/components/news/newsCreate/newsCreate.html' : _212,
	'app/components/newsletters/newsletterCreate/newsletterCreate.html' : _213,
	'app/components/notice/documentation/documentation.html' : _214,
	'app/components/notice/extendNoticeField/extendNoticeField.html' : _215,
	'app/components/notice/lot/depositPaymentDetails.html' : _216,
	'app/components/notice/lot/lot.html' : _217,
	'app/components/notice/lots/lots.html' : _218,
	'app/components/notice/nomenclature/nomenclature.html' : _219,
	'app/components/notice/notice.html' : _220,
	'app/components/notice/procedure/procedure.html' : _221,
	'app/components/notification/notificationForm/notificationPURCHASE_REJECTION.html' : _222,
	'app/components/notification/notificationForm/notificationTRANSFER_TENDERS.html' : _223,
	'app/components/offer/views/offerCreate.html' : _224,
	'app/components/offer/views/offerOpen.html' : _225,
	'app/components/offer/views/offerView.html' : _226,
	'app/components/order/views/orderCreate.html' : _227,
	'app/components/order/views/orderOpen.html' : _228,
	'app/components/order/views/orderView.html' : _229,
	'app/components/order/views/positions.html' : _230,
	'app/components/organizator/agentOrg/agentEdit/agentEditor.html' : _231,
	'app/components/organizator/agentOrg/agentList.html' : _232,
	'app/components/organizator/agentOrg/agents.html' : _233,
	'app/components/organizator/customersOrg/customersOrg.html' : _234,
	'app/components/organizator/organizator.html' : _235,
	'app/components/organizator/reqOrganizator/reqOrganizator.html' : _236,
	'app/components/pagination/pagination.html' : _237,
	'app/components/participant/participant.html' : _238,
	'app/components/participant/participantAccreditation/accreditationList.html' : _239,
	'app/components/participant/participantAgents/participantAgentView/participantAgentView.html' : _240,
	'app/components/participant/participantAgents/participantAgents.html' : _241,
	'app/components/participant/participantRequisites/participantRequisites.html' : _242,
	'app/components/participate/msp223/participate.html' : _243,
	'app/components/participate/participate.html' : _244,
	'app/components/participationInPurchases/participationInPurchases.html' : _245,
	'app/components/paymentOrder/confirmPayment/confirmPayment.html' : _246,
	'app/components/paymentOrder/paymentOrder.html' : _247,
	'app/components/paymentOrder/paymentOrderOperations.html' : _248,
	'app/components/paymentOrder/processingForm/linkedInvoice.html' : _249,
	'app/components/paymentOrder/table/paymentOrdersTable.html' : _250,
	'app/components/paymentOrder/tips.html' : _251,
	'app/components/placeholderCards/placeholderCards.html' : _252,
	'app/components/position/positionOffer.html' : _253,
	'app/components/positionElement/positionElement.html' : _254,
	'app/components/positionsImg/positionsImg.html' : _255,
	'app/components/preview/preview.html' : _256,
	'app/components/preview/previewModal.html' : _257,
	'app/components/privacy/privacy.html' : _258,
	'app/components/procedures/auction/auction.html' : _259,
	'app/components/procedures/cancelLots/informationAboutCancelLots.html' : _260,
	'app/components/procedures/claims/claims.html' : _261,
	'app/components/procedures/contract/contract.html' : _262,
	'app/components/procedures/contract/contractPaper.html' : _263,
	'app/components/procedures/contract/contractReject.html' : _264,
	'app/components/procedures/explanations/explanations.html' : _265,
	'app/components/procedures/noticePostponement/noticePostponementProcedure.html' : _266,
	'app/components/procedures/priceOffers/priceOffers.html' : _267,
	'app/components/procedures/protocol/additional/additionalProtocol.html' : _268,
	'app/components/procedures/protocol/notificationProtocol.html' : _269,
	'app/components/procedures/protocol/protocol.html' : _270,
	'app/components/procedures/protocol/protocolForCbz.html' : _271,
	'app/components/procedures/rebiddings/rebiddings.html' : _272,
	'app/components/procedures/reject/informationAboutRejectProcedure.html' : _273,
	'app/components/procedures/reqExplanations/reqExplanations.html' : _274,
	'app/components/procedures/reqExplanations/reqExplanationsForCbz.html' : _275,
	'app/components/procedures/reqExplanationsOrg/reqExplanationsOrg.html' : _276,
	'app/components/procedures/returnClaims/returnClaims.html' : _277,
	'app/components/procedures/statistics/statistics.html' : _278,
	'app/components/procedures/statistics/statisticsViews.html' : _279,
	'app/components/procedures/views/procedures.html' : _280,
	'app/components/procedures/views/procedures44.html' : _281,
	'app/components/procedures/views/proceduresForCbz.html' : _282,
	'app/components/proceduresEIS/eisAddMethod/addEisMethod.html' : _283,
	'app/components/proceduresEIS/eisIntegrationProcEdit/eisIntegrationProcEdit.html' : _284,
	'app/components/proceduresEIS/procedures.html' : _285,
	'app/components/profile/accreditation/accelerationAccreditation/accelerationAccreditation.html' : _286,
	'app/components/profile/accreditation/accreditation.html' : _287,
	'app/components/profile/accreditation/accreditationEdit/accreditation.html' : _288,
	'app/components/profile/accreditation/accreditationList.html' : _289,
	'app/components/profile/actions/actionsProfile.html' : _290,
	'app/components/profile/actions/cabType/actionsOrganizerCab.html' : _291,
	'app/components/profile/agents/agentEdit/agentEditor.html' : _292,
	'app/components/profile/agents/agentList.html' : _293,
	'app/components/profile/agents/agents.html' : _294,
	'app/components/profile/customers/customersProfile.html' : _295,
	'app/components/profile/filiation/filiation.html' : _296,
	'app/components/profile/filiation/filiationEdit/filiationEditor.html' : _297,
	'app/components/profile/filiation/filiationList.html' : _298,
	'app/components/profile/personalAccount/applicationRefund.html' : _299,
	'app/components/profile/personalAccount/cashFlowInfo.html' : _300,
	'app/components/profile/personalAccount/operationAccountTable.html' : _301,
	'app/components/profile/personalAccount/personalAccount.html' : _302,
	'app/components/profile/personalAccount/revise.html' : _303,
	'app/components/profile/personalAccount/view/personalAccountAccountant.html' : _304,
	'app/components/profile/personalAccount/view/personalAccountDefault.html' : _305,
	'app/components/profile/personalAccount/withdrawalOfFunds.html' : _306,
	'app/components/profile/profile.html' : _307,
	'app/components/profile/pwdChange/pwdChange.html' : _308,
	'app/components/profile/requisites/requisites.html' : _309,
	'app/components/profile/requisites/requisitionCabType/requisitesAccountant.html' : _310,
	'app/components/profile/requisites/requisitionCabType/requisitesAdminCab.html' : _311,
	'app/components/profile/requisites/requisitionCabType/requisitesAgent.html' : _312,
	'app/components/profile/requisites/requisitionCabType/requisitesInspector.html' : _313,
	'app/components/profile/requisites/requisitionCabType/requisitesOrganizerCab.html' : _314,
	'app/components/profile/requisites/requisitionCabType/requisitesParticipant.html' : _315,
	'app/components/profile/userInfo/userInfo.html' : _316,
	'app/components/profile/versionInfo/versionInfo.html' : _317,
	'app/components/protocol/msp223/protocolADDITIONAL.html' : _318,
	'app/components/protocol/msp223/protocolOPEN.html' : _319,
	'app/components/protocol/msp223/protocolPART1.html' : _320,
	'app/components/protocol/msp223/protocolPART2.html' : _321,
	'app/components/protocol/msp223/protocolPARTS.html' : _322,
	'app/components/protocol/msp223/protocolREBIDDING_NOTICE.html' : _323,
	'app/components/protocol/msp223/protocolREZ.html' : _324,
	'app/components/protocol/protocol.html' : _325,
	'app/components/protocol/protocolAll.html' : _326,
	'app/components/protocol/protocolAuction.html' : _327,
	'app/components/purchasePlanUpdate/purchasePlanUpdate.html' : _328,
	'app/components/regionSelector/regionSelector.html' : _329,
	'app/components/registration/registration.html' : _330,
	'app/components/rekl/rekl.html' : _331,
	'app/components/reports/accountTypes.html' : _332,
	'app/components/reports/analyticReports.html' : _333,
	'app/components/reports/data.html' : _334,
	'app/components/reports/orgs.html' : _335,
	'app/components/reports/period.html' : _336,
	'app/components/reports/periodDates.html' : _337,
	'app/components/reports/procTypes.html' : _338,
	'app/components/reports/reportsLokoBank/reportsLokoBank.html' : _339,
	'app/components/reports/types/averageSavingReport.html' : _340,
	'app/components/reports/types/balanceReport.html' : _341,
	'app/components/reports/types/blockingReport.html' : _342,
	'app/components/reports/types/customersReport.html' : _343,
	'app/components/reports/types/declaredLotsReport.html' : _344,
	'app/components/reports/types/openInvoiceReport.html' : _345,
	'app/components/reports/types/participantsReport.html' : _346,
	'app/components/reports/types/paymentOrdersReport.html' : _347,
	'app/components/reports/types/postedInfoReport.html' : _348,
	'app/components/reports/types/ppkTariffReport.html' : _349,
	'app/components/reports/types/socAtcomReport.html' : _350,
	'app/components/reports/types/tariffUsageReport.html' : _351,
	'app/components/reports/types/tariffsReport.html' : _352,
	'app/components/restorePassword/restorePassword.html' : _353,
	'app/components/rfGuaranteeApp/rfGuaranteeApp.html' : _354,
	'app/components/servicesPage/servicesPage.html' : _355,
	'app/components/servicesPage/servicesPageAgent.html' : _356,
	'app/components/startPageSearch/etp.html' : _357,
	'app/components/startPageSearch/ppk.html' : _358,
	'app/components/statement/table/statementTable.html' : _359,
	'app/components/subscription/subscription.html' : _360,
	'app/components/systemMessage/counter.html' : _361,
	'app/components/systemMessage/explreqs.html' : _362,
	'app/components/systemMessage/systemMessage.html' : _363,
	'app/components/tariffsServices/accelAccred/accelAccred_etp.html' : _364,
	'app/components/tariffsServices/accelAccred/accelAccred_ppk.html' : _365,
	'app/components/tariffsServices/financialService.html' : _366,
	'app/components/tariffsServices/securingZmo/securingZmo.html' : _367,
	'app/components/tariffsServices/tariffs/tariffsHomePage_etp.html' : _368,
	'app/components/tariffsServices/tariffs/tariffsHomePage_ppk.html' : _369,
	'app/components/tariffsServices/tariffs/tariffsMenu.html' : _370,
	'app/components/tariffsServices/tariffs/tariffsProfile_etp.html' : _371,
	'app/components/tariffsServices/tariffs/tariffsProfile_ppk.html' : _372,
	'app/components/tender/tender.html' : _373,
	'app/components/versionInfos/versionCreate/versionCreate.html' : _374,
	'app/components/video/video.html' : _375,
	'app/components/widgets/tassBusiness/companyValidate/view/baseView.html' : _376,
	'app/components/widgets/tassBusiness/companyValidate/view/blockedAccounts/blockedAccounts.html' : _377,
	'app/components/widgets/tassBusiness/companyValidate/view/contracts/contracts.html' : _378,
	'app/components/widgets/tassBusiness/companyValidate/view/contracts/template/FZ-223.html' : _379,
	'app/components/widgets/tassBusiness/companyValidate/view/contracts/template/FZ-44.html' : _380,
	'app/components/widgets/tassBusiness/companyValidate/view/customers/customers.html' : _381,
	'app/components/widgets/tassBusiness/companyValidate/view/finReport/finReport.html' : _382,
	'app/components/widgets/tassBusiness/companyValidate/view/providers/providers.html' : _383,
	'app/components/widgets/tassBusiness/companyValidate/view/reporting/reporting.html' : _384,
	'app/components/widgets/tassBusiness/companyValidate/view/taxes/taxes.html' : _385,
	'app/components/widgets/tassBusiness/companyValidate/view/verification/elementVerification/elementVerification.html' : _386,
	'app/components/widgets/tassBusiness/companyValidate/view/verification/verification.html' : _387,
	'app/controllers/restorePassword/changePassword.html' : _388,
	'app/controllers/restorePassword/restorePassword.html' : _389,
	'app/controllers/tables/viewsTable/accreditation.html' : _390,
	'app/controllers/tables/viewsTable/allProcedures.html' : _391,
	'app/controllers/tables/viewsTable/bankGuarantees.html' : _392,
	'app/controllers/tables/viewsTable/bankGuaranteesLoko.html' : _393,
	'app/controllers/tables/viewsTable/bankGuaranteesVTB.html' : _394,
	'app/controllers/tables/viewsTable/bgAgents.html' : _395,
	'app/controllers/tables/viewsTable/bgBanks.html' : _396,
	'app/controllers/tables/viewsTable/commercialProcedures.html' : _397,
	'app/controllers/tables/viewsTable/customer.html' : _398,
	'app/controllers/tables/viewsTable/customerRegistrationTable.html' : _399,
	'app/controllers/tables/viewsTable/documentSamplesHomeTable.html' : _400,
	'app/controllers/tables/viewsTable/documentSamplesTable.html' : _401,
	'app/controllers/tables/viewsTable/eisIntegrationsProc.html' : _402,
	'app/controllers/tables/viewsTable/eisMonitoring.html' : _403,
	'app/controllers/tables/viewsTable/explanationList.html' : _404,
	'app/controllers/tables/viewsTable/explanationReqList.html' : _405,
	'app/controllers/tables/viewsTable/getds.html' : _406,
	'app/controllers/tables/viewsTable/invoiceTable.html' : _407,
	'app/controllers/tables/viewsTable/jointProcedures.html' : _408,
	'app/controllers/tables/viewsTable/myProcedures.html' : _409,
	'app/controllers/tables/viewsTable/newsTable.html' : _410,
	'app/controllers/tables/viewsTable/newsletters.html' : _411,
	'app/controllers/tables/viewsTable/notices.html' : _412,
	'app/controllers/tables/viewsTable/offers.html' : _413,
	'app/controllers/tables/viewsTable/offersMy.html' : _414,
	'app/controllers/tables/viewsTable/orders.html' : _415,
	'app/controllers/tables/viewsTable/ordersMy.html' : _416,
	'app/controllers/tables/viewsTable/organizators.html' : _417,
	'app/controllers/tables/viewsTable/otherProcedures.html' : _418,
	'app/controllers/tables/viewsTable/participants.html' : _419,
	'app/controllers/tables/viewsTable/paymentOrders.html' : _420,
	'app/controllers/tables/viewsTable/positions.html' : _421,
	'app/controllers/tables/viewsTable/positionsImg.html' : _422,
	'app/controllers/tables/viewsTable/positions_old.html' : _423,
	'app/controllers/tables/viewsTable/priceAnalysisSubTable.html' : _424,
	'app/controllers/tables/viewsTable/priceAnalysisTable.html' : _425,
	'app/controllers/tables/viewsTable/procedures.html' : _426,
	'app/controllers/tables/viewsTable/protocolNotifications.html' : _427,
	'app/controllers/tables/viewsTable/publicParticipant.html' : _428,
	'app/controllers/tables/viewsTable/publicProcedures.html' : _429,
	'app/controllers/tables/viewsTable/publicProcedures44.html' : _430,
	'app/controllers/tables/viewsTable/publicProceduresSOC.html' : _431,
	'app/controllers/tables/viewsTable/purchasePlan.html' : _432,
	'app/controllers/tables/viewsTable/questionAnswerHomeTable.html' : _433,
	'app/controllers/tables/viewsTable/questionAnswerTable.html' : _434,
	'app/controllers/tables/viewsTable/rfGuarantees.html' : _435,
	'app/controllers/tables/viewsTable/specialAccount.html' : _436,
	'app/controllers/tables/viewsTable/subscriptionTable.html' : _437,
	'app/controllers/tables/viewsTable/systemMessages.html' : _438,
	'app/controllers/tables/viewsTable/uc.html' : _439,
	'app/controllers/tables/viewsTable/versionTable.html' : _440,
	'app/controllers/uc/uc-PAK-table.html' : _441,
	'app/controllers/uc/uc-base.html' : _442,
	'app/controllers/uc/uc-infoAUC-edit.html' : _443,
	'app/directives/actionsBtn/actionsBtn.html' : _444,
	'app/directives/backBtn/backBtn.html' : _445,
	'app/directives/bunkGuarantees/bunkGuarantees.html' : _446,
	'app/directives/carouselPurchase/carouselPurchase.html' : _447,
	'app/directives/counter300Years/counter300Years.html' : _448,
	'app/directives/dragdropPanel/dragdropPanel.html' : _449,
	'app/directives/extendField/extendField.html' : _450,
	'app/directives/formatDate/formatDate.html' : _451,
	'app/directives/inputfiles/inputfiles.html' : _452,
	'app/directives/subscribe/panel/subscribePanel.html' : _453,
	'app/directives/subscribe/subscribe.html' : _454,
	'views/carousel.html' : _455,
	'views/dialog/dialogAddress.html' : _456,
	'views/dialog/dialogAnswer.html' : _457,
	'views/dialog/dialogCloseSession.html' : _458,
	'views/dialog/dialogConfirm.html' : _459,
	'views/dialog/dialogInfo.html' : _460,
	'views/dialog/dialogInfoHtml.html' : _461,
	'views/dialog/dialogOperation.html' : _462,
	'views/dialog/dialogPrompt.html' : _463,
	'views/dialog/dialogReject.html' : _464,
	'views/factoringcarousel.html' : _465,
	'views/freshdoc.html' : _466,
	'views/subscription/subscriptionButtons.html' : _467,
	'views/subscription/subscriptionNews.html' : _468,
	'views/tabletLogin.html' : _469,
	'appindex.html' : _470,
	'bootstrap/choices.tpl.html' : _471,
	'bootstrap/match-multiple.tpl.html' : _472,
	'bootstrap/match.tpl.html' : _473,
	'bootstrap/no-choice.tpl.html' : _474,
	'bootstrap/select-multiple.tpl.html' : _475,
	'bootstrap/select.tpl.html' : _476,
};